import GatePassInOut from "../Pages/GatePassInOut";
import EventCustomApprove from "../Pages/HR/EventManagement/CustomAprove/EventCustomApprove";

export const ROUTES = {
  dashboard: "/dashboard",
  developersCentre: "/developerscentre",
  developersConversation: "/developersConversation",
  mediaInfo: "/mediaInfo",
  ViewMediaInfo: "/ViewMediaInfo",
  //Start
  //Homeo Login

  ResearchandPublication: "/ResearchandPublication",

  //Homeo Academics -
  HomeopathyStudentsList: "/HomeopathyStudentsList",
  HomeopathyResults: "/HomeopathyResults",
  ViewHomeoStudentsList: "/ViewHomeoStudentsList",
  ViewHomeopathyResults: "/ViewHomeopathyResults",
  //
  CollegePermission: "/CollegePermission",
  ViewCollegePermission: "/ViewCollegePermission",
  AdmissionNotifications: "/AdmissionNotifications",

  ViewAdmissionNotifications: "/ViewAdmissionNotifications",
  UniversityNotifications: "/UniversityNotifications",
  ViewUniversityNotifications: "/ViewUniversityNotifications",
  EventCalendar: "/EventCalendar",
  ViewEventCalendar: "/ViewEventCalendar",
  Recruitment: "/Recruitment",
  ViewRecruitment: "/ViewRecruitment",

  ViewStaffDairy: "/ViewStaffDairy",

  ViewTickets: "/ViewTickets",

  homeoEvents: "/homeoEvents",
  IT: {
    leaveEntitlement: "/IT/leaveEntitlement",
    developersCentre: "/IT/ticketscentre",
    leaveApplication: "/IT/leaveApplication",
    leaveSchedule: "/IT/schedule",
    PayRoll: "/IT/SalaryView",
    AnnualStatement: "/IT/annualStatement",
    documentManagement: "/IT/DocumentManagement",
    teamDiscussion: "/IT/teamDiscussion",
    teamDocuments: "/IT/teamDocuments",
    teamAnnouncement: "/IT/teamAnnouncement",
  },
  FrontOffice: {
    Document: "/frontOffice/DocumentManagement",
    Attendance: "/frontOffice/attendance",
    AdmissionEnquiry: "/frontOffice/admissionEnquiry",
    VisitorsBook: "/frontOffice/visitorsBook",
    PhoneCallLog: "/frontOffice/phoneCallLog",
    PostalDispatch: "/frontOffice/postalDispatch",
    PostalRecieve: "/frontOffice/postalRecieve",
    // ViewPostalDispatchRecieve: "/frontOffice/viewpostaldispatchrecieve",
    Complain: "/frontOffice/complain",
    SetupOffice: "/frontOffice/setupOffice",
    PayRoll: {
      Salary: "/frontOffice/SalaryView",
      AnnualStatement: "/frontOffice/annualStatement",
      Payslip: "/frontOffice/payslip",
    },
    Leave: {
      Application: "/frontOffice/leaveApplication",
      Entitlement: "/frontOffice/leaveEntitlement",
      Schedule: "/frontOffice/schedule",
    },
    Team: {
      Discussion: "/frontOffice/teamDiscussion",
      Announcement: "/frontOffice/teamAnnouncement",
      Documents: "/frontOffice/teamDocuments",
    },
  },
  Admin: {
    toexamination: "/redirect/exam",
    dashboard: "/dashboard/admin",
    Ticket: "/admin/ticket",
    ticketConversation: "/admin/ticketConversation",
    frontOffice: {
      AdmissionEnquiry: "/admin/admissionEnquiry",
      VisitorsBook: "/admin/visitorsBook",
      PhoneCallLog: "/admin/phoneCallLog",
      PostalDispatch: "/admin/postalDispatch",
      PostalRecieve: "/admin/postalRecieve",
      Complain: "/admin/complain",
      SetupOffice: "/admin/setupOffice",
    },
    Admission: {
      AdmissionProfile: "/admin/admission/studentProfile",
      NewAdmission: "/admin/admission/newAdmission",
      AdmissionEnquiry: "/admin/admission/Enquirry",
      AdmissionDetails: "/admin/admission/Details",
      PhdAdmissions: "/phdAdmission",
      PhdAdmissionsprofile: "/phdAdmissionprofile",
      PhdAdmitCard: "/phdAdmitCard",
      PhdExamList: "/phdExamlist",
      PhdExamprofile: "/phdExamprofile",
      PhdExamAdmitCard: "/phdExamadmitcard",
    },
    Student: {
      StudentAdmission: "/studentAdmission",
      StudentDetails: "/studentDetails",
      StudentDetails1: "/studentDetails1",
      DisableStudents: "/disableStudents",
      EditStudentDetails: "/editStudentDetails",
      StudentProfile: "/studentProfile",
      ViewStudentProfile: "/viewStudentProfile",
    },
    Registar: {
      NAAC: {
        index: "/registarar/naac",
        dashboard: {
          activityTracking: "/registarar/naac/activity-tracking",
          performanceTracking: "/registarar/naac/performance-tracking",
          statusReport: "/registarar/naac/status-reports",
        },
        approvalFlow: "/registarar/naac/approval-flow",
        naacData: {
          executiveSummary: "/registarar/naac/executive-summary",
          executiveSummaryPdf: "/registarar/naac/executive-summary/pdf",
          profile: "/registarar/naac/profile",
          extendedProfile: "/registarar/naac/extended-profile",
          nepParameters: "/registarar/naac/nep-parameters",
          qifQualitative: "/registarar/naac/qif-qualitative",
          qifQuantative: "/registarar/naac/qif-quantitative",
          myDownloads: "/registarar/naac/my-downloads",
        },
      },
      dashboard: "/dashboard/registrar",

      frontOffice: {
        AdmissionEnquiry: "/registrar/admissionEnquiry",
        VisitorsBook: "/registrar/visitorsBook",
        PhoneCallLog: "/registrar/phoneCallLog",
        PostalDispatch: "/registrar/postalDispatch",
        PostalRecieve: "/registrar/postalRecieve",
        ViewPostalDispatchRecieve: "/registrar/viewpostaldispatchrecieve",
        Complain: "/registrar/complain",
        SetupOffice: "/registrar/setupOffice",
      },

      Student: {
        StudentAdmission: "/registrar/studentAdmission",
        StudentDetails: "/registrar/studentDetails",
        ViewAluminiStudents: "/registrar/viewaluministudents",
        DisableStudents: "/registrar/disableStudents",
        EditStudentDetails: "/registrar/editStudentDetails",
        StudentProfile: "/registrar/studentProfile",
        AdmissionEnquiry: "/registrar/studentadmissionEnquiry",
        AdmissionDetails: "/registrar/studentadmissionDetails",
        Exam: "/registrar/studentExam",
        Midterm: "/registrar/studentMidterm",
        Viva: "/registrar/studentViva",
      },
      Admission: {
        AdmissionProfile: "/registrar/admission/studentProfile",
        NewAdmission: "/registrar/admission/newAdmission",
        AdmissionEnquiry: "/registrar/admission/Enquirry",
        AdmissionDetails: "/registrar/admission/Details",
        PhdAdmissions: "/phdAdmission",
        PhdAdmissionsprofile: "/phdAdmissionprofile",
        PhdAdmitCard: "/phdAdmitCard",
        PhdExamList: "/phdExamlist",
        PhdExamprofile: "/phdExamprofile",
        PhdExamAdmitCard: "/phdExamadmitcard",
      },
      HR: {
        AddNewStaff: "/addNewStaff",
        ViewStaff: "/viewStaff",
        StaffAttendance: "/staffAttendance",
        TimeClockReport: "/attendanceTimeClockReport",
        AttendanceMonthEmployee: "/attendanceMonthEmployee",
        TodayAttendance: "/attendanceTodayAttendance",
        AddDesignation: "/addDesignation",
        AddDepartment: "/addDepartment",
        Feedback: "/feedback",
      },
      Accounts: {
        Income: {
          View: "/registrar/viewIncome",
          Add: "/registrar/addIncome",
          AddSource: "/registrar/addIncomeSource",
        },
        Expense: {
          View: "/registrar/viewExpense",
          Add: "/registrar/addExpense",
          AddSource: "/registrar/addExpenseSource",
        },
        FeeCollection: {
          CollegeFee: "/registrar/feeCollectionCollegeFee",
          SearchFee: "/registrar/feeCollectionSearchFee",
          SearchDue: "/registrar/feeCollectionSearchDue",
          FeeMaster: "/registrar/feeCollectionFeeMaster",
          FeeGroup: "/registrar/feeCollectionFeeGroup",
          FeeType: "/registrar/feeCollectionFeeType",
          FeeDiscount: "/registrar/feeCollectionFeeDiscount",
          FeeTypeAmount: "/registrar/feeCollectionFeeTypeAmount",
          AddFee: "/registrar/addFee",
          assignFee: "/registrar/assignFee",
          filterForFees: "/registrar/filters",
          Reports: "/registrar/feereports",
        },
      },
      Communicate: {
        Announcements: "/registrar/announcements",
        UpcomingEvents: "/registrar/upcoming-events",
      },
      Academics: {
        ClassTimeTable: "/registrar/classTimeTable",
        CreateTimeTable: "/registrar/createTimeTable",
        TeacherTimeTable: "/registrar/teacherTimeTable",
        PromoteStudents: "/registrar/promoteStudents",
        ChangeBranch: "/registrar/changeBranch",
        AddBatch: "/registrar/addBatch",
        AddSubject: "/registrar/addSubject",
        AddClass: "/registrar/addClass",
        ManageLessonPlan: "/registrar/manageLessonPlan",
        SyllabusStatus: "/registrar/syllabusStatus",
        AddTopic: "/registrar/addTopic",
        AddBloomsLevel: "/registrar/addBloomsLevel",
        CourseOutcomes: "/registrar/courseOutcomes",
        AddQuestionBank: "/registrar/addQuestionBank",
        AddLesson: "/registrar/addLesson",
        AddSemester: "/registrar/addSemester",
        AddSection: "/registrar/addSection",
        CourseRegistration: "/registrar/courseRegistration",
      },
      Attendance: {
        ClassAttendance: "/registrar/classAttendance",
        LabAttendance: "/registrar/labattendance",
        TodayAttendance: "/attendanceTodayAttendance",
      },
      Document: {
        UploadContent: "/registrar/documentUploadContent",
        DownloadContent: {
          Assignment: "/registrar/documentAssignment",
          Syllabus: "/registrar/documentSyllabus",
          Studymaterial: "/registrar/documentStudyMaterial",
          OtherDownload: "/registrar/documentOtherDownload",
        },
      },
      Transport: {
        Route: "/registrar/registrar/transportRoutes",
        Vehicles: "/registrar/registrar/transportVehicles",
        AssignVehicles: "/registrar/registrar/transportAssignVehicles",
      },
      Hostel: {
        HostelRooms: "/registrar/HostelRooms",
        RoomType: "/registrar/RoomTypes",
        Hostel: "/registrar/hostel",
      },
      Inventory: {
        IssueItems: "/registrar/IssueItemsList",
        AddItemstock: "/registrar/AddItemstock",
        AddItem: "/registrar/AddItem",
        ItemCategory: "/registrar/ItemCategory",
        ItemStore: "/registrar/ItemStore",
        ItemSupplier: "/registrar/ItemSupplier",
        IssueItem: "/registrar/IssueItem",
        PurchaseOrder: "/registrar/PurchaseOrder",
        CreatePurchaseOrder: "/registrar/PurchaseOrder/create",
      },
      Certificates: {
        DesignHallticket: "/registrar/DesignHallticket",
        DesignMarkscard: "/registrar/DesignMarkscard",
      },
      Library: {
        Books: "/Library/Books",
        Library_Settings: "/Library/Settings",
        Borrow: "/Library/Borrow",
        Reports: "/Library/Report",
        Dashboard: "/Library/Dashboard",
      },
      Reports: {
        Home: "/reg/reports",
        StudentDetails1: "studentDetails1",
        Fee: {
          Home: "/reg/reports/fee",
          BHA0: "/reg/reports/fee/bha0",
          BHA1: "/reg/reports/fee/bha1",
          BHA2: "/reg/reports/fee/bha2",
          BHA3: "/reg/reports/fee/bha3",
          BHA4: "/reg/reports/fee/bha4",
          BHA5: "/reg/reports/fee/bha5",
          BHA6: "/reg/reports/fee/bha6",
          Pending: "/reg/reports/fee/pendig",
          Transport: "/reg/reports/fee/transport",
          AdvancePayment: "/reg/reports/AdvancePayment",
          AdvancePaymentHome: "/reg/reports/AdvancePaymentHome",
          PendingCollegewise: "/reg/reports/fee/pendig-college-wise",
        },
        Staff: {
          Home: "/reg/reports/staff",
          Leave: "/reg/reports/staff/leave",
        },
      },
      LMS: {
        Create: "/reg/lms-create",
        View: "/reg/lms-list",
      },
    },
    Accounts: {
      Income: {
        View: "/viewIncome",
        Add: "/addIncome",
        AddSource: "/addIncomeSource",
      },
      Expense: {
        View: "/viewExpense",
        Add: "/addExpense",
        AddSource: "/addExpenseSource",
      },
      FeeCollection: {
        CollegeFee: "/feeCollectionCollegeFee",
        SearchFee: "/feeCollectionSearchFee",
        SearchDue: "/feeCollectionSearchDue",
        FeeMaster: "/feeCollectionFeeMaster",
        FeeGroup: "/feeCollectionFeeGroup",
        FeeType: "/feeCollectionFeeType",
        FeeDiscount: "/feeCollectionFeeDiscount",
      },
    },
    Academics: {
      ClassTimeTable: "/classTimeTable",
      CreateTimeTable: "/createTimeTable",

      TeacherTimeTable: "/teacherTimeTable",
      PromoteStudents: "/promoteStudents",
      ChangeBranch: "/changeBranch",
      AddBatch: "/addBatch",
      AssignStudents: "/assignStudents",
      ViewPracStudents: "/viewPracticalAssignedStudents",
      AddSubject: "/addSubject",
      AddClass: "/addClass",
      ManageLessonPlan: "/manageLessonPlan",
      SyllabusStatus: "/syllabusStatus",
      AddTopic: "/addTopic",
      AddBloomsLevel: "/addBloomsLevel",
      CourseOutcomes: "/courseOutcomes",
      AddLesson: "/addLesson",
      CourseRegistration: "/courseRegistration",
    },
    Document: {
      UploadContent: "/documentUploadContent",
      DownloadContent: {
        Assignment: "/documentAssignment",
        Syllabus: "/documentSyllabus",
        Studymaterial: "/documentStudyMaterial",
        OtherDownload: "/documentOtherDownload",
      },
    },
    Transport: {
      Route: "/transportRoutes",
      Vehicles: "/transportVehicles",
      AssignVehicles: "/transportAssignVehicles",
      pickupPoints: "/pickupPoints",
      transportFee: "/admin/transportFee",
      addtransportFee: "/admin/addtransportFee",
    },

    Examination: {
      Commitee: "/exam_committee",
      CreateExams: "/CreateExams",
      CreateQuestionPaper: "/createquestionpapers",
      CreateExamTimetable: "/createexamtimetable",
      ExamSchedules: "/examSchedules",
      ExamSeatingArrangements: "/examseatingarrangments",
      PostExaminations: "/postexaminations",
      DownloadQuestionPaper: "/downloadQuestionPaper",
      OtpVerification: "/otpverification",
      InvigilatorLogin: "/invigilatorLogin",
      AssignAnswerSheets: "/assignAnswersheets",
      AnswersheetChecking: "/answersheetEvaluation",
      StudentList: "/studentsList",
    },

    Hostel: {
      HostelRooms: "/HostelRooms",
      RoomType: "/RoomTypes",
      Hostel: "/hostel",
      hostelFee: "/admin/hostelfee",
      addhostelFee: "/admin/addhostelFee",
    },
    Inventory: {
      IssueItems: "/IssueItemsList",
      AddItemstock: "/AddItemstock",
      AddItem: "/AddItem",
      ItemCategory: "/ItemCategory",
      ItemStore: "/ItemStore",
      ItemSupplier: "/ItemSupplier",
      IssueItem: "/IssueItem",
      PurchaseOrder: "/PurchaseOrder",
      CreatePurchaseOrder: "/PurchaseOrder/create",
    },
    Certificates: {
      DesignHallticket: "/DesignHallticket",
      DesignMarkscard: "/DesignMarkscard",
    },

    Library: {
      Books: "/Admin/Books",
      Library_Settings: "/Admin/Settings",
      Borrow: "/Admin/borrow",
    },
    HR: {
      AddNewStaff: "/addNewStaff",
      ViewStaff: "/viewStaff",
      StaffAttendance: "/staffAttendance",
      AddDesignation: "/addDesignation",
      AddDepartment: "/addDepartment",
      Feedback: "/feedback",
    },
  },
  HR: {
    Home: "/dashboard/hr",
    Declaration_Forms: {
      DeclarationForm1: "/declarationForm1",
      DeclarationForm2: "/declarationForm2",
    },
    Ticket: "/hr/ticket",
    ticketConversation: "/hr/ticketConversation",
    Employee: {
      AddStaff: "/addStaff",
      ViewStaff: "/viewStaff",
      EmployeeCollegeTransfer: "/employeeCollegeTransfer",
      Placement: "/placement",
      EmployeeTerms: "/employeeTerms",
      Education: "/education",
      Experience: "/experience",
      Training: "/training",
      LegalDocument: "/legalDocument",
      WebAccount: "/webAccount",
      HRRole: "/HRRole",
      EditStaff: "/editStaff/:id",
      EditStaffRoute: "/editStaff",
    },
    Employer: {
      Information: "/employerInfo",
      JobPosition: "/jobPosition",
      Department: "/department",
      DepartmentCourse: "/course",
      ApprovalWorkflow: "/EmployerApprovalWorkflow",
      Branch: "/branch",
      Level: "/level",
      Bank: "/bank",
      Course: "/course",
      Trainer: "/trainer",
      Ethnicity: "/ethnicity",
      Religion: "/religion",
      DocumentCategory: "/documnetCategory",
      CollegeType: "/employerCollegeType",
      Program: "/employerProgram",
      Specialization: "/employerSpecialization",
      College: "/employerCollege",
      CollegeSpecialization: "/employerCollegeSpecialization",
    },
    PayRoll: {
      EmployerSalary: "/employerSalary",
      PaySlipPrint: "/payslipStatement",
      PayScale: "/payrollPayScale",
      Earning: "/payrollEarning",
      Bonus: "/payrollBonus",
      Deduction: "/payrollDeduction",
      StationaryContribution: "/payrollStationaryContribution",
      Process: "/payrollProcess",
      EditStaffAttendance: "/editStaffAttendance",
      EditLateInEarlyOut: "/editLateInEarlyOut",
      BasicSalary: "/BasicSalary",
    },
    Leave: {
      Setting: "/leaveSetting",
      Workday: "/leaveWorkday",
      Holiday: "/leaveHoliday",
      LeaveType: "/leaveType",
      CustomApprover: "/leaveCustomApprover",
      ApprovalWorkflow: "/leaveApprovalWorkflow",
      EarningPolicy: "/leaveEarningPolicy",
      EntitlementReport: "/leaveEntitlementReport",
      TransactionReport: "/leaveTransactionReport",
      Review: "/leaveReview",
      Schedule: "/leaveSchedule",
      Planner: "/leavePlanner",
      Management: "/leaveManagement",
      Reports: {
        Entitlement: "/leaveReportsEntitlement",
        Leave: "/leaveReportsLeave",
      },
      EmployeeShift: "/employeeShift",
    },
    Attendance: {
      Management: "/attendanceManagement",
      FieldCheckIn: "/attendanceFieldCheckIn",
      TimeClockReport: "/attendanceTimeClockReport",
      AttendanceMonthEmployee: "/attendanceMonthEmployee",
      StaffResignation: "/staffResignation",
      TodayAttendance: "/attendanceTodayAttendance",
      WorkDay: "/attendanceWorkDay",
      HoliDay: "/attendanceHoliday",
      MonthlyReport: "/attendanceMonthlyReport",
      TimeReport: "/attendanceTimeReport",
      LateinReport: "/attendanceLateinReport",
    },
    Team: {
      Discussion: "/teamDiscussion",
      Announcement: "/teamAnnouncement",
      documents: "/teamDocuments",
      Chat: "/teamChat/:id",
    },
    Document: {
      Management: "/documentManagement",
      Review: "/documentReview",
      ApprovalWorkFlow: "/documentApprovlWorkflow",
    },
    Feedback: {
      FeedbackTrigger: "/feedbackTrigger",
      AddFeedbackQuestions: "/addfeedbackquestions",
    },

    // Event : {
    //   EventCustomApproves: "/eventCustomApprove",
    //   EventWorkFlow:"/eventApprovalWorkFlow",
    // },
    Interview: {
      JobOpenings: "/interview/jobOpenings",
      ReviewJobApplications: "/interview/reviewJobApplications",
      JobApplicationProfile: "/interview/jobApplicationProfile",
      JobApplication: "/interview/jobApplication",
    },
  },
  JobApplication: "/jobApplication",
  Warden: {
    HostelFees: "/warden/hostelfees",
    HostelRooms: "/warden/hostelrooms",
    HostelBeds: "/warden/hostelBeds",
    RoomType: "/warden/roomType",
    Hostel: "/warden/hostel",
    HostelFloors: "/warden/hostelfloors",
    StudentDetails: "/warden/studentdetails",
    StudentProfile: "/warden/studentprofile",
    ViewStudentProfile: "/warden/viewstudentprofile",
    HostelEditWarden: "/warden/hostelfeeedit",
    ModalHostelRooms: "/warden/modalhostelrooms",
    ModalHostelRoomsAssign: "/warden/modalHostelroomsAssign",
    ViewStudentGatePass: "/warden/gatepassInOut",
    GatePassInOut: "/warden/viewstudentgatepass",
    StudentGatePass: "/warden/studentgatepass",
    GatePassRequests: "/warden/gatepassrequests",
    GatePassRequest2: "/warden/gatepassrequest2",
    ModalStudentgatePass: "/warden/ModalStudentgatePass",
    AssignHostelStudents: "/warden/assignHostelStudents",
  },
  Registar: {
    NAAC: {
      index: "/registarar/naac",
      dashboard: {
        activityTracking: "/registarar/naac/activity-tracking",
        performanceTracking: "/registarar/naac/performance-tracking",
        statusReport: "/registarar/naac/status-reports",
      },
      approvalFlow: "/registarar/naac/approval-flow",
      naacData: {
        executiveSummary: "/registarar/naac/executive-summary",
        executiveSummaryPdf: "/registarar/naac/executive-summary/pdf",
        profile: "/registarar/naac/profile",
        extendedProfile: "/registarar/naac/extended-profile",
        nepParameters: "/registarar/naac/nep-parameters",
        qifQualitative: "/registarar/naac/qif-qualitative",
        qifQuantative: "/registarar/naac/qif-quantitative",
        myDownloads: "/registarar/naac/my-downloads",
      },
    },
    dashboard: "/dashboard/registrar",
    dashboard2: "/dashboard2/registrar",
    Ticket: "/registrar/ticket",
    developersCentre: "/ticketscentre",
    ticketConversation: "/registrar/ticketConversation",
    ApproveStudentId: "/registrar/approveStudentId",
    frontOffice: {
      AdmissionEnquiry: "/registrar/admissionEnquiry",
      VisitorsBook: "/registrar/visitorsBook",
      PhoneCallLog: "/registrar/phoneCallLog",
      PostalDispatch: "/registrar/postalDispatch",
      PostalRecieve: "/registrar/postalRecieve",
      ViewPostalDispatchRecieve: "/registrar/viewpostaldispatchrecieve",
      Complain: "/registrar/complain",
      SetupOffice: "/registrar/setupOffice",
    },
    Student: {
      StudentAdmission: "/registrar/studentAdmission",
      NewComplaint: "/registrar/newcomplaint",
      assignStudents: "/registrar/assignStudents",
      StudentDetails: "/registrar/studentDetails",
      ViewAluminiStudents: "/registrar/viewaluministudents",
      StudentDetails1: "/registrar/studentDetails1",
      DisableStudents: "/registrar/disableStudents",
      EditStudentDetails: "/registrar/editStudentDetails",
      StudentProfile: "/registrar/studentProfile",
      AdmissionEnquiry: "/registrar/studentadmissionEnquiry",
      AdmissionDetails: "/registrar/studentadmissionDetails",
      Exam: "/registrar/studentExam",
      Midterm: "/registrar/studentMidterm",
      Viva: "/registrar/studentViva",
      ABCUpdate: "/registrar/studentABCUpdate",
      ViewStudentProfile: "/registrar/viewStudentProfile",
    },
    Admission: {
      AdmissionProfile: "/registrar/admission/studentProfile",
      NewAdmission: "/registrar/admission/newAdmission",
      AdmissionEnquiry: "/registrar/admission/Enquirry",
      AdmissionDetails: "/registrar/admission/Details",
      PhdAdmissions: "/phdAdmission",
      PhdAdmissionsprofile: "/phdAdmissionprofile",
      PhdAdmitCard: "/phdAdmitCard",
      PhdExamList: "/phdExamlist",
      PhdExamprofile: "/phdExamprofile",
      PhdExamAdmitCard: "/phdExamadmitcard",
    },
    HR: {
      AddNewStaff: "/addNewStaff",
      ViewStaff: "/viewStaff",
      StaffAttendance: "/staffAttendance",
      AddDesignation: "/addDesignation",
      AddDepartment: "/addDepartment",
      Feedback: "/feedback",
    },
    Accounts: {
      Income: {
        View: "/registrar/viewIncome",
        Add: "/registrar/addIncome",
        AddSource: "/registrar/addIncomeSource",
      },
      Expense: {
        View: "/registrar/viewExpense",
        Add: "/registrar/addExpense",
        AddSource: "/registrar/addExpenseSource",
      },
      FeeCollection: {
        CollegeFee: "/registrar/feeCollectionCollegeFee",
        SearchFee: "/registrar/feeCollectionSearchFee",
        SearchDue: "/registrar/feeCollectionSearchDue",
        FeeMaster: "/registrar/feeCollectionFeeMaster",
        FeeGroup: "/registrar/feeCollectionFeeGroup",
        FeeType: "/registrar/feeCollectionFeeType",
        FeeDiscount: "/registrar/feeCollectionFeeDiscount",
        FeeTypeAmount: "/registrar/feeCollectionFeeTypeAmount",
        AddFee: "/registrar/addFee",
        assignFee: "/registrar/assignFee",
        filterForFees: "/registrar/filters",
        Reports: "/registrar/feereports",
      },
    },
    Academics: {
      ClassTimeTable: "/registrar/classTimeTable",
      CreateTimeTable: "/registrar/createTimeTable",
      TeacherTimeTable: "/registrar/teacherTimeTable",
      PromoteStudents: "/registrar/promoteStudents",
      ChangeBranch: "/registrar/changeBranch",
      AddBatch: "/registrar/addBatch",
      AddSubject: "/registrar/addSubject",
      AddClass: "/registrar/addClass",
      AddBloomsLevel: "/registrar/addBloomsLevel",
      CourseOutcomes: "/registrar/courseOutcomes",
      ProgramOutcome: "/registrar/ProgramOutcomes",
      ManageLessonPlan: "/registrar/manageLessonPlan",
      SyllabusStatus: "/registrar/syllabusStatus",
      AddTopic: "/registrar/addTopic",
      AddQuestionBank: "/registrar/addQuestionBank",
      AddLesson: "/registrar/addLesson",
      ViewPracStudents: "/registrar/viewPracticalAssignedStudents",
      AddSemester: "/registrar/addSemester",
      AddSection: "/registrar/addSection",
      CourseRegistration: "/registrar/courseRegistration",
      AssignHospital: "/registrar/AssignHospital",
      ClinicalStudiesReport: "/registrar/clinical-studies-report",
    },
    Event: {
      EventCustomApproves: "/registrar/eventCustomApprove",
      EventWorkFlow: "/registrar/eventApprovalWorkFlow",
      AddEvent: "/registrar/add-event",
    },
    Communicate: {
      Announcements: "/registrar/announcements",
      UpcomingEvents: "/registrar/upcoming-events",
    },
    Attendance: {
      ClassAttendance: "/registrar/classAttendance",
      LabAttendance: "/registrar/labattendance",
      VisitorsList: "/registrar/VisitorsList",
    },
    Document: {
      UploadContent: "/registrar/documentUploadContent",
      DownloadContent: {
        Assignment: "/registrar/documentAssignment",
        Syllabus: "/registrar/documentSyllabus",
        Studymaterial: "/registrar/documentStudyMaterial",
        OtherDownload: "/registrar/documentOtherDownload",
      },
    },
    Transport: {
      Route: "/registrar/transportRoutes",
      Vehicles: "/registrar/transportVehicles",
      AssignVehicles: "/registrar/transportAssignVehicles",
      transportFee: "/registrar/transportFee",
      addtransportFee: "/registrar/addtransportFee",
      pickupPoints: "/registrar/pickupPoints",
      AssignPickupPoints: "/registrar/transportAssignPickupPoints",
      TransportFees: "/registrar/TransportFees",
    },
    Hostel: {
      HostelRooms: "/registrar/HostelRooms",
      RoomType: "/registrar/RoomTypes",
      Hostel: "/registrar/hostel",
      hostelFee: "/registrar/hostelfee",
      addhostelFee: "/registrar/addhostelFee",
      HostelFloors: "/registrar/HostelFloors",
      StudentGatePass: "/registrar/studentgatepass",
      ViewStudentGatePass: "/registrar/viewstudentgatepass",
      ModalStudentgatePass: "/registrar/modalstudentgatepass",
    },
    Inventory: {
      IssueItems: "/registrar/IssueItemsList",
      ViewAllIssueItems: "/registrar/AllIssueItemList",
      AddItemstock: "/registrar/AddItemstock",
      AddItem: "/registrar/AddItem",
      ItemCategory: "/registrar/ItemCategory",
      ItemStore: "/registrar/ItemStore",
      ItemSupplier: "/registrar/ItemSupplier",
      IssueItem: "/registrar/IssueItem",
      PurchaseOrder: "/registrar/PurchaseOrder",
      PurchaseOrderById: "/registrar/PurchaseOrder/:id",
      ViewPurchaseOrderById: "/registrar/PurchaseOrder/view/details/:id",
      CreatePurchaseOrder: "/registrar/PurchaseOrder/create",
      PurchaseReceives: "/registrar/PurchaseReceives",
      PurchaseBills: "/registrar/PurchaseBill",
      RecordPayment: "/registrar/RecordPayment",
    },
    Certificates: {
      DesignHallticket: "/registrar/DesignHallticket",
      DesignMarkscard: "/registrar/DesignMarkscard",
    },
    Library: {
      Books: "/Library/Books",
      Library_Settings: "/Library/Settings",
      Borrow: "/Library/Borrow",
      Reports: "/Library/Report",
      Dashboard: "/Library/Dashboard",
    },
    Reports: {
      Home: "/reg/reports",
      StudentDetails1: "/reg/reports/studentdetails1",
      Income: {
        IncomeReport: "/reg/reports/incomereport",
      },
      Expense: {
        ExpenseReport: "/reg/reports/expensereport",
      },
      LessonPlan: {
        LessonPlanReport: "/reg/reports/lessonplanreport",
      },
      StaffAttendance: {
        StaffAttendanceReport: "/reg/reports/staffattendancereport",
      },
      BasicSalaryReport: "/reg/reports/BasicSalaryReport",
      BasicSalaryReport1: "/reg/reports/BasicSalaryReport1",
      Fee: {
        Home: "/reg/reports/fee",
        BHA0: "/reg/reports/fee/bha0",
        BHA1: "/reg/reports/fee/bha1",
        BHA2: "/reg/reports/fee/bha2",
        BHA3: "/reg/reports/fee/bha3",
        BHA4: "/reg/reports/fee/bha4",
        BHA5: "/reg/reports/fee/bha5",
        BHA6: "/reg/reports/fee/bha6",
        BHA7: "/reg/reports/fee/bha7",
        Pending: "/reg/reports/fee/pendig",
        Transport: "/reg/reports/fee/transport",
        AdvancePayment: "/reg/reports/AdvancePayment",
        AdvancePaymentHome: "/reg/reports/AdvancePaymentHome",
        PendingCollegewise: "/reg/reports/fee/pendig-college-wise",
        hostelfeeCollection: "/reg/reports/fee/hostelFee-Collection",
        hostelfeeFacultyWise: "/reg/reports/fee/hostelfeeFacultyWise",
        collegewiseCllection: "/reg/reports/fee/collegewise-collection",
        departmentwiseCllection: "/reg/reports/fee/departmentwise-collection",
        programwiseCllection: "/reg/reports/fee/programwise-collection",
        CancelledReceipts: "/reg/reports/fee/cancelledreceipts",
        ScholarshipReport: "/reg/reports/fee/scholarship-report",
        SummaryFeeReport: "/reg/reports/fee/summary-report",
      },
      Staff: {
        Home: "/reg/reports/staff",
        Leave: "/reg/reports/staff/leave",
        StaffDetails: "/reg/reports/staff/staffDetails",
      },
      Student: {
        Home: "/reg/reports/student/attendance",
        StudentAdmissionReport: "/reg/reports/admission/studentAdmissionReport",
        MonthWise: "/reg/reports/student/month",
        AttendanceSemWise: "/reg/reports/student/sem",
        StudentStat: "/reg/reports/student/student-stat",
        StudentSubStat: "/reg/reports/student/student-sub-stat",
      },
      Feedbacks: {
        FeedbacksHome: "/reg/reports/feedbacksHome",
      },
      Syllabus: {
        SyllabusStatus: "/reg/reports/syllabus",
      },
    },
    LMS: {
      Create: "/reg/lms-create",
      View: "/reg/lms-list",
    },
    StaffDairy: {
      StaffDairy: "/registrar/StaffDairy",
      ViewStaffDairy: "/registrar/ViewStaffDairy",
      StaffDairyReport: "/registrar/StaffDairyReport",
      PurchaseOrder: "/registrar/PurchaseOrder",
      CreatePurchaseOrder: "/registrar/PurchaseOrder/create",
      StaffDairyReport2: "/registrar/StaffDairyReport2",
    },
  },
  Principal: {
    dashboard: "/dashboard/principal",
    Ticket: "/principal/ticket",
    PhdAdmissions: "/principal/phdAdmission",
    PhdAdmissionsprofile: "/principal/PhdAdmissionsprofile",

    PhdAdmitCard: "/principal/phdAdmitCard",
    PhdExamList: "/principal/phdExamlist",
    ticketConversation: "/principal/ticketConversation",
    frontOffice: {
      AdmissionEnquiry: "/Principal/admissionEnquiry",
      VisitorsBook: "/Principal/visitorsBook",
      PhoneCallLog: "/Principal/phoneCallLog",
      PostalDispatch: "/Principal/postalDispatch",
      PostalRecieve: "/Principal/postalRecieve",
      Complain: "/Principal/complain",
      SetupOffice: "/Principal/setupOffice",
    },
    Student: {
      StudentAdmission: "/Principal/studentAdmission",
      AssignStudents: "/Principal/assignStudents",
      StudentDetails: "/Principal/studentDetails",
      StudentDetails1: "/studentDetails1",
      DisableStudents: "/Principal/disableStudents",
      StudentProfile: "/Principal/studentProfile",
      ViewStudentProfile: "/PrincipalviewStudentProfile",

      EditStudentDetails: "/Principal/editStudentDetails",
      UpdateABC: "/principal/updateABC",
      Exam: "/principal/studentExam",
      Midterm: "/principal/midterm",
      Viva: "/principal/viva",
    },
    StaffDairy: {
      StaffDairy: "/principal/StaffDairy",
      ViewStaffDairy: "/principal/ViewStaffDairy",
    },
    Event: {
      // EventCustomApproves: "/principal/eventCustomApprove",
      // EventWorkFlow:"/principal/eventApprovalWorkFlow",
      AddEvent: "/principal/add-event",
    },
    HR: {
      AddNewStaff: "/addNewStaff",
      ViewStaff: "/viewStaff",
      EmployeeCollegeTransfer: "/employeeCollegeTransfer",
      StaffAttendance: "/staffAttendance",
      AddDesignation: "/addDesignation",
      AddDepartment: "/addDepartment",
      Feedback: "/feedback",
    },
    Admission: {
      AdmissionProfile: "/principal/admission/studentProfile",
      NewAdmission: "/principal/admission/newAdmission",
      AdmissionEnquiry: "/principal/admission/Enquirry",
      AdmissionDetails: "/principal/admission/Details",
    },
    Accounts: {
      Income: {
        View: "/principal/viewIncome",
        Add: "Tprincipal/addIncome",
        AddSource: "/principal/addIncomeSource",
      },
      Expense: {
        View: "/principal/viewExpense",
        Add: "/principal/addExpense",
        AddSource: "/principal/addExpenseSource",
      },
      FeeCollection: {
        CollegeFee: "/principal/feeCollectionCollegeFee",
        SearchFee: "/principal/feeCollectionSearchFee",
        SearchDue: "/principal/feeCollectionSearchDue",
        FeeMaster: "/principal/feeCollectionFeeMaster",
        FeeGroup: "/principal/feeCollectionFeeGroup",
        FeeType: "/principal/feeCollectionFeeType",
        FeeDiscount: "/principal/feeCollectionFeeDiscount",
        FeeTypeAmount: "/principal/feeCollectionFeeTypeAmount",
        AddFee: "/principal/feeCollectionAddFee",
        assignFee: "/principal/feeCollectionAssignFee",
        Reports: "/principal/feeCollectionReports",
      },
    },
    Academics: {
      ClassTimeTable: "/principal/classTimeTable",
      CreateTimeTable: "/principal/createTimeTable",
      TeacherTimeTable: "/principal/teacherTimeTable",
      ViewPracStudents: "/principal/ViewPracStudents",
      PromoteStudents: "/principal/promoteStudents",
      ChangeBranch: "/principal/changeBranch",
      AddBatch: "/principal/addBatch",
      AddSubject: "/principal/addSubject",
      AddClass: "/principal/addClass",
      ManageLessonPlan: "/principal/manageLessonPlan",
      SyllabusStatus: "/principal/syllabusStatus",
      AddTopic: "/principal/addTopic",
      AddLesson: "/principal/addLesson",
      AddSemester: "/principal/addSemester",
      AddSection: "/principal/addSection",
      AddQuestionBank: "/principal/questionBank",
      CourseRegistration: "/principal/courseRegistration",
    },
    Document: {
      UploadContent: "/documentUploadContent",
      DownloadContent: {
        Assignment: "/documentAssignment",
        Syllabus: "/documentSyllabus",
        Studymaterial: "/documentStudyMaterial",
        OtherDownload: "/documentOtherDownload",
      },
    },
    Transport: {
      Route: "/transportRoutes",
      Vehicles: "/transportVehicles",
      AssignVehicles: "/transportAssignVehicles",
    },
    Hostel: {
      HostelRooms: "/HostelRooms",
      RoomType: "/RoomTypes",
      Hostel: "/hostel",
    },
    Inventory: {
      IssueItems: "/IssueItemsList",
      AddItemstock: "/AddItemstock",
      AddItem: "/AddItem",
      ItemCategory: "/ItemCategory",
      ItemStore: "/ItemStore",
      ItemSupplier: "/ItemSupplier",
      PurchaseOrder: "/PurchaseOrder",
      CreatePurchaseOrder: "/PurchaseOrder/create",
    },
    Certificates: {
      DesignHallticket: "/DesignHallticket",
      DesignMarkscard: "/DesignMarkscard",
    },
    Attendance: "/principal/ClassAttendance",
    LabAttendance: "/principal/labAttendance",

    Reports: {
      Home: "/principal/reports",
      StudentDetails1: "/principal/studentDetails1",
      Attendance: "/principal/attendance",
      AttendanceSemWise: "/principal/attendance/semwise",
      MonthWise: "/principal/attendance/month",
      StudentStat: "/principal/reports/student/student-stat",
      StudentSubStat: "/principal/reports/student/student-sub-stat",
      Staff: {
        Home: "/principal/reports/staff",
        Leave: "/principal/reports/staff/leave",
      },
    },
  },
  NonTeachingStaff: {
    Home: "/non-teaching/dashboard",
    Application: "/non-teaching/leaveApplication",
    Entitlement: "/non-teaching/leaveEntitlement",
    Schedule: "/non-teaching/schedule",
    Ticket: "/non-teaching/ticket",
  },
  Employee: {
    NAAC: {
      index: "/employee/naac",
      dashboard: {
        activityTracking: "/employee/naac/activity-tracking",
        performanceTracking: "/employee/naac/performance-tracking",
        statusReport: "/employee/naac/status-reports",
      },
      approvalFlow: "/employee/naac/approval-flow",
      naacData: {
        executiveSummary: "/employee/naac/executive-summary",
        executiveSummaryPdf: "/employee/naac/executive-summary/pdf",
        profile: "/employee/naac/profile",
        extendedProfile: "/employee/naac/extended-profile",
        nepParameters: "/employee/naac/nep-parameters",
        qifQualitative: "/employee/naac/qif-qualitative",
        qifQuantative: "/employee/naac/qif-quantitative",
        myDownloads: "/employee/naac/my-downloads",
      },
    },
    Home: "/dashboard/employee",
    Reports: {
      Home: "/employee/reports",
      StudentDetails1: "/employee/studentDetails1",
      Attendance: "/employee/Attendance",
      AttendanceSemWise: "/employee/attendance/semwise",
      MonthWise: "/employee/attendance/month",
      StudentStat: "/employee/reports/student/student-stat",
      StudentSubStat: "/employee/reports/student/student-sub-stat",
    },
    Ticket: "/employee/ticket",
    ticketConversation: "/employee/ticketConversation",
    Profile: "/employee/profile",
    ProcteeList: "/employee/ProcteeList",
    StudentGatePass: "/employee/StudentGatePass",
    ViewGatePass: "/employee/viewGatePass",
    CreateProctoringMeeting: "/employee/createProctoringMeeting",
    ViewProctoringMeetingDetails: "/employee/ViewProctoringMeetingDetails",
    NextProctoringMeetingDetails: "/employee/NextProctoringMeetingDetails",
    PayRoll: {
      Salary: "/employee/SalaryView",
      AnnualStatement: "/employee/annualStatement",
      Payslip: "/employee/payslip",
      EditStaffAttendance: "/editStaffAttendance",
    },
    Leave: {
      Application: "/employee/leaveApplication",
      Entitlement: "/employee/leaveEntitlement",
      Schedule: "/employee/schedule",
    },
    Attendance: "/employee/attendance",
    Document: "/employee/DocumentManagement",
    Team: {
      Discussion: "/employee/teamDiscussion",
      Announcement: "/employee/teamAnnouncement",
      Documents: "/employee/teamDocuments",
      Chat: "/employee/teamChat/:id",
    },
    Academics: {
      ClassTimeTable: "/employee/classTimeTable",
      ViewPracStudents: "/employee/ViewPracStudents",
      TeacherTimeTable: "/employee/teacherTimeTable",
      PromoteStudents: "/employee/promoteStudents",
      ChangeBranch: "/employee/changeBranch",
      AddSubject: "/employee/addSubject",
      AddClass: "/employee/addClass",
      ManageLessonPlan: "/employee/manageLessonPlan",
      SyllabusStatus: "/employee/syllabusStatus",
      AddTopic: "/employee/addTopic",
      AddLesson: "/employee/addLesson",
      AddSemester: "/employee/addSemester",
      AddSection: "/employee/addSection",
      AddQuestionBank: "/employee/questionBank",
      CourseRegistration: "/employee/courseRegistration",
    },
    StaffDairy: {
      StaffDairy: "/employee/StaffDairy",
      ViewStaffDairy: "/employee/ViewStaffDairy",
    },

    Review: "/employee/reviewApplication",
    Student: {
      AdmissionDetails: "/employee/studentDetails",
      AssignStudents: "/employee/assignStudents",
    },
    StudentAttendance: {
      ClassAttendance: "/employee/classAttendance",
      LabAttendance: "/employee/labAttendance",
    },
    Event: {
      AddEvent: "/employee/add-event",
    },
  },
  Examination: {
    Commitee: "/exam_committee",
    AssignStaff: "/AssignStaff",
    CreateQuestionPaper: "/createquestionpapers",
    CreateExamTimetable: "/createexamtimetable",
    ExamSchedules: "/examSchedules",
    ExamSeatingArrangements: "/examseatingarrangments",
    PostExaminations: "/postexaminations",
    DownloadQuestionPaper: "/downloadQuestionPaper",
    OtpVerification: "/otpverification",
    InvigilatorLogin: "/invigilatorLogin",
    AssignAnswerSheets: "/assignAnswersheets",
    AnswersheetChecking: "/answersheetEvaluation",
    StudentList: "/studentsList",
    ExamEnrollment: "/examEnrollment",
    StudentApplicationForm: "/studentApplicationForm",
    DownloadHallTickets: "/downloadHallTickets",
    AssignCommitteeMembers: "/assignCommitteeMambers",
    AddInvigilators: "/inviteInvigilator",
    AddEvaluators: "/inviteEvaluators",
    Hallticket: "/hallTicket",
    AttendanceList: "/attendanceList",
    DownloadAttendanceList: "/downloadAttendanceList",
  },
  Cashier: {
    Dashboard: "/cashier/dashboard",
    StudentDetails: "/cashier/studentDetails",
    Reports: "/cashier/reports",
    report1: "/cashier/report1",
    report2: "/cashier/report2",
    report3: "/cashier/report3",
    report4: "/cashier/report4",
    onlineTransaction: "/cashier/onlineTransaction",
    transportFee: "/cashier/transportFee",
    hostelFee: "/cashier/hostelfee",
    addhostelFee: "/cashier/addhostelFee",
    addtransportFee: "/cashier/addtransportFee",
    collegewiseCollection: "/cashier/reports/fee/collegewise-Collection",
    hostelfeeCollection: "/cashier/reports/fee/hostelFee-Collection",
    hostelfeeFacultyWise: "/cashier/reports/fee/hostelfeeFacultyWise",
    departmentwiseCollection: "/cashier/reports/fee/departmentwise-Collection",
    programwiseCollection: "/cashier/reports/fee/programwise-Collection",
    CollectFee: "/cashier/collectfee",
    SearchDue: "/cashier/searchfee",
    TransportDateWise: "/cashier/reports/fee/transport",
    PendingCollegewise: "/cashier/report-college-pending",
    StudentProfile: "/cashier/studentProfile",
    ViewStudentProfile: "/cashier/viewStudentProfile",
    AddFee: "/cashier/addFee",
    ScholarshipReport: "/cashier/reports/fee/scholarship-report",
  },
  Accountant: {
    Dashboard: "/account/dashboard",
    CollectFee: "/account/collectfee",
    Ticket: "/account/ticket",
    ticketConversation: "/account/ticketConversation",
    SearchDue: "/account/serachfee",
    FeeMaster: "/account/feemaster",
    AssignFee: "/account/assignfee",
    TransportDateWise: "/account/reports/fee/transport",
    AddFee: "/account/addFee",
    StudentDetails: "/account/studentDetails",
    StudentProfile: "/accountant/studentProfile",
    ViewStudentProfile: "/accountant/viewStudentProfile",
    StudentDetails1: "/studentDetails1",
    FeeGroup: "/account/feeCollectionFeeGroup",
    FeeType: "/account/feeCollectionFeeType",
    FeeDiscount: "/account/feeCollectionFeeDiscount",
    FeeTypeAmount: "/account/feeCollectionFeeTypeAmount",
    collegewiseCollection: "/account/reports/fee/collegewise-Collection",
    hostelfeeCollection: "/account/reports/fee/hostelFee-Collection",
    hostelfeeFacultyWise: "/account/reports/fee/hostelfeeFacultyWise",
    departmentwiseCollection: "/account/reports/fee/departmentwise-Collection",
    programwiseCollection: "/account/reports/fee/programwise-Collection",
    Reports: "/account/reports",
    report1: "/account/report1",
    report2: "/account/report2",
    report3: "/account/report3",
    report4: "/account/report4",
    onlineTransaction: "/account/onlineTransaction",
    PendingCollegewise: "/account/report-college-pending",
    CancelledReceipts: "/account/reports/fee/cancelledreceipts",
    ScholarshipReport: "/account/reports/fee/scholarship-report",
    FeeSummaryReport: "/account/repors/fee-summary-report",
    Transport: {
      Route: "/account/transportRoutes",
      Vehicles: "/account/transportVehicles",
      AssignVehicles: "/account/transportAssignVehicles",
      transportFee: "/accountant/transportFee",
      addtransportFee: "/accountant/addtransportFee",
      pickupPoints: "/accountant/pickupPoints",
      AssignPickupPoints: "/accountant/transportAssignPickupPoints",
    },
    Hostel: {
      HostelRooms: "/account/HostelRooms",
      RoomType: "/account/RoomTypes",
      Hostel: "/account/hostel",
      hostelFee: "/accountant/hostelfee",
      addhostelFee: "/accountant/addhostelFee",
      HostelFloors: "/accountant/HostelFloors",
    },
  },
  Reception: {
    AdmissionEnquiry: "/reception/admissionEnquiry",
    VisitorsBook: "/reception/visitorsBook",
    PhoneCallLog: "/reception/phoneCallLog",
    PostalDispatch: "/reception/postalDispatch",
    PostalRecieve: "/reception/postalRecieve",
    Complain: "/reception/complain",
    SetupOffice: "/reception/setupOffice",
  },
  AdConsult: {
    DashboardAC: "/con/dashboardAd_Con",
    EnuiryModal: "/con/enquiryModal",
    AdmissionEnquiry: "/con/admissionEnquiry",
    StudentDetails: "/con/studentDetails",
    // CollectFee: "/con/collectfee",
    // SearchDue: "/con/searchfee",
    StudentDetails1: "/studentDetails1",
    Admission: "/con/admission",
    Ticket: "/con/ticket",
    ticketConversation: "/con/ticketConversation",
    VisitorsBook: "/con/visitorsBook",
    PhoneCallLog: "/con/phoneCallLog",
    PostalDispatch: "/con/postalDispatch",
    PostalRecieve: "/con/postalRecieve",
    Complain: "/con/complain",
    SetupOffice: "/con/setupOffice",
    OnlineEnquiry: "/con/onlineEnquiry",
    HrNavigator: "/con/hrNavigator",
    Reports: {
      BHA1: "/con/reports/fee/bha1",
      StudentAdmissionReport: "/con/reports/studentAdmissionReport",
      departmentwiseCllection: "/con/reports/fee/departmentwise-collection",
    },
    HR: {
      PayRoll: {
        Salary: "/ad-con/hr/SalaryView",
        AnnualStatement: "/ad-con/hr/annualStatement",
        Payslip: "/ad-con/hr/payslip",
      },
      Leave: {
        Application: "/ad-con/hr/leaveApplication",
        Entitlement: "/ad-con/hr/leaveEntitlement",
        Schedule: "/ad-con/hr/schedule",
      },
      Document: "/ad-con/hr/DocumentManagement",
      Team: {
        Discussion: "/ad-con/hr/teamDiscussion",
        Announcement: "/ad-con/hr/teamAnnouncement",
        Documents: "/ad-con/hr/teamDocuments",
        Chat: "/ad-con/hr/teamChat/:id",
      },
      Review: "/ad-con/hr/reviewApplication",
    },
  },
  Library: {
    Books: "/Library/Books",
    SearchBook: "/Library/searchBook",
    Library_Settings: "/Library/Settings",
    Borrow: "/Library/Borrow",
    Reports: "/Library/Report",
    Dashboard: "/Library/Dashboard",
    Ebook: "/Library/ebook",
    leaveEntitlement: "/library/leaveEntitlement",
    leaveApplication: "/library/leaveApplication",
    leaveSchedule: "/library/schedule",
    PayRoll: "/library/SalaryView",
    AnnualStatement: "/library/annualStatement",
    documentManagement: "/library/DocumentManagement",
    teamDiscussion: "/library/teamDiscussion",
    teamDocuments: "/library/teamDocuments",
    teamAnnouncement: "/library/teamAnnouncement",
  },
  UmsSettings: "/ums-settings",
  Proctoring: {
    Dashboard: "/proctoring/dashboard",
    AssignProcteeToProctor: "/proctoring/assignProcteeToProctor",
    CreateProctoringMeeting: "/proctoring/createProctoringMeeting",
    NextProctoringMeetingDetails: "/proctoring/ViewProctoringMeetingDetails",
    MyProcteeList: "/proctoring/myProcteeList",
    History: "/proctoring/history",
    Meetings: "/proctoring/meetings",
    AddMeatingMany: "/proctoring/addMeetingMany",
    ProcteeProctoringHistory: "/proctoring/procteeProctoringHistory",
    Reports: {
      History: "/proctoring/reports/history",
      ProctoringMeetingForStaff:
        "/proctoring/reports/proctoringMeetingForStaff",
      ProctorWiseStudentList: "/proctoring/reports/proctorWiseStudentList",
      ProctoringMeetingByProctee:
        "/proctoring/reports/proctoringMeetingByProctee",
      ProctoringMeetingList: "/proctoring/reports/proctoringMeetingList",
      ProctoringSummary: "/proctoring/reports/proctoringSummary",
      DepartmentWiseProctoringDetails:
        "/proctoring/reports/departmentWiseProctoringDetails",
    },
  },
  StudentPortal: {
    Home: "/student-portal/Home",
    Profile: "/student-portal/profile",
    Subjects: "/student-portal/subjects",
    Lessons: "/student-portal/lessons",
    Topics: "/student-portal/topics",
    Attendance: "/student-portal/attendance",
    StudentAttendance: "/student-portal/studentAttendance",
    CourseRegistrationByStudent: "/student-portal/courseRegistration",
    ClinicalStudies: "/student-portal/clinical-studies",
    Documents: "/student-portal/documents",
    StudentGatePass: "/student-portal/studentgatepass",
    ViewGatePass: "/student-portal/viewgatepass",
  },
  AdmissionPortal: {
    Dashboard: "/admission-portal/Dashboard",
    Admission: {
      basicHome: "/admission-portal/basicHome",
      apply: "/admission-portal/apply",
      bed: "/admission-portal/ApplyBed",
      bsc: "/admission-portal/ApplyBsc",
      commerce: "/admission-portal/ApplyCommerce",
      law: "/admission-portal/ApplyLaw",
      basicHome: "/admission-portal/basicHome",
      msc: "/admission-portal/ApplyMsc",
      vedicastro: "/admission-portal/ApplyVedicAstro",
      bscNursing: "/admission-portal/ApplyBscNursing",
      gnm: "/admission-portal/ApplyGNM",
      masterNursing: "/admission-portal/ApplyMasterInNursing",
      postBasicBsc: "/admission-portal/ApplyPostBasicBscNursing",
      basicInformations: "/admission-portal/BasicInformations",
      AdmissionPostBasicBscNur: "/admission-portal/AdmissionPostBasicBscNur",
      AdmissionMscNursing: "/admission-portal/MscNursing",
      UGAdmissionPharmacy: "/admission-portal/UGPharmacy",
      PGAdmissionPharmacy: "/admission-portal/PGPharmacy",
      admissionEnquiry: "/admission-portal/AdmissionEnquiry",
      admissionForm: "/admission-portal/AdmissionForm",
      homeopathy: "/admission-portal/AdmissionHomeopathy",
      ayurveda: "/admission-portal/AdmissionAyurveda",
      ayurvedaPG: "/admission-portal/AdmissionAyurvedaPG",
      ayurvedaMsc: "/admission-portal/AdmissionAyurvedaMsc",
      profile: "/admission-portal/profile",
    },
  },
};
