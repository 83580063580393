import React, { useEffect, useState } from "react";
import {
  Button,
  Popconfirm,
  Table,
  Modal,
  Form,
  Input,
  Select,
  InputNumber,
} from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { NAAC } from "../../../../utils/apiConstants";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const { Option } = Select;
export default function ForCommunication({
  setUpdatedData,
  setLoading,
  isAuthor,
  college_id,
}) {
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [badge, setBadge] = useState("");
  const [form] = Form.useForm();
  const [isRejected, setIsRejected] = useState(false);
  const [rejectionMessage, setRejectionMessage] = useState("");
  const [rejectionMessages, setRejectionMessages] = useState("");
  console.log("rejectionMessage", rejectionMessage);
  console.log("rejectionMessages", rejectionMessages);

  const handleAdd = () => {
    form.resetFields();
    setEditingRecord(null);
    setIsModalVisible(true);
  };
  let emp_id = sessionStorage.getItem("employee_id");

  const getFormData = async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/contact-persons/${isAuthor?.application_id}?role=${isAuthor.role}&emp_id=${emp_id}&college_id=${college_id}`,
    };
    setLoading(1);
    await axios(config)
      .then((res) => {
        setData(res.data.data);
        setRejectionMessages(res.data?.rejectionMessages);
        if (isAuthor.role === "REVIEWER") {
          setRejectionMessage(res.data?.rejectionMessage);
        }
        let fetchedData = res.data.data;
        console.log("fetchedData", fetchedData);
        setBadge(() => {
          if (
            fetchedData?.length > 0 &&
            fetchedData.every((item) => item.status === "REVIEW")
          ) {
            return "REVIEW";
          }
          if (
            fetchedData?.length > 0 &&
            fetchedData?.every((item) => item.status === "DRAFT")
          ) {
            return "DRAFT";
          }
          if (
            fetchedData?.length > 0 &&
            fetchedData?.every((item) => item.status === "APPROVED")
          ) {
            return "APPROVED";
          }
          if (
            fetchedData?.length > 0 &&
            fetchedData?.every((item) => item.status === "REJECTED")
          ) {
            return "REJECTED";
          }
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };

  const handleEdit = (record) => {
    setEditingRecord(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };
  useEffect(() => {
    if (isAuthor?.application_id) {
      getFormData();
    }
  }, [isAuthor?.application_id]);
  const handleDelete = (identifier) => {
    const updatedData = data.filter((item) => {
      if (item.id === identifier) {
        // For existing items with `id`, mark as INACTIVE
        item.status = "INACTIVE";
        return true; // Keep in the array to send in `toDelete`
      }
      // For new items with `tempId` and no `id`, remove from array
      return item.tempId !== identifier;
    });

    setData(updatedData);
  };
  console.log("data", data);
  const handleSave = () => {
    form.validateFields().then((values) => {
      if (editingRecord) {
        // Editing an existing record
        setData(
          data.map((item) =>
            (item.id && item.id === editingRecord.id) ||
            (item.tempId && item.tempId === editingRecord.tempId)
              ? { ...item, ...values, updated_by: emp_id }
              : item
          )
        );
      } else {
        // Adding a new record without `id`
        setData([
          ...data,
          {
            ...values,
            university_profile_id: 1,
            application_id: isAuthor?.application_id,
            college_id,
            tempId: uuidv4(),
            created_by: emp_id,
            updated_by: emp_id,
            status: "DRAFT",
          },
        ]);
      }
      setIsModalVisible(false);
    });
  };
  const columns = [
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Telephone",
      dataIndex: "telephone",
      key: "telephone",
    },
    {
      title: "Mobile",
      dataIndex: "mobile_no",
      key: "mobile_no",
    },
    {
      title: "Fax",
      dataIndex: "fax_no",
      key: "fax_no",
    },
    {
      title: "Email",
      dataIndex: "email_address",
      key: "email_address",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button>
          <Popconfirm
            title="Are you sure to delete this record?"
            onConfirm={() => handleDelete(record.id || record.tempId)}
            okText="Yes"
            cancelText="No"
            placement="topRight"
          >
            <Button type="link" danger icon={<DeleteOutlined />}>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const handleFormSubmission = async (e, status) => {
    e.preventDefault();
    if (data.length === 0) {
      return toast.error("Please add at least one contact detail");
    }

    // Separate data for backend operations
    const toDelete = data.filter(
      (item) => item.status === "INACTIVE" && item.id
    );
    const toUpdate = data.filter(
      (item) => item.status !== "INACTIVE" && item.id
    );
    const toCreate = data.filter((item) => !item.id);

    setLoading(1);
    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/contact-persons`,
      data: {
        toDelete,
        toUpdate,
        toCreate,
        status,
        role: isAuthor?.role,
        emp_id,
        application_id: isAuthor?.application_id,
        rejection_message: status === "APPROVED" ? "" : rejectionMessage,
      },
    };

    try {
      const res = await axios(config);
      toast.success(
        status === "REVIEW"
          ? "Successfully submitted for review"
          : "Successfully saved as draft"
      );
      setUpdatedData(uuidv4());
      getFormData(); // Refetch data to update view
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
    } finally {
      setLoading(0);
    }
  };

  const handleSaveASDraft = (e) => {
    e.preventDefault();
    handleFormSubmission("DRAFT");
  };

  const handleSaveAndSubmitForReview = (e) => {
    e.preventDefault();
    handleFormSubmission("REVIEW");
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex flex-column gap-2">
          <span className="fw-semibold h5"> For Communication</span>
          <span
            className={`text-white d-inline-block text-center px-4 py-1 shadow-sm text-xs rounded-pill ${
              badge === "DRAFT"
                ? "bg-primary"
                : badge === "REVIEW"
                ? "bg-warning"
                : badge === "REJECTED"
                ? "bg-danger"
                : badge === "APPROVED"
                ? "bg-success"
                : "bg-secondary"
            }`}
            style={{ width: "fit-content" }}
          >
            {badge === "DRAFT"
              ? "In Draft"
              : badge === "REVIEW"
              ? "Submitted for review"
              : badge === "REJECTED"
              ? "Rejected"
              : badge === "APPROVED"
              ? "Approved"
              : "Not Started"}
          </span>
        </div>
        <Button type="primary" icon={<PlusOutlined />} onClick={handleAdd}>
          Add New Row
        </Button>
      </div>
      <div className="table-responsive">
        <Table
          className="table table-bordered"
          dataSource={data.filter((item) => item.status !== "INACTIVE")}
          columns={columns}
          rowKey="id"
          pagination={false}
        />
      </div>
      <Modal
        title={editingRecord ? "Edit Record" : "Add New Record"}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSave}
        okText="Save"
        cancelText="Cancel"
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Designation"
            name="designation"
            rules={[{ required: true, message: "Please select a designation" }]}
          >
            <Select>
              <Option value="Principle">Principle</Option>
              <Option value="Vice-Principle">Vice-Principle</Option>
              <Option value="IQAC Co-Ordinator">IQAC Co-Ordinator</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input the name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Telephone"
            name="telephone"
            rules={[
              {
                type: "number",
                required: true,
                message: "Please input office telephone number",
              },
            ]}
          >
            <InputNumber type="number" className="w-100" />
          </Form.Item>
          <Form.Item
            label="Mobile"
            name="mobile_no"
            rules={[{ required: true, message: "Please input mobile number" }]}
          >
            <InputNumber type="number" className="w-100" />
          </Form.Item>
          <Form.Item label="Fax" name="fax_no">
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email_address"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please input a valid email",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      {/* <div className="d-flex justify-content-end gap-2 mb-3">
        <button
          type="submit"
          onClick={handleSaveASDraft}
          className="btn btn-primary"
        >
          Save as Draft
        </button>
        <button
          type="button"
          onClick={handleSaveAndSubmitForReview}
          className="btn btn-warning text-dark"
        >
          Submit for Review
        </button>
      </div> */}
      {isAuthor?.role === "AUTHOR" ? (
        <div className="text-right mt-5">
          <button
            className="btn btn-primary mr-2"
            onClick={(e) => handleFormSubmission(e, "DRAFT")}
          >
            Save as Draft
          </button>
          <button
            className="btn btn-warning"
            onClick={(e) => handleFormSubmission(e, "REVIEW")}
          >
            Submit for Review
          </button>
        </div>
      ) : (
        <div className="text-right mt-5">
          <button
            className="btn btn-danger mr-2"
            onClick={(e) => {
              e.preventDefault();
              setIsRejected((prev) => !prev);
            }}
          >
            Reject
          </button>
          <button
            className="btn btn-success"
            onClick={(e) => handleFormSubmission(e, "APPROVED")}
          >
            Approve
          </button>
        </div>
      )}

      {(isAuthor?.role === "REVIEWER" && rejectionMessage) || isRejected ? (
        <div className="align-items-end d-flex flex-column gap-2">
          <textarea
            name="rejection_message"
            id="rejection_message"
            placeholder="Please write the reason for rejection..."
            value={rejectionMessage}
            onChange={(e) => setRejectionMessage(e.target.value)}
            rows={3}
            className="form-control"
          />
          <button
            onClick={(e) => handleFormSubmission(e, "REJECTED")}
            className="btn btn-primary"
          >
            Save
          </button>
        </div>
      ) : (
        ""
      )}
      {isAuthor?.role === "AUTHOR" && (
        <div className="rejection-messages bg-light p-3 my-3 border rounded">
          <h5>Rejection Messages</h5>
          {rejectionMessages?.length > 0 ? (
            rejectionMessages?.map((item, index) => {
              return (
                <div key={index} className="rejection-message ">
                  <strong>
                    {index + 1}) {item?.reviewer_name} :
                  </strong>{" "}
                  {item?.rejection_message}
                </div>
              );
            })
          ) : (
            <p>No rejection messages available.</p>
          )}
        </div>
      )}
    </>
  );
}
