import React, { useEffect, useRef, useState } from "react";
import { Button, Popconfirm, Table, Modal, Form, Input, Upload } from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  FileExcelOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { ASSET_EMPLOYEE_DOCUMENT } from "../../../../utils/AssetsReferenceTypes";
import { getFileUrl } from "../../../../Helpers/Helpers";
import { NAAC } from "../../../../utils/apiConstants";
import { useReactToPrint } from "react-to-print";
const title = "program";
const sub_title = "programs_offered";
export default function NumberOfCoursesOfferedByTheInstitution({
  setUpdatedData,
  setLoading,
}) {
  const [recordId, setRecordId] = useState(null);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState({});
  console.log("allData", allData);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [badge, setBadge] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [form] = Form.useForm();
  const pdfRef = useRef();
  console.log("uploadedFile", uploadedFile);
  const emp_id = sessionStorage.getItem("employee_id");
  console.log("data", data);
  const handleEdit = (record) => {
    setEditingRecord(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const handleAdd = () => {
    form.resetFields();
    setEditingRecord(null);
    setIsModalVisible(true);
  };
  const extractFileName = (fileName) => {
    const lastDotIndex = fileName.lastIndexOf(".");
    return fileName.substring(0, lastDotIndex); // Removes the extension
  };
  const getALlData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      //  Make this url dynamic after adding the university or college id to the university-profile table
      url: `${NAAC}/get-extended-profile/get-all-data`,
    };

    await axios(config)
      .then((res) => {
        let data = res.data;
        if (data) {
          setAllData(data);
        }
        console.log("data fetched is", res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };
  const handleDelete = (id) => {
    let updatedData = data.filter((item) => {
      if (recordId && item.id === id) {
        item.status = "INACTIVE";
        return true;
      }
      return item.id !== id;
    });
    setData(updatedData);
  };

  const handleSave = () => {
    form.validateFields().then((values) => {
      if (editingRecord) {
        setData(
          data.map((item) =>
            (item.id && item.id === editingRecord.id) ||
            (item.tempId && item.tempId === editingRecord.tempId)
              ? { ...item, ...values, updated_by: emp_id }
              : item
          )
        );
      } else {
        setData([
          ...data,
          {
            ...values,
            id: uuidv4(),
            status: "ACTIVE",
            created_by: emp_id,
            updated_by: emp_id,
          },
        ]);
      }
      setIsModalVisible(false);
    });
  };

  const handleDraftOrSubmit = async (e, status) => {
    e.preventDefault();

    if (data.length === 0) {
      return toast.error("Please add at least one contact detail");
    }
    // Set up the common information fields
    let info = {
      data,
      document_url: fileUrl,
      status: status,
      title: title,
      sub_title: sub_title,
      application_id: 1,
      document_name: uploadedFile,
      ...(recordId
        ? { id: recordId, updated_by: emp_id }
        : { updated_by: emp_id, created_by: emp_id }),
    };

    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/extended-profile`,
      data: info,
    };

    await axios(config)
      .then((res) => {
        console.log("response", res);
        toast.success("Successfully saved the data");
        getFormData(); // Refetch data to update view
        setUpdatedData(uuidv4());
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      });
  };
  const handleSaveAsDraft = (e) => {
    handleDraftOrSubmit(e, "DRAFT");
  };
  useEffect(() => {
    getFormData();
    getALlData();
  }, []);
  const handleSaveAndSubmitForReview = async (e) => {
    handleDraftOrSubmit(e, "REVIEW");
  };
  const columns = [
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
    },
    {
      title: "Number of Courses",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button>
          <Popconfirm
            title="Are you sure to delete this record?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger icon={<DeleteOutlined />}>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];
  const addAttachment = async (file) => {
    try {
      const fileLink = await getFileUrl(
        ASSET_EMPLOYEE_DOCUMENT,
        "Resume",
        extractFileName(file?.name),
        setLoading,
        file
      );
      setFileUrl(fileLink);
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("File upload failed");
    }
  };
  const beforeUpload = (file) => {
    setUploadedFile(file?.name);
    addAttachment(file);
    return false;
  };

  const getFormData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
      url: `${NAAC}/extended-profile/${sub_title}`,
    };
    try {
      const res = await axios(config);
      let result = res?.data[0];
      if (result?.data) {
        let jsonData;
        try {
          // Attempt to parse the data only if it's a string
          jsonData =
            typeof result?.data === "string"
              ? JSON.parse(result?.data)
              : result?.data;
          setData(jsonData);
        } catch (parseError) {
          console.error("Error parsing JSON:", parseError);
          toast.error("Failed to parse data");
        }
      } else {
        // toast.error("No data found");
        console.log("No data found");
      }

      // let jsonData = JSON.parse(result?.data);
      // setData(jsonData);
      setBadge(result?.status);
      setFileUrl(result?.document_url);
      setRecordId(result?.id);
      setUploadedFile(result?.document_name);
    } catch (err) {
      // toast.error("Failed to fetch data");
      console.log(err);
    } finally {
      setLoading(0);
    }
  };
  const printPdf = useReactToPrint({
    content: () => pdfRef.current,
  });
  const handlePrint = () => {
    pdfRef.current.style.display = "block";
    setTimeout(() => {
      printPdf();
      pdfRef.current.style.display = "none";
    }, 1);
  };

  return (
    <>
      <Modal
        title={editingRecord ? "Edit Record" : "Add New Record"}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSave}
        okText="Save"
        cancelText="Cancel"
      >
        <Form form={form} layout="vertical">
          <Form.Item label="Year" name="year" rules={[{ required: true }]}>
            <Input type="text" />
          </Form.Item>
          <Form.Item
            label="Number of Courses"
            name="number"
            rules={[{ required: true }]}
          >
            <Input type="number" />
          </Form.Item>
        </Form>
      </Modal>
      <div className="d-flex justify-content-end">
        {badge == "DRAFT" || badge == "REVIEW" ? (
          <button type="button" class="btn btn-primary" onClick={handlePrint}>
            Export PDF
          </button>
        ) : (
          ""
        )}
      </div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex flex-column">
          <span className="fw-semibold h5">
            Number of programs offered year-wise for last five years
          </span>
          <span
            className={`text-white d-inline-block text-center px-4 py-1 shadow-sm text-xs rounded-pill ${
              badge === "DRAFT"
                ? "bg-primary"
                : badge === "REVIEW"
                ? "bg-warning"
                : badge === "REJECTED"
                ? "bg-danger"
                : badge === "APPROVED"
                ? "bg-success"
                : "bg-secondary"
            }`}
            style={{ width: "fit-content" }}
          >
            {badge === "DRAFT"
              ? "In Draft"
              : badge === "REVIEW"
              ? "Submitted for review"
              : badge === "REJECTED"
              ? "Rejected"
              : badge === "APPROVED"
              ? "Approved"
              : "Not Started"}
          </span>
        </div>
        <Button type="primary" onClick={handleAdd} icon={<PlusOutlined />}>
          Add New Record
        </Button>
      </div>

      <Table
        dataSource={data.filter((item) => item.status !== "INACTIVE")}
        columns={columns}
        rowKey="id"
        pagination={false}
      />

      <div className="my-4">
        <span className="h5">Upload Data Template</span>
        <div className="d-flex flex-column my-2 gap-2 mt-2">
          <a
            className="text-primary mb-3"
            href="/templates/Extended Profile 1.1 Template.xlsx"
            download
          >
            <FileExcelOutlined /> Download 1.1 Template
          </a>
          {fileUrl ? (
            <div className="d-flex">
              <div className="mr-2">
                <a
                  className=" btn btn-primary mr-4"
                  style={{ width: "fit-content" }}
                  href={fileUrl}
                  download
                  target="_blank"
                >
                  <DownloadOutlined /> Download
                </a>
              </div>
              <button onClick={() => setFileUrl("")} className="btn btn-danger">
                <DeleteOutlined /> Delete
              </button>
            </div>
          ) : (
            <Upload accept=".pdf,.xlsx,.docx,.zip" beforeUpload={beforeUpload}>
              <button className="btn btn-primary">
                <UploadOutlined /> Browse
              </button>
            </Upload>
          )}

          {fileUrl ? (
            <div style={{ marginTop: "10px" }}>{uploadedFile}</div>
          ) : (
            ""
          )}
        </div>
      </div>
      {allData?.programs_offered && Array.isArray(allData.programs_offered) ? (
        <div ref={pdfRef} style={{ display: "none" }}>
          <div className="m-5 d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h4>1 Program</h4>
              <div>
                <h5>
                  1.1 Number of programs offered year-wise for last five years
                </h5>
                {allData?.programs_offered[0]?.data?.length > 0 ? (
                  <table className="table table-bordered nowrap table-hover">
                    <tr>
                      {allData?.programs_offered[0]?.data?.map((item, i) => (
                        <td key={i}>{item.year}</td>
                      ))}
                    </tr>
                    <tr>
                      {allData?.programs_offered[0]?.data?.map((item, i) => (
                        <td key={i}>{item.number}</td>
                      ))}
                    </tr>
                  </table>
                ) : (
                  "N/A"
                )}
                <table className="table table-bordered nowrap table-hover">
                  <tbody>
                    <tr>
                      <th>File Description</th>
                      <th>Document</th>
                    </tr>
                    <tr>
                      <td>Data Template</td>
                      <td>
                        {allData?.programs_offered[0]?.document_url ? (
                          <a
                            target="_blank"
                            href={allData?.programs_offered[0]?.document_url}
                          >
                            View Document
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <h5>1.2 Number of departments offering academic programmes</h5>
                <span>
                  <strong>Response: </strong>{" "}
                  {allData?.departments_offering[0]?.response || "N/A"}
                </span>
              </div>
            </div>
            <div className="d-flex flex-column gap-3">
              <h4>2 Students</h4>
              <div className="d-flex flex-column gap-2">
                <h5>2.1 Number of students year-wise during last five years</h5>
                {allData?.number_of_students[0]?.data?.length > 0 ? (
                  <table className="table table-bordered nowrap table-hover">
                    <tr>
                      {allData?.number_of_students[0]?.data?.map((item, i) => (
                        <td key={i}>{item.year}</td>
                      ))}
                    </tr>
                    <tr>
                      {allData?.number_of_students[0]?.data?.map((item, i) => (
                        <td key={i}>{item.number}</td>
                      ))}
                    </tr>
                  </table>
                ) : (
                  "N/A"
                )}
                <table className="table table-bordered nowrap table-hover">
                  <tbody>
                    <tr>
                      <th>File Description</th>
                      <th>Document</th>
                    </tr>
                    <tr>
                      <td>Data Template</td>
                      <td>
                        {allData?.number_of_students[0]?.document_url ? (
                          <a
                            target="_blank"
                            href={allData?.number_of_students[0]?.document_url}
                          >
                            View Document
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex flex-column gap-2">
                <h5>
                  2.2 Number of outgoing / final year students year-wise during
                  last five years
                </h5>
                {allData?.number_of_outgoing_students[0]?.data.length > 0 ? (
                  <table className="table table-bordered nowrap table-hover">
                    <tr>
                      {allData?.number_of_outgoing_students[0]?.data?.map(
                        (item, i) => (
                          <td key={i}>{item.year}</td>
                        )
                      )}
                    </tr>
                    <tr>
                      {allData?.number_of_outgoing_students[0]?.data?.map(
                        (item, i) => (
                          <td key={i}>{item.number}</td>
                        )
                      )}
                    </tr>
                  </table>
                ) : (
                  "N/A"
                )}
                <table className="table table-bordered nowrap table-hover">
                  <tbody>
                    <tr>
                      <th>File Description</th>
                      <th>Document</th>
                    </tr>
                    <tr>
                      <td>Data Template</td>
                      <td>
                        {allData?.number_of_outgoing_students[0]
                          ?.document_url ? (
                          <a
                            target="_blank"
                            href={
                              allData?.number_of_outgoing_students[0]
                                .document_url
                            }
                          >
                            View Document
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex flex-column gap-2">
                <h5>
                  2.3 Number of students appeared in the University examination
                  year-wise during the last five years
                </h5>
                {allData?.number_of_students_appeared_in_exam[0]?.data?.length >
                0 ? (
                  <table className="table table-bordered nowrap table-hover">
                    <tr>
                      {allData?.number_of_students_appeared_in_exam[0]?.data?.map(
                        (item, i) => (
                          <td key={i}>{item.year}</td>
                        )
                      )}
                    </tr>
                    <tr>
                      {allData?.number_of_students_appeared_in_exam[0]?.data?.map(
                        (item, i) => (
                          <td key={i}>{item.number}</td>
                        )
                      )}
                    </tr>
                  </table>
                ) : (
                  "N/A"
                )}
                <table className="table table-bordered nowrap table-hover">
                  <tbody>
                    <tr>
                      <th>File Description</th>
                      <th>Document</th>
                    </tr>
                    <tr>
                      <td>Data Template</td>
                      <td>
                        {allData?.number_of_students_appeared_in_exam[0]
                          ?.document_url ? (
                          <a
                            target="_blank"
                            href={
                              allData?.number_of_students_appeared_in_exam[0]
                                ?.document_url
                            }
                          >
                            View Document
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex flex-column gap-2">
                <h5>
                  2.4 Number of revaluation applications year-wise during the
                  last 5 years
                </h5>
                {badge != "" &&
                allData?.number_of_revaluations[0]?.data?.length > 0 ? (
                  <table className="table table-bordered nowrap table-hover">
                    <tr>
                      {allData?.number_of_revaluations[0]?.data?.map(
                        (item, i) => (
                          <td key={i}>{item.year || "N/A"}</td>
                        )
                      )}
                    </tr>
                    <tr>
                      {allData?.number_of_revaluations[0]?.data?.map(
                        (item, i) => (
                          <td key={i}>{item.number || "N/A"}</td>
                        )
                      )}
                    </tr>
                  </table>
                ) : (
                  "N/A"
                )}
                <table className="table table-bordered nowrap table-hover">
                  <tbody>
                    <tr>
                      <th>File Description</th>
                      <th>Document</th>
                    </tr>
                    <tr>
                      <td>Data Template</td>
                      <td>
                        {allData?.number_of_revaluations[0]?.document_url ? (
                          <a
                            target="_blank"
                            href={
                              allData?.number_of_revaluations[0]?.document_url
                            }
                          >
                            View Document
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="d-flex flex-column gap-3">
              <h4>3 Teachers</h4>
              <div className="d-flex flex-column gap-2">
                <h5>
                  3.1 Number of courses in all programs year-wise during last
                  five years
                </h5>
                {allData?.number_of_courses_in_all_programs[0]?.data?.length >
                0 ? (
                  <table className="table table-bordered nowrap table-hover">
                    <tr>
                      {allData?.number_of_courses_in_all_programs[0]?.data?.map(
                        (item, i) => (
                          <td key={i}>{item.year || "N/A"}</td>
                        )
                      )}
                    </tr>
                    <tr>
                      {allData?.number_of_courses_in_all_programs[0]?.data?.map(
                        (item, i) => (
                          <td key={i}>{item.number || "N/A"}</td>
                        )
                      )}
                    </tr>
                  </table>
                ) : (
                  "N/A"
                )}
                <table className="table table-bordered nowrap table-hover">
                  <tbody>
                    <tr>
                      <th>File Description</th>
                      <th>Document</th>
                    </tr>
                    <tr>
                      <td>Data Template</td>
                      <td>
                        {allData?.number_of_courses_in_all_programs[0]
                          ?.document_url ? (
                          <a
                            target="_blank"
                            href={
                              allData?.number_of_courses_in_all_programs[0]
                                ?.document_url
                            }
                          >
                            View Document
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex flex-column gap-2">
                <h5>
                  3.2 Number of full time teachers year-wise during the last
                  five years
                </h5>
                {allData?.fulltime_teachers[0]?.data?.length > 0 ? (
                  <table className="table table-bordered nowrap table-hover">
                    <tr>
                      {allData?.fulltime_teachers[0]?.data?.map((item, i) => (
                        <td key={i}>{item.year || "N/A"}</td>
                      ))}
                    </tr>
                    <tr>
                      {allData?.fulltime_teachers[0]?.data?.map((item, i) => (
                        <td key={i}>{item.number || "N/A"}</td>
                      ))}
                    </tr>
                  </table>
                ) : (
                  "N/A"
                )}
                <table className="table table-bordered nowrap table-hover">
                  <tbody>
                    <tr>
                      <th>File Description</th>
                      <th>Document</th>
                    </tr>
                    <tr>
                      <td>Data Template</td>
                      <td>
                        {allData?.fulltime_teachers[0]?.document_url ? (
                          <a
                            target="_blank"
                            href={allData?.fulltime_teachers[0]?.document_url}
                          >
                            View Document
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex flex-column gap-2">
                <h5>
                  3.3 Number of sanctioned posts year-wise during last five
                  years
                </h5>
                {allData?.sanctioned_posts[0]?.data?.length > 0 ? (
                  <table className="table table-bordered nowrap table-hover">
                    <tr>
                      {allData?.sanctioned_posts[0]?.data.map((item, i) => (
                        <td key={i}>{item.year || "N/A"}</td>
                      ))}
                    </tr>
                    <tr>
                      {allData?.sanctioned_posts[0]?.data.map((item, i) => (
                        <td key={i}>{item.number || "N/A"}</td>
                      ))}
                    </tr>
                  </table>
                ) : (
                  "N/A"
                )}
                <table className="table table-bordered nowrap table-hover">
                  <tbody>
                    <tr>
                      <th>File Description</th>
                      <th>Document</th>
                    </tr>
                    <tr>
                      <td>Data Template</td>
                      <td>
                        {allData?.sanctioned_posts[0]?.document_url ? (
                          <a
                            target="_blank"
                            href={allData?.sanctioned_posts[0]?.document_url}
                          >
                            View Document
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex flex-column gap-3">
              <h4>4 Institution</h4>
              <div className="d-flex flex-column gap-2">
                <h5>
                  4.1 Number of eligible applications received for admissions to
                  all the programs year-wise during last five years
                </h5>
                {allData?.number_of_eligible_applications[0]?.data?.length >
                0 ? (
                  <table className="table table-bordered nowrap table-hover">
                    <tr>
                      {allData?.number_of_eligible_applications[0]?.data?.map(
                        (item, i) => (
                          <td key={i}>{item.year || "N/A"}</td>
                        )
                      )}
                    </tr>
                    <tr>
                      {allData?.number_of_eligible_applications[0]?.data?.map(
                        (item, i) => (
                          <td key={i}>{item.number || "N/A"}</td>
                        )
                      )}
                    </tr>
                  </table>
                ) : (
                  "N/A"
                )}
                <table className="table table-bordered nowrap table-hover">
                  <tbody>
                    <tr>
                      <th>File Description</th>
                      <th>Document</th>
                    </tr>
                    <tr>
                      <td>Data Template</td>
                      <td>
                        {allData?.number_of_eligible_applications[0]
                          ?.document_url ? (
                          <a
                            target="_blank"
                            href={
                              allData?.number_of_eligible_applications[0]
                                ?.document_url
                            }
                          >
                            View Document
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex flex-column gap-2">
                <h5>
                  4.2 Number of seats earmarked for reserved category as per
                  GOI/State Govt rule year-wise during last five years
                </h5>
                {allData?.seats_for_reserved_category[0]?.data.length > 0 ? (
                  <table className="table table-bordered nowrap table-hover">
                    <tr>
                      {allData?.seats_for_reserved_category[0]?.data?.map(
                        (item, i) => (
                          <td key={i}>{item.year || "N/A"}</td>
                        )
                      )}
                    </tr>
                    <tr>
                      {allData?.seats_for_reserved_category[0]?.data?.map(
                        (item, i) => (
                          <td key={i}>{item.number || 0}</td>
                        )
                      )}
                    </tr>
                  </table>
                ) : (
                  "N/A"
                )}
                <table className="table table-bordered nowrap table-hover">
                  <tbody>
                    <tr>
                      <th>File Description</th>
                      <th>Document</th>
                    </tr>
                    <tr>
                      <td>Data Template</td>
                      <td>
                        {allData?.seats_for_reserved_category[0]
                          ?.document_url ? (
                          <a
                            target="_blank"
                            href={
                              allData?.seats_for_reserved_category[0]
                                ?.document_url
                            }
                          >
                            View Document
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex flex-column gap-2">
                <h5>4.3 Total number of classrooms and seminar halls</h5>

                <span>
                  <strong>Response: </strong>{" "}
                  {allData?.classrooms_and_seminar_hall[0]?.response || "N/A"}
                </span>
              </div>
              <br />
              <br />
              <br />
              <br />
              <div className="d-flex flex-column gap-2">
                <h5>
                  4.4 Total number of computers in the campus for academic
                  purpose
                </h5>

                <span>
                  <strong>Response: </strong>{" "}
                  {allData?.number_of_computers[0]?.response || "N/A"}
                </span>
              </div>
              <div className="d-flex flex-column gap-2">
                <h5>
                  4.5 Total Expenditure excluding salary year-wise during last
                  five years ( INR in Lakhs)
                </h5>

                {allData?.expenditure[0]?.data?.length > 0 ? (
                  <table className="table table-bordered nowrap table-hover">
                    <tr>
                      {allData?.expenditure[0]?.data?.map((item, i) => (
                        <td key={i}>{item.year || "N/A"}</td>
                      ))}
                    </tr>
                    <tr>
                      {allData?.expenditure[0]?.data?.map((item, i) => (
                        <td key={i}>{item.number || 0}</td>
                      ))}
                    </tr>
                  </table>
                ) : (
                  "N/A"
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="d-flex justify-content-end gap-2 mb-3">
        <button
          type="submit"
          onClick={handleSaveAsDraft}
          className="btn btn-primary"
        >
          Save as Draft
        </button>
        <button
          type="button"
          onClick={handleSaveAndSubmitForReview}
          className="btn btn-warning text-dark"
        >
          Submit for Review
        </button>
      </div>
    </>
  );
}
