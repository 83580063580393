import InputTag from "../../../../Components/InputTag.jsx";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { NAAC } from "../../../../utils/apiConstants.js";
import { Select } from "antd";
import { international } from "../../../../Data/Countries/international.js";
import SelectTag from "../../../../Components/SelectTag.jsx";
import { v4 as uuidv4 } from "uuid";
import { useReactToPrint } from "react-to-print";
import getIsNAACAuthorOrReviewer from "../../../../utils/NAAC/getIsNAACAuthorOrReviewer.js";
export default function BasicInformation({
  setUpdatedData,
  setLoading,
  isAuthor,
  college_id,
}) {
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    address: "",
    city: "",
    state: "",
    country: "",
    pin_code: "",
    website: "",
    nature_of_university: "",
    type_of_institute: "",
    established_date_of_university: "",
    college_affiliated_to_university: "",
    two_f_of_ugc: "",
    twelve_b_of_ugc: "",
    created_by: "",
    source_of_funding: "",
    nature_of_university_manual: "",
    status: "",
  });
  const [isRejected, setIsRejected] = useState(false);
  const [rejectionMessage, setRejectionMessage] = useState("");
  const [rejectionMessages, setRejectionMessages] = useState("");
  const pdfRef = useRef();
  const [allData, setAllData] = useState({});
  const [countries, setCountries] = useState([]);
  const [city, setCity] = useState([]);
  const handleOnchange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  let emp_id = sessionStorage.getItem("employee_id");
  const setCountryAndState = async () => {
    let arr = [];
    await international?.map((item, key) => {
      arr.push({ label: item?.country, value: item?.country });
    });
    setCountries(arr);
  };
  const printPdf = useReactToPrint({
    content: () => pdfRef.current,
  });
  const handlePrint = () => {
    pdfRef.current.style.display = "block";
    setTimeout(() => {
      printPdf();
      pdfRef.current.style.display = "none";
    }, 1);
  };
  console.log("isAuthor", isAuthor);
  const getFormData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      //  Make this url dynamic after adding the university or college id to the university-profile table
      url: `${NAAC}/university-profile/${isAuthor?.application_id}?role=${isAuthor?.role}&emp_id=${emp_id}`,
    };

    await axios(config)
      .then((res) => {
        let data = res.data?.data[0];
        if (data) {
          setFormData({
            ...data,
            two_f_of_ugc: data["2f_of_ugc"],
            twelve_b_of_ugc: data["12b_of_ugc"],
          });
          if (isAuthor.role === "AUTHOR") {
            setRejectionMessages(res?.data?.rejected_messages);
          }
          setRejectionMessage(data?.rejection_message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message || "Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };
  const getALlData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      //  Make this url dynamic after adding the university or college id to the university-profile table
      url: `${NAAC}/get-all-data`,
    };

    await axios(config)
      .then((res) => {
        let data = res.data;
        if (data) {
          setAllData(data);
        }
        console.log("data fetched is", res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message || "Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };
  useEffect(() => {
    setCountryAndState();
    getALlData();
  }, []);
  useEffect(() => {
    if (isAuthor?.application_id) {
      getFormData();
    }
  }, [isAuthor?.application_id]);

  useEffect(() => {
    const fetchToken = async () => {
      let response;
      let data;

      try {
        if (formData.country && formData.state) {
          // console.log("Fetching cities for:", formData.country, formData.state);

          response = await fetch(
            "https://countriesnow.space/api/v0.1/countries/state/cities",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                country: formData.country,
                state: formData.state,
              }),
            }
          );

          if (!response.ok) {
            const errorBody = await response.text();
            console.error(`Error: ${response.status}`, errorBody);
            throw new Error(`Error: ${response.status}`);
          }

          // Parsing the JSON response
          data = await response.json();
          setCity(data.data);
          console.log("Fetched cities:", data); // Log the cities
        }
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };

    fetchToken();
  }, [formData.country, formData.state]);
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const requiredFields = [
    "name",
    "address",
    "city",
    "state",
    "country",
    "pin_code",
    "website",
    "nature_of_university",
    "type_of_institute",
    "college_affiliated_to_university",
    "established_date_of_university",
    "two_f_of_ugc",
    "twelve_b_of_ugc",
    "source_of_funding",
  ];

  // Function to validate required fields
  const validateFields = (fields, data) => {
    for (const field of fields) {
      if (!data[field]) {
        toast.error(`Please fill in the ${field.split("_").join(" ")} field`);
        return false;
      }
    }
    return true;
  };

  // Function to handle form submission
  const handleFormSubmission = async (e, status) => {
    e.preventDefault();
    if (status === "REJECTED") {
      if (!rejectionMessage) {
        return toast.error("Please fill the reason for rejection");
      }
    }
    if (status === "APPROVED") {
      setIsRejected(false);
    }

    setLoading(1);
    if (!validateFields(requiredFields, formData)) {
      setLoading(0);
      return;
    }
    const method = "post";
    const successMessage =
      status === "DRAFT"
        ? "Successfully saved as draft"
        : "Successfully submitted for review";
    const config = {
      method,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${NAAC}/university-profile`,
      data: {
        ...formData,
        status,
        college_id,
        application_id: isAuthor?.application_id,
        role: isAuthor?.role,
        rejection_message: status === "APPROVED" ? "" : rejectionMessage,
        ...(formData.id
          ? { id: formData.id, updated_by: emp_id }
          : { updated_by: emp_id, created_by: emp_id }),
      },
    };

    await axios(config)
      .then(() => {
        toast.success(successMessage);
        getFormData();
        setUpdatedData(uuidv4());
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message || "Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };

  return (
    <>
      <div className=" mt-2">
        <div className="d-flex justify-content-end">
          {formData.status !== "" ? (
            <button type="button" class="btn btn-primary" onClick={handlePrint}>
              Export PDF
            </button>
          ) : (
            ""
          )}
        </div>
        <h5> BASIC INFORMATION</h5>
        <form>
          <div className="d-flex flex-column gap-5">
            <div>
              <div className="mb-3 d-flex flex-column">
                <span className="fw-semibold text-dark h5">
                  A. Name and Address of the University
                </span>

                <span
                  className={`text-white d-inline-block text-center px-4 py-1 shadow-sm small rounded-pill ${
                    formData.status === "DRAFT"
                      ? "bg-primary"
                      : formData.status === "REVIEW"
                      ? "bg-warning"
                      : formData.status === "REJECTED"
                      ? "bg-danger"
                      : formData.status === "APPROVED"
                      ? "bg-success"
                      : "bg-secondary"
                  }`}
                  style={{ width: "fit-content" }}
                >
                  {formData.status === "DRAFT"
                    ? "In Draft"
                    : formData.status === "REVIEW"
                    ? "Submitted for review"
                    : formData.status === "REJECTED"
                    ? "Rejected"
                    : formData.status === "APPROVED"
                    ? "Approved"
                    : "Not Started"}
                </span>
              </div>

              <div className="mt-3 row g-4">
                <div className="col-md-6">
                  <InputTag
                    type="text"
                    name="name"
                    label="Name"
                    onChange={handleOnchange}
                    value={formData.name}
                    required={true}
                    disabled={isAuthor?.role !== "AUTHOR"}
                  />
                </div>
                <div className="col-md-6">
                  <InputTag
                    type="text"
                    name="website"
                    label="Website"
                    onChange={handleOnchange}
                    value={formData.website}
                    required={true}
                    disabled={isAuthor?.role !== "AUTHOR"}
                  />
                </div>
              </div>
              <div className="row g-4">
                <div className="col-md-3 form-group">
                  <label className="form-label" htmlFor="country">
                    Country
                  </label>
                  <Select
                    size="large"
                    showSearch
                    id="country"
                    placeholder="Select country"
                    optionFilterProp="children"
                    onChange={(value) => {
                      setFormData({ ...formData, country: value });
                    }}
                    filterOption={filterOption}
                    value={formData.country}
                    options={countries}
                    className="d-block w-100"
                    disabled={isAuthor?.role !== "AUTHOR"}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label" htmlFor="state">
                    State
                  </label>
                  <Select
                    size="large"
                    showSearch
                    id="state"
                    placeholder="Select state"
                    optionFilterProp="children"
                    onChange={(value) => {
                      setFormData({ ...formData, state: value });
                    }}
                    filterOption={filterOption}
                    value={formData.state}
                    options={
                      international
                        ?.find((s) => s.country === formData?.country)
                        ?.states?.map((i) => ({ value: i, label: i })) || []
                    }
                    className="d-block w-100"
                    disabled={isAuthor?.role !== "AUTHOR"}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label" htmlFor="city">
                    City
                  </label>
                  {city.length > 0 ? (
                    <Select
                      size="large"
                      showSearch
                      onChange={(value) => {
                        setFormData({ ...formData, city: value });
                      }}
                      value={formData.city}
                      className="d-block w-100"
                      disabled={isAuthor?.role !== "AUTHOR"}
                    >
                      {city.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                      <option value={"other"}>Other</option>
                    </Select>
                  ) : (
                    <InputTag
                      type="text"
                      name="city"
                      value={formData.city}
                      onChange={handleOnchange}
                      disabled={isAuthor?.role !== "AUTHOR"}
                    />
                  )}
                  {formData.city === "other" && (
                    <InputTag
                      type="text"
                      name="city"
                      value={formData.city}
                      placeholder="Please enter the city"
                      onChange={(value) => {
                        setFormData({ ...formData, city: value });
                      }}
                      disabled={isAuthor?.role !== "AUTHOR"}
                    />
                  )}
                </div>
                <div className="col-md-3">
                  <InputTag
                    type="number"
                    name="pin_code"
                    label="Pin Code"
                    onChange={handleOnchange}
                    value={formData.pin_code}
                    disabled={isAuthor?.role !== "AUTHOR"}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="address" className="form-label">
                  Address
                </label>
                <textarea
                  rows={5}
                  name="address"
                  id="address"
                  onChange={handleOnchange}
                  value={formData.address}
                  placeholder="Enter the address of the institution"
                  className="form-control"
                  disabled={isAuthor?.role !== "AUTHOR"}
                ></textarea>
              </div>
            </div>
            <div class="bg-danger border-2 border-top border-secondary " />
            <div>
              <span className="fw-semibold text-dark h5">
                B. Nature & Type of University
              </span>

              <div className="mt-3 row ">
                <div className="col-md-6 ">
                  <div className="form-group">
                    <label
                      htmlFor="nature_of_university"
                      className="form-label"
                    >
                      Please select the status of Institution
                    </label>
                    <select
                      id="nature_of_university"
                      name="nature_of_university"
                      value={formData.nature_of_university}
                      className="form-control"
                      onChange={(e) => {
                        setFormData((prev) => ({
                          ...prev,
                          nature_of_university: e.target.value,
                        }));
                      }}
                      disabled={isAuthor?.role !== "AUTHOR"}
                    >
                      <option disabled value="default">
                        Select Option
                      </option>
                      <option value="Affiliated College">
                        Affiliated College
                      </option>
                      <option value="Constituent College">
                        Constituent College
                      </option>
                      <option value="other">Other</option>
                    </select>
                  </div>

                  {formData.nature_of_university === "other" && (
                    <InputTag
                      type="text"
                      name="nature_of_university_manual"
                      value={formData.nature_of_university_manual}
                      label="Other (Please Specify)"
                      onChange={handleOnchange}
                      disabled={isAuthor?.role !== "AUTHOR"}
                    />
                  )}
                </div>
                <div className="col-md-6">
                  <InputTag
                    type="text"
                    name="type_of_institute"
                    label="Type of institute"
                    value={formData.type_of_institute}
                    onChange={handleOnchange}
                    disabled={isAuthor?.role !== "AUTHOR"}
                  />
                </div>
              </div>
            </div>
            <div class="bg-danger border-2 border-top border-secondary " />
            <div>
              <span className="fw-semibold text-dark h5">
                C. Date Of Establishment And Affiliation Details
              </span>

              <div className="mt-3 row g-4">
                <div className="col-md-4">
                  <InputTag
                    type="date"
                    name="established_date_of_university"
                    label="Date Of Establishment of College"
                    value={formData.established_date_of_university}
                    onChange={handleOnchange}
                    disabled={isAuthor?.role !== "AUTHOR"}
                  />
                </div>
                <div className="col-md-8">
                  <InputTag
                    type="text"
                    name="college_affiliated_to_university"
                    label="University to which the college is affiliated or which governs the college (If it is a constituent college)"
                    onChange={handleOnchange}
                    value={formData.college_affiliated_to_university}
                    disabled={isAuthor?.role !== "AUTHOR"}
                  />
                </div>
              </div>
            </div>
            <div class="bg-danger border-2 border-top border-secondary " />
            <div>
              <span className="fw-semibold text-dark h5">
                D. Recognition Details
              </span>

              <div className="mt-3 row g-4">
                <div className="col-md-6">
                  <InputTag
                    type="date"
                    name="two_f_of_ugc"
                    label="2F of UGC"
                    onChange={handleOnchange}
                    value={formData.two_f_of_ugc}
                    disabled={isAuthor?.role !== "AUTHOR"}
                  />
                </div>
                <div className="col-md-6">
                  <InputTag
                    type="date"
                    name="twelve_b_of_ugc"
                    label="12B of UGC"
                    onChange={handleOnchange}
                    value={formData.twelve_b_of_ugc}
                    disabled={isAuthor?.role !== "AUTHOR"}
                  />
                </div>
              </div>
            </div>
            <div class="bg-danger border-2 border-top border-secondary " />
            <div className="d-flex flex-column">
              <span className="fw-semibold text-dark h5">
                E. Source of Funding
              </span>

              <div className="row">
                <div className="mb-3 col-md-6">
                  <SelectTag
                    name="source_of_funding"
                    label="Source Of Funding"
                    data={[
                      { id: 0, value: "Government" },
                      { id: 1, value: "Grant-In-Aid" },
                      { id: 2, value: "Self Financing" },
                      { id: 3, value: "Other" },
                    ]}
                    onChange={handleOnchange}
                    value={formData.source_of_funding}
                    disabled={isAuthor?.role !== "AUTHOR"}
                  />
                </div>
              </div>
            </div>
            {isAuthor?.role === "AUTHOR" ? (
              <div className="text-right mt-5">
                <button
                  className="btn btn-primary mr-2"
                  onClick={(e) => handleFormSubmission(e, "DRAFT")}
                >
                  Save as Draft
                </button>
                <button
                  className="btn btn-warning"
                  onClick={(e) => handleFormSubmission(e, "REVIEW")}
                >
                  Submit for Review
                </button>
              </div>
            ) : (
              <div className="text-right mt-5">
                <button
                  className="btn btn-danger mr-2"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsRejected((prev) => !prev);
                  }}
                >
                  Reject
                </button>
                <button
                  className="btn btn-success"
                  onClick={(e) => handleFormSubmission(e, "APPROVED")}
                >
                  Approve
                </button>
              </div>
            )}

            {(isAuthor?.role === "REVIEWER" && rejectionMessage) ||
            isRejected ? (
              <div className="align-items-end d-flex flex-column gap-2">
                <textarea
                  name="rejection_message"
                  id="rejection_message"
                  placeholder="Please write the reason for rejection..."
                  value={rejectionMessage}
                  onChange={(e) => setRejectionMessage(e.target.value)}
                  rows={3}
                  className="form-control"
                />
                <button
                  onClick={(e) => handleFormSubmission(e, "REJECTED")}
                  className="btn btn-primary"
                >
                  Save
                </button>
              </div>
            ) : (
              ""
            )}
            {isAuthor?.role === "AUTHOR" && (
              <div className="rejection-messages bg-light p-3 my-3 border rounded">
                <h5>Rejection Messages</h5>
                {rejectionMessages?.length > 0 ? (
                  rejectionMessages?.map((item, index) => {
                    return (
                      <div key={index} className="rejection-message ">
                        <strong>
                          {index + 1}) {item?.reviewer_name} :
                        </strong>{" "}
                        {item?.rejection_message}
                      </div>
                    );
                  })
                ) : (
                  <p>No rejection messages available.</p>
                )}
              </div>
            )}
          </div>
        </form>

        {formData.status != "" &&
          allData?.universityProfile &&
          Array.isArray(allData.universityProfile) && (
            <div ref={pdfRef} style={{ display: "none" }}>
              <div className="m-5 d-flex flex-column gap-4">
                <h3>2. PROFILE</h3>
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column gap-2">
                    <h4>2.1 BASIC INFORMATION</h4>
                    <div>
                      <table className="table table-bordered nowrap table-hover">
                        <tbody>
                          <tr>
                            <th colSpan={2}>
                              <h5>Name and Address of the University</h5>
                            </th>
                          </tr>
                          <tr>
                            <th>Name</th>
                            <td>
                              {allData?.universityProfile[0]?.name || "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <th>Address</th>
                            <td>
                              {allData?.universityProfile[0]?.address || "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <th>City</th>
                            <td>
                              {allData?.universityProfile[0]?.city || "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <th>State</th>
                            <td>
                              {allData?.universityProfile[0]?.state || "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <th>Pin</th>
                            <td>
                              {allData?.universityProfile[0]?.pin_code || "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <th>Website</th>
                            <td>
                              {allData?.universityProfile[0]?.website || "N/A"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <br />
                    <div>
                      <table className="table table-bordered nowrap table-hover">
                        <tbody>
                          <tr>
                            <th colSpan={2}>
                              <h5>Nature & Type of University</h5>
                            </th>
                          </tr>
                          <tr>
                            <th>Nature of University</th>
                            <td>
                              {allData?.universityProfile[0]
                                ?.nature_of_university || "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <th>Type of institute</th>
                            <td>
                              {allData?.universityProfile[0]
                                ?.type_of_institute || "N/A"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <br />
                    <div>
                      <table className="table table-bordered nowrap table-hover">
                        <tbody>
                          <tr>
                            <th colSpan={2}>
                              <h5>Establishment Details</h5>
                            </th>
                          </tr>
                          <tr>
                            <th>Establishment Date of the University</th>
                            <td className="text-nowrap">
                              {allData?.universityProfile[0]
                                ?.established_date_of_university || "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <th className="text-wrap">
                              University to which the college is affiliated or
                              which governs the college (If it is a constituent
                              college)
                            </th>
                            <td className="text-nowrap">
                              {allData?.universityProfile[0]
                                ?.college_affiliated_to_university || "N/A"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <br />
                    <div>
                      <table className="table table-bordered nowrap table-hover">
                        <tbody>
                          <tr>
                            <th colSpan={2}>
                              <h5>Recognition Details</h5>
                            </th>
                          </tr>
                          <tr>
                            <th colSpan={2}>
                              Date of Recognition as a University by UGC or Any
                              Other National Agency :
                            </th>
                          </tr>
                          <tr>
                            <th>Under Section</th>
                            <th>Date</th>
                          </tr>
                          <tr>
                            <td>2f of UGC </td>
                            <td>
                              {allData?.universityProfile[0]?.["2f_of_ugc"] ||
                                "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>12B of UGC </td>
                            <td>
                              {allData?.universityProfile[0]?.["12b_of_ugc"] ||
                                "N/A"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div>
                      <table className="table table-bordered nowrap table-hover">
                        <tbody>
                          <tr>
                            <th colSpan={2}>
                              <h5>Source of Funding</h5>
                            </th>
                          </tr>
                          <tr>
                            <th>Source of Funding</th>
                            <td>
                              {allData?.universityProfile[0]
                                ?.source_of_funding || "N/A"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <br />
                    <div>
                      <table className="table table-bordered nowrap table-hover">
                        <tbody>
                          <tr>
                            <th colSpan={6}>
                              <h5>Contacts for Communication</h5>
                            </th>
                          </tr>
                          <tr>
                            <th>Designation</th>
                            <th>Name</th>
                            <th>Telephone with STD Code</th>
                            <th>Mobile</th>
                            <th>Fax</th>
                            <th>Email</th>
                          </tr>
                          {allData?.contactPersons?.map((item, i) => (
                            <tr key={item?.mobile || uuidv4()}>
                              <td>{item?.designation || "N/A"}</td>
                              <td>{item?.name || "N/A"}</td>
                              <td>{item?.telephone || "N/A"}</td>
                              <td>{item?.mobile_no || "N/A"}</td>
                              <td>{item?.fax_no || "N/A"}</td>
                              <td>{item?.email_address || "N/A"}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                    <div>
                      <table className="table table-bordered nowrap table-hover">
                        <tbody>
                          <tr>
                            <th colSpan={8}>
                              <h5> Location, Area and Activity of Campus</h5>
                            </th>
                          </tr>
                          <tr>
                            <th>Campus Type</th>
                            <th>Address</th>
                            <th>Location*</th>
                            <th>Campus Area in Acres</th>
                            <th>Built up Area in sq.mts</th>
                            <th>Programmes Offered</th>
                            <th>Date of Establishment</th>
                            <th>Date of Recognition by UGC/MHRD</th>
                          </tr>
                          {allData?.universityLocation?.map((item, i) => (
                            <tr key={i}>
                              <td>{item?.campus_type || "N/A"}</td>
                              <td>{item?.address || "N/A"}</td>
                              <td>{item?.location || "N/A"}</td>
                              <td>{item?.campus_area_in_acres || "N/A"}</td>
                              <td>{item?.built_up_in_sqmt || "N/A"}</td>
                              <td>{item?.programmes_offered || "N/A"}</td>
                              <td>{item?.date_of_establishment || "N/A"}</td>
                              <td>{item?.date_of_recog_by_ugc || "N/A"}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="d-flex flex-column gap-2">
                    <h3>2.2 ACADEMIC INFORMATION</h3>
                    <div>
                      <h5>Furnish the Details of Colleges of University</h5>
                      <table className="table table-bordered nowrap table-hover">
                        <tbody>
                          <tr>
                            <th>Type Of Colleges</th>
                            <th>Numbers</th>
                          </tr>
                          <tr>
                            <th>Constituent Colleges</th>
                            <td>
                              {allData?.collegesUnderUniversity[0]
                                ?.constituent_colleges || 0}
                            </td>
                          </tr>
                          <tr>
                            <th>Affiliated Colleges</th>
                            <td>
                              {allData?.collegesUnderUniversity[0]
                                ?.affiliated_colleges || 0}
                            </td>
                          </tr>
                          <tr>
                            <th>Colleges Under 2(f)</th>
                            <td>
                              {allData?.collegesUnderUniversity[0]
                                ?.colleges_under_2f || 0}
                            </td>
                          </tr>
                          <tr>
                            <th>Colleges Under 2(f) and 12B </th>
                            <td>
                              {allData?.collegesUnderUniversity[0]
                                ?.colleges_under_12b || 0}
                            </td>
                          </tr>
                          <tr>
                            <th>NAAC Accredited Colleges </th>
                            <td>
                              {allData?.collegesUnderUniversity[0]
                                ?.naac_accredited_colleges || 0}
                            </td>
                          </tr>
                          <tr>
                            <th>Autonomous Colleges</th>
                            <td>
                              {allData?.collegesUnderUniversity[0]
                                ?.autonomous_colleges || 0}
                            </td>
                          </tr>
                          <tr>
                            <th>Colleges with Post Graduate Departments</th>
                            <td>
                              {allData?.collegesUnderUniversity[0]
                                ?.colleges_with_postgraduate_departments || 0}
                            </td>
                          </tr>
                          <tr>
                            <th>Colleges with Research Departments</th>
                            <td>
                              {allData?.collegesUnderUniversity[0]
                                ?.colleges_with_research_departments || 0}
                            </td>
                          </tr>
                          <tr>
                            <th>
                              University Recognized Research Institutes/Centers
                            </th>
                            <td>
                              {allData?.collegesUnderUniversity[0]
                                ?.university_recognized_research_institutes ||
                                0}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="table table-bordered nowrap table-hover">
                        <tbody>
                          <td className="text-wrap">
                            Is the University Offering any Programmes Recognised
                            by any Statutory Regulatory Authority (SRA)
                            {allData?.collegesUnderUniversity[0]?.sra_data ? (
                              <table className="table table-bordered nowrap table-hover mt-2">
                                <tr>
                                  <th>SRA program</th>
                                  <th>Document</th>
                                </tr>
                                {JSON.parse(
                                  allData?.collegesUnderUniversity[0]?.sra_data
                                ).map((item, i) => (
                                  <tr key={i}>
                                    <td>{item?.sra_program || "N/A"}</td>
                                    <td>
                                      {item?.document ? (
                                        <a
                                          target="_blank"
                                          href={item?.document}
                                        >
                                          view document
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </table>
                            ) : (
                              ""
                            )}
                          </td>

                          <td>
                            {allData?.collegesUnderUniversity[0]
                              ?.programmes_recognized_by_sra || "N/A"}
                          </td>
                        </tbody>
                      </table>
                    </div>
                    <div>
                      <h5>
                        Details Of Teaching & Non-Teaching Staff Of University
                      </h5>
                      <table className="table table-bordered nowrap table-hover">
                        <tbody>
                          <tr>
                            <th className="text-center" colSpan={13}>
                              Teaching Faculty
                            </th>
                          </tr>
                          <tr>
                            <th></th>
                            <th colSpan={4}>Professor</th>
                            <th colSpan={4}>Associate Professor</th>
                            <th colSpan={4}>Assistant Professor</th>
                          </tr>
                          <tr>
                            <th></th>
                            <th>Male</th>
                            <th>Female</th>
                            <th>Others</th>
                            <th>Total</th>
                            <th>Male</th>
                            <th>Female</th>
                            <th>Others</th>
                            <th>Total</th>
                            <th>Male</th>
                            <th>Female</th>
                            <th>Others</th>
                            <th>Total</th>
                          </tr>
                          {allData?.teachingFaculty?.map((item, i) => (
                            <tr key={i}>
                              <td>{item?.position_type || "N/A"}</td>
                              <td>{item?.professor_male || 0}</td>
                              <td>{item?.professor_female || 0}</td>
                              <td>{item?.professor_other || 0}</td>
                              <td>{item?.professor_total || 0}</td>
                              <td>{item?.associate_male || 0}</td>
                              <td>{item?.associate_female || 0}</td>
                              <td>{item?.associate_other || 0}</td>
                              <td>{item?.associate_total || 0}</td>
                              <td>{item?.assistant_male || 0}</td>
                              <td>{item?.assistant_female || 0}</td>
                              <td>{item?.assistant_other || 0}</td>
                              <td>{item?.assistant_total || 0}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div>
                      <table className="table table-bordered nowrap table-hover">
                        <tbody>
                          <tr>
                            <th className="text-center" colSpan={5}>
                              Non-Teaching Staff
                            </th>
                          </tr>
                          <tr>
                            <th></th>
                            <th>Male</th>
                            <th>Female</th>
                            <th>Others</th>
                            <th>Total</th>
                          </tr>

                          {allData?.nonTeachingFaculty?.map((item, i) => (
                            <tr key={i}>
                              <td>{item?.non_teaching_type || "N/A"}</td>
                              <td>{item?.male || 0}</td>
                              <td>{item?.female || 0}</td>
                              <td>{item?.other || 0}</td>
                              <td>{item?.total || 0}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div>
                      <table className="table table-bordered nowrap table-hover">
                        <tbody>
                          <tr>
                            <th className="text-center" colSpan={5}>
                              Technical Staff
                            </th>
                          </tr>
                          <tr>
                            <th></th>
                            <th>Male</th>
                            <th>Female</th>
                            <th>Others</th>
                            <th>Total</th>
                          </tr>

                          {allData?.technicalFaculty?.map((item, i) => (
                            <tr key={i}>
                              <td>{item?.teaching_type || "N/A"}</td>
                              <td>{item?.male || 0}</td>
                              <td>{item?.female || 0}</td>
                              <td>{item?.other || 0}</td>
                              <td>{item?.total || 0}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div>
                      <h5>Qualification Details of the Teaching Staff</h5>
                      <table className="table table-bordered nowrap table-hover">
                        <tbody>
                          <tr>
                            <th className="text-center" colSpan={11}>
                              Temporary Teachers
                            </th>
                          </tr>
                          <tr>
                            <th>Highest Qualification</th>
                            <th colSpan={3}>Professor</th>
                            <th colSpan={3}>Associate Professor</th>
                            <th colSpan={3}>Assistant Professor</th>
                            <th></th>
                          </tr>
                          <tr>
                            <th></th>
                            <th>Male</th>
                            <th>Female</th>
                            <th>Others</th>
                            <th>Male</th>
                            <th>Female</th>
                            <th>Others</th>
                            <th>Male</th>
                            <th>Female</th>
                            <th>Others</th>
                            <th>Total</th>
                          </tr>
                          {allData?.permanentTeachers?.map((item, i) => (
                            <tr key={i}>
                              <td>{item?.highest_qualification || "N/A"}</td>
                              <td>{item?.professor_male || 0}</td>
                              <td>{item?.professor_female || 0}</td>
                              <td>{item?.professor_other || 0}</td>
                              <td>{item?.associate_male || 0}</td>
                              <td>{item?.associate_female || 0}</td>
                              <td>{item?.associate_other || 0}</td>
                              <td>{item?.assistant_male || 0}</td>
                              <td>{item?.assistant_female || 0}</td>
                              <td>{item?.assistant_other || 0}</td>
                              <td>{item?.total || 0}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div>
                      <table className="table table-bordered nowrap table-hover">
                        <tbody>
                          <tr>
                            <th className="text-center" colSpan={11}>
                              Temporary Teachers
                            </th>
                          </tr>
                          <tr>
                            <th>Highest Qualification</th>
                            <th colSpan={3}>Professor</th>
                            <th colSpan={3}>Associate Professor</th>
                            <th colSpan={3}>Assistant Professor</th>
                            <th></th>
                          </tr>
                          <tr>
                            <th></th>
                            <th>Male</th>
                            <th>Female</th>
                            <th>Others</th>
                            <th>Male</th>
                            <th>Female</th>
                            <th>Others</th>
                            <th>Male</th>
                            <th>Female</th>
                            <th>Others</th>
                            <th>Total</th>
                          </tr>
                          {allData?.temporaryTeachers?.map((item, i) => (
                            <tr key={i}>
                              <td>{item?.highest_qualification || "N/A"}</td>
                              <td>{item?.professor_male || 0}</td>
                              <td>{item?.professor_female || 0}</td>
                              <td>{item?.professor_other || 0}</td>
                              <td>{item?.associate_male || 0}</td>
                              <td>{item?.associate_female || 0}</td>
                              <td>{item?.associate_other || 0}</td>
                              <td>{item?.assistant_male || 0}</td>
                              <td>{item?.assistant_female || 0}</td>
                              <td>{item?.assistant_other || 0}</td>
                              <td>{item?.total || 0}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div>
                      <table className="table table-bordered nowrap table-hover">
                        <tbody>
                          <tr>
                            <th className="text-center" colSpan={11}>
                              Part Time Teachers
                            </th>
                          </tr>
                          <tr>
                            <th>Highest Qualification</th>
                            <th colSpan={3}>Professor</th>
                            <th colSpan={3}>Associate Professor</th>
                            <th colSpan={3}>Assistant Professor</th>
                            <th></th>
                          </tr>
                          <tr>
                            <th></th>
                            <th>Male</th>
                            <th>Female</th>
                            <th>Others</th>
                            <th>Male</th>
                            <th>Female</th>
                            <th>Others</th>
                            <th>Male</th>
                            <th>Female</th>
                            <th>Others</th>
                            <th>Total</th>
                          </tr>
                          {allData?.partTimeTeachers?.map((item, i) => (
                            <tr key={i}>
                              <td>{item?.highest_qualification || "N/A"}</td>
                              <td>{item?.professor_male || 0}</td>
                              <td>{item?.professor_female || 0}</td>
                              <td>{item?.professor_other || 0}</td>
                              <td>{item?.associate_male || 0}</td>
                              <td>{item?.associate_female || 0}</td>
                              <td>{item?.associate_other || 0}</td>
                              <td>{item?.assistant_male || 0}</td>
                              <td>{item?.assistant_female || 0}</td>
                              <td>{item?.assistant_other || 0}</td>
                              <td>{item?.total || 0}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div>
                      <h5>Distinguished Academicians Appointed As</h5>
                      <table className="table table-bordered nowrap table-hover">
                        <tbody>
                          <tr>
                            <th></th>
                            <th>Male</th>
                            <th>Female</th>
                            <th>Others</th>
                            <th>Total</th>
                          </tr>

                          {allData?.distinguishedAcademicians?.map(
                            (item, i) => (
                              <tr key={i}>
                                <td>{item?.professor_type || "N/A"}</td>
                                <td>{item?.male || 0}</td>
                                <td>{item?.female || 0}</td>
                                <td>{item?.other || 0}</td>
                                <td>{item?.total || 0}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div>
                      <h5>Chairs Instituted by the University</h5>
                      <table className="table table-bordered nowrap table-hover">
                        <tbody>
                          <tr>
                            <th>Sl.No</th>
                            <th>Name of the Department</th>
                            <th>Name of the Chair </th>
                            <th>Name of the Sponsor Organisation/Agency</th>
                          </tr>

                          {allData?.chairsInstituted?.map((item, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{item?.department_name || "N/A"}</td>
                              <td>{item?.chair_name || "N/A"}</td>
                              <td>{item?.sponsor_organization || "N/A"}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div>
                      <h5>
                        Provide the Following Details of Students Enrolled in
                        the University During the Current Academic Year
                      </h5>
                      <table className="table table-bordered nowrap table-hover">
                        <tbody>
                          <tr>
                            <th>Programme</th>
                            <th>Gender</th>
                            <th>From the State Where University is Located</th>
                            <th>From Other States of India</th>
                            <th>NRI Students </th>
                            <th>Foreign Students</th>
                            <th>Total</th>
                          </tr>

                          {allData?.studentsInCurrentAcademicYear?.map(
                            (item, i) => (
                              <tr key={i}>
                                <td>{item?.programme_type || "N/A"}</td>
                                <td>{item?.gender || "N/A"}</td>
                                <td>{item?.from_state || 0}</td>
                                <td>{item?.from_other_states || 0}</td>
                                <td>{item?.nri_students || 0}</td>
                                <td>{item?.foreign_students || 0}</td>
                                <td>{item?.total || 0}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div>
                      <h5>
                        Details of UGC Human Resource Development Centre, If
                        applicable
                      </h5>
                      <table className="table table-bordered nowrap table-hover">
                        <tbody>
                          <tr>
                            <td>Year of Establishment</td>
                            <td>
                              {allData?.hrDevelopmentCenter[0]
                                ?.year_of_establishment || "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>Number of UGC Orientation Programmes </td>
                            <td>
                              {allData?.hrDevelopmentCenter[0]
                                ?.ugc_orientation_programmes || 0}
                            </td>
                          </tr>
                          <tr>
                            <td>Number of UGC Refresher Courses</td>
                            <td>
                              {allData?.hrDevelopmentCenter[0]
                                ?.ugc_refresher_course || 0}
                            </td>
                          </tr>
                          <tr>
                            <td>Number of University-owned Programmes</td>
                            <td>
                              {allData?.hrDevelopmentCenter[0]
                                ?.university_owned_programmes || 0}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Total Number of Programmes Conducted (Last Five
                              Years)
                            </td>
                            <td>
                              {allData?.hrDevelopmentCenter[0]
                                ?.total_programmes || 0}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <div>
                      <h5>Integrated Programmes</h5>
                      <table className="table table-bordered nowrap table-hover">
                        <tbody>
                          <tr>
                            <td>
                              Does the University offer any Integrated
                              Programmes?
                            </td>
                            <td>
                              {allData?.integratedPrograms[0]
                                ?.integrated_programs_offered || "N/A"}
                            </td>
                          </tr>
                          {allData?.integratedPrograms[0]
                            ?.integrated_programs_offered === "Yes" ? (
                            <tr>
                              <td>Total Number of Integrated Programmes</td>
                              <td>
                                {allData?.integratedPrograms[0]
                                  ?.total_number_of_integrated_programmes ||
                                  "N/A"}
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="d-flex flex-column gap-2">
                    <h3>2.3 EVALUATIVE REPORT OF THE DEPARTMENTS</h3>
                    <table className="table table-bordered nowrap table-hover">
                      <tr>
                        <th>Department Name</th>
                        <th>Upload Report</th>
                      </tr>
                      {allData?.evaluativeReports?.map((item, i) => (
                        <tr key={i}>
                          <td>{item?.department || "N/A"}</td>
                          <td>
                            {item?.document_report ? (
                              <a target="_blank" href={item?.document_report}>
                                View Document
                              </a>
                            ) : (
                              "N/A"
                            )}
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    </>
  );
}
