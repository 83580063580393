import React, { useEffect, useState } from "react";
import { NAAC } from "../../utils/apiConstants";
import axios from "axios";
import { Select } from "antd";
import { toast } from "react-toastify";
const ModalApplicationApproval = ({
  type,
  setLoading,
  employeeOpt,
  collegeId,
  reloadData,
  data,
}) => {
  let emp_id = sessionStorage.getItem("employee_id");
  const [formData, setFormData] = useState({
    title: "",
    author: [],
    reviewer: [],
    year: new Date().getFullYear(),
    id: "",
    created_by: "",
    updated_by: "",
    status: "",
  });
  const clearData = {
    title: "",
    author: [],
    reviewer: [],
    year: new Date().getFullYear(),
    id: "",
    created_by: "",
    updated_by: "",
    status: "",
  };
  const yearStart = 2010;
  const yearOptions = Array.from({ length: 30 }, (_, i) => ({
    value: (yearStart + i).toString(),
    label: (yearStart + i).toString(),
  }));
  // useEffect(() => {
  //   if (type === "edit") {
  //     if (data) {
  //       setFormData(data);
  //     }
  //   }
  //   if (type === "add") {
  //     setFormData(clearData);
  //   }
  // }, [data, type]);
  console.log("formData", formData);
  useEffect(() => {
    if (type === "edit" && data) {
      setFormData({
        ...data,
        year: data.year ? data.year.toString() : "", // Convert year to a string
      });
    } else if (type === "add") {
      setFormData(clearData);
    }
  }, [data, type]);
  const handleDelete = async () => {
    setLoading(1);
    const config = {
      method: "put",
      url: `${NAAC}/approval-flow/${data?.id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
      data: {
        status: "INACTIVE",
        author: formData.author,
        reviewer: formData.reviewer,
      },
    };
    await axios(config)
      .then((res) => {
        toast.success(res?.data?.message || "Successfully deleted the flow");
        reloadData();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || "Something went wrong");
      });
    setLoading(0);
  };
  const handleSubmit = async () => {
    setLoading(1);
    if (!formData.title) {
      setLoading(0);
      return toast.error("Please add the title");
    }
    if (formData.author.length < 1) {
      setLoading(0);
      return toast.error("Authors field can not be empty");
    }
    if (formData.reviewer.length < 1) {
      setLoading(0);
      return toast.error("Reviewers field can not be empty");
    }
    const config = {
      method: "post",
      url: `${NAAC}/approval-flow`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
      data: {
        ...formData,
        status: "ACTIVE",
        created_by: type === "edit" ? formData.created_by : emp_id,
        updated_by: emp_id,
        author: formData.author,
        reviewer: formData.reviewer,
        // year: JSON.stringify(formData.year),
      },
    };
    await axios(config)
      .then((res) => {
        toast.success(
          res?.data?.message || "Successfully added/updated the flow"
        );
        reloadData();
        setFormData(clearData);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message || "Something went wrong");
      });
    setLoading(0);
  };
  return (
    <div
      className="modal fade"
      id="ModalNAACApplicationApproval"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered mw-100 w-75"
        role="document"
      >
        <div className="modal-content ">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              NAAC Application Approval Flow
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Title of the Approver"
                    value={formData.title}
                    onChange={(e) =>
                      setFormData({ ...formData, title: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="">Year</label>
                  <Select
                    showSearch
                    size="large"
                    value={formData.year}
                    onChange={(value) =>
                      setFormData({ ...formData, year: value })
                    }
                    options={yearOptions}
                    placeholder="Select year"
                    className="w-100"
                  />
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="">
                    Author
                  </label>

                  <Select
                    style={{ width: "100%" }}
                    mode="multiple"
                    showSearch
                    placeholder="Select an Employee"
                    options={employeeOpt}
                    filterOption={(input, option) =>
                      option?.label.toLowerCase().includes(input.toLowerCase())
                    }
                    value={formData?.author}
                    onChange={(value) =>
                      setFormData({ ...formData, author: value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="">
                    Reviewer
                  </label>

                  <Select
                    style={{ width: "100%" }}
                    mode="multiple"
                    showSearch
                    placeholder="Select an Employee"
                    options={employeeOpt}
                    filterOption={(input, option) =>
                      option?.label.toLowerCase().includes(input.toLowerCase())
                    }
                    value={formData?.reviewer}
                    onChange={(value) =>
                      setFormData({ ...formData, reviewer: value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-between px-2">
              {type == "edit" ? (
                <button
                  className="btn btn-danger btn-rounded btn-outline"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              ) : (
                <button
                  className="btn btn-info btn-rounded btn-outline"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
              )}
              <button
                className="btn btn-success btn-rounded btn-outline"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalApplicationApproval;
