import React, { useEffect, useState } from "react";
import DraggableTable from "./DraggableTable";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import {
  INVENTORY_ITEM_SUPPLIER,
  INVENTORY_PURCHASE_ORDER,
} from "../../../utils/Inventory.apiConst";
import { Button, Upload } from "antd";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { getFileUrl } from "../../../Helpers/Helpers";
import { ASSET_EMPLOYEE_DOCUMENT } from "../../../utils/AssetsReferenceTypes";
const paymentTermsOptions = [
  { value: "Net 15", label: "Net 15" },
  { value: "Net 30", label: "Net 30" },
  { value: "Net 45", label: "Net 45" },
  { value: "Net 60", label: "Net 60" },
  { value: "Due On Receipt", label: "Due On Receipt" },
  { value: "Due end of the month", label: "Due end of the month" },
  { value: "Due end of the next month", label: "Due end of the next month" },
];
const CreatePurchaseOrder = ({ setLoading, collegeId }) => {
  const [data, setData] = useState({
    id: "",
    status: "",
  });
  const [formData, setFormData] = useState({
    subTotal: 0,
    discount: 0,
    percentageOrRs: "percentage",
    total: 0,
    selectedTax: null,
    selectedTaxAmount: 0,
    adjustmentAmount: 0,
  });
  const [vendorName, setVendorName] = useState("");
  const [organizationAddress, setOrganizationAddress] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [selectedAddressType, setSelectedAddressType] =
    useState("Organization");
  const [purchaseOrder, setPurchaseOrder] = useState("");
  const [reference, setReference] = useState("");
  const [date, setDate] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [paymentTerms, setPaymentTerms] = useState("");
  const [shipmentPreference, setShipmentPreference] = useState("");
  const [customerNotes, setCustomerNotes] = useState("");
  const [vendorOptions, setVendorOptions] = useState([]);
  const [termsAndConditions, setTermsAndConditions] = useState("");
  let emp_id = sessionStorage.getItem("employee_id");
  const [dataSource, setDataSource] = useState([
    {
      key: Date.now().toString(),
      itemDetails: "",
      account: "",
      quantity: 1.0,
      rate: 0.0,
      amount: 0.0,
      quantity_received: 0.0,
      quantity_to_received: 0.0,
      quantity_in_transit: 0.0,
      is_received: false,
    },
  ]);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileUrl, setFileUrl] = useState("");
  const [tcsOrTds, setTcsOrTds] = useState("TDS");
  const navigate = useNavigate();
  const getVendorOptions = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${INVENTORY_ITEM_SUPPLIER}?college_id=${collegeId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        let response = res.data.data;
        let options = response.map((item) => {
          return { value: item?.id, label: item?.item_supplier_name };
        });
        setVendorOptions(options);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };
  const getPurchaseOrderNumber = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${INVENTORY_PURCHASE_ORDER}/number?college_id=${collegeId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        let paddedNum = res?.data?.purchaseOrder.toString().padStart(5, "0");
        let purchase_order = `PO-${paddedNum}`;
        // console.log("purchase_order", purchase_order);
        setPurchaseOrder(purchase_order);
      })
      .catch((err) => {
        setLoading(0);
        // toast.error("Something Went Wrong");
      });
  };
  const handleSaveOrSubmit = async (e, status) => {
    e.preventDefault();
    if (dataSource.length < 1) {
      toast.error("Please add at least one item ");
      return;
    }
    let info = {
      vendor_name_id: vendorName,
      delivery_address:
        selectedAddressType === "Organization"
          ? organizationAddress
          : customerAddress,
      purchase_order: purchaseOrder,
      reference,
      date,
      delivery_date: deliveryDate,
      payment_terms: paymentTerms,
      shipment_preference: shipmentPreference,
      items_data: dataSource,
      customer_notes: customerNotes,
      terms_and_conditions: termsAndConditions,
      documents: fileUrl,
      documents_name: uploadedFile,
      status,
      created_by: emp_id,
      updated_by: emp_id,
      total_amount_data: { ...formData, tcsOrTds },
      ...(data.id
        ? { id: data.id, updated_by: emp_id }
        : { created_by: emp_id, updated_by: emp_id }),
    };
    setLoading(1);
    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${INVENTORY_PURCHASE_ORDER}`,
      data: info,
    };
    await axios(config)
      .then((res) => {
        toast.success(
          `${
            status == "DRAFT"
              ? "Successfully saved as draft"
              : "Successfully submitted for review"
          } `
        );
        navigate(-1);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };
  const getDataById = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${INVENTORY_PURCHASE_ORDER}/id/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        console.log("data by id", res.data);
        const response = res.data;
        // setDataById(res.data);
        setData({ id: response?.id, status: response?.status });
        setFormData(response?.total_amount_data);
        setVendorName(response?.vendor_name_id);
        setOrganizationAddress(response?.delivery_address);
        setCustomerAddress(response?.delivery_address);
        setPurchaseOrder(response?.purchase_order);
        setReference(response?.reference);
        setDate(response?.date);
        setDeliveryDate(response?.delivery_date);
        setPaymentTerms(response?.payment_terms);
        setShipmentPreference(response?.shipment_preference);
        setDataSource(response?.items_data);
        setTermsAndConditions(response?.terms_and_conditions);
        setFileUrl(response?.documents);
        setUploadedFile(response?.documents_name);
        setCustomerNotes(response?.customer_notes);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };
  const extractFileName = (fileName) => {
    const lastDotIndex = fileName.lastIndexOf(".");
    return fileName.substring(0, lastDotIndex); // Removes the extension
  };
  const handleDeleteDoc = (e) => {
    e.preventDefault();
    setFileUrl("");
    setUploadedFile("");
  };
  const addAttachment = async (file) => {
    try {
      const fileLink = await getFileUrl(
        ASSET_EMPLOYEE_DOCUMENT,
        "Resume",
        extractFileName(file?.name),
        setLoading,
        file
      );
      setFileUrl(fileLink);
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("File upload failed");
    }
  };
  const beforeUpload = (file) => {
    setUploadedFile(file?.name);
    addAttachment(file);
    return false;
  };
  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0]; // Format: YYYY-MM-DD
    setDate(formattedDate);
    getVendorOptions();
    getPurchaseOrderNumber();
  }, []);
  useEffect(() => {
    if (id) {
      getDataById();
    }
  }, [id]);

  return (
    <div className="PurchaseOrder">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">
                    {id ? "Edit Purchase Order" : "New Purchase Order"}
                  </h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card py-4">
                  <div className="card-body">
                    <div className="container">
                      <form>
                        <div className="d-flex flex-column gap-3">
                          <div className="row  mb-3">
                            <div className="col-md-6 h-100 form-group">
                              <label
                                htmlFor="vendorName"
                                className="form-label"
                              >
                                Vendor Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-control"
                                id="vendorName"
                                name="vendorName"
                                value={vendorName}
                                onChange={(e) => setVendorName(e.target.value)}
                              >
                                <option value="" disabled>
                                  Select a Vendor
                                </option>
                                {vendorOptions?.map((item) => (
                                  <option key={item.label} value={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-md-6">
                              <div className="d-flex gap-2">
                                <label htmlFor="deliveryAddress">
                                  Delivery Address{" "}
                                  <span className="text-danger">*</span>
                                </label>

                                {/* <div className="d-flex gap-5 mb-3">
                                  <div class=" d-flex align-items-center gap-1">
                                    <input
                                      type="radio"
                                      name="deliveryAddress"
                                      id="Organization"
                                      value="Organization"
                                      onChange={(e) =>
                                        setSelectedAddressType(e.target.value)
                                      }
                                      checked={
                                        selectedAddressType === "Organization"
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      for="Organization"
                                    >
                                      Organization
                                    </label>
                                  </div>
                                  <div class=" d-flex align-items-center gap-1">
                                    <input
                                      type="radio"
                                      name="deliveryAddress"
                                      id="Customer"
                                      value="Customer"
                                      onChange={(e) =>
                                        setSelectedAddressType(e.target.value)
                                      }
                                      checked={
                                        selectedAddressType === "Customer"
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      for="Customer"
                                    >
                                      Customer
                                    </label>
                                  </div>
                                </div> */}
                              </div>
                              <select
                                className="form-control"
                                id="organization"
                                name="organization"
                                placeholder="Select Organization"
                                value={organizationAddress}
                                onChange={(e) =>
                                  setOrganizationAddress(e.target.value)
                                }
                              >
                                <option value="example1">
                                  Example Organization
                                </option>
                                <option value="example1.1">
                                  Example1.1 Organization
                                </option>
                              </select>
                              {/* {selectedAddressType === "Organization" ? (
                                <div>
                                  <select
                                    className="form-control"
                                    id="organization"
                                    name="organization"
                                    placeholder="Select Organization"
                                    value={organizationAddress}
                                    onChange={(e) =>
                                      setOrganizationAddress(e.target.value)
                                    }
                                  >
                                    <option value="example1">
                                      Example Organization
                                    </option>
                                    <option value="example1.1">
                                      Example1.1 Organization
                                    </option>
                                  </select>
                                </div>
                              ) : (
                                <div>
                                  <select
                                    className="form-control"
                                    id="customer"
                                    name="customer"
                                    placeholder="Select Customer"
                                    value={customerAddress}
                                    onChange={(e) =>
                                      setCustomerAddress(e.target.value)
                                    }
                                  >
                                    <option value="example2">
                                      Example Customer
                                    </option>
                                    <option value="example2.2">
                                      Example2.2 Customer
                                    </option>
                                  </select>
                                </div>
                              )} */}
                            </div>
                          </div>

                          <div className="row align-items-center mb-3">
                            <div className="col-md-6">
                              <label
                                htmlFor="purchaseOrder"
                                className="form-label"
                              >
                                Purchase Order#{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="purchaseOrder"
                                id="purchaseOrder"
                                value={purchaseOrder}
                                onChange={(e) =>
                                  setPurchaseOrder(e.target.value)
                                }
                              />
                            </div>
                            <div className="col-md-6">
                              <label htmlFor="reference" className="form-label">
                                Reference#
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="reference"
                                id="reference"
                                value={reference}
                                onChange={(e) => setReference(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="row align-items-center mb-3">
                            <div className="col-md-6">
                              <label htmlFor="date" className="form-label">
                                Date
                              </label>
                              <input
                                className="form-control"
                                type="date"
                                name="date"
                                id="date"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                              />
                            </div>
                            <div className="col-md-6">
                              <label
                                htmlFor="deliveryDate"
                                className="form-label"
                              >
                                Delivery Date
                              </label>
                              <input
                                className="form-control"
                                type="date"
                                name="deliveryDate"
                                id="deliveryDate"
                                value={deliveryDate}
                                onChange={(e) =>
                                  setDeliveryDate(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="row align-items-center mb-3">
                            <div className="col-md-6">
                              <label
                                htmlFor="paymentTerms"
                                className="form-label"
                              >
                                Payment Terms
                              </label>
                              <select
                                className="form-control"
                                id="paymentTerms"
                                name="paymentTerms"
                                defaultValue=""
                                placeholder="Select Payment Terms"
                                value={paymentTerms}
                                onChange={(e) =>
                                  setPaymentTerms(e.target.value)
                                }
                              >
                                <option value="" disabled>
                                  Select Payments Terms
                                </option>
                                {paymentTermsOptions.map((item) => (
                                  <option key={item.value} value={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-md-6">
                              <label
                                htmlFor="shipmentPreference"
                                className="form-label"
                              >
                                Shipment Preference
                              </label>
                              <select
                                className="form-control"
                                id="shipmentPreference"
                                name="shipmentPreference"
                                placeholder="Select Shipment Preference"
                                value={shipmentPreference}
                                onChange={(e) =>
                                  setShipmentPreference(e.target.value)
                                }
                              >
                                <option disabled value={""}>
                                  Select Shipment
                                </option>
                                <option value={"Cash"}>Cash</option>
                                <option value={"Online"}>Online</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <DraggableTable
                          dataSource={dataSource}
                          setDataSource={setDataSource}
                          tcsOrTds={tcsOrTds}
                          setTcsOrTds={setTcsOrTds}
                          formData={formData}
                          setFormData={setFormData}
                          setLoading={setLoading}
                          collegeId={collegeId}
                          setCustomerNotes={setCustomerNotes}
                          customerNotes={customerNotes}
                        />
                        <div className="row bg-light p-3">
                          <div className="col-md-6 border-1 border-right border-secondary ">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="termsAndConditions"
                              >
                                Terms & Conditions
                              </label>
                              <textarea
                                placeholder="Enter the terms and conditions of your business to be displayed in transaction"
                                className="form-control"
                                name="termsAndConditions"
                                id="termsAndConditions"
                                value={termsAndConditions}
                                onChange={(e) =>
                                  setTermsAndConditions(e.target.value)
                                }
                                rows={3}
                              ></textarea>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group d-flex flex-column">
                              <label
                                className="form-label"
                                htmlFor="termsAndConditions"
                              >
                                Attach File to Purchase Order
                              </label>
                              {!fileUrl ? (
                                <Upload
                                  accept=".pdf,.png,.jpg,.jpeg"
                                  beforeUpload={beforeUpload}
                                >
                                  <Button icon={<UploadOutlined />}>
                                    Click to Upload
                                  </Button>
                                </Upload>
                              ) : (
                                <div className="d-flex align-items-center gap-4">
                                  <a
                                    href={fileUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {uploadedFile}
                                  </a>
                                  <button
                                    onClick={handleDeleteDoc}
                                    className="btn btn-sm btn-danger"
                                  >
                                    <DeleteOutlined />
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* Submit Button */}
                        <div className="row mt-4">
                          <div className="col-md-12 text-right">
                            <button
                              onClick={(e) => handleSaveOrSubmit(e, "DRAFT")}
                              type="submit"
                              className="btn btn-primary mr-2"
                            >
                              Save
                            </button>
                            {/* <button
                              type="submit"
                              className="btn mx-2 btn-primary"
                              onClick={(e) => handleSaveOrSubmit(e, "SENT")}
                            >
                              Save and Send
                            </button> */}
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate(-1);
                              }}
                              className="btn btn-light"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePurchaseOrder;
