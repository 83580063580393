import React, { useEffect, useState } from "react";
import { BrowserRouter as RR, Routes, Route, Navigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Http } from "../Services/Services";
import { getIndianTime } from "../Helpers/timeHelpers";
import { LOGS_ROUTES } from "../utils/logsConstants";

//nav datas
import { navbarData } from "./../Data/navbar/Admin/navbarData";
import { navbarDataHR } from "./../Data/navbar/HR/navbarDataHR";
import { navbarDataFrontOffice } from "../Data/navbar/FrontOffice/navbarFrontOffice";
import { InfoUploading } from "../Data/navbar/InfoUploadingData/InfoUploading";
import { navbarDataNTSTAFF } from "../Data/navbar/Non-Teaching Staff/navbarDataNTSTAFF.js";

import AdmissionEnquiry from "../Pages/FrontOffice/AdmissionEnquiry/AdmissionEnquiry";
import { ROUTES } from "./routerConfig";
import VisitorsBook from "../Pages/FrontOffice/VisitorsBook/VisitorsBook";
import Navbar from "../Components/Navbar/Navbar";
import Topbar from "../Components/Topbar/Topbar";
import PhoneCallLog from "../Pages/FrontOffice/PhoneCallLog/PhoneCallLog";
import PostalDispatch from "../Pages/FrontOffice/PostalDispatch/PostalDispatch";
import PostalRecieve from "../Pages/FrontOffice/PostalRecieve/PostalRecieve";
import Complain from "../Pages/FrontOffice/Complain/Complain";
import SetupOffice from "../Pages/FrontOffice/SetupOffice/SetupOffice";
import StudentAdmission from "../Pages/Students/StudentAdmission/StudentAdmission";
import StudentDetails from "../Pages/Students/StudentDetails/StudentDetails";
import DisableStudents from "../Pages/Students/DisableStudents/DisableStudents";
import StudentAdmissionEnquiry from "./../Pages/Students/admissionEnquiry/AdmissionEnquirry";
import StudentAdmissionDetails from "./../Pages/Students/admissionDetails/AdmissionDetails";
import AddNewStaff from "../Pages/HR/AddNewStaff/AddNewStaff";
import ViewStaff from "../Pages/HR/ViewStaff/ViewStaff";
import EditStudentDetails from "../Pages/Students/EditStudentDetails/EditStudentDetails";
import StudentProfile from "../Pages/Students/StudentProfile/StudentProfile";
import EditStaff from "../Pages/HR/EditStaff/EditStaff";
import WebAccount from "../Pages/HR/Staff/WebAccount/WebAccount";
import Footer from "../Components/Footer/Footer";
import DashboardHR from "../Pages/HR/Dashboard/DashboardHR";
import JobPosition from "../Pages/HR/Employer/JobPosition/JobPosition";
import Department from "../Pages/HR/Employer/Department/Department";
import EmployerBranch from "../Pages/HR/Employer/Branch/EmployerBranch";
import EmployerLevel from "../Pages/HR/Employer/Level/EmployerLevel";
import EmployerBank from "../Pages/HR/Employer/Bank/EmployerBank";
import EmployerCourse from "../Pages/HR/Employer/Course/EmployerCourse";
import EmployerTrainer from "../Pages/HR/Employer/Trainer/EmployerTrainer";
import EmployerEnthicity from "../Pages/HR/Employer/Enthicity/EmployerEnthicity";
import EmployerReligion from "../Pages/HR/Employer/Religion/EmployerReligion";
import EmployerDocs from "../Pages/HR/Employer/Document/EmployerDocs";
import SalaryAdjustment from "../Pages/HR/PayRoll/SalaryAdjustment/SalaryAdjustment";
import PayRollEarning from "../Pages/HR/PayRoll/Earning/PayRollEarning";
import PayRollPayScale from "../Pages/HR/PayRoll/Earning/PayRollPayScale";
import PaySlipPrint from "../Pages/HR/PayRoll/Process/PaySlipPrint.jsx";
import PayRollBonus from "../Pages/HR/PayRoll/Earning/PayRollBonus";
import PayRollDeduction from "../Pages/HR/PayRoll/Earning/PayRollDeduction";
import PayRollStationaryContribution from "../Pages/HR/PayRoll/Earning/PayRollStationaryContribution";
import PayRollProcess from "../Pages/HR/PayRoll/Process/PayRollProcess";
import LeaveSetting from "../Pages/HR/Leave/Setting/LeaveSetting";
import LeaveWorkDay from "../Pages/HR/Leave/Workday/LeaveWorkDay";
import LeaveHoliday from "../Pages/HR/Leave/Holiday/LeaveHoliday";
import EarningPolicy from "../Pages/HR/Leave/EarningPolicy/EarningPolicy";
import AppovalWorkflow from "../Pages/HR/Leave/ApprovalWorkflow/AppovalWorkflow";
import LeaveCustomApprover from "../Pages/HR/Leave/CustomApprover/LeaveCustomApprover";
import LeaveLeaveType from "../Pages/HR/Leave/LeaveType/LeaveLeaveType";
import LeaveEntitlementReport from "../Pages/HR/Leave/EntitlementReport/LeaveEntitlementReport";
import LeaveTransactionReport from "../Pages/HR/Leave/TransactionReport/LeaveTransactionReport";
import LeaveReview from "../Pages/HR/Leave/Riview/LeaveReview";
import ViewIncome from "../Pages/Accounts/Income/ViewIncome";
import AddIncome from "../Pages/Accounts/Income/AddIncome";
import AddIncomeSource from "../Pages/Accounts/Income/AddIncomeSource";
import ViewExpense from "../Pages/Accounts/Expense/ViewExpense";
import AddExpense from "../Pages/Accounts/Expense/AddExpense";
import AddExpenseSource from "../Pages/Accounts/Expense/AddExpenseSource";
import FeeCollectionCollectFee from "../Pages/Accounts/FeeCollection/FeeCollectionCollectFee";
import FeeCollectionSearchFee from "../Pages/Accounts/FeeCollection/FeeCollectionSearchFee";
import FeeCollectionSearchDue from "../Pages/Accounts/FeeCollection/FeeCollectionSearchDue";
import FeeCollectionFeeMaster from "../Pages/Accounts/FeeCollection/FeeCollectionFeeMaster";
import FeeCollectionFeeType from "../Pages/Accounts/FeeCollection/FeeCollectionFeeType";
import FeeCollectionFeeGroup from "../Pages/Accounts/FeeCollection/FeeCollectionFeeGroup";
import FeeCollectionFeeDiscount from "../Pages/Accounts/FeeCollection/FeeCollectionFeeDiscount";
import AttendanceManagement from "../Pages/HR/Attendance/Management/AttendanceManagement";
import AttendanceFieldCheckIn from "../Pages/HR/Attendance/FieldCheckIn/AttendanceFieldCheckIn";
import AttendanceMonthEmployee from "../Pages/HR/Attendance/TimeClockReport/AttendanceMonthEmployee.jsx";
import AttendanceTimeClockReport from "../Pages/HR/Attendance/TimeClockReport/AttendanceTimeClockReport";
import EditStaffAttendance from "../Pages/HR/PayRoll/Earning/EditStaffAttendance";
import ReportStaffTodayAttendance from "../Pages/Reports/Staff/ReportStaffTodayAttendance.jsx";
import TeamDiscussion from "../Pages/HR/Team/Discussion/TeamDiscussion";
import TeamAnnouncement from "../Pages/HR/Team/Announcement/TeamAnnouncement";
import TeamDocumentandForm from "../Pages/HR/Team/Document/TeamDocumentandForm";
import DocManagement from "../Pages/HR/DocumentWorkflow/Management/DocManagement";
import DocReview from "../Pages/HR/DocumentWorkflow/Review/DocReview";
import DocApprovalWorkflow from "../Pages/HR/DocumentWorkflow/ApprovalWorkFlow/DocApprovalWorkflow";
import DocCustomApprover from "../Pages/HR/DocumentWorkflow/CustomApprover/DocCustomApprover";
import EmployerInformation from "../Pages/HR/Employer/Information/EmployerInformation";
import EmployeeCollegeType from "../Pages/HR/Employer/CollegeType/EmployeeCollegeType";
import EmployeeSpecialization from "../Pages/HR/Employer/Specialization/EmployeeSpecialization";
import EmployeeProgram from "../Pages/HR/Employer/Program/EmployeeProgram";
import EmployeeCollege from "../Pages/HR/Employer/College/EmployeeCollege";
import EmployeeCollegeSpecialization from "../Pages/HR/Employer/CollegeSpecialization/EmployeeCollegeSpecialization";
import { ALL_DATA, EMPLOYEE, EMPLOYEE_NAAC } from "../utils/apiConstants";
import LeaveManagement from "../Pages/HR/Leave/Management/LeaveManagement";
import TeamChat from "../Pages/HR/Team/Chat/TeamChat";
import Planner from "../Pages/HR/Leave/Planner/Planner";
import Register from "../Pages/Register/Register";
import Schedule from "../Pages/HR/Leave/Schedule/Schedule";
import Announcements from "../Pages/Communicate/Announcements";
import UpcomingEvents from "../Pages/Communicate/UpcomingEvents";
import ClinicalStudies from "../Pages/Student_Portal/ClinicalStudies";
import QifQualitative from "../Pages/NAAC/qif-qualitative/QifQualitative.jsx";
import QifQuantitative from "../Pages/NAAC/qif-quantitative/QifQuantitative.jsx";
import {
  LOCAL_COLLEGE,
  LOCAL_COLLEGE_SPECIALIZATION,
  LOCAL_COLLEGE_TYPES,
  LOCAL_DEPARTMENT,
  LOCAL_EMPLOYEE,
  LOCAL_JOBROLES,
  LOCAL_PROGRAM,
  LOCAL_SPECIALIZATION,
  LOCAL_USER_ROLES,
} from "../utils/LocalStorageConstants";
import { navbarDataIT } from "../Data/navbar/IT/navbarIT.js";

import EmployerApprovalWorkflow from "../Pages/HR/Employer/EmployerApprovalWorkFlow/EmployerApprovalWorkflow";
import TransportRoute from "../Pages/Transportation/TransportRoute/TransportRoute";
import TransportVehicle from "../Pages/Transportation/TransportVehicle/TransportVehicle";
import TransportAssignVehicles from "../Pages/Transportation/TransportAssignVehicles/TransportAssignVehicles";
import HostelRooms from "../Pages/Hostel/HostelRooms/HostelRooms";
import Hostel from "../Pages/Hostel/Hostel/Hostel";
import RoomType from "../Pages/Hostel/RoomType/RoomType";
import IssueItems from "../Pages/Inventory/IssueItem/IssueItems";
import Additemstock from "../Pages/Inventory/AddItemStock/AddItemstock";
import AddItem from "../Pages/Inventory/AddItem/AddItem";
import ItemCategory from "../Pages/Inventory/ItemCategory/ItemCategory";
import ItemStore from "../Pages/Inventory/ItemStore/ItemStore";
import Itemsupplier from "../Pages/Inventory/ItemSupplier/ItemSupplier";
import DesignHallticket from "../Pages/Certificates/DesignHallticket/DesignHallticket";
import DesignMarkscard from "../Pages/Certificates/DesignMarksCard/DesignMarkscard";
import MonthWiseAttendance from "../Pages/Reports/Student/MonthWiseAttendance";

import Loader from "../Components/Loader/Loader";
import {
  navbarDataEmployee,
  navbarDataEmployeeReview,
} from "../Data/navbar/Employee/NavbarEmployee";
import {
  SESSION_AUTH,
  SESSION_COLLEGE_ID,
  SESSION_EMPLOYEE_ID,
  SESSION_EMPLOYEE_REVIEW,
  SESSION_ROLE,
} from "../utils/sessionStorageContants";

import ClassTimeTable from "../Pages/Academics/ClassTimeTable";
import CreateTimeTable from "../Pages/Academics/CreateTimeTabel";
import TeachersTimetable from "../Pages/Academics/TeachesTimetable";
import PromoteStudents from "../Pages/Academics/PromoteStudents";
import ChangeBranch from "../Pages/Academics/ChangeBranch";
import AddBatch from "../Pages/Academics/AddBatch";
import AssignStudents from "../Pages/Academics/AssignStudents";
import AddSubjects from "../Pages/Academics/AddSubjects";
import AddClass from "../Pages/Academics/AddClass";
import AddLessons from "../Pages/Academics/AddLessons";
import AddTopics from "../Pages/Academics/AddTopics";
import AddBloomsLevel from "../Pages/Academics/AddBloomsLevel";
import CourseOutcomes from "../Pages/Academics/CourseOutcomes";
import CourseRegistration from "../Pages/Academics/CourseRegistration";
import SyllabusStatus from "../Pages/Academics/SyllabusStatus";
import ManageLessonPlan from "../Pages/Academics/ManageLessonPlan";

/////////////////////////////// EMPLOYEE //////////////////////////////

import EmployeeLeaveApplication from "../Pages/Employee/Leave/Application";
import EmployeeLeaveEntitlement from "../Pages/Employee/Leave/Entitlement";
import EmployeeSchedule from "../Pages/Employee/Leave/Schedule";

import EmployeeProfile from "../Pages/Employee/Profile/EmployeeProfile";
import EmployeeTeamAnnouncement from "./../Pages/Employee/Team/Announcement/EmployeeTeamAnnouncement";
import EmployeeTeamDocument from "../Pages/Employee/Team/Document/EmployeeTeamDocument";
import EmployeeTeamDiscussion from "../Pages/Employee/Team/Discussion/EmployeeTeamDiscussion";
import EmployeeDocumentManagement from "../Pages/Employee/Document/EmployeeDocumentManagement";

import EmployeeReview from "./../Pages/Employee/Leave/Review";

import EmployeeAnnualStatement from "./../Pages/Employee/PayRoll/AnnualStatement/AnnualStatements";
// import Employeepayslip from './../Pages/Employee/PayRoll/PaySlip/Payslip'
import EmployerSalary from "./../Pages/Employee/PayRoll/Salary/Salary";
import EmployerAttendance from "./../Pages/Employee/Attendance/AttendanceManagement";
import { navbarDataRegistar } from "../Data/navbar/Registar/NavbarDataRegistar";
import { navbarDataPrincipal } from "../Data/navbar/Principal/navbarDataPrincipal";
import DashboardEmployee from "../Pages/Employee/Dashboard/DashboardEmployee";
import FeeCollectionFeeTypeAmount from "../Pages/Accounts/FeeCollection/FeeCollectionFeeTypeAmount";
import AddSemester from "../Pages/Academics/AddSemester";
import AddSection from "../Pages/Academics/AddSection";

///////////////////////////////////////////////////Admission/////////////////////////////////////////////////
import AdmssionStudentDetails from "./../Pages/Admission/admissionDetails/AdmissionDetails";
import AdmssionStudentEnquiry from "./../Pages/Admission/admissionEnquiry/AdmissionEnquirry";
import AdmissionNewAdmission from "./../Pages/Admission/StudentAdmission/StudentAdmission.jsx";
import AdmissionStudentProfile from "./../Pages/Admission/admissionProfile/AdmissionProfile";
import FeeCollectionAddFee from "../Pages/Accounts/FeeCollection/FeeCollectionAddFee";
import FeeCollectionAssignFee from "../Pages/Accounts/FeeCollection/FeeCollectionAssignFee";
import { ExamNavbar } from "../Data/navbar/Examination/ExamNavbar";

import Commitee from "../Pages/Examination/Exam_committee";
import AssignStaff from "../Pages/Examination/Create_Exams";
import CreateQuestionPaper from "../Pages/Examination/CreateQuestionPaper";
import CreateExamTimetable from "../Pages/Examination/CreateExamTimetable";
import ExamSchedule from "../Pages/Examination/ExamSchedule";
import ExamSeatingArrangements from "../Pages/Examination/ExamSeatingArrangements";
import PostExamination from "../Pages/Examination/PostExaminations";
import OtpVerification from "../Pages/Examination/OtpVerification";
import DownloadQuestionPaper from "../Pages/Examination/DownloadQuestionPaper";
import InvigilatorLogin from "../Pages/Examination/InvigilatorLogin";
import AssignAnswersheets from "../Pages/Examination/AssignAnswersheets";
import AnswersheetChecking from "../Pages/Examination/AnswersheetChecking";
import Studentslist from "../Pages/Examination/Studentslist";
import ExamEnrollment from "../Pages/Examination/ExamEnrollment";
import StudentApplicationForm from "../Pages/Examination/StudentApplicationForm";
import DownloadHallTicket from "../Pages/Examination/DownloadHallTicket";

import AssignCommitteeStaff from "../Pages/Examination/CommitteeMembers";
import AddInvigilators from "../Pages/Examination/AddInvigilators";
import AddEvaluators from "../Pages/Examination/AddEvaluators";
import HallTickets from "../Pages/Examination/HallTickets";
import DownloadAttendanceList from "../Pages/Examination/DownloadAttendanceList";
import AttendanceList from "../Pages/Examination/AttendanceList";
import { navbarDataAccountant } from "../Data/navbar/Accountant/navbarDataAc";
import DashboardAccountant from "../Pages/Dashboard/DashboardAccount";
import PhdAdmissions from "../Pages/Admission/PhdAdmissions";
import PhdAdmitCard from "../Pages/Admission/PrintAdmitCard";
import ApproveStudentId from "../Pages/Students/ApproveStudentId/ApproveStudentId";

//PhdExam
import PhdList from "../Pages/Examination/PhdExam/PhdList";
import PhdExamProfile from "../Pages/Examination/PhdExam/PhdExamProfile";
import PhdExamHallTicket from "../Pages/Examination/PhdExam/PhdExamHallTicket";

import DeclarationForm1 from "../Pages/HR/Declaration_Forms/DeclarationForm1";
import IssueItem from "../Pages/Inventory/Issue/IssueItem";
import { navbarDataReceptionist } from "../Data/navbar/Reception/NavbarDataReception";
import ClassAttendance from "../Pages/Attendance/ClassAttendance";
import LabAttendance from "../Pages/Attendance/LabAttendance";
import ViewAssignedStudents from "../Pages/Academics/ViewAssignedStudents";

//Library

import Books from "../Pages/Library/Books/Books";
import LibrarySettings from "../Pages/Library/Settings/Setting/LibrarySettings";
import BorrowBook from "../Pages/Library/BorrowReturnedBooks/Borrow/BorrowBook";
import LibraryReport from "../Pages/Library/Reports/Reports";
import LibraryDashboard from "../Pages/Library/Dashboard/DashBoard";
import LibraryEbook from "../Pages/Library/Ebook/Ebook";

//LMS
import LMSCreate from "./../Pages/LMS/Create";
import LMSList from "./../Pages/LMS/List";

import PhdStudentProfile from "../Pages/Admission/PhdStudentProfile";
import FeeCollectionDummySearch from "../Pages/Accounts/FeeCollection/FeeCollectionDummySearch";
import { navbarDataLib } from "../Data/navbar/Library/navbarDataLib";
import FeeCollectionReports from "../Pages/Accounts/FeeCollection/FeeCollectionReports";
import AddQuestionBank from "../Pages/Academics/AddQuestionBank";
import { navbarDataSHR } from "../Data/navbar/SHR/navbarDataSHR";

//reports
import StudentAdmissionReport from "../Pages/Reports/Student/StudentAdmissionReport";
import Reports from "../Pages/Reports/Reports";
import ReportsFeeHome from "../Pages/Reports/Fees/ReportsFeeHome";
import AdvancePayment from "../Pages/Reports/Fees/AdvancePayment";
import AdvancePaymentHome from "../Pages/Reports/Fees/AdvancePaymentHome";
import ReportsBha1 from "../Pages/Reports/Fees/ReportsBha1";
import ReportsBha2 from "../Pages/Reports/Fees/ReportsBha2";
import ReportsBha3 from "../Pages/Reports/Fees/ReportsBha3";
import ReportsBha4 from "../Pages/Reports/Fees/ReportsBha4";
import ReportsBha6 from "../Pages/Reports/Fees/ReportsBha6";
import ReportsBha5 from "../Pages/Reports/Fees/ReportsBha5";
import hostelFeeCollectionReport from "../Pages/Reports/Fees/hostelFeeCollectionReport";
import FacultyWiseHostelReport from "../Pages/Reports/Fees/FacultyWiseHostelReport";
import CollegeWiseCollectionReport from "../Pages/Reports/Fees/CollegeWiseCollectionReport";
import DepartmentWiseCollectionReport from "../Pages/Reports/Fees/DepartmentWiseCollectionReport";
import ProgramWiseCollectionReport from "../Pages/Reports/Fees/ProgramWiseCollectionReport";
import AdmintoExamination from "../Pages/Navigator/AdmintoExamination";
import ForgotPassword from "../Pages/Register/ForgotPassword";
import ForgotPassword1 from "../Pages/Register/ForgotPassword1";
import DeanReports from "../Pages/Reports/DeanReports";
import StudentsExams from "../Pages/Students/Exams/StudentsExams";
import ReportsPendingClasswise from "../Pages/Reports/Fees/ReportsPendingClasswise";
import ReportFeePendingCollege from "../Pages/Reports/Fees/ReportFeePendingCollege";
import StudentMidtermExam from "../Pages/Students/Exams/StudentMidtermExam";
import StudentsVivaMarks from "../Pages/Students/Exams/StudentVivaMarks";
import DashboardRegistrar from "../Pages/Dashboard/DashboardRegistrar";
import DashboardRegistrar2 from "../Pages/Dashboard/DashboardRegistrar2";
import ReportStaffHome from "../Pages/Reports/Staff/ReportStaffHome";
import ReportStaffLeave from "../Pages/Reports/Staff/ReportStaffLeave";
import ReportStudentAttendance from "../Pages/Reports/Student/Attendance";

import transportFee from "../Pages/Transportation/TransportFee/transportFee";
import hostelFees from "../Pages/Hostel/HostelFee/hostelfee";
import AddHostelFee from "../Pages/Hostel/HostelFee/addhostelFee";
import AddTransportFee from "../Pages/Transportation/TransportFee/addTransportFee";
import Tickets from "../Pages/Tickets/Tickets";
import Complaints from "../Pages/Students/Complaints/Complaints";
import DevelopersCentre from "../Pages/Tickets/DevelopersCentre";
import DevelopersConversation from "../Pages/Tickets/DevelopersConversation";
import TicketConversation from "../Pages/Tickets/TicketConversation";
import MediaInfo from "../Pages/InfoUploading/MediaInfo";
import SearchBook from "../Pages/Library/Books/SearchBook.jsx";
import HomeoInfo from "../Pages/InfoUploading/HomeoInfo";

import { NavbarAdCon } from "../Data/navbar/AdmissionConsultant/NavbarAdCon";
import ReportStudentAttendanceSemWise from "../Pages/Reports/Student/ReportStudentAttendanceSemWise";
import ReportOneStudentStat from "../Pages/Reports/Student/ReportOneStudentStat";
import { navbarCashier } from "../Data/navbar/Cashier/navbarCashier.js";
import ReportOneStudentSubject from "../Pages/Reports/Student/ReportOneStudentSubject";
import AdmissionConsultanttoHR from "../Pages/Navigator/AdmissionConsultanttoHR";
import StudentUpdateAbc from "../Pages/Students/UpdateAbc/StudentUpdateAbc";

import StudentDetails1 from "../Pages/Students/StudentDetails/StudentDetails1";

import DateWiseTransportFees from "../Pages/Reports/Fees/TransportFeesReportDateWise";
import ViewStudentProfile from "../Pages/Students/StudentProfile/ViewStudentProfile";
import ResearchandPublication from "../Pages/InfoUploading/ResearchandPublication";
import HomeopathyStudentsList from "../Pages/InfoUploading/HomeopathyStudentsList";
import ViewHomeoStudentsList from "../Pages/InfoUploading/ViewHomeoStudentsList";
import CollegePermission from "../Pages/InfoUploading/CollegePermission";

import ViewCollegePermission from "../Pages/InfoUploading/ViewCollegePermission";
import StaffDairy from "../Pages/InfoUploading/StaffDairy";

import EntitlementDetaildReport from "../Pages/HR/Leave/Reports/EntitlementDetaildReport";
import ViewStaffDairy from "../Pages/InfoUploading/ViewStaffDairy";

import HomeopathyResults from "../Pages/InfoUploading/HomeopathyResults";

import LeaveDetailedReport from "../Pages/HR/Leave/Reports/LeaveDetailedReport";
import ViewTickets from "../Pages/Tickets/ViewTickets";
import ViewMediaInfo from "../Pages/InfoUploading/ViewMediaInfo";
import PickupPoints from "../Pages/Transportation/TransportPickupPoints/PickupPoints";
import TransportAssignPickupPoints from "../Pages/Transportation/TransportAssignPickupPoints/TransportAssignPickupPoints";
import TransportFees from "../Pages/Transportation/TransportFees/TransportFees";
import HostelFloors from "../Pages/Hostel/HostelFloors/HostelFloors";
import StaffDairyReport from "../Pages/InfoUploading/StaffDairyReport";
import ViewPostalDispatchRecieve from "../Pages/FrontOffice/ViewPostalDispatchRecieve/ViewPostalDispatchRecieve";
import StaffDairyReport2 from "../Pages/InfoUploading/StaffDairyReport2";
import ReportStaffDetails from "../Pages/Reports/Staff/ReportStaffDetails.jsx";
import UmsSettings from "../Pages/UMS_Settings/UmsSettings.jsx";
import ViewAluminiStudents from "../Pages/Academics/ViewAluminiStudents.jsx";
import StudentGatePass from "../Pages/InfoUploading/StudentGatePass.jsx";
import ViewStudentGatePass from "../Pages/InfoUploading/ViewStudentGatePass.jsx";
import ModalStudentGatePass from "../modals/ModalStudentGatePass.jsx";
import { InfoWarden } from "../Data/navbar/Warden/navbarWarden.js";
import HostelEditWarden from "../Pages/HostelEditWarden.jsx";
import ModalHostelRooms from "../modals/ModalHostelRooms.jsx";
import ModalHostelRoomsAssign from "../modals/ModalHostelRoomsAssign.jsx";
import StudentDetailsHostel from "../Pages/Students/StudentDetails/StudentDetailsHostel.jsx";
import StudentProfile1 from "../modals/StudentProfile1.jsx";
import HostelBeds from "../Pages/Hostel/HostelBeds/HostelBeds.jsx";
import FeedbackTrigger from "../Components/HR/Feedback/FeedbackTrigger.jsx";
import AddFeedbackQuestions from "../Components/HR/Feedback/AddFeedbackQuestions.jsx";
import FeedbacksHome from "../Pages/Reports/Feedbacks/FeedbacksHome.jsx";
import ProctorDashboard from "../Pages/Proctoring/ProctorDashboard.jsx";
import MyProcteeList from "../Pages/Proctoring/MyProcteeList.jsx";
import NextMeetingDetails from "../Pages/Proctoring/NextMeetingDetails.jsx";
import ProcteeList from "../Pages/Proctoring/ProcteeList.jsx";
import ViewMeetingDetails from "../Pages/Proctoring/ViewMeetingDetails.jsx";
import CreateProctoringMeeting from "../Pages/Proctoring/CreateProctoringMeeting.jsx";
import AssignProctee from "../Pages/Proctoring/AssignProctee.jsx";
import EmployeeCollegeTransfer from "../Pages/HR/EmployeeCollegeTransfer copy/EmployeeCollegeTransfer.jsx";
import ProgramOutcomes from "../Pages/Academics/ProgramOutcomes.jsx";
import ExpenseReport from "../Pages/Reports/Expenses/ExpenseReport.jsx";
import IncomeReport from "../Pages/Reports/Income/IncomeReport.jsx";
import StaffAttendanceReport from "../Pages/Reports/Staff/StaffAttendance/StaffAttendanceReport.jsx";
import LessonPlanReport from "../Pages/Reports/LessonPlan/LessonPlanReport.jsx";
import OnlineTransactionReport from "../Pages/Reports/Fees/OnlineTransactionReport.jsx";
import CancelledReceipts from "../Pages/Reports/Fees/CancelledReceipts.jsx";
import {
  navbarDataGatePassStudent,
  navbarDataMedicalStudent,
  navbarDataProvisionalStudent,
  navbarDataStudent,
} from "../Data/navbar/StudentPortal/StudentPortal.js";
import HomeStudent from "../Pages/Student_Portal/HomeStudent.jsx";
import Redirct from "../Pages/Student_Portal/Redirct.jsx";
import LoginStudent from "../Pages/Student_Portal/Login.jsx";
import Profile from "../Pages/Student_Portal/Profile.jsx";
import TopbarStudent from "../Pages/Student_Portal/Topbar/Topbar.jsx";
import Subjects from "../Pages/Student_Portal/Subjects.jsx";
import CourseRegistrationStudent from "../Pages/Student_Portal/CourseRegistrationStudent";
import AssignHospital from "../Pages/Academics/AssignHospital";
import ClinicalStudiesReport from "../Pages/Academics/ClinicalStudiesReport";
import Lessons from "../Pages/Student_Portal/Lessons.jsx";
import Topics from "../Pages/Student_Portal/Topics.jsx";
import Attendance from "../Pages/Student_Portal/Attendance.jsx";
import AttendanceCourse from "../Pages/Student_Portal/AttendanceCourse.jsx";
import Documents from "../Pages/Student_Portal/PersonalDetails/Documents.jsx";
import StudentDocuments from "../Pages/Student_Portal/StudentDocuments.jsx";
import GatePassStudent from "../Pages/Student_Portal/GatePassStudent.jsx";
import ViewGatePass from "../Pages/Student_Portal/ViewGatePass.jsx";
import GatePass from "../Pages/GatePass.jsx";
import GatePassWarden from "../Pages/GatePassWarden.jsx";
import AssignHostelStudents from "../Pages/Students/AssignHostelStudents.jsx";
import GatePassWarden2 from "../Pages/GatePassWarden2.jsx";
import VisitorsList from "../Pages/InfoUploading/VisitorsList.jsx";
import GatePassInOut from "../Pages/GatePassInOut.jsx";
import EventCustomApprove from "../Pages/HR/EventManagement/CustomAprove/EventCustomApprove.jsx";
import EventWorkFlow from "../Pages/HR/EventManagement/EventApprovalWorkFlow/EventWorkFlow.jsx";
import LoginAdmission from "../Pages/Admission_Portal/LoginAdmission.jsx";
import {
  GET_ALL_DROP_DATA,
  GET_DETAILS,
  UPDATE_PRE_ADMISSION,
} from "../Pages/Admission_Portal/AdmissionApiConstants.js";
import {
  COLLEGE,
  DEPARTEMENT,
  HEADER,
  PROGRAM,
  USER_AUTH,
} from "../Pages/Admission_Portal/AdmissionStorageConstats.js";
import ProfileCard from "../Pages/Admission_Portal/profileCard/ProfileCard.jsx";
import ProfileSwitches from "../Pages/Admission_Portal/ProfileSwitches/ProfileSwitches.jsx";
import BasicInformations from "../Pages/Admission_Portal/BasicInformations/BasicInformations.jsx";
import Payment from "../Pages/Admission_Portal/Payment/ApplicationFeePayment.jsx";
import Status from "../Pages/Admission_Portal/Status/Status.jsx";
import Dashboard from "../Pages/Admission_Portal/Dashboard/Dashboard.jsx";
import BasicHome from "../Pages/Admission_Portal/BasicInformations/BasicHome.jsx";
import Admission from "../Pages/Admission_Portal/Admission/Admission.jsx";
import Bed from "../Pages/Admission_Portal/Bed/Bed.jsx";
import Bsc from "../Pages/Admission_Portal/Bsc/Bsc.jsx";
import Commerce from "../Pages/Admission_Portal/Commerce/Commerce.jsx";
import Law from "../Pages/Admission_Portal/Law/Law.jsx";
import Msc from "../Pages/Admission_Portal/Msc/Msc.jsx";
import PBBscNursing from "../Pages/Admission_Portal/Nursing/PG/PBBscNursing.jsx";
import MscNursing from "../Pages/Admission_Portal/Nursing/PG/MscNursing.jsx";
import UGPharmacy from "../Pages/Admission_Portal/Nursing/PG/UGPharmacy.jsx";
import PGPharmacy from "../Pages/Admission_Portal/Nursing/PG/PGPharmacy.jsx";
import VedicAstro from "../Pages/Admission_Portal/VedicAstro/VedicAstro.jsx";
import BscNursing from "../Pages/Admission_Portal/Nursing/BscNursing.jsx";
import GNM from "../Pages/Admission_Portal/Nursing/Gnm.jsx";
import PostBasicBsc from "../Pages/Admission_Portal/Nursing/PostBasicBsc.jsx";
import Masters from "../Pages/Admission_Portal/Nursing/Master.jsx";
import AdmissionEnquirys from "../Pages/Admission_Portal/Applications/AdmissionEnquiry.jsx";
import AdmissionForm from "../Pages/Admission_Portal/Applications/AdmissionForm.jsx";
import AdmissionProfile from "../Pages/Admission_Portal/Profile/Profile.jsx";
import Homeopathy from "../Pages/Admission_Portal/Homeopathy/Homeopathy.jsx";
import Ayurveda from "../Pages/Admission_Portal/Ayurveda/Ayurveda.jsx";
import AyurvedaPG from "../Pages/Admission_Portal/Ayurveda/AyurvedaPG.jsx";
import AyurvedaMsc from "../Pages/Admission_Portal/Ayurveda/AyurvedaMsc.jsx";
import NavbarAdmission from "../Pages/Admission_Portal/Navbar/Navbar.jsx";
import FooterAdmission from "../Pages/Admission_Portal/Footer/Footer.jsx";
import DocumentsAdmission from "../Pages/Admission_Portal/Documents/Documents.jsx";
import JobOpenings from "../Pages/HR/Interview/JobOpenings.jsx";
import ReviewJobApplications from "../Pages/HR/Interview/ReviewJobApplications.jsx";
import JobApplicationProfile from "../Pages/HR/Interview/JobApplicationProfile.jsx";
import JobApplication from "../Pages/HR/Interview/JobApplication.jsx";
import JobApplications from "../Pages/HR/Interview/jobApplication/JobApplications.jsx";
import AddEvent from "../Pages/Communicate/AddEvent.jsx";
import DashboardAd_Co from "../Pages/FrontOffice/DashboardAD_CON/DashboardAd_Co.jsx";
import ModalAdmissionEnquirry from "../modals/Students/ModalAdmissionEnquirry.jsx";
import Syllabus from "../Pages/Reports/Syllabus/Syllabus.jsx";
import EditLateInEarlyOut from "../Pages/HR/PayRoll/Earning/EditLateInEarlyOut.jsx";
import AttendanceMonthlyReport from "../Pages/HR/Attendance/TimeClockReport/AttendanceMonthlyReport.jsx";
import AttendanceTimeReport from "../Pages/HR/Attendance/TimeClockReport/AttendanceTimeReport.jsx";
import AttendanceMonthlyLateinReport from "../Pages/HR/Attendance/TimeClockReport/AttendanceMonthlyLateinReport.jsx";
import NAAC from "../Pages/NAAC/NAAC.jsx";
import ExecutiveSummary from "../Pages/NAAC/executive-summary/ExecutiveSummary.jsx";
import NAACProfile from "../Pages/NAAC/profile/Profile.jsx";
import NAACBasicInformation from "../Pages/NAAC/profile/sub-pages/BasicInformation.jsx";
import ProfileRoutes from "./ProfileRoutes.jsx";
import NepParameters from "../Pages/NAAC/nep-parameters/NepParameters.jsx";
import NepParametersRoutes from "./NepParametersRoutes.jsx";
import ExtendedProfileRoutes from "./ExtendedProfileRoutes.jsx";
import MyDownloads from "../Pages/NAAC/my-downloads/MyDownloads.jsx";
import ActivityTracking from "../Pages/NAAC/dashboard/ActivityTracking.jsx";
import PerformanceTracking from "../Pages/NAAC/dashboard/PerformanceTracking.jsx";
import StatusReport from "../Pages/NAAC/dashboard/StatusReport.jsx";
import DashboardPrincipal from "../Pages/Dashboard/DashboardPrincipal.jsx";
import NAACApprovalFlow from "../Pages/NAAC/NAACApprovalFlow.jsx";

import ExecutiveSummaryPdf from "../Pages/NAAC/modals/ExecutiveSummaryPdf.jsx";
import ExecutiveSummaryPdfFormat from "../Components/NAAC/ExecutiveSummaryPdfFormat.jsx";
import BasicSalaryReport from "../Pages/BasicSalaryReport.jsx";
import BasicSalaryReport1 from "../Pages/BasicSalaryReport1.jsx";
import EmployeeShift from "../Pages/Reports/Shift/EmployeeShift.jsx";
import DepartmentCourse from "../Pages/HR/Employer/Department/DepartmentCourse.jsx";
import PurchaseOrder from "../Pages/Inventory/PurchaseOrder/PurchaseOrder.jsx";
import CreatePurchaseOrder from "../Pages/Inventory/PurchaseOrder/CreatePurchaseOrder.jsx";
import ViewPurchaseOrders from "../Pages/Inventory/PurchaseOrder/ViewPurchaseOrders.jsx";
import ViewAllIssueItems from "../Pages/Inventory/IssueItem/ViewAllIssueItems.jsx";
import ScholarshipReport from "../Pages/Reports/Fees/ScholarshipReport.jsx";
import SummaryFeeReport from "../Pages/Reports/Fees/SummaryFeeReport.jsx";
import PurchaseReceives from "../Pages/Inventory/PurchaseReceives/PurchaseReceives.jsx";
import Bills from "../Pages/Inventory/Bills/Bills.jsx";
import RecordPayment from "../Pages/Inventory/RecordPayment/RecordPayment.jsx";

function Router() {
  const [role, setRole] = useState("");
  const [colleges, setColleges] = useState();

  const getRoles = async () => {
    setRole(sessionStorage.getItem("role"));
  };

  const changeCollege = (id) => {
    console.log("Selected College ID:", id);
    sessionStorage.setItem(SESSION_COLLEGE_ID, id);
    setCollegeId(id);
  };

  const getCollegeId = () => {
    if (sessionStorage.getItem(SESSION_COLLEGE_ID) !== null) {
      return sessionStorage.getItem(SESSION_COLLEGE_ID);
    } else return null;
  };

  const [collegeId, setCollegeId] = useState(getCollegeId());

  console.log("collegeId -", collegeId);

  const getAllData = async () => {
    var config = {
      method: "get",
      url: ALL_DATA,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        localStorage.setItem("ALL_DATA", JSON.stringify(res.data));
        localStorage.setItem(
          LOCAL_USER_ROLES,
          JSON.stringify(res.data.userRoles)
        );
        localStorage.setItem(LOCAL_COLLEGE, JSON.stringify(res.data.college));
        localStorage.setItem(
          LOCAL_COLLEGE_SPECIALIZATION,
          JSON.stringify(res.data.collegeSpecialization)
        );
        localStorage.setItem(
          LOCAL_COLLEGE_TYPES,
          JSON.stringify(res.data.collegeType)
        );
        localStorage.setItem(
          LOCAL_DEPARTMENT,
          JSON.stringify(res.data.department)
        );
        localStorage.setItem(LOCAL_JOBROLES, JSON.stringify(res.data.jobRoles));
        localStorage.setItem(LOCAL_PROGRAM, JSON.stringify(res.data.program));
        localStorage.setItem(
          LOCAL_SPECIALIZATION,
          JSON.stringify(res.data.specialization)
        );
        localStorage.setItem(
          LOCAL_USER_ROLES,
          JSON.stringify(res.data.userRoles)
        );
        localStorage.setItem(LOCAL_EMPLOYEE, JSON.stringify(res.data.employee));
        setColleges(res.data.college);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const RouteWithHR = ({ Element }) => {
    const [loading, setLoading] = useState(0);

    const getAuth = () => {
      return sessionStorage.getItem(SESSION_AUTH) ? true : false;
    };

    const getRole = () => {
      return sessionStorage.getItem(SESSION_ROLE)
        ? sessionStorage.getItem(SESSION_ROLE)
        : false;
    };

    const [auth, setAuth] = useState(getAuth());
    const [role, setRole] = useState(getRole());

    useEffect(() => {
      setRole(getRole());
    }, [sessionStorage.getItem(SESSION_ROLE)]);

    const setupCollege = async () => {
      await getCollegeId()
        .then((data) => {
          console.log(`college Id data`, data);
          if (data == "null") {
            console.log("init");
            console.log("hello");
            setCollegeId(colleges[0]?.id);
          } else {
            setCollegeId(data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    useEffect(() => {
      setupCollege();
    }, []);

    if (auth == false || collegeId == false) return <Navigate replace to="/" />;

    let newNavbar;
    if (parseInt(sessionStorage.getItem("employee_id")) == 502) {
      let employer = navbarDataSHR?.find((s) => s.title == "Employer");
      let employee = navbarDataSHR?.find((s) => s.title == "Employee");

      if (employer) {
        employer.drop = employer?.drop?.filter(
          (s) =>
            s.title != "College" &&
            s.title != "Department" &&
            s.title != "College Types" &&
            s.title != "Programs" &&
            s.title != "Specialization"
        );
      }

      if (employee) {
        employee.drop = employee?.drop?.filter(
          (s) => s.title != "Staff Transfer"
        );
      }

      newNavbar = navbarDataSHR?.filter(
        (s) => s.title != "Employer" && s.title != "Employee"
      );

      if (employer) {
        newNavbar.push(employer);
      }
      if (employee) {
        newNavbar.push(employee);
      }
    } else {
      newNavbar = navbarDataSHR;
    }

    return (
      <>
        {/* {auth ? null : <Navigate replace to="/" />} */}
        <Loader loading={loading} />
        <Topbar changeCollege={changeCollege} />
        <Navbar
          data={
            role == "SHR" || role == "SUPERADMIN" ? navbarDataSHR : navbarDataHR
          }
        />
        <Element setLoading={setLoading} collegeId={collegeId} />
        <Footer />
      </>
    );
  };

  const RouteWithOpen = ({ Element }) => {
    const [loading, setLoading] = useState(0);

    return (
      <>
        {/* {auth ? null : <Navigate replace to="/" />} */}
        <Loader loading={loading} />
        <Element setLoading={setLoading} />
        <Footer />
      </>
    );
  };

  const RouteWithWarden = ({ Element }) => {
    const [loading, setLoading] = useState(0);

    const getAuth = () => {
      return sessionStorage.getItem(SESSION_AUTH) ? true : false;
    };

    const getRole = () => {
      return sessionStorage.getItem(SESSION_ROLE)
        ? sessionStorage.getItem(SESSION_ROLE)
        : false;
    };
    const [role, setRole] = useState(getRole());
    const [auth, setAuth] = useState(getAuth());

    useEffect(() => {
      setRole(getRole());
    }, [sessionStorage.getItem(SESSION_ROLE)]);

    const setupCollege = async () => {
      await getCollegeId()
        .then((data) => {
          if (data == "null") {
            setCollegeId(colleges[0]?.id);
          } else {
            setCollegeId(data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    useEffect(() => {
      setupCollege();
    }, []);

    if (auth == false || collegeId == false) return <Navigate replace to="/" />;

    return (
      <>
        {/* <Topbar /> */}
        <Topbar changeCollege={changeCollege} />
        <Loader loading={loading} />
        <Navbar data={role == "WARDEN" ? InfoWarden : InfoWarden} />
        {/* <Element setLoading={setLoading} /> */}
        <Element setLoading={setLoading} collegeId={collegeId} />
        <Footer />
      </>
    );
  };

  // const RouteWithWarden = ({ Element }) => {
  //   const [loading, setLoading] = useState(0);

  //   const getAuth = () => {
  //     return sessionStorage.getItem(SESSION_AUTH) ? true : false;
  //   };

  //   let x = sessionStorage.getItem("role");

  //   const [auth, setAuth] = useState(getAuth());

  //   const setupCollege = async () => {
  //     let data = await getCollegeId();
  //     if (data == "null") {
  //       console.log("init");
  //       console.log("hello");
  //       setCollegeId(colleges[0]?.id);
  //     } else {
  //       setCollegeId(data);
  //     }
  //   };

  //   useEffect(() => {
  //     setupCollege();
  //   }, []);

  //   if (auth == false || collegeId == false) return <Navigate replace to="/" />;

  //   return (
  //     <>
  //       <Loader loading={loading} />
  //       <Topbar changeCollege={changeCollege} />
  //       {/* <Navbar data={navbarData} /> */}
  //       <Navbar data={role == "WARDEN" ? InfoWarden : InfoWarden} />
  //       <Element setLoading={setLoading} collegeId={collegeId} />
  //       <Footer />
  //     </>
  //   );
  // };

  const RouteWithDevelopers = ({ Element }) => {
    const [loading, setLoading] = useState(0);

    const getAuth = () => {
      return sessionStorage.getItem(SESSION_AUTH) ? true : false;
    };

    const getRole = () => {
      return sessionStorage.getItem(SESSION_ROLE)
        ? sessionStorage.getItem(SESSION_ROLE)
        : false;
    };
    const [role, setRole] = useState(getRole());

    useEffect(() => {
      setRole(getRole());
    }, [sessionStorage.getItem(SESSION_ROLE)]);

    return (
      <>
        {/* {auth ? null : <Navigate replace to="/" />} */}
        <Loader loading={loading} />
        <Topbar />
        <Element setLoading={setLoading} collegeId={collegeId} />
        <Footer />
      </>
    );
  };

  const RouteWithIT = ({ Element }) => {
    const [loading, setLoading] = useState(0);

    const getAuth = () => {
      return sessionStorage.getItem(SESSION_AUTH) ? true : false;
    };

    const getRole = () => {
      return sessionStorage.getItem(SESSION_ROLE)
        ? sessionStorage.getItem(SESSION_ROLE)
        : false;
    };
    const [role, setRole] = useState(getRole());

    useEffect(() => {
      setRole(getRole());
    }, [sessionStorage.getItem(SESSION_ROLE)]);

    return (
      <>
        {/* {auth ? null : <Navigate replace to="/" />} */}
        <Loader loading={loading} />
        <Topbar />
        <Navbar data={navbarDataIT} />
        <Element setLoading={setLoading} collegeId={collegeId} />
        <Footer />
      </>
    );
  };

  const RouteWithInfoUploadingTeam = ({ Element }) => {
    const [loading, setLoading] = useState(0);

    const getAuth = () => {
      return sessionStorage.getItem(SESSION_AUTH) ? true : false;
    };

    const getRole = () => {
      return sessionStorage.getItem(SESSION_ROLE)
        ? sessionStorage.getItem(SESSION_ROLE)
        : false;
    };

    const [auth, setAuth] = useState(getAuth());
    const [role, setRole] = useState(getRole());

    useEffect(() => {
      setRole(getRole());
    }, [sessionStorage.getItem(SESSION_ROLE)]);

    return (
      <>
        {/* {auth ? null : <Navigate replace to="/" />} */}
        <Topbar />
        <Loader loading={loading} />
        <Navbar data={InfoUploading} />
        <Element setLoading={setLoading} />
        <Footer />
      </>
    );
  };

  const RouteWithEmployee = ({ Element }) => {
    const [loading, setLoading] = useState(0);
    const [showNAAC, setShowNAAC] = useState(false);
    const [emp_id, setEmp_id] = useState(
      sessionStorage.getItem(SESSION_EMPLOYEE_ID)
    );
    const getNAACData = async () => {
      const config = {
        method: "get",
        url: `${EMPLOYEE_NAAC}/${emp_id}`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      await axios(config)
        .then(async (res) => {
          console.log("showNAAC", res?.data?.showNaac);
          setShowNAAC(res?.data?.showNaac);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const getAuth = () => {
      return sessionStorage.getItem(SESSION_AUTH) ? true : false;
    };

    const getReview = () => sessionStorage.getItem(SESSION_EMPLOYEE_REVIEW);

    const [review, setReiview] = useState(getReview());
    useEffect(() => {
      setReiview(getReview());
      setEmp_id(sessionStorage.getItem(SESSION_EMPLOYEE_ID));
    }, [sessionStorage.getItem(SESSION_EMPLOYEE_REVIEW)]);
    useEffect(() => {
      getNAACData();
    }, []);

    const getRole = () => {
      return sessionStorage.getItem(SESSION_ROLE)
        ? sessionStorage.getItem(SESSION_ROLE)
        : false;
    };

    const [auth, setAuth] = useState(getAuth);
    const [role, setRole] = useState(getRole);

    let x = sessionStorage.getItem("role");

    const setupCollege = async () => {
      await getCollegeId()
        .then((data) => {
          setCollegeId(data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    useEffect(() => {
      setupCollege();
    }, []);

    return (
      <>
        {auth ? null : <Navigate replace to="/" />}
        <Loader loading={loading} />
        {/* <Topbar /> */}
        <Topbar changeCollege={changeCollege} />
        {review == "true" ? (
          <Navbar data={navbarDataEmployeeReview} />
        ) : (
          <Navbar data={navbarDataEmployee} />
        )}
        <Element setLoading={setLoading} collegeId={collegeId} />
        <Footer />
      </>
    );
  };

  const RouteWithAdmin = ({ Element }) => {
    const [loading, setLoading] = useState(0);

    const getAuth = () => {
      return sessionStorage.getItem(SESSION_AUTH) ? true : false;
    };

    let x = sessionStorage.getItem("role");

    const [auth, setAuth] = useState(getAuth());

    const setupCollege = async () => {
      let data = await getCollegeId();
      if (data == "null") {
        console.log("init");
        console.log("hello");
        setCollegeId(colleges[0]?.id);
      } else {
        setCollegeId(data);
      }
    };

    useEffect(() => {
      setupCollege();
    }, []);

    if (auth == false || collegeId == false) return <Navigate replace to="/" />;

    return (
      <>
        <Loader loading={loading} />
        <Topbar changeCollege={changeCollege} />
        <Navbar data={navbarData} />
        <Element setLoading={setLoading} collegeId={collegeId} />
        <Footer />
      </>
    );
  };

  const RouteWithFrontOffice = ({ Element }) => {
    const [loading, setLoading] = useState(0);

    const getAuth = () => {
      return sessionStorage.getItem(SESSION_AUTH) ? true : false;
    };

    let x = sessionStorage.getItem("role");

    const [auth, setAuth] = useState(getAuth());

    const setupCollege = async () => {
      await getCollegeId()
        .then((data) => {
          console.log(`college Id data`, data);
          if (data == "null") {
            console.log("init");
            console.log("hello");
            setCollegeId(colleges[0]?.id);
          } else {
            setCollegeId(data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    useEffect(() => {
      setupCollege();
    }, []);

    if (auth == false || collegeId == false) return <Navigate replace to="/" />;

    return (
      <>
        <Loader loading={loading} />
        <Topbar changeCollege={changeCollege} />
        <Navbar data={navbarDataFrontOffice} />
        <Element setLoading={setLoading} collegeId={collegeId} />
        <Footer />
      </>
    );
  };

  const RouteWithExam = ({ Element }) => {
    const [loading, setLoading] = useState(0);

    const getAuth = () => {
      return sessionStorage.getItem(SESSION_AUTH) ? true : false;
    };

    const [auth, setAuth] = useState(getAuth());

    const setupCollege = async () => {
      await getCollegeId()
        .then((data) => {
          setCollegeId(data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    useEffect(() => {
      setupCollege();
    }, []);

    if (auth == false || collegeId == false) return <Navigate replace to="/" />;

    return (
      <>
        <Loader loading={loading} />
        <Topbar />
        <Navbar data={ExamNavbar} />
        <Element setLoading={setLoading} collegeId={collegeId} />
        <Footer />
      </>
    );
  };

  const RouteWithRegistar = ({ Element }) => {
    let x = sessionStorage.getItem("role");
    const [loading, setLoading] = useState(0);

    const getAuth = () => {
      return sessionStorage.getItem(SESSION_AUTH) ? true : false;
    };

    const [auth, setAuth] = useState(getAuth());

    const setupCollege = async () => {
      let data = await getCollegeId();
      console.log(`college Id data`, data);
      if (data == "null") {
        setCollegeId(colleges[0]?.id);
      } else {
        setCollegeId(data);
      }
    };

    useEffect(() => {
      setupCollege();
    }, []);

    if (x !== "SUPERADMIN") {
      toast.error("You are not authorized to access this page");
      console.log("bro");
      const logObj = {
        uid: sessionStorage.getItem(SESSION_EMPLOYEE_ID),
        route: window.location.href,
        routeof: "SUPERADMIN",
        time: getIndianTime(),
        date: new Date(),
      };
      Http.post(LOGS_ROUTES, logObj)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
      return <Navigate replace to="/" />;
    } else {
      setRole(sessionStorage.getItem("role"));

      if (auth == false || collegeId == false)
        return <Navigate replace to="/" />;
      else {
        let newNavbar;

        if (parseInt(sessionStorage.getItem("employee_id")) == 502) {
          newNavbar = navbarDataRegistar?.filter(
            (s) => s.title != "UMS Settings" && s.title != "Examination"
          );
        } else {
          newNavbar = navbarDataRegistar?.filter(
            (s) => s.title != "Clinical Studies"
          );
        }

        let students = navbarDataRegistar?.find((s) => s.title == "Students");
        let admission = navbarDataRegistar?.find(
          (s) => s.title == "Admissions"
        );

        if (students) {
          students.drop = students?.drop?.filter(
            (s) =>
              s.title != "Internal Exams" &&
              s.title != "Midterm Exams" &&
              s.title != "Viva" &&
              s.title != "Student Complaints"
          );
        }

        if (admission) {
          admission.drop = admission?.drop?.filter(
            (s) => s.title != "Admission Form"
          );
        }

        return (
          <>
            {/* role == "SUPERADMIN" || x == "SUPERADMIN" ? null : (
          <Navigate replace to="/" />
        )*/}
            <Loader loading={loading} />
            <Topbar changeCollege={changeCollege} />
            <Navbar data={newNavbar} />
            <Element setLoading={setLoading} collegeId={collegeId} />
            <Footer />
          </>
        );
      }
    }
  };

  const RouteWithPrincipal = ({ Element }) => {
    const [loading, setLoading] = useState(0);

    const getAuth = () => {
      return sessionStorage.getItem(SESSION_AUTH) ? true : false;
    };

    const [auth, setAuth] = useState(getAuth());

    const setupCollege = async () => {
      await getCollegeId()
        .then((data) => {
          setCollegeId(data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    useEffect(() => {
      setupCollege();
    }, []);

    let collegeId = sessionStorage.getItem("college_id");

    if (auth == false || collegeId == false) return <Navigate replace to="/" />;

    const stat = "static";

    let studentdrop;

    if (collegeId != 1111000 && collegeId != 1111009) {
      studentdrop = [
        {
          title: "Student Details",
          type: stat,
          route: ROUTES.Principal.Student.StudentDetails,
        },
        {
          title: "Exams",
          type: stat,
          route: ROUTES.Principal.Student.Exam,
        },
        {
          title: "Update ABC",
          type: stat,
          route: ROUTES.Principal.Student.UpdateABC,
        },
      ];
    } else {
      studentdrop = [
        {
          title: "Student Details",
          type: stat,
          route: ROUTES.Principal.Student.StudentDetails,
        },
        {
          title: "Internal Exams",
          type: stat,
          route: ROUTES.Principal.Student.Exam,
        },
        {
          title: "Midterm Exams",
          type: stat,
          route: ROUTES.Principal.Student.Midterm,
        },
        {
          title: "Viva",
          type: stat,
          route: ROUTES.Principal.Student.Viva,
        },
        {
          title: "Update ABC",
          type: stat,
          route: ROUTES.Principal.Student.UpdateABC,
        },
      ];
    }

    navbarDataPrincipal?.map((s) => {
      if (s.title == "Students") {
        s.drop = studentdrop;
      }
    });

    return (
      <>
        <Loader loading={loading} />
        <Topbar />
        <Navbar data={navbarDataPrincipal} />
        <Element setLoading={setLoading} collegeId={collegeId} />
        <Footer />
      </>
    );
  };

  const RouteWithEvaluator = ({ Element }) => {
    return (
      <>
        <Topbar />
        <Element />
        <Footer />
      </>
    );
  };

  const RouteWithAccounts = ({ Element }) => {
    const getAuth = () => {
      return sessionStorage.getItem(SESSION_AUTH) ? true : false;
    };

    const [auth, setAuth] = useState(getAuth());

    const setupCollege = async () => {
      let clg = await getCollegeId();
      if (clg == "null") {
        setCollegeId(colleges[0]?.id);
      } else {
        setCollegeId(clg);
      }
    };

    useEffect(() => {
      setupCollege();
    }, []);

    const [loading, setLoading] = useState(0);

    if (auth == false || collegeId == false) return <Navigate replace to="/" />;

    return (
      <>
        <Loader loading={loading} />
        <Topbar changeCollege={changeCollege} />
        <Navbar data={navbarDataAccountant} />
        <Element setLoading={setLoading} collegeId={collegeId} />
        <Footer />
      </>
    );
  };

  const RouteWithCashier = ({ Element }) => {
    const getAuth = () => {
      return sessionStorage.getItem(SESSION_AUTH) ? true : false;
    };

    const [auth, setAuth] = useState(getAuth());

    const setupCollege = async () => {
      let clg = await getCollegeId();
      if (clg == "null") {
        setCollegeId(colleges[0]?.id);
      } else {
        setCollegeId(clg);
      }
    };

    useEffect(() => {
      setupCollege();
    }, []);

    const [loading, setLoading] = useState(0);

    return (
      <>
        <Loader loading={loading} />
        <Topbar changeCollege={changeCollege} />
        <Navbar data={navbarCashier} />
        <Element setLoading={setLoading} collegeId={collegeId} />
        <Footer />
      </>
    );
  };

  const RouteWithAdmissionConsultant = ({ Element }) => {
    const getAuth = () => {
      return sessionStorage.getItem(SESSION_AUTH) ? true : false;
    };

    const [auth, setAuth] = useState(getAuth());

    const setupCollege = async () => {
      await getCollegeId()
        .then((data) => {
          if (data == "null") {
            setCollegeId(colleges[0]?.id);
          } else {
            setCollegeId(data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    useEffect(() => {
      setupCollege();
    }, []);

    const [loading, setLoading] = useState(0);

    if (auth == false || collegeId == false) return <Navigate replace to="/" />;

    return (
      <>
        <Loader loading={loading} />
        <Topbar changeCollege={changeCollege} />
        <Navbar data={NavbarAdCon} />
        <Element setLoading={setLoading} collegeId={collegeId} />
        <Footer />
      </>
    );
  };

  const RouteWithReceptionist = ({ Element }) => {
    const getAuth = () => {
      return sessionStorage.getItem(SESSION_AUTH) ? true : false;
    };

    const [auth, setAuth] = useState(getAuth());

    const [loading, setLoading] = useState(0);

    if (auth == false || collegeId == false) return <Navigate replace to="/" />;

    return (
      <>
        <>
          <Loader loading={loading} />
          <Topbar changeCollege={changeCollege} />
          <Navbar data={navbarDataReceptionist} />
          <Element setLoading={setLoading} collegeId={collegeId} />
          <Footer />
        </>
      </>
    );
  };

  const RouteWithLibrary = ({ Element }) => {
    const [loading, setLoading] = useState(0);

    if (sessionStorage.getItem("role") == "LIB") {
      var x = navbarDataLib.filter((s) => s.title != "UMS Dashboard");
    } else {
      x = navbarDataLib;
    }
    return (
      <>
        <>
          <Loader loading={loading} />
          <Topbar changeCollege={changeCollege} />
          <Navbar data={x} />
          <Element setLoading={setLoading} collegeId={collegeId} />
          <Footer />
        </>
      </>
    );
  };

  useEffect(() => {
    getRoles();
    getAllData();
  }, []);

  const RouteWithNTSTAFF = ({ Element }) => {
    const [loading, setLoading] = useState(0);

    const getAuth = () => {
      return sessionStorage.getItem(SESSION_AUTH) ? true : false;
    };

    let x = sessionStorage.getItem("role");

    const [auth, setAuth] = useState(getAuth());

    const setupCollege = async () => {
      let data = await getCollegeId();
      if (data == "null") {
        console.log("init");
        console.log("hello");
        setCollegeId(colleges[0]?.id);
      } else {
        setCollegeId(data);
      }
    };

    useEffect(() => {
      setupCollege();
    }, []);

    if (auth == false || collegeId == false) return <Navigate replace to="/" />;

    return (
      <>
        <Loader loading={loading} />
        <Topbar changeCollege={changeCollege} />
        <Navbar data={navbarDataNTSTAFF} />
        <Element setLoading={setLoading} collegeId={collegeId} />
        <Footer />
      </>
    );
  };

  const RouteWithStudent = ({ Element }) => {
    // let auth = sessionStorage.getItem("student_auth");

    const college_id = sessionStorage.getItem("college");
    const provisional = sessionStorage.getItem("is_provisional");
    const hostel = sessionStorage.getItem("is_hostel");
    console.log("college_id -", college_id);

    // {auth ? null : <Navigate replace to="/" />}
    const [loading, setLoading] = useState(false);

    return (
      <>
        <Loader loading={loading} />
        <TopbarStudent />
        <Navbar
          data={
            college_id == "1111011" && provisional == "0" && hostel == "0"
              ? navbarDataMedicalStudent
              : college_id != "1111011" && provisional == "0" && hostel == "0"
              ? navbarDataStudent
              : provisional == "1" && hostel == "0"
              ? navbarDataProvisionalStudent
              : navbarDataGatePassStudent
          }
        />
        <Element setLoading={setLoading} />
        <Footer />
      </>
    );
  };

  const getAllDropData = async () => {
    const config = {
      method: "get",
      url: GET_ALL_DROP_DATA,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then(async (res) => {
        console.log(res);
        await localStorage.setItem(COLLEGE, JSON.stringify(res.data.college));
        await localStorage.setItem(
          DEPARTEMENT,
          JSON.stringify(res.data.department)
        );
        await localStorage.setItem(PROGRAM, JSON.stringify(res.data.program));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllDropData();
  }, []);

  const RouteWithAdmission = ({ Element }) => {
    const [loading, setLoading] = useState(false);

    const [step, setStep] = useState(1);

    const getAuth = () => {
      return sessionStorage.getItem(USER_AUTH)
        ? sessionStorage.getItem(USER_AUTH)
        : false;
    };

    const getHeader = () => {
      return sessionStorage.getItem(HEADER)
        ? sessionStorage.getItem(HEADER)
        : "APPLICATION FORM";
    };

    function isStringified(str) {
      try {
        return JSON.parse(str);
      } catch (err) {
        return str;
      }
    }

    const [header, setHeader] = useState(getHeader);
    const [auth, setAuth] = useState(getAuth());
    const [data, setData] = useState({ basic_data: null, form_data: null });
    const [basic_data, set_basic_data] = useState();
    const [form_data, set_form_data] = useState();

    //Dropdown datas
    const [collegeOpt, setCollegeOpt] = useState([]);

    const [programOpt, setProgramOpt] = useState([]);

    const [departmentOpt, setDepartmentOpt] = useState([]);

    const getAllDropData = async () => {
      setLoading(1);
      const config = {
        method: "get",
        url: GET_ALL_DROP_DATA,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      };

      await axios(config)
        .then(async (res) => {
          console.log(res);
          setCollegeOpt(res.data.college);
          setProgramOpt(res.data.program);
          setDepartmentOpt(res.data.department);
          await localStorage.setItem(COLLEGE, JSON.stringify(res.data.college));
          await localStorage.setItem(
            DEPARTEMENT,
            JSON.stringify(res.data.department)
          );
          await localStorage.setItem(PROGRAM, JSON.stringify(res.data.program));
          setLoading(0);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    useEffect(() => {
      getAllDropData();
    }, []);

    const getForm = async (e) => {
      var config = {
        method: "get",
        url: `${GET_DETAILS}?user_id=${sessionStorage.getItem(
          "temp_usn"
        )}&application_no=${sessionStorage.getItem("application_no")}`,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${auth}`,
        },
      };

      setLoading(1);

      await axios(config)
        .then((res) => {
          setLoading(0);
          console.log("Confusing Data", res.data);
          res.data.basic_data.aadhar = isStringified(
            res.data?.basic_data.aadhar
          );
          res.data.basic_data.pu_markscard = isStringified(
            res.data?.basic_data.pu_markscard
          );
          res.data.basic_data.sslc_markscard = isStringified(
            res.data?.basic_data.sslc_markscard
          );
          setData({
            basic_data: res.data?.basic_data,
            form_data: res.data?.form_data,
          });
          set_basic_data(res.data?.basic_data);
          set_form_data(res.data?.form_data);
        })
        .catch((err) => {
          setLoading(0);
          toast.error(err.response.data?.message);
          console.log(err);
        });
    };

    const calculateCompletedPer = (total, completed) => {
      let x = (completed / total) * 100;
      return x;
    };

    const updateForm = async () => {
      var total = Object.keys(basic_data).length;
      var completed = 0;
      Object.values(basic_data)?.map((item, key) => {
        if (item != null && item) {
          completed++;
        }
      });

      basic_data.basic_status = calculateCompletedPer(total, completed).toFixed(
        2
      );

      form_data.basic_status = calculateCompletedPer(total, completed).toFixed(
        2
      );

      total = 0;
      completed = 0;
      total = Object.keys(form_data).length;
      Object.values(form_data)?.map((item1, key) => {
        let x = isStringified(item1);
        if (x?.length > 0) {
          if (x[0]?.link?.length > 0) {
            completed++;
          }
        } else if (item1 != null && item1) {
          completed++;
        }
      });

      form_data.form_status = calculateCompletedPer(total, completed).toFixed(
        2
      );

      console.log(basic_data, form_data);
      // return;
      var config = {
        method: "put",
        url: `${UPDATE_PRE_ADMISSION}/${
          basic_data?.user_id
        }?application_no=${sessionStorage.getItem("application_no")}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
        data: {
          basic_data: {
            ...basic_data,
            application_status: "SUBMITTED",
          },
          temp_usn: sessionStorage.getItem("temp_usn"),
          form_data: form_data,
        },
      };

      setLoading(1);

      await axios(config)
        .then((res) => {
          setLoading(0);
          getForm();
          toast.success("Form updated successfully");
        })
        .catch((err) => {
          setLoading(0);
          toast.error(err.response.data.message);
          console.log(err);
        });
    };

    useEffect(() => {
      if (auth) {
        getForm();
      }
    }, [auth]);

    useEffect(() => {
      setAuth(sessionStorage.getItem(USER_AUTH));
    }, [sessionStorage.getItem(USER_AUTH)]);

    return (
      <>
        {/* {auth ? null : <Navigate to="/" />} */}
        <Loader loading={loading} />
        <ToastContainer autoClose={2000} />
        <NavbarAdmission auth={auth} />
        {/* <Navbar auth={auth} /> */}
        <hr />
        <h2 className="text-center mb-3 mt-3">{header}</h2>
        <hr />
        <div className="row container-fluid m-0">
          <div className="col-md-4">
            <ProfileCard
              basic_data={basic_data}
              programOpt={programOpt}
              collegeOpt={collegeOpt}
              departmentOpt={departmentOpt}
            />
          </div>
          <div className="col-md-8">
            <ProfileSwitches
              step={step}
              setStep={setStep}
              updateForm={updateForm}
              data={data}
            />
            {step == 1 ? (
              <BasicInformations
                setStep={setStep}
                data={data}
                setData={setData}
                setLoading={setLoading}
                step={step}
                basic_data={basic_data}
                set_basic_data={set_basic_data}
                auth={auth}
              />
            ) : step == 2 ? (
              <Payment
                basic_data={basic_data}
                data={data}
                auth={auth}
                setLoading={setLoading}
                form_data={form_data}
              />
            ) : step == 3 ? (
              <Element
                setStep={setStep}
                data={data}
                setData={setData}
                setLoading={setLoading}
                form_data={form_data}
                set_form_data={set_form_data}
              />
            ) : step == 4 ? (
              <DocumentsAdmission data={data} basic_data={basic_data} />
            ) : (
              <Status basic_data={basic_data} />
            )}
          </div>
        </div>
        <FooterAdmission />
        {/* <Footer /> */}
      </>
    );
  };

  const RouteWithBasic = ({ Element }) => {
    const [loading, setLoading] = useState(false);
    const [auth, setAuth] = useState();

    const getAllDropData = async () => {
      const config = {
        method: "get",
        url: GET_ALL_DROP_DATA,
        headers: {
          "Content-Type": "application/json",
        },
      };

      await axios(config)
        .then(async (res) => {
          await localStorage.setItem(COLLEGE, JSON.stringify(res.data.college));
          await localStorage.setItem(
            DEPARTEMENT,
            JSON.stringify(res.data.department)
          );
          await localStorage.setItem(PROGRAM, JSON.stringify(res.data.program));
        })
        .catch((err) => {
          console.log(err);
        });
    };

    useEffect(() => {
      getAllDropData();
    }, []);

    useEffect(() => {
      setAuth(sessionStorage.getItem(USER_AUTH));
    }, [sessionStorage.getItem(USER_AUTH)]);

    return (
      <>
        <Loader loading={loading} />
        <ToastContainer autoClose={2000} />
        <NavbarAdmission auth={auth} setLoading={setLoading} />
        <Element setLoading={setLoading} />
        <FooterAdmission />
      </>
    );
  };

  return (
    <div>
      <Routes>
        <Route
          exact
          path="/"
          element={<Register changeCollege={setCollegeId} />}
        ></Route>
        <Route
          exact
          path="/password/:id"
          element={<ForgotPassword changeCollege={setCollegeId} />}
        ></Route>
        <Route
          exact
          path="/student-password"
          element={<ForgotPassword1 changeCollege={setCollegeId} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Examination.OtpVerification}
          element={<OtpVerification />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Examination.InvigilatorLogin}
          element={<InvigilatorLogin />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Examination.AnswersheetChecking}
          element={<RouteWithEvaluator Element={AnswersheetChecking} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Examination.StudentList}
          element={<RouteWithEvaluator Element={Studentslist} />}
        ></Route>
        /////////////////////////////////////////// /////////////// Admin Panel
        /////////////// ///////////////////////////////////////////
        <Route
          exact
          path={ROUTES.Admin.toexamination}
          element={<AdmintoExamination />}
        ></Route>
        <Route
          exact
          path={`${ROUTES.Admin.Admission.AdmissionProfile}/:id`}
          element={<AdmissionStudentProfile />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.dashboard}
          element={<RouteWithAdmin Element={DashboardHR} />}
        ></Route>
        //Tickets
        <Route
          exact
          path={ROUTES.Admin.Ticket}
          element={<RouteWithAdmin Element={Tickets} />}
        ></Route>
        //TicketConversation
        <Route
          exact
          path={`${ROUTES.Admin.ticketConversation}/:id`}
          element={<RouteWithAdmin Element={TicketConversation} />}
        ></Route>
        // Front Office Routing
        <Route
          exact
          path={ROUTES.Admin.frontOffice.AdmissionEnquiry}
          element={<RouteWithAdmin Element={AdmissionEnquiry} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.frontOffice.VisitorsBook}
          element={<RouteWithAdmin Element={VisitorsBook} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.frontOffice.PhoneCallLog}
          element={<RouteWithAdmin Element={PhoneCallLog} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.frontOffice.PostalDispatch}
          element={<RouteWithAdmin Element={PostalDispatch} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.frontOffice.PostalRecieve}
          element={<RouteWithAdmin Element={PostalRecieve} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.frontOffice.Complain}
          element={<RouteWithAdmin Element={Complain} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.frontOffice.SetupOffice}
          element={<RouteWithAdmin Element={SetupOffice} />}
        ></Route>
        //Student Pages Routing
        <Route
          exact
          path={ROUTES.Admin.Student.StudentAdmission}
          element={<RouteWithAdmin Element={StudentAdmission} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Student.StudentDetails}
          element={<RouteWithAdmin Element={StudentDetails} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Student.DisableStudents}
          element={<RouteWithAdmin Element={DisableStudents} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Student.EditStudentDetails}
          element={<RouteWithAdmin Element={EditStudentDetails} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Student.StudentProfile}
          element={<RouteWithAdmin Element={StudentProfile} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Student.ViewStudentProfile}
          element={<RouteWithAdmin Element={ViewStudentProfile} />}
        ></Route>
        //Income
        <Route
          exact
          path={ROUTES.Admin.Accounts.Income.View}
          element={<RouteWithAdmin Element={ViewIncome} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Accounts.Income.Add}
          element={<RouteWithAdmin Element={AddIncome} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Accounts.Income.AddSource}
          element={<RouteWithAdmin Element={AddIncomeSource} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Accounts.Expense.View}
          element={<RouteWithAdmin Element={ViewExpense} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Accounts.Expense.Add}
          element={<RouteWithAdmin Element={AddExpense} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Accounts.Expense.AddSource}
          element={<RouteWithAdmin Element={AddExpenseSource} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Accounts.FeeCollection.CollegeFee}
          element={<RouteWithAdmin Element={FeeCollectionCollectFee} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Accounts.FeeCollection.SearchFee}
          element={<RouteWithAdmin Element={FeeCollectionSearchFee} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Accounts.FeeCollection.SearchDue}
          element={<RouteWithAdmin Element={FeeCollectionSearchDue} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Accounts.FeeCollection.FeeMaster}
          element={<RouteWithAdmin Element={FeeCollectionFeeMaster} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Accounts.FeeCollection.FeeType}
          element={<RouteWithAdmin Element={FeeCollectionFeeType} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Accounts.FeeCollection.FeeGroup}
          element={<RouteWithAdmin Element={FeeCollectionFeeGroup} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Accounts.FeeCollection.FeeDiscount}
          element={<RouteWithAdmin Element={FeeCollectionFeeDiscount} />}
        ></Route>
        // Academics
        <Route
          exact
          path={ROUTES.Admin.Academics.ClassTimeTable}
          element={<RouteWithAdmin Element={ClassTimeTable} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Academics.CreateTimeTable}
          element={<RouteWithAdmin Element={CreateTimeTable} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Academics.TeacherTimeTable}
          element={<RouteWithAdmin Element={TeachersTimetable} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Academics.PromoteStudents}
          element={<RouteWithAdmin Element={PromoteStudents} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Academics.ChangeBranch}
          element={<RouteWithAdmin Element={ChangeBranch} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Academics.AddBatch}
          element={<RouteWithAdmin Element={AddBatch} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Academics.AssignStudents}
          element={<RouteWithAdmin Element={AssignStudents} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Academics.AddSubject}
          element={<RouteWithAdmin Element={AddSubjects} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Academics.AddClass}
          element={<RouteWithAdmin Element={AddClass} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Academics.ManageLessonPlan}
          element={<RouteWithAdmin Element={ManageLessonPlan} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Academics.SyllabusStatus}
          element={<RouteWithAdmin Element={SyllabusStatus} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Academics.AddTopic}
          element={<RouteWithAdmin Element={AddTopics} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Academics.CourseRegistration}
          element={<RouteWithAdmin Element={CourseRegistration} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Academics.AddBloomsLevel}
          element={<RouteWithAdmin Element={AddBloomsLevel} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Academics.CourseOutcomes + "/:id"}
          element={<RouteWithAdmin Element={CourseOutcomes} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Academics.AddLesson}
          element={<RouteWithAdmin Element={AddLessons} />}
        ></Route>
        //Front Office
        <Route
          exact
          path={ROUTES.FrontOffice.AdmissionEnquiry}
          element={<RouteWithFrontOffice Element={AdmissionEnquiry} />}
        ></Route>
        <Route
          exact
          path={ROUTES.FrontOffice.PhoneCallLog}
          element={<RouteWithFrontOffice Element={PhoneCallLog} />}
        ></Route>
        <Route
          exact
          path={ROUTES.FrontOffice.PayRoll.PaySlipPrint}
          element={<RouteWithFrontOffice Element={PaySlipPrint} />}
        ></Route>
        <Route
          exact
          path={ROUTES.FrontOffice.VisitorsBook}
          element={<RouteWithFrontOffice Element={VisitorsBook} />}
        ></Route>
        <Route
          exact
          path={ROUTES.FrontOffice.PostalDispatch}
          element={<RouteWithFrontOffice Element={PostalDispatch} />}
        ></Route>
        <Route
          exact
          path={ROUTES.FrontOffice.PostalRecieve}
          element={<RouteWithFrontOffice Element={PostalRecieve} />}
        ></Route>
        {/* <Route
          exact
          path={ROUTES.FrontOffice.ViewPostalDispatchRecieve}
          element={<RouteWithFrontOffice Element={ViewPostalDispatchRecieve} />}
        ></Route> */}
        <Route
          exact
          path={ROUTES.FrontOffice.Complain}
          element={<RouteWithFrontOffice Element={Complain} />}
        ></Route>
        <Route
          exact
          path={ROUTES.FrontOffice.SetupOffice}
          element={<RouteWithFrontOffice Element={SetupOffice} />}
        ></Route>
        <Route
          exact
          path={ROUTES.FrontOffice.Document}
          element={
            <RouteWithFrontOffice Element={EmployeeDocumentManagement} />
          }
        ></Route>
        <Route
          exact
          path={ROUTES.FrontOffice.Attendance}
          element={<RouteWithFrontOffice Element={EmployerAttendance} />}
        ></Route>
        <Route
          exact
          path={ROUTES.FrontOffice.Team.Discussion}
          element={<RouteWithFrontOffice Element={EmployeeTeamDiscussion} />}
        ></Route>
        <Route
          exact
          path={ROUTES.FrontOffice.Team.Documents}
          element={<RouteWithFrontOffice Element={EmployeeTeamDocument} />}
        ></Route>
        <Route
          exact
          path={ROUTES.FrontOffice.Team.Announcement}
          element={<RouteWithFrontOffice Element={TeamAnnouncement} />}
        ></Route>
        <Route
          exact
          path={ROUTES.FrontOffice.Leave.Entitlement}
          element={<RouteWithFrontOffice Element={EmployeeLeaveEntitlement} />}
        ></Route>
        <Route
          exact
          path={ROUTES.FrontOffice.Leave.Application}
          element={<RouteWithFrontOffice Element={EmployeeLeaveApplication} />}
        ></Route>
        <Route
          exact
          path={ROUTES.FrontOffice.Leave.Schedule}
          element={<RouteWithFrontOffice Element={EmployeeSchedule} />}
        ></Route>
        <Route
          exact
          path={ROUTES.FrontOffice.PayRoll.Salary}
          element={<RouteWithFrontOffice Element={EmployerSalary} />}
        ></Route>
        <Route
          exact
          path={ROUTES.FrontOffice.PayRoll.AnnualStatement}
          element={<RouteWithFrontOffice Element={EmployeeAnnualStatement} />}
        ></Route>
        //Examinations
        {/* 
        <Route exact path={ROUTES.Admin.Examination.Commitee} element={<RouteWithAdmin Element={Commitee} />}></Route>
        <Route exact path={ROUTES.Admin.Examination.CreateExams} element={<RouteWithAdmin Element={CreateExams} />}></Route>
        <Route exact path={ROUTES.Admin.Examination.CreateQuestionPaper} element={<RouteWithAdmin Element={CreateQuestionPaper} />}></Route>
        <Route exact path={ROUTES.Admin.Examination.CreateExamTimetable} element={<RouteWithAdmin Element={CreateExamTimetable} />}></Route>
        <Route exact path={ROUTES.Admin.Examination.ExamSchedules} element={<RouteWithAdmin Element={ExamSchedule} />}></Route>
        <Route exact path={ROUTES.Admin.Examination.ExamSeatingArrangements} element={<RouteWithAdmin Element={ExamSeatingArrangements} />}></Route>
        <Route exact path={ROUTES.Admin.Examination.PostExaminations} element={<RouteWithAdmin Element={PostExamination} />}></Route>
        <Route exact path={ROUTES.Admin.Examination.OtpVerification} element={<RouteWithAdmin Element={OtpVerification}/>}></Route>
        <Route exact path={ROUTES.Admin.Examination.DownloadQuestionPaper} element={<RouteWithAdmin Element={DownloadQuestionPaper}/>}></Route>

        <Route exact path={ROUTES.Admin.Examination.AssignAnswerSheets} element={<RouteWithAdmin Element={AssignAnswersheets  }/>}></Route>

    

        <Route exact path={ROUTES.Examination.OtpVerification} element={<RouteWithAdmin Element={OtpVerification} />}></Route> */}
        //Document
        <Route
          exact
          path={ROUTES.Admin.Document.UploadContent}
          element={<RouteWithAdmin Element={FeeCollectionFeeDiscount} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Document.DownloadContent.Assignment}
          element={<RouteWithAdmin Element={FeeCollectionFeeDiscount} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Document.DownloadContent.Syllabus}
          element={<RouteWithAdmin Element={FeeCollectionFeeDiscount} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Document.DownloadContent.Studymaterial}
          element={<RouteWithAdmin Element={FeeCollectionFeeDiscount} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Document.DownloadContent.OtherDownload}
          element={<RouteWithAdmin Element={FeeCollectionFeeDiscount} />}
        ></Route>
        //Transport
        <Route
          exact
          path={ROUTES.Admin.Transport.Route}
          element={<RouteWithAdmin Element={TransportRoute} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Transport.pickupPoints}
          element={<RouteWithAdmin Element={PickupPoints} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Transport.Vehicles}
          element={<RouteWithAdmin Element={TransportVehicle} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Transport.AssignVehicles}
          element={<RouteWithAdmin Element={TransportAssignVehicles} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Transport.transportFee}
          element={<RouteWithAdmin Element={transportFee} />}
        ></Route>
        <Route
          exact
          path={`${ROUTES.Admin.Transport.addtransportFee}/:id`}
          element={<RouteWithAdmin Element={AddTransportFee} />}
        ></Route>
        //Hostel
        <Route
          exact
          path={ROUTES.Admin.Hostel.HostelRooms}
          element={<RouteWithAdmin Element={HostelRooms} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Hostel.Hostel}
          element={<RouteWithAdmin Element={Hostel} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Hostel.RoomType}
          element={<RouteWithAdmin Element={RoomType} />}
        ></Route>
        <Route
          exact
          path={`${ROUTES.Admin.Hostel.addhostelFee}/:id`}
          element={<RouteWithAdmin Element={AddHostelFee} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Hostel.hostelFee}
          element={<RouteWithAdmin Element={hostelFees} />}
        ></Route>
        ////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////Inventory//////////////////////////////////////
        <Route
          exact
          path={ROUTES.Registar.Inventory.IssueItems}
          element={<RouteWithRegistar Element={IssueItems} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Inventory.IssueItem}
          element={<RouteWithRegistar Element={IssueItem} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Inventory.ViewAllIssueItems}
          element={<RouteWithRegistar Element={ViewAllIssueItems} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Inventory.AddItem}
          element={<RouteWithRegistar Element={AddItem} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Inventory.AddItemstock}
          element={<RouteWithRegistar Element={Additemstock} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Inventory.ItemCategory}
          element={<RouteWithRegistar Element={ItemCategory} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Inventory.ItemStore}
          element={<RouteWithRegistar Element={ItemStore} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Inventory.ItemSupplier}
          element={<RouteWithRegistar Element={Itemsupplier} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Inventory.ItemSupplier}
          element={<RouteWithAdmin Element={Itemsupplier} />}
        ></Route>
        //Certificate
        <Route
          exact
          path={ROUTES.Admin.Certificates.DesignHallticket}
          element={<RouteWithAdmin Element={DesignHallticket} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Certificates.DesignMarkscard}
          element={<RouteWithAdmin Element={DesignMarkscard} />}
        ></Route>
        ////////////////////////////////////////Library//////////////////////////////////////////////
        <Route
          exact
          path={ROUTES.Admin.Library.Books}
          element={<RouteWithAdmin Element={Books} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Library.Library_Settings}
          element={<RouteWithAdmin Element={LibrarySettings} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.Library.Borrow}
          element={<RouteWithAdmin Element={BorrowBook} />}
        ></Route>
        //////////////////// ///////////////////// /////Principal////////
        ///////////////////// //////////////////// //dashboard
        <Route
          exact
          path={ROUTES.Principal.dashboard}
          element={<RouteWithPrincipal Element={DashboardPrincipal} />}
        ></Route>
        {/* <Route
          exact
          path={ROUTES.Principal.Event.EventCustomApproves}
          element={<RouteWithPrincipal Element={EventCustomApprove} />}
        ></Route>

        <Route
          exact
          path={ROUTES.Principal.Event.EventWorkFlow}
          element={<RouteWithPrincipal Element={EventWorkFlow} />}
        ></Route> */}
        <Route
          exact
          path={ROUTES.Principal.Event.AddEvent}
          element={<RouteWithPrincipal Element={AddEvent} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.StaffDairy.StaffDairy}
          element={<RouteWithPrincipal Element={StaffDairy} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.StaffDairy.ViewStaffDairy}
          element={<RouteWithPrincipal Element={ViewStaffDairy} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Student.Midterm}
          element={<RouteWithPrincipal Element={StudentMidtermExam} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Student.Viva}
          element={<RouteWithPrincipal Element={StudentsVivaMarks} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Reports.StudentStat}
          element={<RouteWithPrincipal Element={ReportOneStudentStat} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.PhdAdmissions}
          element={<RouteWithPrincipal Element={PhdAdmissions} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.PhdAdmissionsprofile}
          element={<RouteWithPrincipal Element={PhdStudentProfile} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.PhdAdmitCard + "/:id"}
          element={<RouteWithPrincipal Element={PhdAdmitCard} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.PhdExamList}
          element={<RouteWithPrincipal Element={PhdList} />}
        ></Route>
        <Route
          exact
          path={
            ROUTES.Principal.Reports.StudentSubStat + "/:student_id/:course_id"
          }
          element={<RouteWithPrincipal Element={ReportOneStudentSubject} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Reports.Home}
          element={<RouteWithPrincipal Element={DeanReports} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Reports.StudentDetails1}
          element={<RouteWithPrincipal Element={StudentDetails1} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Reports.Attendance}
          element={<RouteWithPrincipal Element={ReportStudentAttendance} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Reports.MonthWise}
          element={<RouteWithPrincipal Element={MonthWiseAttendance} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Reports.AttendanceSemWise}
          element={
            <RouteWithPrincipal Element={ReportStudentAttendanceSemWise} />
          }
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Academics.ViewPracStudents}
          element={<RouteWithPrincipal Element={ViewAssignedStudents} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Student.AssignStudents}
          element={<RouteWithPrincipal Element={AssignStudents} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Student.UpdateABC}
          element={<RouteWithPrincipal Element={StudentUpdateAbc} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Student.Exam}
          element={<RouteWithPrincipal Element={StudentsExams} />}
        ></Route>
        //Tickets
        <Route
          exact
          path={ROUTES.Principal.Ticket}
          element={<RouteWithPrincipal Element={Tickets} />}
        ></Route>
        //TicketConversation
        <Route
          exact
          path={`${ROUTES.Principal.ticketConversation}/:id`}
          element={<RouteWithPrincipal Element={TicketConversation} />}
        ></Route>
        //Front Office
        <Route
          exact
          path={ROUTES.Principal.frontOffice.AdmissionEnquiry}
          element={<RouteWithPrincipal Element={AdmissionEnquiry} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.frontOffice.VisitorsBook}
          element={<RouteWithPrincipal Element={VisitorsBook} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.frontOffice.PhoneCallLog}
          element={<RouteWithPrincipal Element={PhoneCallLog} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.frontOffice.PostalDispatch}
          element={<RouteWithPrincipal Element={PostalDispatch} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.frontOffice.PostalRecieve}
          element={<RouteWithPrincipal Element={PostalRecieve} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.frontOffice.Complain}
          element={<RouteWithPrincipal Element={Complain} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.frontOffice.SetupOffice}
          element={<RouteWithPrincipal Element={SetupOffice} />}
        ></Route>
        //Students
        <Route
          exact
          path={ROUTES.Principal.Student.StudentAdmission}
          element={<RouteWithPrincipal Element={StudentAdmission} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Student.StudentDetails}
          element={<RouteWithPrincipal Element={StudentDetails} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Student.DisableStudents}
          element={<RouteWithPrincipal Element={DisableStudents} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Student.EditStudentDetails}
          element={<RouteWithPrincipal Element={EditStudentDetails} />}
        ></Route>
        <Route
          exact
          path={`${ROUTES.Principal.Student.StudentProfile}/:id`}
          element={<RouteWithPrincipal Element={StudentProfile} />}
        ></Route>
        <Route
          exact
          path={`${ROUTES.Principal.Student.ViewStudentProfile}/:id`}
          element={<RouteWithPrincipal Element={ViewStudentProfile} />}
        ></Route>
        //Accounts
        <Route
          exact
          path={ROUTES.Principal.Accounts.Income.View}
          element={<RouteWithPrincipal Element={ViewIncome} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Accounts.Income.Add}
          element={<RouteWithPrincipal Element={AddIncome} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Accounts.Income.AddSource}
          element={<RouteWithPrincipal Element={AddIncomeSource} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Accounts.Expense.View}
          element={<RouteWithPrincipal Element={ViewExpense} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Accounts.Expense.Add}
          element={<RouteWithPrincipal Element={AddExpense} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Accounts.Expense.AddSource}
          element={<RouteWithPrincipal Element={AddExpenseSource} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Accounts.FeeCollection.CollegeFee}
          element={<RouteWithPrincipal Element={FeeCollectionCollectFee} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Accounts.FeeCollection.SearchFee}
          element={<RouteWithPrincipal Element={FeeCollectionSearchFee} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Accounts.FeeCollection.SearchDue}
          element={<RouteWithPrincipal Element={FeeCollectionSearchDue} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Accounts.FeeCollection.FeeMaster}
          element={<RouteWithPrincipal Element={FeeCollectionFeeMaster} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Accounts.FeeCollection.FeeType}
          element={<RouteWithPrincipal Element={FeeCollectionFeeType} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Accounts.FeeCollection.FeeGroup}
          element={<RouteWithPrincipal Element={FeeCollectionFeeGroup} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Accounts.FeeCollection.FeeDiscount}
          element={<RouteWithPrincipal Element={FeeCollectionFeeDiscount} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Accounts.FeeCollection.FeeTypeAmount}
          element={<RouteWithPrincipal Element={FeeCollectionFeeTypeAmount} />}
        ></Route>
        <Route
          exact
          path={`${ROUTES.Principal.Accounts.FeeCollection.AddFee}/:id`}
          element={<RouteWithPrincipal Element={FeeCollectionAddFee} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Accounts.FeeCollection.assignFee}
          element={<RouteWithPrincipal Element={FeeCollectionAssignFee} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Accounts.FeeCollection.Reports}
          element={<RouteWithPrincipal Element={FeeCollectionReports} />}
        ></Route>
        //Academics
        <Route
          exact
          path={ROUTES.Principal.Academics.ClassTimeTable}
          element={<RouteWithPrincipal Element={ClassTimeTable} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Academics.CreateTimeTable}
          element={<RouteWithPrincipal Element={CreateTimeTable} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Academics.TeacherTimeTable}
          element={<RouteWithPrincipal Element={TeachersTimetable} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Academics.PromoteStudents}
          element={<RouteWithPrincipal Element={PromoteStudents} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Academics.ChangeBranch}
          element={<RouteWithPrincipal Element={ChangeBranch} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Academics.AddBatch}
          element={<RouteWithPrincipal Element={AddBatch} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Academics.AddSubject}
          element={<RouteWithPrincipal Element={AddSubjects} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Academics.AddClass}
          element={<RouteWithPrincipal Element={AddClass} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Academics.ManageLessonPlan}
          element={<RouteWithPrincipal Element={ManageLessonPlan} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Academics.SyllabusStatus}
          element={<RouteWithPrincipal Element={SyllabusStatus} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Academics.AddTopic}
          element={<RouteWithPrincipal Element={AddTopics} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Academics.CourseRegistration}
          element={<RouteWithPrincipal Element={CourseRegistration} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Academics.AddBloomsLevel}
          element={<RouteWithPrincipal Element={AddBloomsLevel} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Academics.CourseOutcomes + "/:id"}
          element={<RouteWithPrincipal Element={CourseOutcomes} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Academics.AddQuestionBank}
          element={<RouteWithPrincipal Element={AddQuestionBank} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Academics.AddLesson}
          element={<RouteWithPrincipal Element={AddLessons} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Academics.AddSemester}
          element={<RouteWithPrincipal Element={AddSemester} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Academics.AddSection}
          element={<RouteWithPrincipal Element={AddSection} />}
        ></Route>
        //Admissions
        <Route
          exact
          path={ROUTES.Principal.Admission.AdmissionDetails}
          element={<RouteWithPrincipal Element={AdmssionStudentDetails} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Admission.AdmissionEnquiry}
          element={<RouteWithPrincipal Element={AdmssionStudentEnquiry} />}
        ></Route>
        <Route
          exact
          path={`${ROUTES.Principal.Admission.AdmissionProfile}/:id`}
          element={<RouteWithPrincipal Element={AdmissionStudentProfile} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.Admission.NewAdmission}
          element={<RouteWithPrincipal Element={AdmissionNewAdmission} />}
        ></Route>
        ///Attendance
        <Route
          exact
          path={ROUTES.Principal.Attendance}
          element={<RouteWithPrincipal Element={ClassAttendance} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Principal.LabAttendance}
          element={<RouteWithPrincipal Element={LabAttendance} />}
        ></Route>
        ///////////////////// ///////////////////// //////Registar///////
        ///////////////////// ///////////////////// //Dashboard
        <Route
          exact
          path={ROUTES.Registar.dashboard}
          element={<RouteWithRegistar Element={DashboardRegistrar} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.dashboard2}
          element={<RouteWithRegistar Element={DashboardRegistrar2} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Student.ViewAluminiStudents}
          element={<RouteWithRegistar Element={ViewAluminiStudents} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.StudentDetails1}
          element={<RouteWithRegistar Element={StudentDetails1} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Student.StudentAdmissionReport}
          element={<RouteWithRegistar Element={StudentAdmissionReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Student.assignStudents}
          element={<RouteWithRegistar Element={AssignStudents} />}
        ></Route>
        //
        <Route
          exact
          path={ROUTES.Registar.ApproveStudentId}
          element={<RouteWithRegistar Element={ApproveStudentId} />}
        ></Route>
        {/* NAAC Routes*/}
        <Route
          path={ROUTES.Registar.NAAC.approvalFlow}
          element={<RouteWithRegistar Element={NAACApprovalFlow} />}
        />
        <Route
          path={ROUTES.Registar.NAAC.naacData.executiveSummaryPdf}
          element={<RouteWithRegistar Element={ExecutiveSummaryPdfFormat} />}
        />
        <Route
          exact
          path={ROUTES.Registar.NAAC.index}
          element={<RouteWithRegistar Element={NAAC} />}
        >
          <Route
            path={ROUTES.Registar.NAAC.dashboard.activityTracking}
            element={<ActivityTracking />}
          />
          <Route
            path={ROUTES.Registar.NAAC.dashboard.performanceTracking}
            element={<PerformanceTracking />}
          />

          <Route
            path={ROUTES.Registar.NAAC.dashboard.statusReport}
            element={<StatusReport />}
          />
          <Route
            path={ROUTES.Registar.NAAC.naacData.executiveSummary}
            element={<ExecutiveSummary />}
          />
          {/* <Route
            path={ROUTES.Registar.NAAC.naacData.executiveSummaryPdf}
            element={<ExecutiveSummaryPdfFormat />}
          /> */}
          <Route
            path={`${ROUTES.Registar.NAAC.naacData.profile}/*`}
            element={<ProfileRoutes />}
          />
          <Route
            path={`${ROUTES.Registar.NAAC.naacData.nepParameters}/*`}
            element={<NepParametersRoutes />}
          />
          <Route
            path={`${ROUTES.Registar.NAAC.naacData.extendedProfile}/*`}
            element={<ExtendedProfileRoutes />}
          />
          <Route
            path={ROUTES.Registar.NAAC.naacData.myDownloads}
            element={<MyDownloads />}
          />

          <Route
            path={ROUTES.Registar.NAAC.naacData.qifQualitative}
            element={<QifQualitative />}
          />
          <Route
            path={ROUTES.Registar.NAAC.naacData.qifQuantative}
            element={<QifQuantitative />}
          />
        </Route>
        //Front Office
        <Route
          exact
          path={ROUTES.Registar.frontOffice.AdmissionEnquiry}
          element={<RouteWithRegistar Element={AdmissionEnquiry} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.frontOffice.VisitorsBook}
          element={<RouteWithRegistar Element={VisitorsBook} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.frontOffice.PhoneCallLog}
          element={<RouteWithRegistar Element={PhoneCallLog} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.frontOffice.PostalDispatch}
          element={<RouteWithRegistar Element={PostalDispatch} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.frontOffice.PostalRecieve}
          element={<RouteWithRegistar Element={PostalRecieve} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.frontOffice.ViewPostalDispatchRecieve}
          element={<RouteWithRegistar Element={ViewPostalDispatchRecieve} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.frontOffice.Complain}
          element={<RouteWithRegistar Element={Complain} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.frontOffice.SetupOffice}
          element={<RouteWithRegistar Element={SetupOffice} />}
        ></Route>
        //Students
        <Route
          exact
          path={ROUTES.Registar.Student.StudentAdmission}
          element={<RouteWithRegistar Element={StudentAdmission} />}
        ></Route>
        //StudentComplain
        <Route
          exact
          path={ROUTES.Registar.Student.NewComplaint}
          element={<RouteWithRegistar Element={Complaints} />}
        ></Route>
        //TicketsCenter
        <Route
          exact
          path={ROUTES.Registar.developersCentre}
          element={<RouteWithDevelopers Element={DevelopersCentre} />}
        ></Route>
        <Route
          exact
          path={ROUTES.IT.developersCentre}
          element={<RouteWithIT Element={DevelopersCentre} />}
        ></Route>
        <Route
          exact
          path={ROUTES.IT.PaySlipPrint}
          element={<RouteWithIT Element={PaySlipPrint} />}
        ></Route>
        <Route
          exact
          path={ROUTES.IT.leaveEntitlement}
          element={<RouteWithIT Element={EmployeeLeaveEntitlement} />}
        ></Route>
        <Route
          exact
          path={ROUTES.IT.leaveApplication}
          element={<RouteWithIT Element={EmployeeLeaveApplication} />}
        ></Route>
        <Route
          exact
          path={ROUTES.IT.leaveSchedule}
          element={<RouteWithIT Element={EmployeeSchedule} />}
        ></Route>
        <Route
          exact
          path={ROUTES.IT.PayRoll}
          element={<RouteWithIT Element={EmployerSalary} />}
        ></Route>
        <Route
          exact
          path={ROUTES.IT.AnnualStatement}
          element={<RouteWithIT Element={EmployeeAnnualStatement} />}
        ></Route>
        <Route
          exact
          path={ROUTES.IT.documentManagement}
          element={<RouteWithIT Element={EmployeeDocumentManagement} />}
        ></Route>
        <Route
          exact
          path={ROUTES.IT.teamDiscussion}
          element={<RouteWithIT Element={EmployeeTeamDiscussion} />}
        ></Route>
        <Route
          exact
          path={ROUTES.IT.teamDocuments}
          element={<RouteWithIT Element={EmployeeTeamDocument} />}
        ></Route>
        <Route
          exact
          path={ROUTES.IT.teamAnnouncement}
          element={<RouteWithIT Element={EmployeeTeamAnnouncement} />}
        ></Route>
        <Route
          exact
          path={`${ROUTES.developersConversation}/:id`}
          element={<RouteWithDevelopers Element={DevelopersConversation} />}
        ></Route>
        //TicketConversation
        <Route
          exact
          path={`${ROUTES.Registar.ticketConversation}/:id`}
          element={<RouteWithDevelopers Element={TicketConversation} />}
        ></Route>
        //viewtickets
        <Route
          exact
          path={ROUTES.ViewTickets}
          element={<RouteWithDevelopers Element={ViewTickets} />}
        ></Route>
        //Info Uploading Page
        <Route
          exact
          path={ROUTES.mediaInfo}
          element={<RouteWithInfoUploadingTeam Element={MediaInfo} />}
        ></Route>
        <Route
          exact
          path={ROUTES.ViewMediaInfo}
          element={<RouteWithInfoUploadingTeam Element={ViewMediaInfo} />}
        ></Route>
        <Route
          exact
          path={ROUTES.homeoEvents}
          element={<RouteWithInfoUploadingTeam Element={HomeoInfo} />}
        ></Route>
        <Route
          exact
          path={ROUTES.ResearchandPublication}
          element={
            <RouteWithInfoUploadingTeam Element={ResearchandPublication} />
          }
        ></Route>
        <Route
          exact
          path={ROUTES.HomeopathyStudentsList}
          element={
            <RouteWithInfoUploadingTeam Element={HomeopathyStudentsList} />
          }
        ></Route>
        <Route
          exact
          path={ROUTES.ViewHomeoStudentsList}
          element={
            <RouteWithInfoUploadingTeam Element={ViewHomeoStudentsList} />
          }
        ></Route>
        <Route
          exact
          path={ROUTES.HomeopathyResults}
          element={<RouteWithInfoUploadingTeam Element={HomeopathyResults} />}
        ></Route>
        <Route
          exact
          path={ROUTES.CollegePermission}
          element={<RouteWithInfoUploadingTeam Element={CollegePermission} />}
        ></Route>
        //Staff Dairy in Registar
        <Route
          exact
          path={ROUTES.Registar.StaffDairy.StaffDairy}
          element={<RouteWithRegistar Element={StaffDairy} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.StaffDairy.ViewStaffDairy}
          element={<RouteWithRegistar Element={ViewStaffDairy} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.StaffDairy.StaffDairyReport}
          element={<RouteWithRegistar Element={StaffDairyReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.StaffDairy.PurchaseOrder}
          element={<RouteWithRegistar Element={PurchaseOrder} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.StaffDairy.CreatePurchaseOrder}
          element={<RouteWithRegistar Element={CreatePurchaseOrder} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.StaffDairy.StaffDairyReport2}
          element={<RouteWithRegistar Element={StaffDairyReport2} />}
        ></Route>
        <Route
          exact
          path={ROUTES.ViewCollegePermission}
          element={
            <RouteWithInfoUploadingTeam Element={ViewCollegePermission} />
          }
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Student.StudentDetails}
          element={<RouteWithRegistar Element={StudentDetails} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Student.StudentDetails1}
          element={<RouteWithRegistar Element={StudentDetails1} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Student.DisableStudents}
          element={<RouteWithRegistar Element={DisableStudents} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Student.EditStudentDetails}
          element={<RouteWithRegistar Element={EditStudentDetails} />}
        ></Route>
        <Route
          exact
          path={`${ROUTES.Registar.Student.StudentProfile}/:id`}
          element={<RouteWithRegistar Element={StudentProfile} />}
        ></Route>
        <Route
          exact
          path={`${ROUTES.Registar.Student.ViewStudentProfile}/:id`}
          element={<RouteWithRegistar Element={ViewStudentProfile} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Student.AdmissionEnquiry}
          element={<RouteWithRegistar Element={StudentAdmissionEnquiry} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Student.AdmissionDetails}
          element={<RouteWithRegistar Element={StudentAdmissionDetails} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Student.Exam}
          element={<RouteWithRegistar Element={StudentsExams} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Student.Midterm}
          element={<RouteWithRegistar Element={StudentMidtermExam} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Student.Viva}
          element={<RouteWithRegistar Element={StudentsVivaMarks} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Student.ABCUpdate}
          element={<RouteWithRegistar Element={StudentUpdateAbc} />}
        ></Route>
        //Admissions
        <Route
          exact
          path={ROUTES.Registar.Admission.AdmissionDetails}
          element={<RouteWithRegistar Element={AdmssionStudentDetails} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Admission.AdmissionEnquiry}
          element={<RouteWithRegistar Element={AdmssionStudentEnquiry} />}
        ></Route>
        <Route
          exact
          path={`${ROUTES.Registar.Admission.AdmissionProfile}/:id`}
          element={<RouteWithRegistar Element={AdmissionStudentProfile} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Admission.NewAdmission}
          element={<RouteWithRegistar Element={AdmissionNewAdmission} />}
        ></Route>
        //Admissions
        <Route
          exact
          path={ROUTES.Registar.Admission.PhdAdmissions}
          element={<RouteWithRegistar Element={PhdAdmissions} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Admission.PhdAdmissionsprofile}
          element={<RouteWithRegistar Element={PhdStudentProfile} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Admission.PhdAdmitCard + "/:id"}
          element={<RouteWithRegistar Element={PhdAdmitCard} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Admission.PhdExamAdmitCard + "/:id"}
          element={<RouteWithRegistar Element={PhdExamHallTicket} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Admission.PhdExamList}
          element={<RouteWithRegistar Element={PhdList} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Admission.PhdExamprofile + "/:id"}
          element={<RouteWithRegistar Element={PhdExamProfile} />}
        ></Route>
        //Accounts
        <Route
          exact
          path={ROUTES.Registar.Accounts.Income.View}
          element={<RouteWithRegistar Element={ViewIncome} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Accounts.Income.Add}
          element={<RouteWithRegistar Element={AddIncome} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Accounts.Income.AddSource}
          element={<RouteWithRegistar Element={AddIncomeSource} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Accounts.Expense.View}
          element={<RouteWithRegistar Element={ViewExpense} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Accounts.Expense.Add}
          element={<RouteWithRegistar Element={AddExpense} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Accounts.Expense.AddSource}
          element={<RouteWithRegistar Element={AddExpenseSource} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Accounts.FeeCollection.CollegeFee}
          element={<RouteWithRegistar Element={FeeCollectionCollectFee} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Accounts.FeeCollection.SearchFee}
          element={<RouteWithRegistar Element={FeeCollectionSearchFee} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Accounts.FeeCollection.SearchDue}
          element={<RouteWithRegistar Element={FeeCollectionSearchDue} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Accounts.FeeCollection.FeeMaster}
          element={<RouteWithRegistar Element={FeeCollectionFeeMaster} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Accounts.FeeCollection.FeeType}
          element={<RouteWithRegistar Element={FeeCollectionFeeType} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Accounts.FeeCollection.FeeGroup}
          element={<RouteWithRegistar Element={FeeCollectionFeeGroup} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Accounts.FeeCollection.FeeDiscount}
          element={<RouteWithRegistar Element={FeeCollectionFeeDiscount} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Accounts.FeeCollection.FeeTypeAmount}
          element={<RouteWithRegistar Element={FeeCollectionFeeTypeAmount} />}
        ></Route>
        <Route
          exact
          path={`${ROUTES.Registar.Accounts.FeeCollection.AddFee}/:id`}
          element={<RouteWithRegistar Element={FeeCollectionAddFee} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Accounts.FeeCollection.assignFee}
          element={<RouteWithRegistar Element={FeeCollectionAssignFee} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Accounts.FeeCollection.Reports}
          element={<RouteWithRegistar Element={FeeCollectionReports} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Accounts.FeeCollection.filterForFees}
          element={<RouteWithRegistar Element={FeeCollectionDummySearch} />}
        ></Route>
        //Academics
        <Route
          exact
          path={ROUTES.Registar.Academics.ClassTimeTable}
          element={<RouteWithRegistar Element={ClassTimeTable} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Event.EventCustomApproves}
          element={<RouteWithRegistar Element={EventCustomApprove} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Event.EventWorkFlow}
          element={<RouteWithRegistar Element={EventWorkFlow} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Event.AddEvent}
          element={<RouteWithRegistar Element={AddEvent} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Communicate.Announcements}
          element={<RouteWithRegistar Element={Announcements} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Communicate.UpcomingEvents}
          element={<RouteWithRegistar Element={UpcomingEvents} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Academics.CreateTimeTable}
          element={<RouteWithRegistar Element={CreateTimeTable} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Academics.TeacherTimeTable}
          element={<RouteWithRegistar Element={TeachersTimetable} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Academics.PromoteStudents}
          element={<RouteWithRegistar Element={PromoteStudents} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Academics.ChangeBranch}
          element={<RouteWithRegistar Element={ChangeBranch} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Academics.AddBatch}
          element={<RouteWithRegistar Element={AddBatch} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Academics.AddSubject}
          element={<RouteWithRegistar Element={AddSubjects} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Academics.AddClass}
          element={<RouteWithRegistar Element={AddClass} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Academics.ManageLessonPlan}
          element={<RouteWithRegistar Element={ManageLessonPlan} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Academics.SyllabusStatus}
          element={<RouteWithRegistar Element={SyllabusStatus} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Academics.AddTopic}
          element={<RouteWithRegistar Element={AddTopics} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Academics.CourseRegistration}
          element={<RouteWithRegistar Element={CourseRegistration} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Academics.AssignHospital}
          element={<RouteWithRegistar Element={AssignHospital} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Academics.ClinicalStudiesReport}
          element={<RouteWithRegistar Element={ClinicalStudiesReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Academics.AddBloomsLevel}
          element={<RouteWithRegistar Element={AddBloomsLevel} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Academics.CourseOutcomes + "/:id"}
          element={<RouteWithRegistar Element={CourseOutcomes} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Academics.AddQuestionBank}
          element={<RouteWithRegistar Element={AddQuestionBank} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Academics.AddLesson}
          element={<RouteWithRegistar Element={AddLessons} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Academics.AddSemester}
          element={<RouteWithRegistar Element={AddSemester} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Academics.AddSection}
          element={<RouteWithRegistar Element={AddSection} />}
        ></Route>
        //Transport
        <Route
          exact
          path={ROUTES.Registar.Transport.Route}
          element={<RouteWithRegistar Element={TransportRoute} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Transport.pickupPoints}
          element={<RouteWithRegistar Element={PickupPoints} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Transport.AssignPickupPoints}
          element={<RouteWithRegistar Element={TransportAssignPickupPoints} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Transport.TransportFees}
          element={<RouteWithRegistar Element={TransportFees} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Transport.Vehicles}
          element={<RouteWithRegistar Element={TransportVehicle} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Transport.AssignVehicles}
          element={<RouteWithRegistar Element={TransportAssignVehicles} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Transport.transportFee}
          element={<RouteWithRegistar Element={transportFee} />}
        ></Route>
        <Route
          exact
          path={`${ROUTES.Registar.Transport.addtransportFee}/:id`}
          element={<RouteWithRegistar Element={AddTransportFee} />}
        ></Route>
        //Attendance
        <Route
          exact
          path={ROUTES.Registar.Attendance.ClassAttendance}
          element={<RouteWithRegistar Element={ClassAttendance} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Attendance.LabAttendance}
          element={<RouteWithRegistar Element={LabAttendance} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Attendance.VisitorsList}
          element={<RouteWithRegistar Element={VisitorsList} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Academics.ViewPracStudents}
          element={<RouteWithRegistar Element={ViewAssignedStudents} />}
        ></Route>
        //////////////////////////////////////////
        //////////////////////////////////////////
        /////////////Warden///////////////////////
        //////////////////////////////////////////
        //////////////////////////////////////////
        <Route
          exact
          path={ROUTES.Warden.HostelFees}
          element={<RouteWithWarden Element={hostelFees} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Warden.HostelRooms}
          element={<RouteWithWarden Element={HostelRooms} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Warden.HostelBeds}
          element={<RouteWithWarden Element={HostelBeds} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Warden.RoomType}
          element={<RouteWithWarden Element={RoomType} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Warden.Hostel}
          element={<RouteWithWarden Element={Hostel} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Warden.HostelFloors}
          element={<RouteWithWarden Element={HostelFloors} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Warden.StudentDetails}
          element={<RouteWithWarden Element={StudentDetailsHostel} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Warden.AssignHostelStudents}
          element={<RouteWithWarden Element={AssignHostelStudents} />}
        ></Route>
        <Route
          exact
          path={`${ROUTES.Warden.StudentProfile}/:id`}
          element={<RouteWithWarden Element={StudentProfile1} />}
        ></Route>
        <Route
          exact
          path={`${ROUTES.Warden.ViewStudentProfile}/:id`}
          element={<RouteWithWarden Element={ViewStudentProfile} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Warden.HostelEditWarden}
          element={<RouteWithWarden Element={HostelEditWarden} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Warden.ModalHostelRooms}
          element={<RouteWithWarden Element={ModalHostelRooms} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Warden.ModalHostelRoomsAssign}
          element={<RouteWithWarden Element={ModalHostelRoomsAssign} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Warden.StudentGatePass}
          element={<RouteWithWarden Element={StudentGatePass} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Warden.ModalStudentgatePass}
          element={<RouteWithWarden Element={ModalStudentGatePass} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Warden.ViewStudentGatePass}
          element={<RouteWithWarden Element={ViewStudentGatePass} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Warden.GatePassInOut}
          element={<RouteWithWarden Element={GatePassInOut} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Warden.GatePassRequests}
          element={<RouteWithWarden Element={GatePassWarden} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Warden.GatePassRequest2}
          element={<RouteWithWarden Element={GatePassWarden2} />}
        ></Route>
        ////////////////////////////////////////////
        //////////////////////////////////////////// //Hostel
        ///////////////////////////////////////////
        <Route
          exact
          path={ROUTES.Registar.Hostel.HostelRooms}
          element={<RouteWithRegistar Element={HostelRooms} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Hostel.Hostel}
          element={<RouteWithRegistar Element={Hostel} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Hostel.RoomType}
          element={<RouteWithRegistar Element={RoomType} />}
        ></Route>
        <Route
          exact
          path={`${ROUTES.Registar.Hostel.addhostelFee}/:id`}
          element={<RouteWithRegistar Element={AddHostelFee} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Hostel.hostelFee}
          element={<RouteWithRegistar Element={hostelFees} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Hostel.HostelFloors}
          element={<RouteWithRegistar Element={HostelFloors} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Hostel.StudentGatePass}
          element={<RouteWithRegistar Element={StudentGatePass} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Hostel.ModalStudentgatePass}
          element={<RouteWithRegistar Element={ModalStudentGatePass} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Hostel.ViewStudentGatePass}
          element={<RouteWithRegistar Element={ViewStudentGatePass} />}
        ></Route>
        //Inventory
        <Route
          exact
          path={ROUTES.Registar.Inventory.IssueItems}
          element={<RouteWithRegistar Element={IssueItems} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Inventory.IssueItem}
          element={<RouteWithRegistar Element={IssueItem} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Inventory.AddItem}
          element={<RouteWithRegistar Element={AddItem} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Inventory.AddItemstock}
          element={<RouteWithRegistar Element={Additemstock} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Inventory.ItemCategory}
          element={<RouteWithRegistar Element={ItemCategory} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Inventory.ItemStore}
          element={<RouteWithRegistar Element={ItemStore} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Inventory.ItemSupplier}
          element={<RouteWithRegistar Element={Itemsupplier} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Inventory.PurchaseOrder}
          element={<RouteWithRegistar Element={PurchaseOrder} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Inventory.ViewPurchaseOrderById}
          element={<RouteWithRegistar Element={ViewPurchaseOrders} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Inventory.CreatePurchaseOrder}
          element={<RouteWithRegistar Element={CreatePurchaseOrder} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Inventory.PurchaseReceives}
          element={<RouteWithRegistar Element={PurchaseReceives} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Inventory.PurchaseBills}
          element={<RouteWithRegistar Element={Bills} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Inventory.RecordPayment}
          element={<RouteWithRegistar Element={RecordPayment} />}
        ></Route>
        //Certificate
        <Route
          exact
          path={ROUTES.Registar.Certificates.DesignHallticket}
          element={<RouteWithRegistar Element={DesignHallticket} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Certificates.DesignMarkscard}
          element={<RouteWithRegistar Element={DesignMarkscard} />}
        ></Route>
        //Reports //fee
        <Route
          exact
          path={ROUTES.Registar.Reports.Home}
          element={<RouteWithRegistar Element={Reports} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Fee.AdvancePayment}
          element={<RouteWithRegistar Element={AdvancePayment} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Fee.AdvancePaymentHome}
          element={<RouteWithRegistar Element={AdvancePaymentHome} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Fee.Home}
          element={<RouteWithRegistar Element={ReportsFeeHome} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Fee.BHA0}
          element={<RouteWithRegistar Element={FeeCollectionReports} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Syllabus.SyllabusStatus}
          element={<RouteWithRegistar Element={Syllabus} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Fee.collegewiseCllection}
          element={<RouteWithRegistar Element={CollegeWiseCollectionReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Fee.BHA1}
          element={<RouteWithRegistar Element={ReportsBha1} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Fee.BHA2}
          element={<RouteWithRegistar Element={ReportsBha2} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Fee.BHA3}
          element={<RouteWithRegistar Element={ReportsBha3} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Fee.BHA4}
          element={<RouteWithRegistar Element={ReportsBha4} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Fee.BHA5}
          element={<RouteWithRegistar Element={ReportsBha5} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Fee.BHA6}
          element={<RouteWithRegistar Element={ReportsBha6} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Fee.BHA7}
          element={<RouteWithRegistar Element={OnlineTransactionReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Fee.Pending}
          element={<RouteWithRegistar Element={ReportsPendingClasswise} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Fee.PendingCollegewise}
          element={<RouteWithRegistar Element={ReportFeePendingCollege} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Fee.departmentwiseCllection}
          element={
            <RouteWithRegistar Element={DepartmentWiseCollectionReport} />
          }
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Income.IncomeReport}
          element={<RouteWithRegistar Element={IncomeReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Expense.ExpenseReport}
          element={<RouteWithRegistar Element={ExpenseReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.LessonPlan.LessonPlanReport}
          element={<RouteWithRegistar Element={LessonPlanReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.StaffAttendance.StaffAttendanceReport}
          element={<RouteWithRegistar Element={StaffAttendanceReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Cashier.onlineTransaction}
          element={<RouteWithRegistar Element={OnlineTransactionReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Fee.CancelledReceipts}
          element={<RouteWithRegistar Element={CancelledReceipts} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Fee.ScholarshipReport}
          element={<RouteWithRegistar Element={ScholarshipReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Fee.SummaryFeeReport}
          element={<RouteWithRegistar Element={SummaryFeeReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Fee.programwiseCllection}
          element={<RouteWithRegistar Element={ProgramWiseCollectionReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Fee.hostelfeeCollection}
          element={<RouteWithRegistar Element={hostelFeeCollectionReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Fee.hostelfeeFacultyWise}
          element={<RouteWithRegistar Element={FacultyWiseHostelReport} />}
        ></Route>
        //staff
        <Route
          exact
          path={ROUTES.Registar.Reports.Staff.Home}
          element={<RouteWithRegistar Element={ReportStaffHome} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Staff.Leave}
          element={<RouteWithRegistar Element={ReportStaffLeave} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Staff.StaffDetails}
          element={<RouteWithRegistar Element={ReportStaffDetails} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Staff.TodayAttendance}
          element={<RouteWithRegistar Element={ReportStaffTodayAttendance} />}
        ></Route>
        //student
        <Route
          exact
          path={ROUTES.Registar.Reports.Student.Home}
          element={<RouteWithRegistar Element={ReportStudentAttendance} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Student.MonthWise}
          element={<RouteWithRegistar Element={MonthWiseAttendance} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Fee.Transport}
          element={<RouteWithRegistar Element={DateWiseTransportFees} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Student.AttendanceSemWise}
          element={
            <RouteWithRegistar Element={ReportStudentAttendanceSemWise} />
          }
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.Student.StudentStat}
          element={<RouteWithRegistar Element={ReportOneStudentStat} />}
        ></Route>
        <Route
          exact
          path={
            ROUTES.Registar.Reports.Student.StudentSubStat +
            "/:student_id/:course_id"
          }
          element={<RouteWithRegistar Element={ReportOneStudentSubject} />}
        ></Route>
        //feedbacks
        <Route
          exact
          path={ROUTES.Registar.Reports.Feedbacks.FeedbacksHome}
          element={<RouteWithRegistar Element={FeedbacksHome} />}
        ></Route>
        //Ticket
        <Route
          exact
          path={ROUTES.Registar.Ticket}
          element={<RouteWithRegistar Element={Tickets} />}
        ></Route>
        //TicketConversation
        <Route
          exact
          path={`${ROUTES.Registar.ticketConversation}/:id`}
          element={<RouteWithRegistar Element={TicketConversation} />}
        ></Route>
        //LMS
        <Route
          exact
          path={ROUTES.Registar.LMS.Create}
          element={<RouteWithRegistar Element={LMSCreate} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.LMS.View}
          element={<RouteWithRegistar Element={LMSList} />}
        ></Route>
        //////////////////////////////////////////////
        /////////////////////////////////////////// //////////////// //HR Pannel
        //////////////// /////////////////////////////////////////////
        ////////////////////////////////////////////// //Dashboard or Home
        <Route
          exact
          path={ROUTES.HR.Home}
          element={<RouteWithHR Element={DashboardHR} />}
        ></Route>
        //Tickets
        <Route
          exact
          path={ROUTES.HR.Ticket}
          element={<RouteWithHR Element={Tickets} />}
        ></Route>
        //TicketConversation
        <Route
          exact
          path={`${ROUTES.HR.ticketConversation}/:id`}
          element={<RouteWithHR Element={TicketConversation} />}
        ></Route>
        //Employee
        <Route
          exact
          path={ROUTES.HR.Employee.AddStaff}
          element={<RouteWithHR Element={AddNewStaff} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Employee.ViewStaff}
          element={<RouteWithHR Element={ViewStaff} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Employee.EmployeeCollegeTransfer}
          element={<RouteWithHR Element={EmployeeCollegeTransfer} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Employee.EditStaff}
          element={<RouteWithHR Element={EditStaff} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Employee.WebAccount}
          element={<RouteWithHR Element={WebAccount} />}
        ></Route>
        //////////////////Employer//////////////
        <Route
          exact
          path={ROUTES.HR.Employer.Information}
          element={<RouteWithHR Element={EmployerInformation} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Employer.CollegeType}
          element={<RouteWithHR Element={EmployeeCollegeType} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Employer.Program}
          element={<RouteWithHR Element={EmployeeProgram} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Employer.Specialization}
          element={<RouteWithHR Element={EmployeeSpecialization} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Employer.College}
          element={<RouteWithHR Element={EmployeeCollege} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Employer.CollegeSpecialization}
          element={<RouteWithHR Element={EmployeeCollegeSpecialization} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Employer.JobPosition}
          element={<RouteWithHR Element={JobPosition} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Employer.Department}
          element={<RouteWithHR Element={DepartmentCourse} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Employer.DepartmentCourse}
          element={<RouteWithHR Element={Department} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Employer.Branch}
          element={<RouteWithHR Element={EmployerBranch} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Employer.Level}
          element={<RouteWithHR Element={EmployerLevel} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Employer.Bank}
          element={<RouteWithHR Element={EmployerBank} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Employer.Course}
          element={<RouteWithHR Element={EmployerCourse} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Employer.Trainer}
          element={<RouteWithHR Element={EmployerTrainer} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Employer.Ethnicity}
          element={<RouteWithHR Element={EmployerEnthicity} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Employer.Religion}
          element={<RouteWithHR Element={EmployerReligion} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Employer.DocumentCategory}
          element={<RouteWithHR Element={EmployerDocs} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Employer.ApprovalWorkflow}
          element={<RouteWithHR Element={EmployerApprovalWorkflow} />}
        ></Route>
        //PayRoll
        <Route
          exact
          path={ROUTES.HR.PayRoll.EditStaffAttendance}
          element={<RouteWithHR Element={EditStaffAttendance} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.PayRoll.EditLateInEarlyOut}
          element={<RouteWithHR Element={EditLateInEarlyOut} />}
        ></Route>
        //BasicSalaryReport
        <Route
          exact
          path={ROUTES.HR.PayRoll.BasicSalary}
          element={<RouteWithHR Element={BasicSalaryReport} />}
        ></Route>
        //EmployeeShift
        <Route
          exact
          path={ROUTES.HR.Leave.EmployeeShift}
          element={<RouteWithHR Element={EmployeeShift} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.PayRoll.EmployerSalary}
          element={<RouteWithHR Element={SalaryAdjustment} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.PayRoll.Earning}
          element={<RouteWithHR Element={PayRollEarning} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.PayRoll.PaySlipPrint}
          element={<RouteWithHR Element={PaySlipPrint} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.PayRoll.PayScale}
          element={<RouteWithHR Element={PayRollPayScale} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.PayRoll.Bonus}
          element={<RouteWithHR Element={PayRollBonus} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.PayRoll.Deduction}
          element={<RouteWithHR Element={PayRollDeduction} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.PayRoll.StationaryContribution}
          element={<RouteWithHR Element={PayRollStationaryContribution} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.PayRoll.Process}
          element={<RouteWithHR Element={PayRollProcess} />}
        ></Route>
        //Interview
        <Route
          exact
          path={ROUTES.HR.Interview.JobOpenings}
          element={<RouteWithHR Element={JobOpenings} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Interview.ReviewJobApplications}
          element={<RouteWithHR Element={ReviewJobApplications} />}
        ></Route>
        <Route
          exact
          path={`${ROUTES.HR.Interview.JobApplicationProfile}/:id`}
          element={<RouteWithHR Element={JobApplicationProfile} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Interview.JobApplication}
          element={<RouteWithHR Element={JobApplications} />}
        ></Route>
        <Route
          exact
          path={"/interview/jobApplications"}
          element={<RouteWithHR Element={JobApplications} />}
        ></Route>
        <Route
          exact
          path={`${ROUTES.HR.Employee.AddStaff}/:id`}
          element={<RouteWithHR Element={AddNewStaff} />}
        ></Route>
        <Route
          exact
          path={ROUTES.JobApplication}
          element={<RouteWithOpen Element={JobApplication} />}
        ></Route>
        //Leave
        <Route
          exact
          path={ROUTES.HR.Leave.Management}
          element={<RouteWithHR Element={LeaveManagement} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Leave.Planner}
          element={<RouteWithHR Element={Planner} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Leave.Schedule}
          element={<RouteWithHR Element={Schedule} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Leave.Review}
          element={<RouteWithHR Element={LeaveReview} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Leave.TransactionReport}
          element={<RouteWithHR Element={LeaveTransactionReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Leave.EntitlementReport}
          element={<RouteWithHR Element={LeaveEntitlementReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Leave.Reports.Entitlement}
          element={<RouteWithHR Element={EntitlementDetaildReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Leave.Reports.Leave}
          element={<RouteWithHR Element={LeaveDetailedReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Leave.LeaveType}
          element={<RouteWithHR Element={LeaveLeaveType} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Leave.EarningPolicy}
          element={<RouteWithHR Element={EarningPolicy} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Leave.ApprovalWorkflow}
          element={<RouteWithHR Element={AppovalWorkflow} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Leave.CustomApprover}
          element={<RouteWithHR Element={LeaveCustomApprover} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Leave.Workday}
          element={<RouteWithHR Element={LeaveWorkDay} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Leave.Holiday}
          element={<RouteWithHR Element={LeaveHoliday} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Leave.Setting}
          element={<RouteWithHR Element={LeaveSetting} />}
        ></Route>
        //Attendance
        <Route
          exact
          path={ROUTES.HR.Attendance.Management}
          element={<RouteWithHR Element={AttendanceManagement} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Attendance.FieldCheckIn}
          element={<RouteWithHR Element={AttendanceFieldCheckIn} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Attendance.TimeClockReport}
          element={<RouteWithHR Element={AttendanceTimeClockReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Attendance.AttendanceMonthEmployee}
          element={<RouteWithHR Element={AttendanceMonthEmployee} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Attendance.TodayAttendance}
          element={<RouteWithHR Element={ReportStaffTodayAttendance} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Attendance.TimeClockReport}
          element={<RouteWithHR Element={AttendanceTimeClockReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Attendance.HoliDay}
          element={<RouteWithHR Element={LeaveHoliday} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Attendance.MonthlyReport}
          element={<RouteWithHR Element={AttendanceMonthlyReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Attendance.TimeReport}
          element={<RouteWithHR Element={AttendanceTimeReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Attendance.LateinReport}
          element={<RouteWithHR Element={AttendanceMonthlyLateinReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Attendance.WorkDay}
          element={<RouteWithHR Element={LeaveWorkDay} />}
        ></Route>
        //Team
        <Route
          exact
          path={ROUTES.HR.Team.Discussion}
          element={<RouteWithHR Element={TeamDiscussion} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Team.Announcement}
          element={<RouteWithHR Element={TeamAnnouncement} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Team.documents}
          element={<RouteWithHR Element={TeamDocumentandForm} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Team.Chat}
          element={<RouteWithHR Element={TeamChat} />}
        ></Route>
        //Document Workflow
        <Route
          exact
          path={ROUTES.HR.Document.Management}
          element={<RouteWithHR Element={DocManagement} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Document.Review}
          element={<RouteWithHR Element={DocReview} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Document.ApprovalWorkFlow}
          element={<RouteWithHR Element={DocApprovalWorkflow} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Document.CustomApprover}
          element={<RouteWithHR Element={DocCustomApprover} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Declaration_Forms.DeclarationForm1}
          element={<RouteWithHR Element={DeclarationForm1} />}
        ></Route>
        //Feedback Trigger
        <Route
          exact
          path={ROUTES.HR.Feedback.FeedbackTrigger}
          element={<RouteWithHR Element={FeedbackTrigger} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HR.Feedback.AddFeedbackQuestions}
          element={<RouteWithHR Element={AddFeedbackQuestions} />}
        ></Route>
        {/* <Route
          exact
          path={ROUTES.HR.Event.EventCustomApproves}
          element={<RouteWithHR Element={EventCustomApprove} />}
        ></Route>

        <Route
          exact
          path={ROUTES.HR.Event.EventWorkFlow}
          element={<RouteWithHR Element={EventWorkFlow} />}
        ></Route> */}
        //////////////////////////////////////////
        //////////////////////////////////////////
        ////////////////////////////////////////
        //////////////Employee/////////////// ///////////////////////////////
        ////////////////////////////////
        <Route
          exact
          path={ROUTES.Employee.Home}
          element={<RouteWithEmployee Element={DashboardEmployee} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.StaffDairy.StaffDairy}
          element={<RouteWithEmployee Element={StaffDairy} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.NAAC.index}
          element={<RouteWithEmployee Element={NAAC} />}
        >
          <Route
            path={ROUTES.Employee.NAAC.dashboard.activityTracking}
            element={<ActivityTracking />}
          />
          <Route
            path={ROUTES.Employee.NAAC.dashboard.performanceTracking}
            element={<PerformanceTracking />}
          />

          <Route
            path={ROUTES.Employee.NAAC.dashboard.statusReport}
            element={<StatusReport />}
          />
          <Route
            path={ROUTES.Employee.NAAC.naacData.executiveSummary}
            element={<ExecutiveSummary />}
          />
          <Route
            path={`${ROUTES.Employee.NAAC.naacData.profile}/*`}
            element={<ProfileRoutes />}
          />
          <Route
            path={`${ROUTES.Employee.NAAC.naacData.nepParameters}/*`}
            element={<NepParametersRoutes />}
          />
          <Route
            path={`${ROUTES.Employee.NAAC.naacData.extendedProfile}/*`}
            element={<ExtendedProfileRoutes />}
          />
          <Route
            path={ROUTES.Employee.NAAC.naacData.myDownloads}
            element={<MyDownloads />}
          />

          <Route
            path={ROUTES.Employee.NAAC.naacData.qifQualitative}
            element={<QifQualitative />}
          />
          <Route
            path={ROUTES.Employee.NAAC.naacData.qifQuantative}
            element={<QifQuantitative />}
          />
        </Route>
        <Route
          exact
          path={ROUTES.Employee.StaffDairy.ViewStaffDairy}
          element={<RouteWithEmployee Element={ViewStaffDairy} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.PayRoll.PaySlipPrint}
          element={<RouteWithEmployee Element={PaySlipPrint} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Reports.StudentStat}
          element={<RouteWithEmployee Element={ReportOneStudentStat} />}
        ></Route>
        <Route
          exact
          path={
            ROUTES.Employee.Reports.StudentSubStat + "/:student_id/:course_id"
          }
          element={<RouteWithEmployee Element={ReportOneStudentSubject} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Reports.Home}
          element={<RouteWithEmployee Element={DeanReports} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Reports.StudentDetails1}
          element={<RouteWithEmployee Element={StudentDetails1} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Reports.Attendance}
          element={<RouteWithEmployee Element={ReportStudentAttendance} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Reports.AttendanceSemWise}
          element={
            <RouteWithEmployee Element={ReportStudentAttendanceSemWise} />
          }
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Reports.MonthWise}
          element={<RouteWithEmployee Element={MonthWiseAttendance} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Academics.ViewPracStudents}
          element={<RouteWithEmployee Element={ViewAssignedStudents} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Student.AssignStudents}
          element={<RouteWithEmployee Element={AssignStudents} />}
        ></Route>
        //Staff Dairy in Employee
        <Route
          exact
          path={ROUTES.Employee.StaffDairy.StaffDairy}
          element={<RouteWithEmployee Element={StaffDairy} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.StaffDairy.ViewStaffDairy}
          element={<RouteWithEmployee Element={ViewStaffDairy} />}
        ></Route>
        //Tickets
        <Route
          exact
          path={ROUTES.Employee.Ticket}
          element={<RouteWithEmployee Element={Tickets} />}
        ></Route>
        //TicketConversation
        <Route
          exact
          path={`${ROUTES.Employee.ticketConversation}/:id`}
          element={<RouteWithEmployee Element={TicketConversation} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Profile}
          element={<RouteWithEmployee Element={EmployeeProfile} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Leave.Application}
          element={<RouteWithEmployee Element={EmployeeLeaveApplication} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Leave.Entitlement}
          element={<RouteWithEmployee Element={EmployeeLeaveEntitlement} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Leave.Schedule}
          element={<RouteWithEmployee Element={EmployeeSchedule} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Attendance}
          element={<RouteWithEmployee Element={EmployerAttendance} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Document}
          element={<RouteWithEmployee Element={EmployeeDocumentManagement} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Team.Announcement}
          element={<RouteWithEmployee Element={EmployeeTeamAnnouncement} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Team.Discussion}
          element={<RouteWithEmployee Element={EmployeeTeamDiscussion} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Team.Documents}
          element={<RouteWithEmployee Element={EmployeeTeamDocument} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Team.Chat}
          element={<RouteWithEmployee Element={TeamChat} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.PayRoll.AnnualStatement}
          element={<RouteWithEmployee Element={EmployeeAnnualStatement} />}
        ></Route>
        {/* <Route exact path={ROUTES.Employee.PayRoll.Payslip} element={<RouteWithEmployee Element={Employeepayslip} />}></Route> */}
        <Route
          exact
          path={ROUTES.Employee.PayRoll.Salary}
          element={<RouteWithEmployee Element={EmployerSalary} />}
        ></Route>
        //Academics
        <Route
          exact
          path={ROUTES.Employee.Academics.ClassTimeTable}
          element={<RouteWithEmployee Element={ClassTimeTable} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Academics.TeacherTimeTable}
          element={<RouteWithEmployee Element={TeachersTimetable} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Academics.PromoteStudents}
          element={<RouteWithEmployee Element={PromoteStudents} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Academics.ChangeBranch}
          element={<RouteWithEmployee Element={ChangeBranch} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Academics.AddSubject}
          element={<RouteWithEmployee Element={AddSubjects} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Academics.AddClass}
          element={<RouteWithEmployee Element={AddClass} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Academics.ManageLessonPlan}
          element={<RouteWithEmployee Element={ManageLessonPlan} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Academics.SyllabusStatus}
          element={<RouteWithEmployee Element={SyllabusStatus} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Academics.AddTopic}
          element={<RouteWithEmployee Element={AddTopics} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Academics.CourseRegistration}
          element={<RouteWithEmployee Element={CourseRegistration} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Academics.AddBloomsLevel}
          element={<RouteWithEmployee Element={AddBloomsLevel} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Academics.CourseOutcomes + "/:id"}
          element={<RouteWithEmployee Element={CourseOutcomes} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Academics.ProgramOutcome}
          element={<RouteWithRegistar Element={ProgramOutcomes} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Academics.AddLesson}
          element={<RouteWithEmployee Element={AddLessons} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Academics.AddSemester}
          element={<RouteWithEmployee Element={AddSemester} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Academics.AddQuestionBank}
          element={<RouteWithEmployee Element={AddQuestionBank} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.BasicSalaryReport}
          element={<RouteWithRegistar Element={BasicSalaryReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Registar.Reports.BasicSalaryReport1}
          element={<RouteWithRegistar Element={BasicSalaryReport1} />}
        ></Route>
        //Review if Approver
        <Route
          exact
          path={ROUTES.Employee.Review}
          element={<RouteWithEmployee Element={EmployeeReview} />}
        ></Route>
        //Attendance
        <Route
          exact
          path={ROUTES.Employee.StudentAttendance.ClassAttendance}
          element={<RouteWithEmployee Element={ClassAttendance} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.Event.AddEvent}
          element={<RouteWithEmployee Element={AddEvent} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.StudentAttendance.LabAttendance}
          element={<RouteWithEmployee Element={LabAttendance} />}
        ></Route>
        //Student Details
        <Route
          exact
          path={ROUTES.Employee.Student.AdmissionDetails}
          element={<RouteWithEmployee Element={StudentDetails} />}
        ></Route>
        //proctoring
        <Route
          exact
          path={ROUTES.Employee.ProcteeList}
          element={<RouteWithEmployee Element={ProcteeList} />}
        ></Route>
        <Route
          exact
          path={
            ROUTES.Employee.CreateProctoringMeeting + "/:proctoring_details_id"
          }
          element={<RouteWithEmployee Element={CreateProctoringMeeting} />}
        ></Route>
        <Route
          exact
          path={
            ROUTES.Employee.ViewProctoringMeetingDetails +
            "/:proctoring_details_id"
          }
          element={<RouteWithEmployee Element={ViewMeetingDetails} />}
        ></Route>
        <Route
          exact
          path={
            ROUTES.Employee.NextProctoringMeetingDetails +
            "/:proctoring_details_id"
          }
          element={<RouteWithEmployee Element={NextMeetingDetails} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.StudentGatePass}
          element={<RouteWithEmployee Element={GatePass} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Employee.ViewGatePass}
          element={<RouteWithEmployee Element={ViewStudentGatePass} />}
        ></Route>
        /////////////////////////////////////////////////////
        /////////////////////////////////////////////////////
        /////////////////////////////////////////////////////
        ////////////////Examination//////////////////////////
        /////////////////////////////////////////////////////
        /////////////////////////////////////////////////////
        /////////////////////////////////////////////////////
        <Route
          exact
          path="/examinationportal"
          element={<RouteWithExam Element={DashboardHR} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.Commitee}
          element={<RouteWithExam Element={Commitee} />}
        ></Route>
        {/* <Route exact path={ROUTES.Examination.CreateExams} element={<RouteWithExam Element={CreateExams} />}></Route> */}
        <Route
          exact
          path={ROUTES.Examination.AssignStaff}
          element={<RouteWithExam Element={AssignStaff} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.CreateQuestionPaper}
          element={<RouteWithExam Element={CreateQuestionPaper} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.CreateExamTimetable}
          element={<RouteWithExam Element={CreateExamTimetable} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.ExamSchedules}
          element={<RouteWithExam Element={ExamSchedule} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.ExamSeatingArrangements}
          element={<RouteWithExam Element={ExamSeatingArrangements} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.PostExaminations}
          element={<RouteWithExam Element={PostExamination} />}
        ></Route>
        {/* <Route exact path={ROUTES.Examination.OtpVerification} element={<RouteWithExam Element={OtpVerification}/>}></Route> */}
        <Route
          exact
          path={ROUTES.Examination.DownloadQuestionPaper}
          element={<RouteWithExam Element={DownloadQuestionPaper} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.AssignAnswerSheets}
          element={<RouteWithExam Element={AssignAnswersheets} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.ExamEnrollment}
          element={<RouteWithExam Element={ExamEnrollment} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.StudentApplicationForm}
          element={<RouteWithExam Element={StudentApplicationForm} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.DownloadHallTickets}
          element={<RouteWithExam Element={DownloadHallTicket} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.AssignCommitteeMembers}
          element={<RouteWithExam Element={AssignCommitteeStaff} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.AddInvigilators}
          element={<RouteWithExam Element={AddInvigilators} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.AddEvaluators}
          element={<RouteWithExam Element={AddEvaluators} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.Hallticket}
          element={<RouteWithExam Element={HallTickets} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.AttendanceList}
          element={<RouteWithExam Element={AttendanceList} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.DownloadAttendanceList}
          element={<RouteWithExam Element={DownloadAttendanceList} />}
        ></Route>
        ///////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////
        ////////////////////////Accounts//////////////////////////
        //////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////
        <Route
          exact
          path={ROUTES.Accountant.StudentDetails}
          element={<RouteWithAccounts Element={StudentDetails} />}
        ></Route>
        <Route
          exact
          path={`${ROUTES.Accountant.StudentProfile}/:id`}
          element={<RouteWithAccounts Element={StudentProfile} />}
        ></Route>
        <Route
          exact
          path={`${ROUTES.Accountant.ViewStudentProfile}/:id`}
          element={<RouteWithAccounts Element={ViewStudentProfile} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.TransportDateWise}
          element={<RouteWithAccounts Element={DateWiseTransportFees} />}
        ></Route>
        //Tickets
        <Route
          exact
          path={ROUTES.Accountant.Ticket}
          element={<RouteWithAccounts Element={Tickets} />}
        ></Route>
        //TicketConversation
        <Route
          exact
          path={`${ROUTES.Accountant.ticketConversation}/:id`}
          element={<RouteWithAccounts Element={TicketConversation} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.collegewiseCollection}
          element={<RouteWithAccounts Element={CollegeWiseCollectionReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.hostelfeeCollection}
          element={<RouteWithAccounts Element={hostelFeeCollectionReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.hostelfeeFacultyWise}
          element={<RouteWithAccounts Element={FacultyWiseHostelReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.departmentwiseCollection}
          element={
            <RouteWithAccounts Element={DepartmentWiseCollectionReport} />
          }
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.programwiseCollection}
          element={<RouteWithAccounts Element={ProgramWiseCollectionReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.Dashboard}
          element={<RouteWithAccounts Element={DashboardAccountant} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.CollectFee}
          element={<RouteWithAccounts Element={FeeCollectionCollectFee} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.SearchDue}
          element={<RouteWithAccounts Element={FeeCollectionSearchDue} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.FeeMaster}
          element={<RouteWithAccounts Element={FeeCollectionFeeMaster} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.AssignFee}
          element={<RouteWithAccounts Element={FeeCollectionAssignFee} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.AddFee + "/:id"}
          element={<RouteWithAccounts Element={FeeCollectionAddFee} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.FeeType}
          element={<RouteWithAccounts Element={FeeCollectionFeeType} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.FeeGroup}
          element={<RouteWithAccounts Element={FeeCollectionFeeGroup} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.FeeDiscount}
          element={<RouteWithAccounts Element={FeeCollectionFeeDiscount} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.FeeTypeAmount}
          element={<RouteWithAccounts Element={FeeCollectionFeeTypeAmount} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.Reports}
          element={<RouteWithAccounts Element={ReportsFeeHome} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.report1}
          element={<RouteWithAccounts Element={FeeCollectionReports} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.report2}
          element={<RouteWithAccounts Element={ReportsBha1} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.report3}
          element={<RouteWithAccounts Element={ReportsBha4} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.report4}
          element={<RouteWithAccounts Element={ReportsPendingClasswise} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.onlineTransaction}
          element={<RouteWithAccounts Element={OnlineTransactionReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.PendingCollegewise}
          element={<RouteWithAccounts Element={ReportFeePendingCollege} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.CancelledReceipts}
          element={<RouteWithAccounts Element={CancelledReceipts} />}
        ></Route>
        //Transport
        <Route
          exact
          path={ROUTES.Accountant.Transport.Route}
          element={<RouteWithAccounts Element={TransportRoute} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.Transport.Vehicles}
          element={<RouteWithAccounts Element={TransportVehicle} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.Transport.AssignVehicles}
          element={<RouteWithAccounts Element={TransportAssignVehicles} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.Transport.transportFee}
          element={<RouteWithAccounts Element={transportFee} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.Transport.AssignPickupPoints}
          element={<RouteWithAccounts Element={TransportAssignPickupPoints} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.Transport.pickupPoints}
          element={<RouteWithAccounts Element={PickupPoints} />}
        ></Route>
        <Route
          exact
          path={`${ROUTES.Accountant.Transport.addtransportFee}/:id`}
          element={<RouteWithAccounts Element={AddTransportFee} />}
        ></Route>
        //Hostel
        <Route
          exact
          path={ROUTES.Accountant.Hostel.HostelRooms}
          element={<RouteWithAccounts Element={HostelRooms} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.Hostel.Hostel}
          element={<RouteWithAccounts Element={Hostel} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.Hostel.RoomType}
          element={<RouteWithAccounts Element={RoomType} />}
        ></Route>
        <Route
          exact
          path={`${ROUTES.Accountant.Hostel.addhostelFee}/:id`}
          element={<RouteWithAccounts Element={AddHostelFee} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.Hostel.hostelFee}
          element={<RouteWithAccounts Element={hostelFees} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.Hostel.HostelFloors}
          element={<RouteWithAccounts Element={HostelFloors} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Accountant.ScholarshipReport}
          element={<RouteWithAccounts Element={ScholarshipReport} />}
        ></Route>
        //Fee Summary Report
        <Route
          exact
          path={ROUTES.Accountant.FeeSummaryReport}
          element={<RouteWithAccounts Element={SummaryFeeReport} />}
        ></Route>
        //Cashier
        <Route
          exact
          path={`${ROUTES.Cashier.addhostelFee}/:id`}
          element={<RouteWithCashier Element={AddHostelFee} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Cashier.AddFee + "/:id"}
          element={<RouteWithCashier Element={FeeCollectionAddFee} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Cashier.hostelFee}
          element={<RouteWithCashier Element={hostelFees} />}
        ></Route>
        <Route
          exact
          path={`${ROUTES.Cashier.addtransportFee}/:id`}
          element={<RouteWithCashier Element={AddTransportFee} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Cashier.transportFee}
          element={<RouteWithCashier Element={transportFee} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Cashier.Reports}
          element={<RouteWithCashier Element={ReportsFeeHome} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Cashier.report1}
          element={<RouteWithCashier Element={FeeCollectionReports} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Cashier.report2}
          element={<RouteWithCashier Element={ReportsBha1} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Cashier.report3}
          element={<RouteWithCashier Element={ReportsBha4} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Cashier.report4}
          element={<RouteWithCashier Element={ReportsPendingClasswise} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Cashier.PendingCollegewise}
          element={<RouteWithCashier Element={ReportFeePendingCollege} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Cashier.collegewiseCollection}
          element={<RouteWithCashier Element={CollegeWiseCollectionReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Cashier.hostelfeeCollection}
          element={<RouteWithCashier Element={hostelFeeCollectionReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Cashier.hostelfeeFacultyWise}
          element={<RouteWithCashier Element={FacultyWiseHostelReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Cashier.departmentwiseCollection}
          element={
            <RouteWithCashier Element={DepartmentWiseCollectionReport} />
          }
        ></Route>
        <Route
          exact
          path={ROUTES.Cashier.programwiseCollection}
          element={<RouteWithCashier Element={ProgramWiseCollectionReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Cashier.Dashboard}
          element={<RouteWithCashier Element={DashboardAccountant} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Cashier.CollectFee}
          element={<RouteWithCashier Element={FeeCollectionCollectFee} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Cashier.SearchDue}
          element={<RouteWithCashier Element={FeeCollectionSearchDue} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Cashier.StudentDetails}
          element={<RouteWithCashier Element={StudentDetails} />}
        ></Route>
        <Route
          exact
          path={`${ROUTES.Cashier.StudentProfile}/:id`}
          element={<RouteWithCashier Element={StudentProfile} />}
        ></Route>
        <Route
          exact
          path={`${ROUTES.Cashier.ViewStudentProfile}/:id`}
          element={<RouteWithCashier Element={ViewStudentProfile} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Cashier.TransportDateWise}
          element={<RouteWithCashier Element={DateWiseTransportFees} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Cashier.ScholarshipReport}
          element={<RouteWithCashier Element={ScholarshipReport} />}
        ></Route>
        {/* <Route exact path={ROUTES.Examination.OtpVerification} element={<RouteWithExam Element={OtpVerification} />}></Route> */}
        ////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////
        ////////////////////////// Receptionist ////////////////////////////
        ////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////
        <Route
          exact
          path={ROUTES.Reception.AdmissionEnquiry}
          element={<RouteWithReceptionist Element={AdmissionEnquiry} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Reception.VisitorsBook}
          element={<RouteWithReceptionist Element={VisitorsBook} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Reception.PhoneCallLog}
          element={<RouteWithReceptionist Element={PhoneCallLog} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Reception.PostalDispatch}
          element={<RouteWithReceptionist Element={PostalDispatch} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Reception.PostalRecieve}
          element={<RouteWithReceptionist Element={PostalRecieve} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Reception.Complain}
          element={<RouteWithReceptionist Element={Complain} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Reception.SetupOffice}
          element={<RouteWithReceptionist Element={SetupOffice} />}
        ></Route>
        ////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////
        ////////////////////////// AdmissionConsultant
        ////////////////////////////
        ////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////
        <Route
          exact
          path={ROUTES.AdConsult.AdmissionEnquiry}
          element={<RouteWithAdmissionConsultant Element={AdmissionEnquiry} />}
        ></Route>
        {/* <Route
          exact
          path={ROUTES.AdConsult.CollectFee}
          element={
            <RouteWithAdmissionConsultant Element={FeeCollectionCollectFee} />
          }
        ></Route> */}
        {/* <Route
          exact
          path={ROUTES.AdConsult.SearchDue}
          element={
            <RouteWithAdmissionConsultant Element={FeeCollectionSearchDue} />
          }
        ></Route> */}
        <Route
          exact
          path={ROUTES.AdConsult.DashboardAC}
          element={<RouteWithAdmissionConsultant Element={DashboardAd_Co} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdConsult.EnuiryModal}
          element={
            <RouteWithAdmissionConsultant Element={ModalAdmissionEnquirry} />
          }
        ></Route>
        <Route
          exact
          path={ROUTES.AdConsult.Reports.StudentAdmissionReport}
          element={
            <RouteWithAdmissionConsultant Element={StudentAdmissionReport} />
          }
        ></Route>
        <Route
          exact
          path={ROUTES.AdConsult.Reports.BHA1}
          element={<RouteWithAdmissionConsultant Element={ReportsBha1} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdConsult.Reports.departmentwiseCllection}
          element={
            <RouteWithAdmissionConsultant
              Element={DepartmentWiseCollectionReport}
            />
          }
        ></Route>
        <Route
          exact
          path={ROUTES.AdConsult.StudentDetails}
          element={<RouteWithAdmissionConsultant Element={StudentDetails} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdConsult.Admission}
          element={
            <RouteWithAdmissionConsultant Element={AdmissionNewAdmission} />
          }
        ></Route>
        <Route
          exact
          path={ROUTES.AdConsult.VisitorsBook}
          element={<RouteWithAdmissionConsultant Element={VisitorsBook} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdConsult.PhoneCallLog}
          element={<RouteWithAdmissionConsultant Element={PhoneCallLog} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdConsult.PostalDispatch}
          element={<RouteWithAdmissionConsultant Element={PostalDispatch} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdConsult.PostalRecieve}
          element={<RouteWithAdmissionConsultant Element={PostalRecieve} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdConsult.Complain}
          element={<RouteWithAdmissionConsultant Element={Complain} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdConsult.SetupOffice}
          element={<RouteWithAdmissionConsultant Element={SetupOffice} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdConsult.OnlineEnquiry}
          element={
            <RouteWithAdmissionConsultant Element={AdmssionStudentEnquiry} />
          }
        ></Route>
        <Route
          exact
          path={ROUTES.AdConsult.HR.Document}
          element={
            <RouteWithAdmissionConsultant
              Element={EmployeeDocumentManagement}
            />
          }
        ></Route>
        <Route
          exact
          path={ROUTES.AdConsult.HR.Leave.Application}
          element={
            <RouteWithAdmissionConsultant Element={EmployeeLeaveApplication} />
          }
        ></Route>
        <Route
          exact
          path={ROUTES.AdConsult.HR.Leave.Entitlement}
          element={
            <RouteWithAdmissionConsultant Element={EmployeeLeaveEntitlement} />
          }
        ></Route>
        <Route
          exact
          path={ROUTES.AdConsult.HR.Leave.Schedule}
          element={<RouteWithAdmissionConsultant Element={EmployeeSchedule} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdConsult.HR.PayRoll.AnnualStatement}
          element={
            <RouteWithAdmissionConsultant Element={EmployeeAnnualStatement} />
          }
        ></Route>
        {/* <Route exact path={ROUTES.Employee.PayRoll.Payslip} element={<RouteWithAdmissionConsultant Element={Employeepayslip} />}></Route> */}
        <Route
          exact
          path={ROUTES.AdConsult.HR.PayRoll.Salary}
          element={<RouteWithAdmissionConsultant Element={EmployerSalary} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdConsult.HR.Review}
          element={<RouteWithAdmissionConsultant Element={EmployeeReview} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdConsult.HR.Team.Announcement}
          element={
            <RouteWithAdmissionConsultant Element={EmployeeTeamAnnouncement} />
          }
        ></Route>
        <Route
          exact
          path={ROUTES.AdConsult.HR.Team.Discussion}
          element={
            <RouteWithAdmissionConsultant Element={EmployeeTeamDiscussion} />
          }
        ></Route>
        <Route
          exact
          path={ROUTES.AdConsult.HR.Team.Documents}
          element={
            <RouteWithAdmissionConsultant Element={EmployeeTeamDocument} />
          }
        ></Route>
        <Route
          exact
          path={ROUTES.AdConsult.HR.Team.Chat}
          element={<RouteWithAdmissionConsultant Element={TeamChat} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdConsult.HrNavigator}
          element={
            <RouteWithAdmissionConsultant Element={AdmissionConsultanttoHR} />
          }
        ></Route>
        /////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////
        //////////////////////////Library////////////////////////////
        /////////////////////////////////////////////////////////////
        <Route
          exact
          path={ROUTES.Library.Books}
          element={<RouteWithLibrary Element={Books} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Library.SearchBook}
          element={<RouteWithLibrary Element={SearchBook} />}
        ></Route>
        //Library HR
        <Route
          exact
          path={ROUTES.Library.leaveEntitlement}
          element={<RouteWithLibrary Element={EmployeeLeaveEntitlement} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Library.leaveApplication}
          element={<RouteWithLibrary Element={EmployeeLeaveApplication} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Library.leaveSchedule}
          element={<RouteWithLibrary Element={EmployeeSchedule} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Library.PayRoll}
          element={<RouteWithLibrary Element={EmployerSalary} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Library.AnnualStatement}
          element={<RouteWithLibrary Element={EmployeeAnnualStatement} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Library.documentManagement}
          element={<RouteWithLibrary Element={EmployeeDocumentManagement} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Library.teamDiscussion}
          element={<RouteWithLibrary Element={EmployeeTeamDiscussion} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Library.teamDocuments}
          element={<RouteWithLibrary Element={EmployeeTeamDocument} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Library.teamAnnouncement}
          element={<RouteWithLibrary Element={EmployeeTeamAnnouncement} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Library.Library_Settings}
          element={<RouteWithLibrary Element={LibrarySettings} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Library.Borrow}
          element={<RouteWithLibrary Element={BorrowBook} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Library.Reports}
          element={<RouteWithLibrary Element={LibraryReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Library.Dashboard}
          element={<RouteWithLibrary Element={LibraryDashboard} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Library.Ebook}
          element={<RouteWithLibrary Element={LibraryEbook} />}
        ></Route>
        /////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////
        /////////////////////UMS SETTINGS////////////////////////
        /////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////
        <Route
          exact
          path={ROUTES.UmsSettings}
          element={<RouteWithRegistar Element={UmsSettings} />}
        ></Route>
        //NON TEACHING STAFF
        <Route
          exact
          path={ROUTES.NonTeachingStaff.Home}
          element={<RouteWithNTSTAFF Element={DashboardEmployee} />}
        ></Route>
        <Route
          exact
          path={ROUTES.NonTeachingStaff.Application}
          element={<RouteWithNTSTAFF Element={EmployeeLeaveApplication} />}
        ></Route>
        <Route
          exact
          path={ROUTES.NonTeachingStaff.Entitlement}
          element={<RouteWithNTSTAFF Element={EmployeeLeaveEntitlement} />}
        ></Route>
        <Route
          exact
          path={ROUTES.NonTeachingStaff.Schedule}
          element={<RouteWithNTSTAFF Element={EmployeeSchedule} />}
        ></Route>
        <Route
          exact
          path={ROUTES.NonTeachingStaff.Ticket}
          element={<RouteWithNTSTAFF Element={Tickets} />}
        ></Route>
        /////////////////////////////////////////////////////////
        ///////////////////// PROCTORING ////////////////////////
        /////////////////////////////////////////////////////////
        <Route
          exact
          path={ROUTES.Proctoring.Dashboard}
          element={<RouteWithRegistar Element={ProctorDashboard} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Proctoring.AssignProcteeToProctor}
          element={<RouteWithRegistar Element={AssignProctee} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Proctoring.MyProcteeList}
          element={<RouteWithRegistar Element={MyProcteeList} />}
        ></Route>
        <Route
          exact
          path={
            ROUTES.Proctoring.CreateProctoringMeeting +
            "/:proctoring_details_id"
          }
          element={<RouteWithRegistar Element={CreateProctoringMeeting} />}
        ></Route>
        <Route
          exact
          path={
            ROUTES.Proctoring.NextProctoringMeetingDetails +
            "/:proctoring_details_id"
          }
          element={<RouteWithRegistar Element={NextMeetingDetails} />}
        ></Route>
        //////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////
        ////////////////StudentPortal////////////////////////////////
        /////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////
        <Route exact path="/student-portal/login" element={<LoginStudent />} />
        <Route exact path="/redirect/:token" element={<Redirct />} />
        <Route
          exact
          path={ROUTES.StudentPortal.Home}
          element={<RouteWithStudent Element={HomeStudent} />}
        />
        //Change Password
        <Route exact path="/forgot-password/:id" element={<ForgotPassword />} />
        <Route
          exact
          path={ROUTES.StudentPortal.Profile}
          element={<RouteWithStudent Element={Profile} />}
        />
        <Route
          exact
          path={ROUTES.StudentPortal.Subjects}
          element={<RouteWithStudent Element={Subjects} />}
        />
        {/* <Route 
          exact 
          path={ROUTES.StudentPortal.Lessons+"/:course_id"} 
          element={<RouteWithStudent Element={Lessons} />} 
        /> */}
        <Route
          exact
          path={ROUTES.StudentPortal.Lessons + "/:course_id/:semester_id"}
          element={<RouteWithStudent Element={Lessons} />}
        />
        <Route
          exact
          path={ROUTES.StudentPortal.Topics + "/:lesson_id"}
          element={<RouteWithStudent Element={Topics} />}
        />
        <Route
          exact
          path={ROUTES.StudentPortal.Attendance}
          element={<RouteWithStudent Element={Attendance} />}
        />
        <Route
          exact
          path={ROUTES.StudentPortal.StudentAttendance + "/:course_id"}
          element={<RouteWithStudent Element={AttendanceCourse} />}
        />
        <Route
          exact
          path={ROUTES.StudentPortal.CourseRegistrationByStudent}
          element={<RouteWithStudent Element={CourseRegistrationStudent} />}
        />
        <Route
          exact
          path={ROUTES.StudentPortal.ClinicalStudies}
          element={<RouteWithStudent Element={ClinicalStudies} />}
        />
        <Route
          exact
          path={ROUTES.StudentPortal.Documents}
          element={<RouteWithStudent Element={StudentDocuments} />}
        />
        <Route
          exact
          path={ROUTES.StudentPortal.StudentGatePass}
          element={<RouteWithStudent Element={GatePassStudent} />}
        />
        <Route
          exact
          path={ROUTES.StudentPortal.ViewGatePass}
          element={<RouteWithStudent Element={ViewGatePass} />}
        />
        //////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////
        ////////////////Admission-Portal////////////////////////////////
        /////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////
        <Route
          exact
          path="/admission-portal"
          element={<RouteWithBasic Element={LoginAdmission} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdmissionPortal.Dashboard}
          element={<RouteWithBasic Element={Dashboard} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdmissionPortal.Admission.basicHome}
          element={<RouteWithBasic Element={BasicHome} />}
        ></Route>
        <Route
          exact
          path="/apply"
          element={<RouteWithAdmission Element={Admission} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdmissionPortal.Admission.bed}
          element={<RouteWithAdmission Element={Bed} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdmissionPortal.Admission.bsc}
          element={<RouteWithAdmission Element={Bsc} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdmissionPortal.Admission.commerce}
          element={<RouteWithAdmission Element={Commerce} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdmissionPortal.Admission.law}
          element={<RouteWithAdmission Element={Law} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdmissionPortal.Admission.msc}
          element={<RouteWithAdmission Element={Msc} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdmissionPortal.Admission.vedicastro}
          element={<RouteWithAdmission Element={VedicAstro} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdmissionPortal.Admission.bscNursing}
          element={<RouteWithAdmission Element={BscNursing} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdmissionPortal.Admission.AdmissionPostBasicBscNur}
          element={<RouteWithAdmission Element={PBBscNursing} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdmissionPortal.Admission.AdmissionMscNursing}
          element={<RouteWithAdmission Element={MscNursing} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdmissionPortal.Admission.UGAdmissionPharmacy}
          element={<RouteWithAdmission Element={UGPharmacy} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdmissionPortal.Admission.PGAdmissionPharmacy}
          element={<RouteWithAdmission Element={PGPharmacy} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdmissionPortal.Admission.gnm}
          element={<RouteWithAdmission Element={GNM} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdmissionPortal.Admission.masterNursing}
          element={<RouteWithAdmission Element={Masters} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdmissionPortal.Admission.postBasicBsc}
          element={<RouteWithAdmission Element={PostBasicBsc} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdmissionPortal.Admission.basicInformations}
          element={<RouteWithAdmission Elemen t={BasicInformations} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdmissionPortal.Admission.admissionEnquiry}
          element={<AdmissionEnquirys />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdmissionPortal.Admission.admissionForm}
          element={<AdmissionForm />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdmissionPortal.Admission.profile}
          element={<AdmissionProfile />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdmissionPortal.Admission.homeopathy}
          element={<RouteWithAdmission Element={Homeopathy} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdmissionPortal.Admission.ayurveda}
          element={<RouteWithAdmission Element={Ayurveda} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdmissionPortal.Admission.ayurvedaPG}
          element={<RouteWithAdmission Element={AyurvedaPG} />}
        ></Route>
        <Route
          exact
          path={ROUTES.AdmissionPortal.Admission.ayurvedaMsc}
          element={<RouteWithAdmission Element={AyurvedaMsc} />}
        ></Route>
      </Routes>
    </div>
  );
}

export default Router;
