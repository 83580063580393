import React, { useEffect, useState } from "react";
import ModalApplicationApproval from "../../modals/NAAC/ModalApplicationApproval";
import { NAAC } from "../../utils/apiConstants";
import axios from "axios";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import { EditOutlined } from "@ant-design/icons";

const NAACApprovalFlow = ({ setLoading, collegeId }) => {
  const [role, setRole] = useState(
    sessionStorage.getItem("role") ? sessionStorage.getItem("role") : null
  );
  const [employeeData, setEmployeeData] = useState([]);
  const [data, setData] = useState([]);
  const [employeeOpt, setEmployeeOpt] = useState([]);
  const [edit, setEdit] = useState("");
  const [type, setType] = useState("");
  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${NAAC}/approval-flow`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config)
      .then((res) => {
        setData(res.data);
        console.log("get data", res.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || "Something went wrong");
      });
    setLoading(0);
  };
  const getEmployee = async () => {
    const config = {
      method: "get",
      url: `${NAAC}/get-all-employees`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config)
      .then((res) => {
        console.log("employees - ", res.data.data);
        setEmployeeData(res.data.data);
        let temp = [];
        let temp1 = [];
        let employee = res.data.data;

        employee?.map((i, key) => {
          let obj = {};
          let obj2 = {};
          obj["value"] = i?.id;
          obj2["value"] = i?.id;
          obj2["label"] =
            i?.first_name + " " + i?.last_name + " (" + i?.role + ")";
          obj["label"] =
            i?.first_name +
            " " +
            i?.last_name +
            " (" +
            i?.role +
            ")" +
            " (" +
            i?.college +
            ")" +
            " (" +
            i?.department +
            ")";
          temp.push(obj);
          temp1.push(obj2);
        });
        setEmployeeOpt(temp);
        setEmployeeData(temp1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEmployee();
    getData();
  }, []);
  let tableHead = ["SI. No", "Title", "Year", "Author", "Reviewer", "Actions"];

  return (
    <div className="main-content">
      <ModalApplicationApproval
        employeeOpt={employeeOpt}
        setLoading={setLoading}
        collegeId={collegeId}
        reloadData={getData}
        data={edit}
        type={type}
        flowData={data}
      />
      <div className="page-content">
        <div className="container-fluid">
          <h4 className=" mb-4"> NAAC Approval Flow</h4>
          <div className="card">
            <div className="card-body bg-nex">
              <div className="row d-flex justify-content-between  p-3">
                {role !== "ADMIN" ? (
                  <button
                    className="btn btn-rounded btn-success btn-outline px-4"
                    data-toggle="modal"
                    data-target="#ModalNAACApplicationApproval"
                    onClick={() => {
                      setEdit();
                      setType("add");
                    }}
                  >
                    Add +
                  </button>
                ) : null}
              </div>
              <div className="table-responsive text-center">
                <table className="table table-bordered">
                  <thead className="">
                    {tableHead.map((item) => (
                      <th key={item}>{item}</th>
                    ))}
                  </thead>
                  <tbody>
                    {data.map((item, i) => (
                      <tr className="" key={uuid()}>
                        <td className="align-content-center">{i + 1}</td>
                        <td className="align-content-center">{item?.title}</td>
                        <td className="align-content-center">{item?.year}</td>
                        <td className="align-content-center">
                          {item?.author?.map((record, i) => (
                            <span key={i}>
                              {
                                employeeData?.find((s) => s.value == record)
                                  ?.label
                              }
                              {i != item?.author.length - 1 &&
                              item?.author.length > 1
                                ? ", "
                                : ""}
                            </span>
                          ))}
                        </td>
                        <td className="align-content-center">
                          {item?.reviewer?.map((record, i) => (
                            <span>
                              {
                                employeeData?.find((s) => s.value == record)
                                  ?.label
                              }
                              {i != item?.reviewer.length - 1 &&
                              item?.reviewer.length > 1
                                ? ", "
                                : ""}
                            </span>
                          ))}
                        </td>
                        <td className="d-flex gap-2 align-items-center justify-content-center">
                          <button
                            className="btn btn-link"
                            data-toggle="modal"
                            data-target="#ModalNAACApplicationApproval"
                            onClick={() => {
                              setEdit(item);
                              setType("edit");
                            }}
                          >
                            <EditOutlined /> Edit
                          </button>
                          {/* <button
                              className="btn btn-link text-danger"
                            >
                              <DeleteOutlined /> Delete
                            </button> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NAACApprovalFlow;
