import React, { useEffect, useState } from "react";
import {
  INVENTORY_ADD_ITEM,
  INVENTORY_PURCHASE_ORDER,
} from "../../../utils/Inventory.apiConst";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
const tableHeadData = [
  "Items & Description",
  "Ordered",
  "Received",
  "In Transit",
  "Quantity to Receive	",
];
const PurchaseReceives = ({ setLoading, college_id }) => {
  const [data, setData] = useState([]);
  const [inventoryItemData, setInventoryItemData] = useState([]);
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let id = searchParams.get("id");
  const handleSaveOrSubmit = async (e, status) => {
    e.preventDefault();
    if (!data.received_date) {
      toast.error("Please Enter the Received Date");
      return;
    }
    if (!data.purchase_receive_no) {
      toast.error("Please Enter the Purchase Receive");
      return;
    }
    let info = {
      ...data,
      status,
      received: status === "RECEIVED" ? true : false,
    };
    setLoading(1);
    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${INVENTORY_PURCHASE_ORDER}`,
      data: info,
    };
    await axios(config)
      .then((res) => {
        toast.success("Successfully saved as received");
        navigate(`registrar/PurchaseOrder/view/details/${id}`);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };
  const getDataById = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${INVENTORY_PURCHASE_ORDER}/id/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        // console.log("data by id", res.data);
        const updatedItemsData = res.data?.items_data?.map((item) => {
          return {
            ...item,
            quantity_to_received:
              item?.quantity_to_received == 0
                ? item?.quantity
                : item?.quantity_to_received,
            quantity_received:
              item?.quantity_received == 0
                ? item?.quantity
                : item?.quantity_received,
          };
        });

        setData({
          ...res.data,
          items_data: updatedItemsData,
        });
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };
  const getInventoryItemDetails = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${INVENTORY_ADD_ITEM}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        setInventoryItemData(res.data);
        // console.log("Inventory Item Details", res.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };
  console.log("data", data);
  useEffect(() => {
    getDataById();
    getInventoryItemDetails();
  }, []);
  const handleOnChangeToReceive = (e, index) => {
    const value = e.target.value;
    const updatedData = [...data.items_data];
    if (value > updatedData[index]["quantity"]) {
      toast.error("Received can not be greater than ordered");
      updatedData[index]["quantity_to_received"] = 0;
      setData((prev) => ({ ...prev, items_data: updatedData }));
      return;
    }
    updatedData[index]["quantity_in_transit"] = Math.max(
      parseFloat(updatedData[index]["quantity"] || 0) -
        (parseFloat(value) || 0),
      0
    );
    updatedData[index]["quantity_to_received"] = value;
    updatedData[index]["is_received"] =
      updatedData[index]["quantity"] ==
      updatedData[index]["quantity_to_received"];
    setData((prev) => ({ ...prev, items_data: updatedData }));
  };
  return (
    <div className="PurchaseOrder">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">New Purchase Receive</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card py-4">
                  <div className="card-body">
                    <div className="container">
                      <form>
                        <div className="d-flex flex-column gap-3">
                          <div className="row  mb-3">
                            <div className="col-md-6 h-100 form-group">
                              <label
                                htmlFor="vendor_name"
                                className="form-label"
                              >
                                Vendor Name
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="vendor_name"
                                id="vendor_name"
                                value={data?.vendor_name}
                                onChange={(e) =>
                                  setData((prev) => ({
                                    ...prev,
                                    vendor_name: e.target.value,
                                  }))
                                }
                                disabled
                                placeholder="Vendor Name"
                              />
                            </div>
                            <div className="col-md-6">
                              <label
                                htmlFor="purchase_order"
                                className="form-label"
                              >
                                Purchase Order#{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="purchase_order"
                                id="purchase_order"
                                value={data?.purchase_order}
                                onChange={(e) =>
                                  setData((prev) => ({
                                    ...prev,
                                    purchase_order: e.target.value,
                                  }))
                                }
                                disabled
                                placeholder="Purchase Order "
                              />
                            </div>
                          </div>

                          <div className="row align-items-center mb-3">
                            <div className="col-md-6">
                              <label
                                htmlFor="purchase_receive_no"
                                className="form-label"
                              >
                                Purchase Receive#{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="purchase_receive_no"
                                id="date"
                                value={data?.purchase_receive_no}
                                onChange={(e) =>
                                  setData((prev) => ({
                                    ...prev,
                                    purchase_receive_no: e.target.value,
                                  }))
                                }
                              />
                            </div>
                            <div className="col-md-6">
                              <label
                                htmlFor="received_date"
                                className="form-label"
                              >
                                Received Date{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="date"
                                name="received_date"
                                id="date"
                                value={data?.received_date}
                                onChange={(e) =>
                                  setData((prev) => ({
                                    ...prev,
                                    received_date: e.target.value,
                                  }))
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="table-responsive mt-4">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                {tableHeadData.map((item) => (
                                  <td key={item}>{item}</td>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {data?.items_data?.length > 0 &&
                                data?.items_data?.map((item, i) => (
                                  <tr key={item?.key}>
                                    <td className="align-middle">
                                      {(() => {
                                        let obj = inventoryItemData?.find(
                                          (record) =>
                                            record?.id == item?.itemDetails
                                        );
                                        return (
                                          <div className="d-flex flex-column gap-1">
                                            <span>
                                              <strong> {obj?.name}</strong>
                                            </span>
                                            <span>
                                              sku: {obj?.sku || "N/A"}
                                            </span>
                                            <span>
                                              {obj?.purchaseInfoData
                                                ?.description ||
                                                obj?.salesInfoData?.description}
                                            </span>
                                          </div>
                                        );
                                      })()}
                                    </td>
                                    <td className="align-middle">
                                      {item?.quantity || 0}
                                    </td>
                                    <td className="align-middle">
                                      {item?.quantity_received || 0}
                                    </td>
                                    <td className="align-middle">
                                      {item?.quantity_in_transit || 0}
                                    </td>
                                    <td className="align-middle">
                                      <input
                                        className="form-control"
                                        type="number"
                                        name="quantity_to_received"
                                        id="quantity_to_received"
                                        value={
                                          item?.quantity_to_received ||
                                          item?.quantity
                                        }
                                        min={0}
                                        max={parseFloat(item?.quantity)}
                                        disabled
                                        placeholder="Quantity to Receive"
                                      />
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                        {/* Submit Button */}
                        <div className="row mt-4">
                          <div className="col-md-12 text-right">
                            <button
                              onClick={(e) => handleSaveOrSubmit(e, "RECEIVED")}
                              type="submit"
                              className="btn btn-primary mr-2"
                            >
                              Save as Received
                            </button>

                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate(-1);
                              }}
                              className="btn btn-light"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseReceives;
