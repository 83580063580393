import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { INVENTORY_PURCHASE_ORDER } from "../../../utils/Inventory.apiConst";
import { toast } from "react-toastify";
import { MdOutlineDelete, MdOutlineEdit, MdMailOutline } from "react-icons/md";
import { FaRegFilePdf } from "react-icons/fa";
import { LuFileSymlink } from "react-icons/lu";
import { Select, Switch } from "antd";
import { useReactToPrint } from "react-to-print";
const ViewPurchaseOrders = ({ setLoading }) => {
  const [data, setData] = useState([]);
  const [dataById, setDataById] = useState([]);
  const [inventoryItemData, setInventoryItemData] = useState([]);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState("All");
  const [pdfView, setPdfView] = useState(false);
  const pdfRef = useRef();
  const navigate = useNavigate();
  let { id } = useParams();
  const printPdf = useReactToPrint({
    content: () => pdfRef.current,
    documentTitle: "Purchase Order",
  });
  const handlePrint = () => {
    pdfRef.current.style.display = "block";
    setTimeout(() => {
      printPdf();
      pdfRef.current.style.display = "none";
    }, 1);
  };
  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${INVENTORY_PURCHASE_ORDER}/menu-items`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        console.log("Menu Items Data", res.data.data);
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };
  const getDataById = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${INVENTORY_PURCHASE_ORDER}/id/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        console.log("data by id", res.data);
        setDataById(res.data);
        setInventoryItemData(res.data?.inventoryItemData);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };
  const deletePurchaseOrderById = async () => {
    setLoading(1);
    const config = {
      method: "put",
      url: `${INVENTORY_PURCHASE_ORDER}/delete/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Successfully deleted the Purchase Order");
        navigate("/registrar/PurchaseOrder");
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Error occurred while deleting the data ");
      });
  };
  useEffect(() => {
    getData();
    getDataById();
  }, []);
  useEffect(() => {
    getDataById();
  }, [id]);
  const tableHead = [
    "Items & Description",
    "Ordered",
    "status",
    "Rate",
    "Amount",
  ];

  const tableHeadPrint = [
    "SI. No",
    "Items & Description",
    "Ordered",
    "Rate",
    "Amount",
  ];
  const handleButtonClick = (e) => {
    navigate("/registrar/PurchaseOrder/create");
  };
  return (
    <div className="ViewPurchaseOrders">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12">
                <div className="card ">
                  <div className="card-body">
                    <div>
                      <div className="row">
                        <div className="col-md-3 border-right table-responsive overflow-scroll">
                          <table className="table table-hover table-bordered ">
                            <tbody>
                              <tr>
                                <div className="d-flex pb-3 justify-content-end gap-2 align-items-center">
                                  {/* <Select
                                    className="w-100"
                                    showSearch
                                    optionFilterProp="label"
                                    onChange={(value) =>
                                      setSelectedPurchaseOrder(value)
                                    }
                                    value={selectedPurchaseOrder}
                                    // onSearch={onSearch}
                                    options={[
                                      {
                                        value: "All",
                                        label: "All",
                                      },
                                      {
                                        value: "Draft",
                                        label: "Draft",
                                      },
                                      {
                                        value: "Pending Approval",
                                        label: "Pending Approval",
                                      },
                                      {
                                        value: "Approved",
                                        label: "Approved",
                                      },
                                      {
                                        value: "Issued",
                                        label: "Issued",
                                      },
                                      {
                                        value: "Partially Billed",
                                        label: "Partially Billed",
                                      },
                                      {
                                        value: "Closed",
                                        label: "Closed",
                                      },
                                      {
                                        value: "Cancelled",
                                        label: "Cancelled",
                                      },
                                      {
                                        value: "Partially Received",
                                        label: "Partially Received",
                                      },
                                      {
                                        value: "Received",
                                        label: "Received",
                                      },
                                      {
                                        value: "Pending Received",
                                        label: "Pending Received",
                                      },
                                      {
                                        value: "Dropshiped",
                                        label: "Dropshiped",
                                      },
                                      {
                                        value: "Backorder",
                                        label: "Backorder",
                                      },
                                      {
                                        value: "Unbilled Backorder",
                                        label: "Unbilled Backorder",
                                      },
                                      {
                                        value: "Billed & Not Received",
                                        label: "Billed & Not Received",
                                      },
                                      {
                                        value: "Received & Not Billed",
                                        label: "Received & Not Billed",
                                      },
                                    ]}
                                  /> */}
                                  <button
                                    className="btn btn-primary text-nowrap"
                                    onClick={handleButtonClick}
                                  >
                                    Add +
                                  </button>
                                </div>
                              </tr>
                              {data && data.length > 0
                                ? data.map((item, i) => (
                                    <tr
                                      className={`   ${
                                        id == item?.id ? "bg-light" : ""
                                      }`}
                                      key={i}
                                    >
                                      <td>
                                        <Link
                                          to={`/registrar/PurchaseOrder/view/details/${item?.id}`}
                                          className="d-flex flex-column gap-2 py-3 px-1"
                                          style={{
                                            textDecoration: "none",
                                            color: "inherit",
                                          }}
                                        >
                                          <div className="d-flex justify-content-between align-items-center">
                                            <h6>{item?.vendor_name}</h6>
                                            <h6>Rs. {item?.total}</h6>
                                          </div>
                                          <div className="d-flex justify-content-between align-items-center">
                                            <span>{item?.purchase_order}</span>
                                            <span>{item?.date}</span>
                                          </div>
                                          <span>{item?.status}</span>
                                        </Link>
                                      </td>
                                    </tr>
                                  ))
                                : ""}
                            </tbody>
                          </table>
                        </div>
                        <div className="col-md-9">
                          <h5>{dataById?.purchase_order}</h5>
                          <div className=" d-flex gap-2 align-items-center justify-content-end">
                            {dataById?.status === "DRAFT" ? (
                              <div className="d-flex gap-2  align-items-center">
                                <Link
                                  to={`/registrar/PurchaseOrder/create?id=${dataById?.id}`}
                                  className="btn btn-nex d-flex gap-1 align-items-center"
                                >
                                  <MdOutlineEdit />
                                  Edit
                                </Link>

                                <button
                                  onClick={deletePurchaseOrderById}
                                  className="btn btn-nex d-flex gap-1 align-items-center"
                                >
                                  <MdOutlineDelete />
                                  Delete
                                </button>
                              </div>
                            ) : (
                              ""
                            )}

                            {/* <button className="btn btn-link d-flex gap-1 align-items-center">
                              <MdMailOutline />
                              Send Mail
                            </button>
                            <div
                              className="vr mx-1"
                              style={{
                                height: "24px",
                                borderLeft: "1px solid #000",
                              }}
                            ></div> */}
                            <button
                              onClick={handlePrint}
                              className="btn btn-nex d-flex gap-1 align-items-center"
                            >
                              <FaRegFilePdf />
                              Print
                            </button>

                            {dataById?.status === "ACTIVE" ||
                            dataById?.status === "DRAFT" ? (
                              <Link
                                to={`/registrar/PurchaseReceives?id=${dataById?.id}`}
                                className="btn btn-nex d-flex gap-1 align-items-center"
                              >
                                <LuFileSymlink />
                                Receive
                              </Link>
                            ) : dataById?.status === "RECEIVED" ? (
                              <Link
                                to={`/registrar/PurchaseBill?id=${dataById?.id}`}
                                className="btn btn-nex d-flex gap-1 align-items-center"
                              >
                                <LuFileSymlink />
                                Convert to Bill
                              </Link>
                            ) : dataById?.status === "BILLED" ? (
                              <Link
                                to={`/registrar/RecordPayment?id=${dataById?.id}`}
                                className="btn btn-nex d-flex gap-1 align-items-center"
                              >
                                <LuFileSymlink />
                                Record Payment
                              </Link>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="m-4">
                            <div className="d-flex justify-content-end align-items-center gap-2 mb-4">
                              show PDF view{" "}
                              <Switch
                                onClick={() => setPdfView((prev) => !prev)}
                              />
                            </div>
                            {!pdfView ? (
                              <div className="row p-4">
                                <div className="col-lg-7 col-md-6">
                                  <div className="d-flex flex-column gap-3">
                                    {dataById?.status == "RECEIVED" ? (
                                      <div>
                                        <h4>Purchase Receive</h4>
                                        <div>
                                          <span>Purchase Receive#</span>{" "}
                                          <span>
                                            <strong>
                                              {dataById?.purchase_receive_no}
                                            </strong>
                                          </span>
                                        </div>
                                      </div>
                                    ) : dataById?.status == "BILLED" ? (
                                      <div>
                                        <h4> Bill</h4>
                                        <div>
                                          <span> Bill#</span>{" "}
                                          <span>
                                            <strong>{dataById?.bill}</strong>
                                          </span>
                                        </div>
                                      </div>
                                    ) : dataById?.status == "PAID" ? (
                                      <div>
                                        <h4>Bill</h4>
                                        <div>
                                          <span> Bill#</span>{" "}
                                          <span>
                                            <strong>{dataById?.bill}</strong>
                                          </span>
                                        </div>
                                      </div>
                                    ) : (
                                      <div>
                                        <h4>Purchase Order</h4>
                                        <div>
                                          <span>Purchase Order#</span>{" "}
                                          <span>
                                            <strong>
                                              {dataById?.purchase_order}
                                            </strong>
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                    <div
                                      style={{ width: "fit-content" }}
                                      className="badge badge-secondary"
                                    >
                                      {dataById?.status}
                                    </div>
                                    {dataById?.status === "BILLED" ||
                                    dataById?.status === "PAID" ? (
                                      <div className="row py-3 gap-1">
                                        <div className="col-md-4">
                                          <h6 className="text-uppercase">
                                            Purchase Order
                                          </h6>
                                        </div>
                                        <div className="col-md-6">
                                          {dataById?.purchase_order}
                                        </div>
                                        <div className="col-md-4">
                                          <h6 className="text-uppercase">
                                            Bill#
                                          </h6>
                                        </div>
                                        <div className="col-md-6">
                                          {dataById?.bill}
                                        </div>
                                        <div className="col-md-4">
                                          <h6 className="text-uppercase">
                                            Bill Date
                                          </h6>
                                        </div>
                                        <div className="col-md-6">
                                          {dataById?.bill_date}
                                        </div>
                                        <div className="col-md-4">
                                          <h6 className="text-uppercase">
                                            Bill Due Date
                                          </h6>
                                        </div>
                                        <div className="col-md-6">
                                          {dataById?.bill_due_date || "N/A"}
                                        </div>
                                        <div className="col-md-4">
                                          <h6 className="text-uppercase">
                                            Total
                                          </h6>
                                        </div>
                                        <div className="col-md-6">
                                          {dataById?.total_amount_data?.total}
                                        </div>
                                      </div>
                                    ) : dataById?.status === "RECEIVED" ? (
                                      <div className="row py-3 gap-1">
                                        <div className="col-md-4">
                                          <h6 className="text-uppercase">
                                            Purchase Order
                                          </h6>
                                        </div>
                                        <div className="col-md-6">
                                          {dataById?.purchase_order}
                                        </div>
                                        <div className="col-md-4">
                                          <h6 className="text-uppercase">
                                            Received Date
                                          </h6>
                                        </div>
                                        <div className="col-md-6">
                                          {dataById?.received_date}
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="row py-3 gap-1">
                                        <div className="col-md-4">
                                          <h6 className="text-uppercase">
                                            Reference#
                                          </h6>
                                        </div>
                                        <div className="col-md-6">
                                          {dataById?.reference}
                                        </div>
                                        <div className="col-md-4">
                                          <h6 className="text-uppercase">
                                            Order Date
                                          </h6>
                                        </div>
                                        <div className="col-md-6">
                                          {dataById?.date}
                                        </div>
                                        <div className="col-md-4">
                                          <h6 className="text-uppercase">
                                            Delivery Date
                                          </h6>
                                        </div>
                                        <div className="col-md-6">
                                          {dataById?.delivery_date}
                                        </div>
                                        <div className="col-md-4">
                                          <h6 className="text-uppercase">
                                            Payment Terms
                                          </h6>
                                        </div>
                                        <div className="col-md-6">
                                          {dataById?.payment_terms}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-5 col-md-6">
                                  <div className="d-flex flex-column gap-3">
                                    <div className="mb-3">
                                      <h6 className="text-uppercase">
                                        Vendor Address
                                      </h6>
                                      <span>
                                        <strong>
                                          {dataById?.vendor_address}
                                        </strong>
                                      </span>
                                    </div>
                                    <div className="pt-3">
                                      <h6 className="text-uppercase">
                                        Delivery Address
                                      </h6>
                                      {dataById?.delivery_address}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="table-responsive">
                                    <table className="table table-bordered">
                                      <thead>
                                        {tableHead.map((item) => (
                                          <th key={item}>{item}</th>
                                        ))}
                                      </thead>
                                      <tbody>
                                        {dataById &&
                                          dataById?.items_data?.map(
                                            (item, i) => (
                                              <tr>
                                                <td>
                                                  {(() => {
                                                    let obj =
                                                      inventoryItemData?.find(
                                                        (record) =>
                                                          record.id ==
                                                          item?.itemDetails
                                                      );

                                                    return (
                                                      <div className="d-flex flex-column gap-1">
                                                        <span>{obj?.name}</span>
                                                        <span>
                                                          SKU: {obj?.sku}
                                                        </span>
                                                        <span>
                                                          {obj?.purchaseInfoData
                                                            ?.description ||
                                                            obj?.salesInfoData
                                                              ?.description}
                                                        </span>
                                                      </div>
                                                    );
                                                  })()}
                                                </td>
                                                <td>{item?.quantity}</td>

                                                <td>
                                                  <div className="d-flex flex-column gap-1">
                                                    <span>
                                                      {item?.quantity_received}{" "}
                                                      Received
                                                    </span>
                                                  </div>
                                                </td>
                                                <td>{item?.rate}</td>
                                                <td>{item?.amount}</td>
                                              </tr>
                                            )
                                          )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div
                                  style={{ marginLeft: "auto" }}
                                  className="col-md-6 my-2"
                                >
                                  <div className="row">
                                    <div className="col-md-6 mb-3 d-flex flex-column align-items-end ">
                                      <h6 className="text-uppercase ">
                                        Sub Total
                                      </h6>
                                      <div className="d-flex gap-1">
                                        <span>Total Quantity</span>
                                        <span>
                                          {dataById?.items_data?.length}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-md-6 mb-3 d-flex flex-column align-items-end ">
                                      <strong>
                                        {" "}
                                        {(
                                          dataById?.total_amount_data
                                            ?.subTotal || 0
                                        ).toFixed(2)}
                                      </strong>
                                    </div>
                                    {dataById?.total_amount_data?.discount ? (
                                      <div className="col-md-6 mb-3 d-flex flex-column align-items-end ">
                                        <span>Discount</span>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {dataById?.total_amount_data?.discount ? (
                                      <div className="col-md-6 mb-3 d-flex flex-column align-items-end ">
                                        <p>
                                          {" "}
                                          {
                                            dataById?.total_amount_data
                                              ?.discount
                                          }{" "}
                                          % {`=>`}{" "}
                                          <span className="text-danger">
                                            (-) Rs.
                                            {dataById?.total_amount_data
                                              ?.discount > 0
                                              ? (
                                                  dataById?.total_amount_data
                                                    ?.subTotal /
                                                  dataById?.total_amount_data
                                                    ?.discount
                                                ).toFixed(2)
                                              : 0.0}
                                          </span>
                                        </p>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {dataById?.total_amount_data
                                      ?.selectedTax ? (
                                      <div className="col-md-6 mb-3 text-justify d-flex flex-column align-items-end ">
                                        {
                                          dataById?.total_amount_data
                                            ?.selectedTax
                                        }
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {dataById?.total_amount_data
                                      ?.selectedTax ? (
                                      <div className="col-md-6 text-danger mb-3 d-flex flex-column align-items-end ">
                                        (-) Rs.
                                        {(
                                          dataById?.total_amount_data
                                            ?.selectedTaxAmount || 0
                                        ).toFixed(2)}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {dataById?.total_amount_data
                                      ?.adjustmentAmount ? (
                                      <div className="col-md-6 mb-3 d-flex flex-column align-items-end ">
                                        Adjustment
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {dataById?.total_amount_data
                                      ?.adjustmentAmount ? (
                                      <div className="col-md-6 mb-3 d-flex flex-column align-items-end ">
                                        Rs.{" "}
                                        {
                                          dataById?.total_amount_data
                                            ?.adjustmentAmount
                                        }
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <div
                                      className="bg-secondary w-100 mt-3 mb-2"
                                      style={{ height: "1px" }}
                                    ></div>
                                    <div className="col-md-6 mb-3 d-flex flex-column align-items-end ">
                                      <strong>Total</strong>
                                    </div>
                                    <div className="col-md-6 mb-3 d-flex flex-column align-items-end ">
                                      <strong>
                                        Rs. {dataById?.total_amount_data?.total}
                                      </strong>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="shadow-lg ">
                                <div className="row p-4">
                                  <div className="p-4 w-100">
                                    <table className="table">
                                      <tr>
                                        <td
                                          className="w-100"
                                          style={{ minWidth: "300px" }}
                                        >
                                          <div className="d-flex flex-column gap-3">
                                            <h4>
                                              {dataById?.status == "RECEIVED"
                                                ? "Purchase Receive"
                                                : dataById?.status ==
                                                    "BILLED" ||
                                                  dataById?.status == "PAID"
                                                ? "BILLED"
                                                : "Purchase Order"}
                                            </h4>
                                            {dataById?.status == "RECEIVED" ? (
                                              <table
                                                style={{ maxWidth: "350px" }}
                                                className="table"
                                              >
                                                <tr>
                                                  <td>Purchase Order#</td>
                                                  <td>
                                                    <strong>
                                                      {dataById?.purchase_order}
                                                    </strong>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Purchase Receive#</td>
                                                  <td>
                                                    <strong>
                                                      {
                                                        dataById?.purchase_receive_no
                                                      }
                                                    </strong>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Purchase Receive Date</td>
                                                  <td>
                                                    <strong>
                                                      {dataById?.received_date}
                                                    </strong>
                                                  </td>
                                                </tr>
                                              </table>
                                            ) : dataById?.status == "BILLED" ||
                                              dataById?.status == "PAID" ? (
                                              <table
                                                style={{ maxWidth: "350px" }}
                                                className="table"
                                              >
                                                <tr>
                                                  <td>Purchase Order#</td>
                                                  <td>
                                                    <strong>
                                                      {dataById?.purchase_order}
                                                    </strong>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Bill#</td>
                                                  <td>
                                                    <strong>
                                                      {dataById?.bill}
                                                    </strong>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Bill Date</td>
                                                  <td>
                                                    <strong>
                                                      {dataById?.bill_date}
                                                    </strong>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Bill Due Date</td>
                                                  <td>
                                                    <strong>
                                                      {dataById?.bill_due_date ||
                                                        "N/A"}
                                                    </strong>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td> Payment Terms</td>
                                                  <td>
                                                    {" "}
                                                    <strong>
                                                      {dataById?.payment_terms}
                                                    </strong>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Total</td>
                                                  <td>
                                                    {" "}
                                                    <strong>
                                                      {
                                                        dataById
                                                          ?.total_amount_data
                                                          ?.total
                                                      }
                                                    </strong>
                                                  </td>
                                                </tr>
                                              </table>
                                            ) : (
                                              <table
                                                style={{ maxWidth: "350px" }}
                                                className="table"
                                              >
                                                <tr>
                                                  <td>Purchase Order#</td>
                                                  <td>
                                                    {" "}
                                                    <strong>
                                                      {dataById?.purchase_order}
                                                    </strong>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td> Reference#</td>
                                                  <td>
                                                    {" "}
                                                    <strong>
                                                      {dataById?.reference}
                                                    </strong>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td> Order Date</td>
                                                  <td>
                                                    {" "}
                                                    <strong>
                                                      {dataById?.date}
                                                    </strong>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Delivery Date</td>
                                                  <td>
                                                    {" "}
                                                    <strong>
                                                      {dataById?.delivery_date}
                                                    </strong>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td> Payment Terms</td>
                                                  <td>
                                                    {" "}
                                                    <strong>
                                                      {dataById?.payment_terms}
                                                    </strong>
                                                  </td>
                                                </tr>
                                              </table>
                                            )}
                                          </div>
                                        </td>
                                        <td
                                          className="w-100"
                                          style={{ minWidth: "300px" }}
                                        >
                                          <div className="d-flex flex-column gap-3">
                                            <div className="mb-3">
                                              <h6 className="text-uppercase">
                                                Vendor Address
                                              </h6>
                                              <span>
                                                {dataById?.vendor_address}
                                              </span>
                                            </div>
                                            <div className="pt-3">
                                              <h6 className="text-uppercase">
                                                Delivery Address
                                              </h6>
                                              {dataById?.delivery_address}
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </table>
                                    <div className="table-responsive">
                                      <table className="table table-bordered">
                                        <thead>
                                          {tableHeadPrint.map((item) => (
                                            <th key={item}>{item}</th>
                                          ))}
                                        </thead>
                                        <tbody>
                                          {dataById &&
                                            dataById?.items_data?.map(
                                              (item, i) => (
                                                <tr>
                                                  <td>{i + 1}</td>
                                                  <td>
                                                    {(() => {
                                                      let obj =
                                                        inventoryItemData?.find(
                                                          (record) =>
                                                            record.id ==
                                                            item?.itemDetails
                                                        );

                                                      return (
                                                        <div className="d-flex flex-column gap-1">
                                                          <span>
                                                            {obj?.name}
                                                          </span>
                                                          <span>
                                                            SKU: {obj?.sku}
                                                          </span>
                                                          <span>
                                                            {obj
                                                              ?.purchaseInfoData
                                                              ?.description ||
                                                              obj?.salesInfoData
                                                                ?.description}
                                                          </span>
                                                        </div>
                                                      );
                                                    })()}
                                                  </td>
                                                  <td>{item?.quantity}</td>

                                                  <td>{item?.rate}</td>
                                                  <td>{item?.amount}</td>
                                                </tr>
                                              )
                                            )}
                                        </tbody>
                                      </table>
                                    </div>
                                    <table className="table">
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td style={{ width: "200px" }}>
                                            <h6 className="text-uppercase ">
                                              Sub Total
                                            </h6>
                                            <div className="d-flex gap-1">
                                              <span>Total Items</span>
                                              <span>
                                                {dataById?.items_data?.length}
                                              </span>
                                            </div>
                                          </td>
                                          <td
                                            style={{
                                              width: "150px",
                                              textAlign: "end",
                                            }}
                                          >
                                            <strong>
                                              {" "}
                                              {(
                                                dataById?.total_amount_data
                                                  ?.subTotal || 0
                                              ).toFixed(2)}
                                            </strong>
                                          </td>
                                        </tr>
                                        {dataById?.total_amount_data
                                          ?.discount ? (
                                          <tr>
                                            <td></td>
                                            <td></td>
                                            <td>
                                              Discount (
                                              {(
                                                dataById?.total_amount_data
                                                  ?.discount || 0
                                              ).toFixed(2)}{" "}
                                              %)
                                            </td>
                                            <td
                                              style={{
                                                width: "150px",
                                                textAlign: "end",
                                              }}
                                            >
                                              <p>
                                                {" "}
                                                {/* {
                                      dataById?.total_amount_data?.discount
                                    } % {`=>`}{" "} */}
                                                <span className="text-danger">
                                                  (-){" "}
                                                  {dataById?.total_amount_data
                                                    ?.discount > 0
                                                    ? (
                                                        dataById
                                                          ?.total_amount_data
                                                          ?.subTotal /
                                                        dataById
                                                          ?.total_amount_data
                                                          ?.discount
                                                      ).toFixed(2)
                                                    : 0.0}
                                                </span>
                                              </p>
                                            </td>
                                          </tr>
                                        ) : (
                                          ""
                                        )}
                                        {dataById?.total_amount_data
                                          ?.selectedTax ? (
                                          <tr>
                                            <td></td>
                                            <td></td>
                                            <td>
                                              {" "}
                                              {
                                                dataById?.total_amount_data
                                                  ?.selectedTax
                                              }
                                            </td>
                                            <td
                                              style={{
                                                width: "150px",
                                                textAlign: "end",
                                              }}
                                            >
                                              <p>
                                                {/* {dataById?.total_amount_data?.discount} %{" "}
                                    {`=>`}{" "} */}
                                                <span className="text-danger">
                                                  (-){" "}
                                                  {(
                                                    dataById?.total_amount_data
                                                      ?.selectedTaxAmount || 0
                                                  ).toFixed(1)}
                                                </span>
                                              </p>
                                            </td>
                                          </tr>
                                        ) : (
                                          ""
                                        )}
                                        {dataById?.total_amount_data
                                          ?.adjustmentAmount ? (
                                          <tr>
                                            <td></td>
                                            <td></td>
                                            <td>Adjustment</td>
                                            <td
                                              style={{
                                                width: "150px",
                                                textAlign: "end",
                                              }}
                                            >
                                              {(
                                                dataById?.total_amount_data
                                                  ?.adjustmentAmount || 0
                                              ).toFixed(2)}
                                            </td>
                                          </tr>
                                        ) : (
                                          ""
                                        )}
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td className="border-t border-secondary">
                                            <strong>Total</strong>
                                          </td>
                                          <td
                                            style={{
                                              width: "150px",
                                              textAlign: "end",
                                            }}
                                            className="border-t border-secondary"
                                          >
                                            <strong>
                                              Rs.{" "}
                                              {(
                                                dataById?.total_amount_data
                                                  ?.total || 0
                                              ).toFixed(2)}
                                            </strong>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <div className="d-flex gap-1 align-items-baseline">
                                      <p>Authorized Signature</p>
                                      <div
                                        className="bg-secondary w-25"
                                        style={{ height: "1px" }}
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div
                              ref={pdfRef}
                              style={{ display: "none" }}
                              className="row p-4"
                            >
                              <div className="p-4">
                                <table className="table">
                                  <tr>
                                    <td
                                      className="w-100"
                                      style={{ minWidth: "300px" }}
                                    >
                                      <div className="d-flex flex-column gap-3">
                                        <h4>
                                          {dataById?.status == "RECEIVED"
                                            ? "Purchase Receive"
                                            : dataById?.status == "BILLED" ||
                                              dataById?.status == "PAID"
                                            ? "BILLED"
                                            : "Purchase Order"}
                                        </h4>
                                        {dataById?.status == "RECEIVED" ? (
                                          <table
                                            style={{ maxWidth: "350px" }}
                                            className="table"
                                          >
                                            <tr>
                                              <td>Purchase Order#</td>
                                              <td>
                                                <strong>
                                                  {dataById?.purchase_order}
                                                </strong>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Purchase Receive#</td>
                                              <td>
                                                <strong>
                                                  {
                                                    dataById?.purchase_receive_no
                                                  }
                                                </strong>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Purchase Receive Date</td>
                                              <td>
                                                <strong>
                                                  {dataById?.received_date}
                                                </strong>
                                              </td>
                                            </tr>
                                          </table>
                                        ) : dataById?.status == "BILLED" ||
                                          dataById?.status == "PAID" ? (
                                          <table
                                            style={{ maxWidth: "350px" }}
                                            className="table"
                                          >
                                            <tr>
                                              <td>Purchase Order#</td>
                                              <td>
                                                <strong>
                                                  {dataById?.purchase_order}
                                                </strong>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Bill#</td>
                                              <td>
                                                <strong>
                                                  {dataById?.bill}
                                                </strong>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Bill Date</td>
                                              <td>
                                                <strong>
                                                  {dataById?.bill_date}
                                                </strong>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Bill Due Date</td>
                                              <td>
                                                <strong>
                                                  {dataById?.bill_due_date ||
                                                    "N/A"}
                                                </strong>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td> Payment Terms</td>
                                              <td>
                                                {" "}
                                                <strong>
                                                  {dataById?.payment_terms}
                                                </strong>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Total</td>
                                              <td>
                                                {" "}
                                                <strong>
                                                  {
                                                    dataById?.total_amount_data
                                                      ?.total
                                                  }
                                                </strong>
                                              </td>
                                            </tr>
                                          </table>
                                        ) : (
                                          <table
                                            style={{ maxWidth: "350px" }}
                                            className="table"
                                          >
                                            <tr>
                                              <td>Purchase Order#</td>
                                              <td>
                                                {" "}
                                                <strong>
                                                  {dataById?.purchase_order}
                                                </strong>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td> Reference#</td>
                                              <td>
                                                {" "}
                                                <strong>
                                                  {dataById?.reference}
                                                </strong>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td> Order Date</td>
                                              <td>
                                                {" "}
                                                <strong>
                                                  {dataById?.date}
                                                </strong>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Delivery Date</td>
                                              <td>
                                                {" "}
                                                <strong>
                                                  {dataById?.delivery_date}
                                                </strong>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td> Payment Terms</td>
                                              <td>
                                                {" "}
                                                <strong>
                                                  {dataById?.payment_terms}
                                                </strong>
                                              </td>
                                            </tr>
                                          </table>
                                        )}
                                      </div>
                                    </td>
                                    <td
                                      className="w-100"
                                      style={{ minWidth: "300px" }}
                                    >
                                      <div className="d-flex flex-column gap-3">
                                        <div className="mb-3">
                                          <h6 className="text-uppercase">
                                            Vendor Address
                                          </h6>
                                          <span>
                                            {dataById?.vendor_address}
                                          </span>
                                        </div>
                                        <div className="pt-3">
                                          <h6 className="text-uppercase">
                                            Delivery Address
                                          </h6>
                                          {dataById?.delivery_address}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </table>
                                <div className="table-responsive">
                                  <table className="table table-bordered">
                                    <thead>
                                      {tableHeadPrint.map((item) => (
                                        <th key={item}>{item}</th>
                                      ))}
                                    </thead>
                                    <tbody>
                                      {dataById &&
                                        dataById?.items_data?.map((item, i) => (
                                          <tr>
                                            <td>{i + 1}</td>
                                            <td>
                                              {(() => {
                                                let obj =
                                                  inventoryItemData?.find(
                                                    (record) =>
                                                      record.id ==
                                                      item?.itemDetails
                                                  );

                                                return (
                                                  <div className="d-flex flex-column gap-1">
                                                    <span>{obj?.name}</span>
                                                    <span>SKU: {obj?.sku}</span>
                                                    <span>
                                                      {obj?.purchaseInfoData
                                                        ?.description ||
                                                        obj?.salesInfoData
                                                          ?.description}
                                                    </span>
                                                  </div>
                                                );
                                              })()}
                                            </td>
                                            <td>{item?.quantity}</td>

                                            <td>{item?.rate}</td>
                                            <td>{item?.amount}</td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                                <table className="table">
                                  <tbody>
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td style={{ width: "200px" }}>
                                        <h6 className="text-uppercase ">
                                          Sub Total
                                        </h6>
                                        <div className="d-flex gap-1">
                                          <span>Total Items</span>
                                          <span>
                                            {dataById?.items_data?.length}
                                          </span>
                                        </div>
                                      </td>
                                      <td
                                        style={{
                                          width: "150px",
                                          textAlign: "end",
                                        }}
                                      >
                                        <strong>
                                          {" "}
                                          {(
                                            dataById?.total_amount_data
                                              ?.subTotal || 0
                                          ).toFixed(2)}
                                        </strong>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td>
                                        Discount (
                                        {(
                                          dataById?.total_amount_data
                                            ?.discount || 0
                                        ).toFixed(2)}{" "}
                                        %)
                                      </td>
                                      <td
                                        style={{
                                          width: "150px",
                                          textAlign: "end",
                                        }}
                                      >
                                        <p>
                                          {" "}
                                          {/* {
                                      dataById?.total_amount_data?.discount
                                    } % {`=>`}{" "} */}
                                          <span className="text-danger">
                                            (-){" "}
                                            {dataById?.total_amount_data
                                              ?.discount > 0
                                              ? (
                                                  dataById?.total_amount_data
                                                    ?.subTotal /
                                                  dataById?.total_amount_data
                                                    ?.discount
                                                ).toFixed(2)
                                              : 0.0}
                                          </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td>
                                        {" "}
                                        {
                                          dataById?.total_amount_data
                                            ?.selectedTax
                                        }
                                      </td>
                                      <td
                                        style={{
                                          width: "150px",
                                          textAlign: "end",
                                        }}
                                      >
                                        <p>
                                          {/* {dataById?.total_amount_data?.discount} %{" "}
                                    {`=>`}{" "} */}
                                          <span className="text-danger">
                                            (-){" "}
                                            {(
                                              dataById?.total_amount_data
                                                ?.selectedTaxAmount || 0
                                            ).toFixed(1)}
                                          </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td>Adjustment</td>
                                      <td
                                        style={{
                                          width: "150px",
                                          textAlign: "end",
                                        }}
                                      >
                                        {(
                                          dataById?.total_amount_data
                                            ?.adjustmentAmount || 0
                                        ).toFixed(2)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td className="border-t border-secondary">
                                        <strong>Total</strong>
                                      </td>
                                      <td
                                        style={{
                                          width: "150px",
                                          textAlign: "end",
                                        }}
                                        className="border-t border-secondary"
                                      >
                                        <strong>
                                          Rs.{" "}
                                          {(
                                            dataById?.total_amount_data
                                              ?.total || 0
                                          ).toFixed(2)}
                                        </strong>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div className="d-flex gap-1 align-items-baseline">
                                  <p>Authorized Signature</p>
                                  <div
                                    className="bg-secondary w-25"
                                    style={{ height: "1px" }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPurchaseOrders;
