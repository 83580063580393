import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  INVENTORY_ADD_ITEM,
  INVENTORY_ITEM_CATEGORY,
  INVENTORY_ITEM_SUPPLIER,
  INVENTORY_OPTIONS,
} from "../../../utils/Inventory.apiConst";
import { Button, Popconfirm, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import AddItemModel from "./AddItemModel";
function AddItem({ setLoading, collegeId }) {
  const [manufacturerOption, setManufacturerOption] = useState([]);
  const [preferredVendorOptions, setPreferredVendorOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [data, setData] = useState([]);
  const [itemCategoryOptions, setItemCategoryOptions] = useState([]);
  const [editData, setEditData] = useState({});
  const [type, setType] = useState("add");

  const getdata = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${INVENTORY_ADD_ITEM}?college_id=${collegeId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        setData(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };
  const getVendorOptions = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${INVENTORY_ITEM_SUPPLIER}?college_id=${collegeId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        let response = res.data.data;
        let options = response.map((item) => {
          return { value: item?.id, label: item?.item_supplier_name };
        });
        setPreferredVendorOptions(options);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };
  const getOptions = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${INVENTORY_OPTIONS}?college_id=${collegeId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        setBrandOptions(res.data.brands);
        setManufacturerOption(res.data.manufacturers);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };

  const handleDelete = (id) => {
    const config = {
      method: "put",
      url: `${INVENTORY_ADD_ITEM}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        status: "INACTIVE",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        getdata();
        toast.success("Successfully deleted the Item");
      })
      .catch((err) => {
        setLoading(0);
        getdata();
        toast.error("Something Went Wrong");
      });
  };
  const getCategoryOptions = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${INVENTORY_ITEM_CATEGORY}?college_id=${collegeId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        let response = res.data.data;
        setItemCategoryOptions(
          response.map((item) => {
            return { value: item?.id, label: item?.item_category_name };
          })
        );
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };
  useEffect(() => {
    getdata();
    getOptions();
    getCategoryOptions();
    getVendorOptions();
  }, []);

  return (
    <div ClassName="AddItem">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <AddItemModel
              // itemData={itemData}
              brandOptions={brandOptions}
              preferredVendorOptions={preferredVendorOptions}
              manufacturerOption={manufacturerOption}
              setPreferredVendorOptions={setPreferredVendorOptions}
              setManufacturerOption={setManufacturerOption}
              setBrandOptions={setBrandOptions}
              type={type}
              setLoading={setLoading}
              collegeId={collegeId}
              getdata={getdata}
              editData={editData}
              itemCategoryOptions={itemCategoryOptions}
            />
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Add Item </h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Inventory</a>
                      </li>
                      <li className="breadcrumb-item active">Add Item</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <h4 className="card-title">Item Lists</h4>
                      </div>
                      <div className="col-md-8 ">
                        <span className="float-right">
                          <button
                            type="button"
                            class="btn btn-primary"
                            data-toggle="modal"
                            data-target="#AddItemModal"
                            onClick={() => setType("add")}
                          >
                            + Add Item
                          </button>
                        </span>
                      </div>
                    </div>
                    <hr />
                    <table
                      id=""
                      className="table table-bordered dt-responsive nowrap table-hover "
                      style={{
                        borderCollapse: "collapse",
                        borderSpacing: 0,
                        width: "100%",
                      }}
                    >
                      <thead>
                        <tr className="text-center">
                          <th> Sl. No.</th>
                          <th>Item Name</th>
                          <th>Category</th>
                          <th>SKU</th>
                          <th>Stock On Hand</th>

                          <th>Amount</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.length > 0
                          ? data.map((item, i) => (
                              <tr className="text-center " key={i}>
                                <td className="align-middle">{i + 1}</td>
                                <td className="align-middle">{item?.name}</td>
                                <td className="align-middle">
                                  {
                                    itemCategoryOptions.find(
                                      (record) => record.value == item?.category
                                    )?.label
                                  }
                                </td>
                                <td className="align-middle">{item?.sku}</td>
                                <td className="align-middle">{0.0}</td>

                                <td className="align-middle">
                                  {item?.purchaseInfoData?.costPrice}
                                </td>
                                <td className="align-middle">
                                  <div className="d-flex justify-content-center gap-2">
                                    <Tooltip title="Edit Item">
                                      <Button
                                        primary
                                        data-toggle="modal"
                                        data-target="#AddItemModal"
                                        onClick={() => {
                                          setType("edit");
                                          setEditData(item);
                                        }}
                                        icon={<EditOutlined />}
                                      ></Button>
                                    </Tooltip>
                                    <Tooltip title="Delete Item">
                                      <Popconfirm
                                        title="Delete the item"
                                        description="Are you sure to delete this item?"
                                        onConfirm={() => handleDelete(item?.id)}
                                        // onCancel={cancel}
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <Button
                                          danger
                                          icon={<DeleteOutlined />}
                                        ></Button>
                                      </Popconfirm>
                                    </Tooltip>
                                  </div>
                                </td>
                              </tr>
                            ))
                          : ""}
                      </tbody>
                    </table>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            {/* end row */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddItem;
