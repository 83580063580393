import React, { useEffect, useState } from "react";
import {
  Divider,
  Input,
  InputNumber,
  message,
  Select,
  Space,
  Tooltip,
  Upload,
} from "antd";
import { InboxOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import CreatableSelect from "react-select/creatable";
import { MdDeleteOutline } from "react-icons/md";
import { INVENTORY_ADD_ITEM } from "../../../utils/Inventory.apiConst";
import { toast } from "react-toastify";
import axios from "axios";
import { getFileUrl } from "../../../Helpers/Helpers";
import { ASSET_EMPLOYEE_DOCUMENT } from "../../../utils/AssetsReferenceTypes";
const { Dragger } = Upload;
const { Option } = Select;
const unitOptions = [
  { value: "box", label: "Box - box" },
  { value: "cm", label: "CMS - cm" },
  { value: "dz", label: "DOZ - dz" },
  { value: "ft", label: "FTS - ft" },
  { value: "gms", label: "GMS - g" },
  { value: "kg", label: "KGS - kg" },
  { value: "km", label: "KME - km" },
  { value: "lb", label: "LBS - lb" },
  { value: "mb", label: "MBS - mb" },
  { value: "mg", label: "MGS - mg" },
  { value: "mlt", label: "MLT - mlt" },
  { value: "mtr", label: "MTR - m" },
  { value: "pcs", label: "PCS - pcs" },
];
const salesInformationAccountOptions = [
  { value: "Discounts", label: "Discounts" },
  { value: "General Income", label: "General Income" },
  { value: "Interest Income", label: "Interest Income" },
  { value: "Late Fee Income", label: "Late Fee Income" },
  { value: "Other Charges", label: "Other Charges" },
  { value: "Sales", label: "Sales" },
  { value: "Shipping Charges", label: "Shipping Charges" },
];
const AddItemModel = ({
  brandOptions,
  preferredVendorOptions,
  manufacturerOption,
  setBrandOptions,
  setManufacturerOption,
  setLoading,
  collegeId,
  getdata,
  type,
  editData,
  itemCategoryOptions,
}) => {
  const [itemData, setItemData] = useState({
    id: "",
    type: "Goods",
    name: "",
    sku: "",
    unit: "",
    dimensionUnit: "cm",
    length: 0,
    height: 0,
    width: 0,
    weight: 0,
    weightUnit: "kg",
    manufacturer: "",
    returnable: true,
    brand: "",
    upc: "",
    mpn: "",
    ean: "",
    isbn: "",
    salesInformation: false,
    purchaseInformation: true,
    documents: "",
    documentsName: "",
    category: "",
    status: "ACTIVE",
  });

  const [salesInfoData, setSalesInfoData] = useState({
    sellingPrice: "",
    account: "",
    description: "",
  });
  const [purchaseInfoData, setPurchaseInfoData] = useState({
    costPrice: "",
    account: "",
    description: "",
    preferredVendor: "",
  });
  const clearData = () => {
    setItemData({
      id: "",
      type: "Goods",
      name: "",
      sku: "",
      unit: "",
      dimensionUnit: "cm",
      length: 0,
      height: 0,
      width: 0,
      weight: 0,
      weightUnit: "kg",
      manufacturer: "",
      returnable: true,
      brand: "",
      upc: "",
      mpn: "",
      ean: "",
      isbn: "",
      salesInformation: false,
      purchaseInformation: true,
      documents: "",
      documentsName: "",
      category: "",
      status: "ACTIVE",
    });
    setPurchaseInfoData({
      costPrice: "",
      account: "",
      description: "",
      preferredVendor: "",
    });
    setSalesInfoData({
      sellingPrice: "",
      account: "",
      description: "",
    });
  };
  const handleOnChangeSalesInfo = (e) => {
    setSalesInfoData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleOnChangePurchaseInfo = (e) => {
    setPurchaseInfoData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const handleOnChange = (e) => {
    setItemData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleSubmitData = (e) => {
    e.preventDefault();
    if (!itemData.name) return toast.error("Please add item name");
    setLoading(1);
    console.log({
      ...itemData,
      college_id: collegeId,
      salesInfoData,
      purchaseInfoData,
    });
    const config = {
      method: "post",
      url: `${INVENTORY_ADD_ITEM}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...itemData,
        college_id: collegeId,
        salesInfoData,
        purchaseInfoData,
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        toast.success(res.data.message);
        clearData();
        getdata();
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err.response.data.message);
      });
  };
  // console.log("type", type);
  // console.log("itemData", itemData);
  // console.log("purchaseInfoData", purchaseInfoData);
  // console.log("salesInfoData", salesInfoData);
  // console.log("type", type);
  // console.log("editData", editData);
  const handleDocumentDelete = (e) => {
    e.preventDefault();
    setItemData((prev) => ({ ...prev, documents: "", documentsName: "" }));
  };
  const selectDimensions = (
    <Select
      value={itemData?.dimensionUnit}
      onChange={(value) =>
        setItemData((prev) => ({ ...prev, dimensionUnit: value }))
      }
    >
      <Option value="cm">cm</Option>
      <Option value="in">in</Option>
    </Select>
  );
  const selectWeight = (
    <Select
      value={itemData?.weightUnit}
      onChange={(value) =>
        setItemData((prev) => ({ ...prev, weightUnit: value }))
      }
    >
      <Option value="cm">cm</Option>
      <Option value="g">g</Option>
      <Option value="lb">lb</Option>
      <Option value="oz">oz</Option>
    </Select>
  );
  const addAttachment = async (file) => {
    try {
      const uploadedUrl = await getFileUrl(
        ASSET_EMPLOYEE_DOCUMENT,
        `Resume`,
        file.name.split(".").pop(),
        setLoading,
        file
      );

      if (uploadedUrl) {
        setItemData((prev) => ({
          ...prev,
          documents: uploadedUrl, // Update documents with the uploaded file URL
          documentsName: file.name,
        }));
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("File upload failed.");
    }
  };
  const uploadProps = {
    name: "file",
    multiple: false, // Restrict to a single file
    beforeUpload: async (file) => {
      if (file.size > 2097152) {
        message.error("File size should not exceed 2MB.");
        return Upload.LIST_IGNORE;
      }
      await addAttachment(file);
      return Upload.LIST_IGNORE; // Prevent default upload behavior
    },
  };
  const createOption = (label) => ({
    label,
    value: label,
  });
  const handleCreateManufacturer = (inputValue) => {
    const newOption = createOption(inputValue);
    setManufacturerOption((prev) => [...prev, newOption]);
    setItemData((prev) => ({ ...prev, manufacturer: newOption }));
  };

  const handleCreateBrand = (inputValue) => {
    const newOption = createOption(inputValue);
    setBrandOptions((prev) => [...prev, newOption]);
    setItemData((prev) => ({ ...prev, brand: newOption }));
  };

  useEffect(() => {
    if (type === "add") {
      clearData();
    }
    if (type === "edit") {
      setItemData({ ...editData });
      setSalesInfoData({ ...editData?.salesInfoData });
      setPurchaseInfoData({ ...editData?.purchaseInfoData });
    }
  }, [type, editData]);
  return (
    <div
      class="modal fade"
      id="AddItemModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="AddItemModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="AddItemModal">
              {type === "add" ? "Add Item" : "Edit Item"}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div className="row">
              <div className="col-xl-12">
                <form>
                  <div className=" p-2">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="row">
                          <div className="col-md-6">
                            {" "}
                            <div>
                              <div className="mb-2">
                                <label>
                                  <strong>Type</strong>
                                </label>
                              </div>
                              <div className="d-flex gap-3 mb-1">
                                <div className="d-flex gap-1">
                                  <input
                                    type="radio"
                                    name="type"
                                    id="goods"
                                    value={"Goods"}
                                    checked={itemData?.type == "Goods"}
                                    onChange={handleOnChange}
                                  />
                                  <label className="m-0" htmlFor="goods">
                                    Goods
                                  </label>
                                </div>
                                <div className="d-flex gap-1">
                                  <input
                                    type="radio"
                                    name="type"
                                    id="services"
                                    value={"Services"}
                                    checked={itemData?.type == "Services"}
                                    onChange={handleOnChange}
                                  />
                                  <label className="m-0" htmlFor="services">
                                    Services
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="name" className="form-label">
                                Name <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                name="name"
                                id="name"
                                value={itemData?.name}
                                className="form-control"
                                onChange={handleOnChange}
                                placeholder="Enter the name"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-4">
                              <div className="form-group">
                                <label htmlFor="sku" className="form-label">
                                  <span className="mr-2">SKU</span>
                                  <Tooltip
                                    className="text-danger"
                                    placement="right"
                                    title={"The Stock Keeping Unit of the item"}
                                  >
                                    <QuestionCircleOutlined />
                                  </Tooltip>
                                </label>

                                <input
                                  type="text"
                                  name="sku"
                                  id="sku"
                                  value={itemData?.sku}
                                  className="form-control"
                                  onChange={handleOnChange}
                                  placeholder="Enter the SKU"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="unit" className="form-label ">
                                <span className={`mr-2`}>
                                  Unit <span className="text-danger">*</span>
                                </span>
                                <Tooltip
                                  className="text-danger"
                                  placement="right"
                                  title={
                                    "The Item will be measured in terms of this unit (eg.: kg, dozen)"
                                  }
                                >
                                  <QuestionCircleOutlined />
                                </Tooltip>
                              </label>
                              <select
                                className="form-control"
                                name="unit"
                                id="unit"
                                value={itemData?.unit}
                                placeholder="Select the Unit"
                                onChange={handleOnChange}
                              >
                                <option value="" disabled>
                                  Select Unit
                                </option>
                                {unitOptions.map((item) => (
                                  <option key={item?.value} value={item?.value}>
                                    {item?.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="d-flex gap-1">
                              <input
                                type="checkbox"
                                name="returnable"
                                id="returnable"
                                checked={itemData?.returnable}
                                onChange={() =>
                                  setItemData((prev) => ({
                                    ...prev,
                                    returnable: !prev.returnable,
                                  }))
                                }
                              />
                              <label className="mb-0" htmlFor="returnable">
                                Returnable Item
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-4">
                              <div className="form-group">
                                <label
                                  htmlFor="category"
                                  className="form-label"
                                >
                                  <span className="mr-2">Category</span>
                                </label>
                                <select
                                  className="form-control"
                                  name="category"
                                  id="category"
                                  value={itemData?.category}
                                  placeholder="Select the Category"
                                  onChange={handleOnChange}
                                >
                                  <option value="" disabled>
                                    Select the Category
                                  </option>
                                  {itemCategoryOptions?.map((item) => (
                                    <option
                                      key={item?.label}
                                      value={item?.value}
                                    >
                                      {item?.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 d-flex flex-column gap-2">
                        <Dragger {...uploadProps}>
                          <div className="p-2">
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                              Click or drag file to this area to upload
                            </p>
                            <p className="ant-upload-hint">
                              File size of the file should not exceed more than
                              2 MB.
                            </p>
                          </div>
                        </Dragger>
                        <div className="text-center">
                          {itemData?.documents && itemData?.documentsName ? (
                            <div className="d-flex justify-content-between">
                              <a
                                href={itemData?.documents}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {itemData?.documentsName}
                              </a>
                              <button
                                className="btn btn-sm btn-danger"
                                onClick={handleDocumentDelete}
                              >
                                <MdDeleteOutline />
                              </button>
                            </div>
                          ) : (
                            <p>No document uploaded yet.</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <Divider />
                    <div className="row">
                      <div className="col-md-4 mb-2 ">
                        <div className="form-group d-flex flex-column gap-1">
                          <label htmlFor="dimensions">
                            <div>
                              <span>Dimensions</span>{" "}
                              <span>(Length x Width x height)</span>
                            </div>
                          </label>

                          <Space.Compact size="large">
                            <Input
                              type="number"
                              id="dimensions"
                              min={0}
                              placeholder="length"
                              name="length"
                              value={itemData?.length}
                              onChange={handleOnChange}
                            />
                            <Input
                              type="number"
                              min={0}
                              placeholder="width"
                              name="width"
                              value={itemData?.width}
                              onChange={handleOnChange}
                            />
                            <Input
                              type="number"
                              style={{ minWidth: "130px" }}
                              min={0}
                              placeholder="height"
                              addonAfter={selectDimensions}
                              name="height"
                              value={itemData?.height}
                              onChange={handleOnChange}
                            />
                          </Space.Compact>
                        </div>
                      </div>
                      <div className="col-md-4 mb-2">
                        <div className=" d-flex flex-column gap-1">
                          <label htmlFor="weight">Weight</label>

                          <Space.Compact size="large">
                            <Input
                              type="number"
                              min={0}
                              id="weight"
                              placeholder="weight"
                              name="weight"
                              value={itemData?.weight}
                              onChange={handleOnChange}
                              addonAfter={selectWeight}
                            />
                          </Space.Compact>
                        </div>
                      </div>
                      <div className="col-md-4 mb-2">
                        <div className="form-group d-flex flex-column gap-1">
                          <label htmlFor="manufacturer">Manufacturer</label>
                          <CreatableSelect
                            isClearable
                            onChange={(newValue) =>
                              setItemData((prev) => ({
                                ...prev,
                                manufacturer: newValue,
                              }))
                            }
                            onCreateOption={handleCreateManufacturer}
                            options={manufacturerOption}
                            value={itemData?.manufacturer}
                            placeholder="Select Manufacturer"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 mb-2">
                        <div className="form-group d-flex flex-column gap-1">
                          <label htmlFor="manufacturer">Brand</label>
                          <CreatableSelect
                            isClearable
                            onChange={(newValue) =>
                              setItemData((prev) => ({
                                ...prev,
                                brand: newValue,
                              }))
                            }
                            onCreateOption={handleCreateBrand}
                            options={brandOptions}
                            value={itemData?.brand}
                            placeholder="Select Brand"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="d-flex flex-column gap-4">
                          <div className="form-group">
                            <label htmlFor="upc" className="form-label">
                              <span className="mr-2">UPC</span>
                              <Tooltip
                                className="text-danger"
                                placement="right"
                                title={
                                  "Twelve digit unique number associated with the bar code (Universal Product Code)"
                                }
                              >
                                <QuestionCircleOutlined />
                              </Tooltip>
                            </label>

                            <input
                              type="number"
                              name="upc"
                              id="upc"
                              value={itemData?.upc}
                              className="form-control"
                              onChange={handleOnChange}
                              placeholder="Enter the UPC"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="d-flex flex-column gap-4">
                          <div className="form-group">
                            <label htmlFor="mpn" className="form-label">
                              <span className="mr-2">MPN</span>
                              <Tooltip
                                className="text-danger"
                                placement="right"
                                title={
                                  "Manufacturing Part Number, Unambiguously identifies a part design"
                                }
                              >
                                <QuestionCircleOutlined />
                              </Tooltip>
                            </label>

                            <input
                              name="mpn"
                              id="mpn"
                              value={itemData?.mpn}
                              className="form-control"
                              onChange={handleOnChange}
                              placeholder="Enter the MPN"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="d-flex flex-column gap-4">
                          <div className="form-group">
                            <label htmlFor="ean" className="form-label">
                              <span className="mr-2">EAN</span>
                              <Tooltip
                                className="text-danger"
                                placement="right"
                                title={
                                  "Thirteen digit unique number (International Article Number)"
                                }
                              >
                                <QuestionCircleOutlined />
                              </Tooltip>
                            </label>

                            <input
                              type="number"
                              name="ean"
                              id="ean"
                              value={itemData?.ean}
                              className="form-control"
                              onChange={handleOnChange}
                              placeholder="Enter the EAN"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="d-flex flex-column gap-4">
                          <div className="form-group">
                            <label htmlFor="isbn" className="form-label">
                              <span className="mr-2">ISBN</span>
                              <Tooltip
                                className="text-danger"
                                placement="right"
                                title={
                                  "Thirteen digit unique commercial book identifier (International Standard Book Number)"
                                }
                              >
                                <QuestionCircleOutlined />
                              </Tooltip>
                            </label>

                            <input
                              name="isbn"
                              id="isbn"
                              value={itemData?.isbn}
                              className="form-control"
                              onChange={handleOnChange}
                              placeholder="Enter the ISBN"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <Divider />
                    <div className="row">
                      <div className="col-md-6">
                        <div class="d-flex gap-2 align-items-center mb-4">
                          <input
                            type="checkbox"
                            value={itemData?.purchaseInformation}
                            name="purchaseInformation"
                            id="purchaseInformation"
                            checked={itemData?.purchaseInformation}
                            onChange={() =>
                              setItemData((prev) => ({
                                ...prev,
                                purchaseInformation: !prev.purchaseInformation,
                              }))
                            }
                          />
                          <label className="mb-0" for="purchaseInformation">
                            Purchase Information
                          </label>
                        </div>

                        <div className="row">
                          <div className="col-md-4 mb-3">
                            <div className=" d-flex flex-column gap-1">
                              <label htmlFor="costPrice">Cost Price </label>

                              <Input
                                size="large"
                                min={0}
                                type="number"
                                id="costPrice"
                                placeholder="Enter Cost Price"
                                name="costPrice"
                                value={purchaseInfoData?.costPrice}
                                onChange={handleOnChangePurchaseInfo}
                                addonBefore={"INR"}
                                disabled={!itemData?.purchaseInformation}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 mb-3">
                            <div className=" d-flex flex-column gap-1">
                              <label htmlFor="PurchaseAccount">Account</label>

                              <select
                                name="account"
                                id="PurchaseAccount"
                                className="form-control"
                                onChange={handleOnChangePurchaseInfo}
                                value={purchaseInfoData?.account}
                                disabled={!itemData?.purchaseInformation}
                                placeholder="Select an Account"
                              >
                                <option value="" disabled>
                                  Select an Account
                                </option>
                                {salesInformationAccountOptions?.map((item) => (
                                  <option key={item?.value} value={item?.value}>
                                    {item?.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4 mb-3">
                            <div className=" d-flex flex-column gap-1">
                              <label htmlFor="preferredVendor">
                                Preferred Vendor
                              </label>

                              {/* <CreatableSelect
                                className="text-nowrap"
                                isClearable
                                onChange={(newValue) =>
                                  setPurchaseInfoData((prev) => ({
                                    ...prev,
                                    preferredVendor: newValue,
                                  }))
                                }
                                onCreateOption={handleCreatePreferredVendor}
                                options={preferredVendorOptions}
                                value={purchaseInfoData?.preferredVendor}
                                placeholder="Select Preferred Vendor"
                                isDisabled={!itemData?.purchaseInformation}
                              /> */}
                              <select
                                name="preferredVendor"
                                id="preferredVendor"
                                className="form-control"
                                onChange={(e) =>
                                  setPurchaseInfoData((prev) => ({
                                    ...prev,
                                    preferredVendor: e.target.value,
                                  }))
                                }
                                value={purchaseInfoData?.preferredVendor}
                                disabled={!itemData?.purchaseInformation}
                                placeholder="Select Preferred Vendor"
                              >
                                <option value="" disabled>
                                  Select Preferred Vendor
                                </option>
                                {preferredVendorOptions?.map((item) => (
                                  <option key={item?.value} value={item?.value}>
                                    {item?.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12 mb-3">
                            <div className="form-group d-flex flex-column gap-1">
                              <label
                                className="form-label"
                                htmlFor="purchaseDescription"
                              >
                                Description
                              </label>

                              <textarea
                                className="form-control"
                                rows={3}
                                name="description"
                                id="purchaseDescription"
                                value={purchaseInfoData?.description}
                                onChange={handleOnChangePurchaseInfo}
                                disabled={!itemData?.purchaseInformation}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div class="d-flex gap-2 align-items-center mb-4">
                          <input
                            type="checkbox"
                            value={itemData?.salesInformation}
                            name="salesInformation"
                            id="salesInformation"
                            checked={itemData?.salesInformation}
                            onChange={() =>
                              setItemData((prev) => ({
                                ...prev,
                                salesInformation: !prev.salesInformation,
                              }))
                            }
                          />
                          <label className="mb-0" for="salesInformation">
                            Sales Information
                          </label>
                        </div>

                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <div className=" d-flex flex-column gap-1">
                              <label htmlFor="sellingPrice">
                                Selling Price
                              </label>

                              <Input
                                size="large"
                                min={0}
                                type="number"
                                id="sellingPrice"
                                placeholder="Enter Selling Price"
                                name="sellingPrice"
                                value={salesInfoData?.sellingPrice}
                                onChange={handleOnChangeSalesInfo}
                                addonBefore={"INR"}
                                disabled={!itemData?.salesInformation}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <div className=" d-flex flex-column gap-1">
                              <label htmlFor="account">Account</label>

                              <select
                                name="account"
                                id="account"
                                className="form-control"
                                onChange={handleOnChangeSalesInfo}
                                value={salesInfoData?.account}
                                disabled={!itemData?.salesInformation}
                                placeholder="Select an Account"
                              >
                                <option value="" disabled>
                                  Select an Account
                                </option>
                                {salesInformationAccountOptions?.map((item) => (
                                  <option key={item?.value} value={item?.value}>
                                    {item?.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12 mb-3">
                            <div className="form-group d-flex flex-column gap-1">
                              <label
                                className="form-label"
                                htmlFor="description"
                              >
                                Description
                              </label>

                              <textarea
                                className="form-control"
                                rows={3}
                                name="description"
                                id="description"
                                value={salesInfoData?.description}
                                onChange={handleOnChangeSalesInfo}
                                disabled={!itemData?.salesInformation}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <button
                    className="btn btn-nex btn-rounded float-right  "
                    type="submit"
                    name="submit"
                    onClick={(e) => handleSubmitData(e)}
                  >
                    <i className="fa fa-save" aria-hidden="true" /> Save
                  </button> */}
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              onClick={(e) => {
                // clearData();
                handleSubmitData(e);
              }}
            >
              {type === "add" ? "Save" : "Save Changes"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddItemModel;
