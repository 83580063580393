import axios from "axios";
import { NAAC } from "../apiConstants";
import { toast } from "react-toastify";

const getIsNAACAuthorOrReviewer = async (emp_id, setIsAuthor) => {
  const config = {
    method: "get",
    url: `${NAAC}/user-role/${emp_id}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  await axios(config)
    .then((res) => {
      console.log("role", res?.data);
      setIsAuthor(res?.data);
    })
    .catch((err) => {
      console.log("err", err);
      toast.error(err?.response?.data?.message || "Something went wrong");
    });
};
export default getIsNAACAuthorOrReviewer;
