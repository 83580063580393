import React, { useState, useContext, useMemo, useEffect } from "react";
import {
  HolderOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  Input,
  InputNumber,
  Button,
  Table,
  Select,
  Space,
  Radio,
  Upload,
} from "antd";
import { INVENTORY_ADD_ITEM } from "../../../utils/Inventory.apiConst";
import axios from "axios";
import { toast } from "react-toastify";

const RowContext = React.createContext({});
const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{ cursor: "move" }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const DraggableRow = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };

  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners,
    }),
    [setActivatorNodeRef, listeners]
  );

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

const DraggableTable = ({
  dataSource,
  setDataSource,
  tcsOrTds,
  setTcsOrTds,
  formData,
  setFormData,
  collegeId,
  setLoading,
  customerNotes,
  setCustomerNotes,
}) => {
  const [itemOptions, setItemOptions] = useState([]);
  // console.log("itemOptions", itemOptions);
  const columns = [
    {
      key: "sort",
      align: "center",
      width: 80,
      render: () => <DragHandle />,
    },
    {
      title: "Item Details",
      dataIndex: "itemDetails",
      render: (_, record, index) => (
        <Select
          className="w-100"
          style={{ minWidth: "150px" }}
          showSearch
          placeholder="Select an Item"
          optionFilterProp="label"
          onChange={(value) => {
            const updatedData = [...dataSource];
            updatedData[index]["itemDetails"] = value;
            setDataSource(updatedData);
            getItemDataById(value, index);
          }}
          options={itemOptions}
          value={dataSource[index].itemDetails}
          disabled
        />
      ),
    },
    {
      title: "Account",
      dataIndex: "account",
      render: (_, record, index) => {
        return (
          <Select
            className="w-100"
            style={{ minWidth: "150px" }}
            showSearch
            placeholder="Select a person"
            optionFilterProp="label"
            onChange={(value) => {
              const updatedData = [...dataSource];
              updatedData[index]["account"] = value;
              setDataSource(updatedData);
            }}
            value={record?.account}
            disabled
            options={[
              { value: "Discounts", label: "Discounts" },
              { value: "General Income", label: "General Income" },
              { value: "Interest Income", label: "Interest Income" },
              { value: "Late Fee Income", label: "Late Fee Income" },
              { value: "Other Charges", label: "Other Charges" },
              { value: "Sales", label: "Sales" },
              { value: "Shipping Charges", label: "Shipping Charges" },
            ]}
          />
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      render: (_, record, index) => (
        <Input
          placeholder="Enter Quantity"
          type="number"
          value={record.quantity}
          min={1}
          onChange={(e) => handleInputChange(e, index, "quantity")}
          disabled
        />
      ),
    },
    {
      title: "Rate",
      dataIndex: "rate",
      render: (_, record, index) => (
        <Input
          placeholder="Enter Rate"
          type="number"
          value={record?.rate}
          min={0}
          onChange={(e) => handleInputChange(e, index, "rate")}
          disabled
        />
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (_, record, index) => {
        let quantity = parseFloat(record?.quantity);
        let rate = parseFloat(record?.rate);
        return (quantity * rate || 0).toFixed(2);
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record, index) => (
        <Button
          icon={<DeleteOutlined />}
          onClick={() => handleDeleteRow(index)}
          danger
        />
      ),
    },
  ];

  const handleOnChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };
  const handleInputChange = (e, index, field) => {
    const updatedData = [...dataSource];
    updatedData[index][field] = e.target.value;
    const quantity = parseFloat(updatedData[index].quantity) || 1;
    const rate = parseFloat(updatedData[index].rate) || 0;
    updatedData[index].amount = (quantity * rate).toFixed(2);
    setDataSource(updatedData);
  };

  const handleDeleteRow = (index) => {
    setDataSource((prev) => prev.filter((_, i) => i !== index));
  };

  const handleAddRow = () => {
    setDataSource((prev) => [
      ...prev,
      {
        key: Date.now().toString(),
        itemDetails: "",
        account: "",
        quantity: 1.0,
        rate: 0.0,
        amount: 0.0,
      },
    ]);
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record.key === active?.id
        );
        const overIndex = prevState.findIndex(
          (record) => record.key === over?.id
        );
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };

  const getItemData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${INVENTORY_ADD_ITEM}?college_id=${collegeId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        let response = res.data.map((item) => {
          return { label: item?.name, value: item?.id };
        });

        setItemOptions(response);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };
  // console.log("dataSource", dataSource);
  const getItemDataById = async (id, index) => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${INVENTORY_ADD_ITEM}/id/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then((res) => {
        setLoading(0);
        // console.log("fetched data", res.data);
        let response = res.data;
        let account =
          response?.purchaseInfoData?.account ||
          response?.salesInfoData?.account;
        let rate =
          response?.purchaseInfoData?.costPrice ||
          response?.salesInfoData?.sellingPrice;
        let amount = (
          dataSource[index]?.quantity * parseFloat(rate) || 0
        ).toFixed(2);
        let newData = {
          account,
          rate,
          amount,
        };
        // console.log("updatedData", newData);

        setDataSource((prevData) => {
          const updatedData = [...prevData];
          updatedData[index] = { ...updatedData[index], ...newData };
          return updatedData;
        });
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };
  let subTotal = dataSource?.reduce(
    (acc, curr) => acc + parseFloat(curr?.amount) || 0,
    0
  );
  // console.log("subTotal", subTotal);
  useEffect(() => {
    getItemData();
  }, []);
  // useEffect(() => {
  //   setFormData((prev) => ({ ...prev, subTotal: subTotal, total: subTotal }));
  //   let discountAmount = 0;
  //   if (formData?.percentageOrRs === "percentage") {
  //     discountAmount = (subTotal * formData?.discount) / 100 || 0;
  //   } else {
  //     discountAmount = formData?.discount || 0;
  //   }
  //   const extractPercentage = (label) => {
  //     const match = label.match(/\[(\d+(\.\d+)?)%\]/);
  //     return match ? parseFloat(match[1]) : 0;
  //   };

  //   const taxPercentage = formData?.selectedTax
  //     ? extractPercentage(formData?.selectedTax)
  //     : 0;

  //   const taxAmount = (formData?.total * taxPercentage) / 100 || 0;
  //   const adjustmentAmount = parseFloat(formData?.adjustmentAmount || 0);

  //   const discountedTotal = Math.max(
  //     formData?.subTotal - discountAmount + adjustmentAmount - taxAmount,
  //     0
  //   );
  //   console.log("taxPercentage", taxPercentage);
  //   console.log("taxAmount", taxAmount);
  //   console.log("formData?.total", formData?.total);
  //   console.log({
  //     subTotal,
  //     discountedTotal,
  //     taxAmount,
  //     taxPercentage,
  //     discountAmount,
  //     total: formData?.total,
  //   });
  //   setFormData((prev) => ({ ...prev, selectedTaxAmount: taxAmount }));
  //   setFormData((prev) => ({ ...prev, total: discountedTotal }));
  //   setFormData((prev) => ({ ...prev, discountAmount: discountAmount }));
  // }, [
  //   subTotal,
  //   formData?.discount,
  //   formData?.percentageOrRs,
  //   tcsOrTds,
  //   formData?.selectedTax,
  //   formData?.adjustmentAmount,
  // ]);
  console.log("formData", formData);
  useEffect(() => {
    if (formData?.total == 0) {
      setFormData((prev) => ({ ...prev, subTotal: subTotal, total: subTotal }));
    }
    setFormData((prev) => ({ ...prev, subTotal: subTotal }));
    let discountAmount = 0;
    if (formData?.percentageOrRs === "percentage") {
      discountAmount = (subTotal * formData?.discount) / 100 || 0;
    } else {
      discountAmount = formData?.discount || 0;
    }

    const discountedTotal = Math.max(
      formData?.subTotal -
        discountAmount -
        formData?.selectedTaxAmount +
        formData?.adjustmentAmount,
      0
    );

    setFormData((prev) => ({
      ...prev,
      total: discountedTotal,
      discountAmount: discountAmount,
    }));
  }, [subTotal, formData?.discount, formData?.percentageOrRs]);
  useEffect(() => {
    const extractPercentage = (label) => {
      const match = label.match(/\[(\d+(\.\d+)?)%\]/);
      return match ? parseFloat(match[1]) : 0;
    };

    const taxPercentage = formData?.selectedTax
      ? extractPercentage(formData?.selectedTax)
      : 0;

    const taxAmount =
      ((formData?.subTotal - formData?.discountAmount) * taxPercentage) / 100 ||
      0;

    const discountedTotal = Math.max(
      formData?.subTotal -
        formData?.discountAmount -
        taxAmount +
        formData?.adjustmentAmount,
      0
    );
    setFormData((prev) => ({
      ...prev,
      total: discountedTotal,
      selectedTaxAmount: taxAmount,
    }));
  }, [subTotal, tcsOrTds, formData?.selectedTax, formData?.discountAmount]);
  useEffect(() => {
    const adjustmentAmount = parseFloat(formData?.adjustmentAmount || 0);
    const discountedTotal =
      formData?.subTotal -
      formData?.selectedTaxAmount -
      formData?.discountAmount +
      adjustmentAmount;
    setFormData((prev) => ({
      ...prev,
      total: discountedTotal,
    }));
  }, [formData?.adjustmentAmount]);

  const handleAdjustmentChange = (value) => {
    setFormData((prev) => ({ ...prev, adjustmentAmount: value }));
  };

  return (
    <div>
      {dataSource ? (
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            items={dataSource?.map((i) => i.key)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              rowKey="key"
              rowClassName="editable-row"
              components={{
                body: {
                  row: DraggableRow,
                },
              }}
              columns={columns}
              dataSource={dataSource}
              pagination={false}
            />
          </SortableContext>
        </DndContext>
      ) : (
        ""
      )}
      <div className="row my-4 ">
        <div className="col-md-6 d-flex flex-column justify-content-between">
          <Button
            style={{ width: "fit-content" }}
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddRow}
          >
            Add Row
          </Button>
          <div className="form-group">
            <label className="form-label" htmlFor="customerNotes">
              Customer Notes
            </label>
            <textarea
              placeholder="Will be displayed on purchase order"
              className="form-control w-100"
              value={customerNotes}
              onChange={(e) => setCustomerNotes(e.target.value)}
              name="customerNotes"
              id="customerNotes"
              rows={3}
            ></textarea>
          </div>
        </div>

        <div className="col-md-6 p-3 card bg-light d-flex flex-column gap-2">
          <div className="d-flex justify-content-between">
            <p>
              <strong>Sub Total</strong>
            </p>
            <p>
              <strong>{subTotal ? subTotal.toFixed(2) : "0.00"}</strong>
            </p>
          </div>
          <div className="row">
            <div className="col-md-4">
              <p>Discount</p>
            </div>
            <div className="col-md-4">
              <Space.Compact>
                <InputNumber
                  min={0}
                  className="w-100"
                  value={formData?.discount}
                  onChange={(value) => handleOnChange("discount", value || 0)}
                  disabled
                />
                <Select
                  value={formData?.percentageOrRs}
                  onChange={(value) => handleOnChange("percentageOrRs", value)}
                  options={[
                    { value: "percentage", label: "%" },
                    { value: "Rs", label: "Rs" },
                  ]}
                  disabled
                />
              </Space.Compact>
            </div>
            <div className="col-md-4 d-flex justify-content-end">
              <p>-{(formData?.discountAmount || 0).toFixed(2)}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Radio.Group
                onChange={(e) => setTcsOrTds(e.target.value)}
                value={tcsOrTds}
              >
                <Radio value={"TDS"}>TDS</Radio>
                <Radio value={"TCS"}>TCS</Radio>
              </Radio.Group>
            </div>
            <div className="col-md-4">
              <Select
                className="w-100"
                placeholder="Select a Tax"
                value={formData?.selectedTax}
                onChange={(value) => {
                  handleOnChange("selectedTax", value);
                }}
                disabled
                options={[
                  {
                    value: "Commission or Brokerage [2%]",
                    label: "Commission or Brokerage [2%]",
                  },
                  {
                    value: "Commission or Brokerage (Reduced) [3.75%]",
                    label: "Commission or Brokerage (Reduced) [3.75%]",
                  },
                  { value: "Dividend [10%]", label: "Dividend [10%]" },
                  {
                    value: "Dividend (Reduced) [7.5%]",
                    label: "Dividend (Reduced) [7.5%]",
                  },
                  {
                    value: "Other interest than securities [10%]",
                    label: "Other interest than securities [10%]",
                  },
                  {
                    value: "Other interest than securities (Reduced) [7.5%]",
                    label: "Other interest than securities (Reduced) [7.5%]",
                  },
                  {
                    value: "Payment of contractors for others [2%]",
                    label: "Payment of contractors for others [2%]",
                  },
                  {
                    value: "Payment of contractors for others (Reduced) [1.5%]",
                    label: "Payment of contractors for others (Reduced) [1.5%]",
                  },
                ]}
              />
            </div>
            <div className="col-md-4 d-flex justify-content-end">
              <p>- {`₹ ${(formData?.selectedTaxAmount || 0).toFixed(2)}`}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Input
                placeholder="Enter Quantity"
                defaultValue={"Adjustment"}
                type="text"
                disabled
              />
            </div>
            <div className="col-md-4">
              <InputNumber
                className="w-100"
                value={formData?.adjustmentAmount || 0}
                onChange={handleAdjustmentChange}
                min={0}
                disabled
              />
            </div>
            <div className="col-md-4 d-flex justify-content-end">
              <p>+ {`₹ ${(formData?.adjustmentAmount || 0).toFixed(2)}`}</p>
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <p>
              <strong>Total</strong>
            </p>
            <p>
              <strong>{(formData?.total || 0).toFixed(2)}</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DraggableTable;
