import {
  CaretDownOutlined,
  CodeOutlined,
  DownloadOutlined,
  MoreOutlined,
  ReloadOutlined,
  SettingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, message, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { RiFileTransferLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import {
  INVENTORY_ITEM_SUPPLIER,
  INVENTORY_PURCHASE_ORDER,
} from "../../../utils/Inventory.apiConst";
import axios from "axios";
import { toast } from "react-toastify";
const PurchaseOrder = ({ setLoading }) => {
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState("All");
  const [vendorName, setVendorName] = useState([]);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const navigate = useNavigate();
  const handleButtonClick = (e) => {
    navigate("create");
  };
  const handleMenuClick = (e) => {
    let sortedData = [...data]; // Create a shallow copy of the data array to avoid mutating the original

    switch (e.key) {
      case "vendorName":
        sortedData.sort((a, b) =>
          a.vendor_name.localeCompare(b.vendor_name, undefined, {
            sensitivity: "base",
          })
        );
        break;
      case "createdTime":
        sortedData.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );
        break;
      case "date":
        sortedData.sort((a, b) => new Date(a.date) - new Date(b.date));
        break;
      case "purchaseOrder":
        sortedData.sort((a, b) =>
          a.PurchaseOrder.localeCompare(b.PurchaseOrder, undefined, {
            sensitivity: "base",
          })
        );
        break;
      case "amount":
        sortedData.sort(
          (a, b) => a?.total_amount_data?.total - b?.total_amount_data?.total
        );
        break;
      case "deliveryDate":
        sortedData.sort(
          (a, b) => new Date(a.delivery_date) - new Date(b.delivery_date)
        );
        break;
      case "lastModifiedTime":
        sortedData.sort(
          (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
        );
        break;
      default:
        return;
    }

    setData(sortedData); // Update the state with the sorted data
  };

  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${INVENTORY_PURCHASE_ORDER}?status=${selectedPurchaseOrder}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        // console.log("Purchase Order Data", res.data.data);
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };
  const deleteData = async (ids) => {
    setLoading(1);
    const config = {
      method: "put",
      url: `${INVENTORY_PURCHASE_ORDER}/delete`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: ids,
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        console.log("Purchase Order Deleted", res.data);
        toast.success(
          res.data.message || "Successfully deleted the Purchase Order"
        );
        getData();
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Purchase Order#",
      dataIndex: "purchase_order",
    },
    {
      title: "Reference",
      dataIndex: "reference",
    },
    {
      title: "Vendor Name",
      dataIndex: "vendor_name",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Received",
      dataIndex: "received",
      render: (_, record, index) =>
        record.received === 1 ? "Received" : "Not Yet Received",
    },
    {
      title: "Billed",
      dataIndex: "billed",
      render: (_, record, index) =>
        record.billed === 1 ? "Billed" : "Not Yet Billed",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (_, record, index) => (
        <div>{record?.total_amount_data?.total}</div>
      ),
    },
    {
      title: "Delivery Date",
      dataIndex: "delivery_date",
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };
  // console.log("setSelectedRowKeys", selectedRowKeys);
  const handleDelete = () => {
    // const newData = data.filter((item) => !selectedRowKeys.includes(item.id));
    // console.log("newData", newData);
    deleteData(selectedRowKeys);
    // setData(newData);
    setSelectedRowKeys([]); // Clear selection after deletion
  };

  const itemsMore = [
    {
      key: "1",
      label: "Sort buy",
      children: [
        {
          key: "createdTime",
          label: "Created Time",
        },
        {
          key: "date",
          label: "Date",
        },
        {
          key: "purchaseOrder",
          label: "Purchase Order",
        },
        {
          key: "vendorName",
          label: "Vendor Name",
        },
        {
          key: "amount",
          label: "Amount",
        },
        {
          key: "deliveryDate",
          label: "Delivery Date",
        },
        {
          key: "lastModifiedTime",
          label: "Last Modified Time",
        },
      ],
    },
    {
      type: "divider",
    },
    {
      key: "importPurchaseOrders",
      icon: <DownloadOutlined />,
      label: "Import Purchase Orders",
    },
    {
      key: "exportPurchaseOrders",
      icon: <UploadOutlined />,
      label: "Export Purchase Orders",
    },
    {
      type: "divider",
    },
    {
      key: "importPurchaseReceives",
      icon: <DownloadOutlined />,
      label: "Import Purchase Receives",
    },
    {
      key: "exportPurchaseReceives",
      icon: <UploadOutlined />,
      label: "Export Purchase Receives",
    },
    {
      type: "divider",
    },
    {
      key: "exportCurrentView",
      icon: <UploadOutlined />,
      label: "Export Current View",
    },
    {
      type: "divider",
    },
    {
      key: "preferences",
      icon: <SettingOutlined />,
      label: "Preferences",
    },
    {
      key: "manageCustomFields",
      icon: <CodeOutlined />,
      label: "Manage Custom Fields",
    },
    { type: "divider" },
    {
      key: "refreshList",
      icon: <ReloadOutlined />,
      label: "Refresh List",
    },
  ];
  const items = [
    {
      label: "Create New Purchase Order",
      key: "1",
    },
    {
      label: "Create New Purchase Order(Pre GST)",
      key: "2",
    },
  ];
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  useEffect(() => {
    getData();
  }, [selectedPurchaseOrder]);
  return (
    <div className="PurchaseOrder">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0"> Purchase Order </h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row d-flex border-bottom justify-content-between">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="validationCustom01"
                          >
                            <h6 className="mb-0">
                              {selectedPurchaseOrder} Purchase Order
                            </h6>
                          </label>
                          <Select
                            className="w-100"
                            showSearch
                            optionFilterProp="label"
                            onChange={(value) =>
                              setSelectedPurchaseOrder(value)
                            }
                            value={selectedPurchaseOrder}
                            // onSearch={onSearch}
                            options={[
                              {
                                value: "All",
                                label: "All",
                              },
                              {
                                value: "DRAFT",
                                label: "Draft",
                              },
                              // {
                              //   value: "Pending Approval",
                              //   label: "Pending Approval",
                              // },
                              // {
                              //   value: "Approved",
                              //   label: "Approved",
                              // },
                              // {
                              //   value: "Issued",
                              //   label: "Issued",
                              // },
                              // {
                              //   value: "Partially Billed",
                              //   label: "Partially Billed",
                              // },
                              // {
                              //   value: "Closed",
                              //   label: "Closed",
                              // },
                              // {
                              //   value: "Cancelled",
                              //   label: "Cancelled",
                              // },
                              // {
                              //   value: "Partially Received",
                              //   label: "Partially Received",
                              // },
                              {
                                value: "RECEIVED",
                                label: "Received",
                              },
                              {
                                value: "BILLED",
                                label: "Billed",
                              },
                              {
                                value: "PAID",
                                label: "Paid",
                              },
                              // {
                              //   value: "Pending Received",
                              //   label: "Pending Received",
                              // },
                              // {
                              //   value: "Dropshiped",
                              //   label: "Dropshiped",
                              // },
                              // {
                              //   value: "Backorder",
                              //   label: "Backorder",
                              // },
                              // {
                              //   value: "Unbilled Backorder",
                              //   label: "Unbilled Backorder",
                              // },
                              // {
                              //   value: "Billed & Not Received",
                              //   label: "Billed & Not Received",
                              // },
                              // {
                              //   value: "Received & Not Billed",
                              //   label: "Received & Not Billed",
                              // },
                            ]}
                          />
                        </div>
                      </div>
                      <div className="d-flex gap-2 align-items-center px-2">
                        <button className="btn text-nowrap btn-outline-primary d-flex gap-2 align-items-center">
                          <RiFileTransferLine />
                          In Transit Receives
                        </button>

                        {/* <Dropdown.Button
                          type="primary"
                          menu={menuProps}
                          onClick={handleButtonClick}
                          icon={<CaretDownOutlined />}
                        >
                          + New
                        </Dropdown.Button> */}
                        <button
                          className="btn btn-primary text-nowrap"
                          // data-toggle="modal"
                          // data-target="#ModalPurchaseOrder"
                          onClick={handleButtonClick}
                        >
                          Add +
                        </button>
                        <Dropdown
                          menu={{
                            items: itemsMore,
                            onClick: handleMenuClick,
                          }}
                        >
                          <a
                            className="btn btn-outline-secondary"
                            onClick={(e) => e.preventDefault()}
                          >
                            <MoreOutlined />
                          </a>
                        </Dropdown>
                      </div>
                    </div>

                    {data && data.length > 0 ? (
                      <div className="d-flex flex-column gap-2 my-2">
                        <div>
                          <Button
                            type="primary"
                            danger
                            onClick={handleDelete}
                            disabled={selectedRowKeys.length === 0}
                          >
                            Delete Selected
                          </Button>
                        </div>
                        <Table
                          rowSelection={{
                            type: "checkbox",
                            ...rowSelection,
                          }}
                          columns={columns}
                          dataSource={data}
                          rowKey={(record) => record.id}
                          onRow={(record) => {
                            return {
                              onClick: () => {
                                navigate(`view/details/${record.id}`);
                              },
                            };
                          }}
                        />
                      </div>
                    ) : (
                      <div className="d-flex my-5 p-5 flex-column justify-content-center align-items-center">
                        <h3>Start Managing Your Purchase Activities!</h3>
                        <p>
                          Create, customize, and send professional Purchase
                          Orders to your vendors.
                        </p>
                        <button
                          onClick={handleButtonClick}
                          className="btn btn-primary"
                        >
                          CREATE NEW PURCHASE ORDER
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseOrder;
