import React, { useEffect, useState } from "react";
import {
  INVENTORY_ADD_ITEM,
  INVENTORY_PURCHASE_ORDER,
} from "../../../utils/Inventory.apiConst";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import CreatableSelect from "react-select/creatable";
import { getFileUrl } from "../../../Helpers/Helpers";
import { ASSET_EMPLOYEE_DOCUMENT } from "../../../utils/AssetsReferenceTypes";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
const tableHeadData = [
  "Items & Description",
  "Ordered",
  "Received",
  "In Transit",
  "Quantity to Receive	",
];
const paymentOptions = [
  { label: "Online", value: "Online" },
  { label: "Cash", value: "Cash" },
  { label: "Bank Remittance", value: "Bank Remittance" },
  { label: "Bank Transfer", value: "Bank Transfer" },
  { label: "Check", value: "Check" },
  { label: "Credit Card", value: "Credit Card" },
  { label: "Stripe", value: "Stripe" },
];
const paidThroughOpt = [
  { label: "Bank", value: "Bank", disabled: true },
  { label: "Bank Account 1", value: "Bank Account 1", disabled: false },
  { label: "Bank Account 2", value: "Bank Account 2", disabled: false },
  {
    label: "Accounts Receivable",
    value: "Accounts Receivable",
    disabled: true,
  },
  {
    label: "Accounts Receivable1",
    value: "Accounts Receivable1",
    disabled: false,
  },
  {
    label: "Other Current Asset",
    value: "Other Current Asset",
    disabled: true,
  },
  { label: "Advance Tax", value: "Advance Tax", disabled: false },
  {
    label: "Other Current Liability",
    value: "Other Current Liability",
    disabled: true,
  },
  { label: "Reimbursements", value: "Reimbursements", disabled: false },
];
const RecordPayment = ({ setLoading, college_id }) => {
  const [data, setData] = useState([]);
  const [paymentModeOption, setPaymentModeOption] = useState(paymentOptions);
  const [paidThroughOption, setPaidThroughOption] = useState(paidThroughOpt);
  const [inventoryItemData, setInventoryItemData] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileUrl, setFileUrl] = useState("");
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let id = searchParams.get("id");
  const handleSaveOrSubmit = async (e, status) => {
    e.preventDefault();
    if (!data.payment_mode) {
      toast.error("Please Enter the Payment Mode");
      return;
    }
    if (!data.payment_date) {
      toast.error("Please Enter the Payment Date");
      return;
    }
    let info = {
      ...data,
      status,
      bill_attachment: fileUrl,
      bill_attachment_name: uploadedFile,
    };
    setLoading(1);
    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${INVENTORY_PURCHASE_ORDER}`,
      data: info,
    };
    await axios(config)
      .then((res) => {
        toast.success("Successfully saved the Bill");
        navigate(`registrar/PurchaseOrder/view/details/${id}`);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(0);
      });
  };
  const getDataById = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${INVENTORY_PURCHASE_ORDER}/id/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        // console.log("data by id", res.data);
        setData(res.data);
        setUploadedFile(res.data?.bill_attachment_name);
        setFileUrl(res.data?.bill_attachment);
        // navigate(-1);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };
  const createOption = (label) => ({
    label,
    value: label,
  });
  const handleCreatePaymentMode = (inputValue) => {
    const newOption = createOption(inputValue);
    setPaymentModeOption((prev) => [...prev, newOption]);
    setData((prev) => ({ ...prev, payment_mode: newOption }));
  };
  const getInventoryItemDetails = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${INVENTORY_ADD_ITEM}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        setInventoryItemData(res.data);
        // console.log("Inventory Item Details", res.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };
  const extractFileName = (fileName) => {
    const lastDotIndex = fileName.lastIndexOf(".");
    return fileName.substring(0, lastDotIndex); // Removes the extension
  };
  const addAttachment = async (file) => {
    try {
      const fileLink = await getFileUrl(
        ASSET_EMPLOYEE_DOCUMENT,
        "Resume",
        extractFileName(file?.name),
        setLoading,
        file
      );
      setFileUrl(fileLink);
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("File upload failed");
    }
  };
  const beforeUpload = (file) => {
    setUploadedFile(file?.name);
    addAttachment(file);
    return false;
  };
  const handleDeleteDoc = (e) => {
    e.preventDefault();
    setFileUrl("");
    setUploadedFile("");
  };
  useEffect(() => {
    getDataById();
    getInventoryItemDetails();
  }, []);

  console.log("data", data);
  return (
    <div className="PurchaseOrder">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">
                    Payment for {data?.purchase_receive_no}
                  </h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card py-4">
                  <div className="card-body">
                    <div className="container">
                      <form>
                        <div className="d-flex flex-column gap-3">
                          <div className="row  mb-3">
                            <div className="col-md-4 h-100 form-group">
                              <label
                                htmlFor="payment_made"
                                className="form-label"
                              >
                                Payment Made
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="payment_made"
                                id="payment_made"
                                value={
                                  data?.payment_made ||
                                  data?.total_amount_data?.total
                                }
                                onChange={(e) =>
                                  setData((prev) => ({
                                    ...prev,
                                    payment_made: e.target.value,
                                  }))
                                }
                                placeholder="Vendor Name"
                                disabled
                              />
                            </div>
                            <div className="col-md-4 h-100 form-group">
                              <label
                                htmlFor="bank_charges"
                                className="form-label"
                              >
                                Bank Charges (if any)
                              </label>
                              <input
                                className="form-control"
                                type="number"
                                name="bank_charges"
                                id="bank_charges"
                                value={data?.bank_charges}
                                onChange={(e) =>
                                  setData((prev) => ({
                                    ...prev,
                                    bank_charges: e.target.value,
                                  }))
                                }
                                placeholder="Bank Charges"
                              />
                            </div>
                            <div className="col-md-4">
                              <label
                                htmlFor="payment_mode"
                                className="form-label"
                              >
                                Payment Mode#{" "}
                                <span className="text-danger">*</span>
                              </label>

                              <CreatableSelect
                                isClearable
                                onChange={(newValue) =>
                                  setData((prev) => ({
                                    ...prev,
                                    payment_mode: newValue,
                                  }))
                                }
                                onCreateOption={handleCreatePaymentMode}
                                options={paymentModeOption}
                                value={data?.payment_mode}
                                placeholder="Select Payment Mode"
                              />
                              {console.log("payment_mode", data?.payment_mode)}
                              {console.log(
                                "payment_mode type",
                                typeof data?.payment_mode
                              )}
                            </div>
                            <div className="col-md-4 h-100 form-group">
                              <label
                                htmlFor="payment_date"
                                className="form-label"
                              >
                                Payment Date{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="date"
                                name="payment_date"
                                id="payment_date"
                                value={data?.payment_date}
                                onChange={(e) =>
                                  setData((prev) => ({
                                    ...prev,
                                    payment_date: e.target.value,
                                  }))
                                }
                                placeholder="Select Payment Date"
                              />
                            </div>
                            <div className="col-md-4 h-100 form-group">
                              <label
                                htmlFor="payment_no"
                                className="form-label"
                              >
                                Payment# <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="number"
                                name="payment_no"
                                id="payment_no"
                                value={data?.payment_no}
                                onChange={(e) =>
                                  setData((prev) => ({
                                    ...prev,
                                    payment_no: e.target.value,
                                  }))
                                }
                                placeholder="Bill Payment Number"
                              />
                            </div>

                            <div className="col-md-4 h-100 form-group">
                              <label
                                htmlFor="paid_through"
                                className="form-label"
                              >
                                Paid Through
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-control"
                                name="paid_through"
                                id="paid_through"
                                value={data?.paid_through || ""}
                                onChange={(e) =>
                                  setData((prev) => ({
                                    ...prev,
                                    paid_through: e.target.value,
                                  }))
                                }
                              >
                                <option value="" disabled>
                                  Select Paid Through
                                </option>
                                {paidThroughOption?.map((item) => (
                                  <option
                                    className={`${
                                      item.disabled
                                        ? " text-dark font-weight-bold"
                                        : ""
                                    }`}
                                    disabled={item.disabled}
                                    value={item?.value}
                                    key={item?.value}
                                  >
                                    {item?.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-md-4">
                              <label
                                htmlFor="bill_reference"
                                className="form-label"
                              >
                                Reference#{" "}
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="bill_reference"
                                id="date"
                                value={data?.bill_reference}
                                onChange={(e) =>
                                  setData((prev) => ({
                                    ...prev,
                                    bill_reference: e.target.value,
                                  }))
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <div className="form-group d-flex flex-column">
                                <label
                                  className="form-label"
                                  htmlFor="termsAndConditions"
                                >
                                  Attach File to Bill
                                </label>
                                {!fileUrl ? (
                                  <Upload
                                    accept=".pdf,.png,.jpg,.jpeg"
                                    beforeUpload={beforeUpload}
                                  >
                                    <Button icon={<UploadOutlined />}>
                                      Click to Upload
                                    </Button>
                                  </Upload>
                                ) : (
                                  <div className="d-flex align-items-center gap-4">
                                    <a
                                      href={fileUrl}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {uploadedFile}
                                    </a>
                                    <button
                                      onClick={handleDeleteDoc}
                                      className="btn btn-sm  btn-danger"
                                    >
                                      <DeleteOutlined />
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-8">
                              <label
                                htmlFor="bill_notes"
                                className="form-label"
                              >
                                Notes
                              </label>
                              <textarea
                                className="form-control"
                                name="bill_notes"
                                id="date"
                                value={data?.bill_notes}
                                onChange={(e) =>
                                  setData((prev) => ({
                                    ...prev,
                                    bill_notes: e.target.value,
                                  }))
                                }
                              />
                            </div>
                          </div>
                        </div>
                        {/* Submit Button */}
                        <div className="row mt-4">
                          <div className="col-md-12 text-right">
                            <buttons
                              onClick={(e) => handleSaveOrSubmit(e, "PAID")}
                              type="submit"
                              className="btn btn-primary mr-2"
                            >
                              Save
                            </buttons>

                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate(-1);
                              }}
                              className="btn btn-light"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecordPayment;
