import React, { useEffect, useState } from "react";

// Components
import Stepper from "../../../Components/Stepper.jsx";
import StatusBadges from "../../../Components/StatusBadge.jsx";
import profile_stepper_data from "./profile-stepper-data";
import { IoMdSend } from "react-icons/io";

export default function Profile({ children, data }) {
  return (
    <>
      <div className="shadow p-3 border my-2 mb-5 bg-white">
        <div className="d-flex flex-column gap-4">
          <div className="d-flex flex-column my-2 gap-2 align-items-start align-items-md-center justify-content-between flex-md-row">
            <h3>Profile of the University</h3>
            <StatusBadges />
          </div>
          {/* <div className="d-flex justify-content-end gap-2 text-sm">
            <div className="d-flex align-items-center my-3">
              <span className="fw-semibold">Status of the Application</span>
              <div className="bg-success px-4 py-1 text-white rounded-pill shadow">
                Approved
              </div>
            </div>
          </div> */}

          <div className="table-responsive">
            <Stepper data={profile_stepper_data(data)} />
          </div>

          {children}
        </div>
      </div>
      <div className="shadow p-3 pb-4 border bg-white mt-3 mb-5">
        <p>Team discussion</p>
        <form className="d-flex align-items-center gap-2">
          <div className="flex-grow-1">
            <input
              type="text"
              className="form-control border-0 border-bottom border-secondary focus-border-primary"
              placeholder="Write your message here"
            />
            <hr />
          </div>
          <button
            type="submit"
            className="btn btn-primary px-3 py-2 d-flex align-items-center"
          >
            <IoMdSend />
          </button>
        </form>
      </div>
    </>
  );
}
